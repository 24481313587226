import item from './item';

const state = {};

const getters = {};

const mutations = {};

const actions = {};

const modules = {
    item,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules,
};
