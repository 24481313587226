import { mainClient } from '~/network';

const state = {
    getAddressSuggestResult: null,
    getAddressSuggestIsLoading: false,
    getAddressSuggestError: null,
};

const getters = {};

const mutations = {};

const actions = {
    async getAddressSuggest({ state }, payload = {}) {
        if (state.getAddressSuggestIsLoading) {
            return;
        }

        state.getAddressSuggestIsLoading = true;

        const { data, error, status } = await mainClient.dadata.getAddressSuggest(payload);
        if (error) {
            console.error(error, status);
            console.error('Error above relates to store/dadata getAddressSuggest action');
            state.getAddressSuggestResult = null;
            state.getAddressSuggestError = error;
        } else if (data) {
            state.getAddressSuggestResult = data;
            state.getAddressSuggestError = null;
        }

        state.getAddressSuggestIsLoading = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
