<template>
    <a class="common-product-slider"
        v-bind:href="product.url"
        v-on:click.prevent="productClickHandler"
        v-bind:class="{ _sale: isSale }"
        ref="root"
        >
        <div class="common-product-slider__observer">
            <common-intersection-observer
                v-on:intersect="init"
            />
        </div>
        <div class="common-product-slider__slider swiper-container"
            ref="slider"
            >
            <div class="swiper-wrapper common-product-slider__slider--wrapper"
                v-bind:class="{ _animated: isAnimationReady }"
                >
                <div class="swiper-slide common-product-slider__slider-item swiper-lazy"
                    v-for="(slide, index) in slides"
                    v-bind:key="index"
                    v-bind:data-background="slide.image"
                    >
                    <div class="common-product-slider__slider-item-video"
                        v-if="slide.video && activeSlideIndex === index"
                        >
                        <ui-video
                            v-bind:video="slide.video"
                            v-bind:poster="slide.image"
                            v-bind:aspect-ratio="slide.aspectRatio"
                        />
                    </div>
                    <div class="common-product-slider__slider-item-loader">
                        <ui-loader />
                    </div>
                </div>
            </div>
        </div>
        <div class="common-product-slider__info">
            <div class="common-product-slider__info-badges"
                v-if="badges.length > 0"
                >
                <div class="common-product-slider__info-badges-item"
                    v-for="(badge, index) in badges"
                    v-bind:key="index"
                    >
                    <icon v-bind:name="'badge-' + badge" height="1em" width="auto" original />
                </div>
            </div>
            <div class="common-product-slider__info-collection"
                v-if="product.collection"
                v-text="product.collection"
            />
            <div class="common-product-slider__info-model"
                v-if="product.model && product.color"
                v-text="product.model"
            />
            <div class="common-product-slider__info-botline">
                <div class="common-product-slider__info-botline-color"
                    v-text="product.color ? product.color : product.model"
                />
                <div class="common-product-slider__info-botline-price">
                    <div class="common-product-slider__info-botline-price-old"
                        v-if="product.price.oldText"
                        v-text="product.price.oldText"
                    />
                    <div class="common-product-slider__info-botline-price-current"
                        v-text="product.price.text"
                    />
                </div>
            </div>
        </div>
    </a>
</template>

<script>
import Swiper from 'swiper';
import config from '~/config';
import utils from '~/utils';
import { productClick } from '~/utils/ecommerce';
import { ImpressionController } from '~/utils/analytics';

export default {
    name: 'common-product-slider',
    props: {
        product: {
            type: Object,
        },
        isFocused: {
            type: Boolean,
            default: false,
        },
        ecommerceListData: {
            type: Object,
        },
    },
    data: () => ({
        slider: null,
        isAnimated: false,
        isAnimationReady: false,
        impressionController: null,
        activeSlideIndex: 0,
    }),
    computed: {
        slides() {
            let result = [];
            if (this.product.video) {
                const videoSize = utils.common.getVideoResize();
                result.push({
                    image: this.product.videoPreview[videoSize] || null,
                    video: this.product.video[videoSize],
                    aspectRatio: config.media.defaultAspectRatio,
                });
            }
            if (this.product.images.length > 0) {
                result.push({ image: this.product.images[0].medium });
                if (this.product.images.length > 1) {
                    const activeGenderFilter = this.$store.getters['catalog/filters/isFemaleFilterActive']
                        ? config.filters.gender.values.female.code
                        : this.$store.getters['catalog/filters/isMaleFilterActive']
                            ? config.filters.gender.values.male.code
                            : null;
                    const currentCollectionGender = this.$store.getters['collections/currentCollectionGender'];
                    const genderCode = config.media.markers.genders[activeGenderFilter || currentCollectionGender] || null;
                    if (genderCode !== null) {
                        result = result.concat(
                            this.product.images
                                .filter((_, i) => i > 0)
                                .sort((a, b) => {
                                    if (a.gender === b.gender) {
                                        return 0;
                                    }
                                    if (a.gender === genderCode && b.gender !== genderCode) {
                                        return -1;
                                    }
                                    if (!!b.gender && b.gender !== genderCode) {
                                        return -1;
                                    }
                                    if (!!a.gender && a.gender !== genderCode) {
                                        return 1;
                                    }
                                })
                                .map(x => ({ image: x.medium }))
                        );
                    } else {
                        result = result.concat(
                            this.product.images
                                .filter((_, i) => i > 0)
                                .map(x => ({ image: x.medium }))
                        );
                    }
                } else {
                    result.push({ image: this.product.images[0].medium });
                }
            } else {
                result.push({ image: 'https://via.placeholder.com/750x1000/eee/ccc?text=No+Image' });
            }
            return result;
        },
        badges() {
            const result = [];
            if (this.product.isClipon) {
                result.push('clipon');
            }
            if (this.product.isSale) {
                result.push('sale-light');
            }
            return result;
        },
        isSale() {
            return !!this.product.price.oldText;
        },
        isVideoAtTheBeginning() {
            return this.slides.length > 0 && !!this.slides[0].video;
        },
    },
    methods: {
        init() {
            if (!this.$store.state.common.isCatalogSliderHintShown) {
                this.isAnimated = true;
                this.$store.commit('common/setIsCatalogSliderHintShown', true);
            }
            this.initSlider();
        },
        initSlider() {
            if (this.slider) {
                return;
            }
            const params = {
                slidesPerView: 1,
                speed: 500,
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                },
                mousewheel: {
                    forceToAxis: true,
                },
                loop: this.slides.length > 1,
                watchOverflow: true,
                watchSlidesVisibility: true,
            };
            params.on = {
                transitionEnd: () => {
                    if (!this.slider) {
                        this.activeSlideIndex = 0;
                        return;
                    }
                    if (this.isVideoAtTheBeginning && this.slider.activeIndex === this.slider.slides.length - 1) {
                        this.slider.params.speed = 0;
                        this.slider.slideTo(1);
                        this.slider.params.speed = 500;
                    }
                    this.activeSlideIndex = this.slider.realIndex;
                },
            };
            if (this.isAnimated) {
                params.on.lazyImageReady = this.setAnimationReady;
            };
            this.slider = new Swiper(this.$refs.slider, params);
        },
        destroySlider() {
            if (this.slider !== null) {
                this.slider.destroy();
            }
        },
        setAnimationReady() {
            this.isAnimationReady = true;
        },
        productClickHandler(e) {
            productClick({ ...this.ecommerceListData, product: this.product.ecommerce });
            let url = this.product.url;
            if (this.ecommerceListData?.listId && this.ecommerceListData?.listTitle) {
                url = this.product.url + `?tgm_list_id=${this.ecommerceListData?.listId}&tgm_list_title=${this.ecommerceListData.listTitle}`;
            }
            if (e.metaKey || e.ctrlKey) {
                window.open(url, '_blank');
            } else {
                window.location.href = url;
            }
        },
    },
    mounted() {
        if (this.isFocused) {
            this.$refs.root.scrollIntoView();
        }
        this.impressionController = new ImpressionController(this.$refs.root, { ...this.ecommerceListData, product: this.product.ecommerce });
    },
    beforeDestroy() {
        this.impressionController.destroy();
        this.destroySlider();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-product-slider {
    position: relative;
    z-index: 1;

    display: block;
    width: 100%;
    min-height: 300px;
    padding-top: 125%;

    font-size: 1.4rem;
    line-height: 2rem;

    background-color: @color-gray-main;

    cursor: pointer;

    &__observer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
    &__slider {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;

        &--wrapper {
            will-change: transform;
            &._animated {
                animation: catalog-slider-animation-hint 1s 0.33s cubic-bezier(.5,.02,.27,1.57) 1;
                @keyframes catalog-slider-animation-hint {
                    0% {
                        transform: translateX(-100vw);
                    }
                    66% {
                        transform: translateX(-133vw);
                    }
                    100% {
                        transform: translateX(-100vw);
                    }
                }
            }
        }
        &-item {
            position: relative;
            z-index: 1;

            width: 100%;
            height: 100%;

            background-color: @color-gray-main;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            &-loader {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;

                width: 100px;

                opacity: 0.3;

                transform: translate(-50%, -50%);
                .swiper-lazy-loaded > & {
                    display: none;
                }
            }
            &-video {
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 0;

                width: 100%;

                pointer-events: none;

                transform: translateY(-50%);
            }
        }
    }
    &__info {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;
        padding: 8px 20px 20px;

        pointer-events: none;
        &-badges {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 auto auto 0;

            pointer-events: none;
            &-item {
                padding: 12px 0;

                font-size: 2.4rem;
            }
        }
        &-collection {
            flex: 0 0 auto;
        }
        &-model {
            flex: 0 0 auto;
        }
        &-botline {
            flex: 0 0 auto;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            &-color {
                flex: 1 1 auto;
                padding-right: 20px;
            }
            &-price {
                flex: 1 1 auto;
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                justify-content: flex-end;

                text-align: right;
                &-old {
                    text-decoration: line-through;
                }
                &-old + &-current {
                    padding-left: 8px;
                }
            }
        }
    }
    @media @media-sm-down {
        padding-top: 80%;
    }
}
</style>
