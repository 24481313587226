<template>
    <section class="page-item-info">
        <img class="page-item-info__image"
            v-bind:src="imageSrc"
        />
        <div class="page-item-info__description"
            v-if="type === 'clipon'"
            >
            Это не очки, а clip-on, насадка на оправу, с помощью которой вы сможете превратить свои очки в солнцезащитные. <a v-bind:href="cliponInfoUrl" target="_blank" v-text="'Узнать больше ↗'"/>
            <br />
            <br />
            Для установки линз в оправу под clip-on подходят только тонкие или ультратонкие асферические линзы. Обратите на это внимание при выборе линз. Это обязательное правило и оно распространяется даже на линзы без коррекции.
        </div>
        <div class="page-item-info__description"
            v-if="currentChild.description"
            >
            <div v-html="currentChild.description" />
        </div>
        <div class="page-item-info__stats"
            v-if="type === 'glasses' || type === 'clipon'"
            >
            <div class="page-item-info__stats-block">
                <template v-if="formattedSize">
                    <ui-trigger v-on:click="showSizes">Размер</ui-trigger>: {{ formattedSize }}
                    <br />
                </template>
                <ui-trigger v-on:click="showMaterials">Материал</ui-trigger>: {{ material }}
            </div>
            <div class="page-item-info__stats-block">
                <template v-if="lensType === 'Оптика'">
                    Установлены <ui-trigger v-on:click="showLens">демолинзы</ui-trigger>, не предназначены для постоянного ношения
                </template>
                <template v-if="lensType === 'Солнцезащитные'">
                    <ui-trigger v-on:click="showLens">Линзы</ui-trigger> с полной UV защитой (400HM) в цвете {{ lensColor }}
                    <span v-if="blackout !== null"> с затемнением {{ blackout }}</span>
                </template>
            </div>
        </div>
        <div class="page-item-info__clipons"
            v-if="type === 'clipon' || item.clipon !== null"
            >
            <page-item-info-clipons
                v-bind:item="item"
                v-bind:type="type"
            />
        </div>
        <div class="page-item-info__lens"
            v-if="type === 'glasses' && lensType === 'Оптика'"
            >
            <h2 class="page-item-info__lens-title">
                Установка линз
            </h2>
            <div class="page-item-info__lens-title _mobile">
                Установка линз для коррекции
            </div>
            <div class="page-item-info__lens-features">
                <div class="page-item-info__lens-features-item">
                    <div class="page-item-info__lens-features-item-icon">
                        <icon name="lens-forms" v-bind:fill="false" width="auto" height="100%" />
                    </div>
                    <div class="page-item-info__lens-features-item-text">
                        Классические, тонкие или ультратонкие линзы на выбор
                    </div>
                </div>
                <div class="page-item-info__lens-features-item">
                    <div class="page-item-info__lens-features-item-icon">
                        <icon name="lens-antiglare" v-bind:fill="false" width="auto" height="100%" />
                    </div>
                    <div class="page-item-info__lens-features-item-text">
                        Три варианта покрытий с защитой от царапин, бликов и загрязнений
                    </div>
                </div>
                <div class="page-item-info__lens-features-item">
                    <div class="page-item-info__lens-features-item-icon">
                        <icon name="lens-computer" v-bind:fill="false" width="auto" height="100%" />
                    </div>
                    <div class="page-item-info__lens-features-item-text"
                        v-text="'Компьютерные линзы с коррекцией и без'"
                    />
                </div>
                <div class="page-item-info__lens-features-item">
                    <div class="page-item-info__lens-features-item-icon">
                        <icon name="lens-photochromic" v-bind:fill="false" width="auto" height="100%" />
                    </div>
                    <div class="page-item-info__lens-features-item-text">
                        Фотохромные, поляризационные или солнцезащитные линзы с коррекцией зрения <ui-trigger v-on:click="$store.commit('common/chatraOpen')">(напишите нам для заказа)</ui-trigger>
                    </div>
                </div>
            </div>
            <div class="page-item-info__lens-block">
                Подробнее о линзах, стоимости и сроках установки вы можете узнать <a v-bind:href="lensInfoUrl" target="_blank">здесь</a>.
                <br />
                Если остались вопросы, <ui-trigger v-on:click="$store.commit('common/chatraOpen')">напишите нам в чат</ui-trigger>.
            </div>
            <div class="page-item-info__lens-button">
                <ui-button
                    v-if="isInCartWithLens"
                    v-on:click="showCart"
                    is-uppercase
                    >
                    уже в корзине ✓
                </ui-button>
                <ui-button
                    v-else
                    v-on:click="selectLensHandler"
                    is-uppercase
                    >
                    выбрать линзы
                </ui-button>
            </div>
        </div>
        <div class="page-item-info__lens"
            v-if="type === 'glasses' && lensType === 'Солнцезащитные'"
            >
            <h2 class="page-item-info__lens-title">
                Нужны солнцезащитные линзы с коррекцией зрения?
            </h2>
            <div class="page-item-info__lens-title _mobile">
                Нужны солнцезащитные линзы с коррекцией зрения?
            </div>
            <div class="page-item-info__lens-features">
                <div class="page-item-info__lens-features-item">
                    <div class="page-item-info__lens-features-item-icon">
                        <icon name="lens-photochromic" v-bind:fill="false" width="auto" height="100%" />
                    </div>
                    <div class="page-item-info__lens-features-item-text">
                        2 в 1. Фотохромные, поляризационные или окрашенные линзы с коррекцией зрения. Укажите в комментарии к заказу, что вам нужны специальные линзы. Мы свяжемся с вами и подберем лучший вариант, исходя из рекомендаций врача, вашего рецепта на очки и бюджета.
                    </div>
                </div>
            </div>
            <div class="page-item-info__lens-block">
                Подробнее о линзах, стоимости и сроках установки вы можете узнать <a v-bind:href="lensInfoUrl" target="_blank">здесь</a>.
                <br />
                Если остались вопросы, <ui-trigger v-on:click="$store.commit('common/chatraOpen')">напишите нам в чат</ui-trigger>.
            </div>
        </div>
        <img class="page-item-info__image"
            v-if="!item.isAccessory"
            v-bind:src="staticPathRoot + '/images/page-item-mobile.jpg'"
        />
    </section>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    name: 'page-item-info',
    inject: [ 'staticPathRoot' ],
    props: {
        item: {
            type: Object,
        },
        type: {
            type: String,
        },
        currentChild: {
            type: Object,
        },
        lensType: {
            type: String,
        },
    },
    data: () => ({
        cliponInfoUrl: config.urls.cliponInfo,
        lensInfoUrl: config.urls.lensInfo,
    }),
    computed: {
        ...mapState('cart', [ 'getCartResult' ]),
        imageSrc() {
            const image = this.currentChild.images[this.currentChild.images.length - 1];
            if (image) {
                return image.medium;
            }
            return null;
        },
        sizes() {
            const getAttribute = (attributeCode) => {
                const attribute = this.currentChild.attributes.find(x => x.code === attributeCode);
                if (attribute !== undefined) {
                    return attribute.value;
                } else {
                    return null;
                }
            };
            const sizes = {
                lens: getAttribute('Ширина линзы'),
                frame: getAttribute('Ширина оправы'),
                bridge: getAttribute('Переносица'),
                temple: getAttribute('Длина дужек'),
            };
            return sizes;
        },
        formattedSize() {
            const result = [];
            // if (this.sizes.frame !== null) {
            //     result.push(this.sizes.frame);
            // }
            if (this.sizes.lens !== null) {
                result.push(this.sizes.lens);
            }
            if (this.sizes.bridge !== null) {
                result.push(this.sizes.bridge);
            }
            if (this.sizes.temple !== null) {
                result.push(this.sizes.temple);
            }
            return result.join('/');
        },
        material() {
            const result = this.currentChild.attributes.find(x => x.code === 'Материал');
            return result !== undefined ? result.value : null;
        },
        lensColor() {
            const result = this.currentChild.attributes.find(x => x.code === 'Цвет линзы');
            return result !== undefined ? result.value : null;
        },
        blackout() {
            const result = this.currentChild.attributes.find(x => x.code === 'Затемнение');
            return result !== undefined ? result.value : null;
        },
        cartLine() {
            if (!this.getCartResult) {
                return null;
            }
            return this.getCartResult.lines.find(x => x.product.id === this.currentChild.id) || null;
        },
        isInCartWithLens() {
            if (!this.cartLine) {
                return false;
            }
            return this.getCartResult.lines.find(x => x.id === this.cartLine.child_id) || false;
        },
    },
    methods: {
        showSideblock(sideblock) {
            this.$store.commit('sideblocks/push', sideblock);
        },
        showSizes() {
            this.showSideblock({ name: 'sizes-guide', props: { sizes: this.sizes } });
        },
        showLens() {
            this.$store.commit('sideblocks/push', {
                name: 'lens',
                props: {
                    lensType: this.lensType,
                },
            });
        },
        showMaterials() {
            this.showSideblock('materials');
        },
        showCart() {
            this.showSideblock('cart');
        },
        selectLensHandler() {
            const props = {
                glasses: this.currentChild,
                isShowCartRequired: true,
            };
            if (this.cartLine) {
                props.lineId = this.cartLine.id;
            }
            this.$store.commit('sideblocks/push', {
                name: 'select-lens',
                props,
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-item-info {
    min-height: 100%;

    background-color: @color-gray-main;
    &__image {
        display: none;
    }
    &__description {
        .typography-display-md();

        padding: 16px 18px 72px;
        border-top: 1px solid @color-gray-lightest;

        white-space: pre-line;
        & /deep/ p {
            margin: 0;
        }
    }
    &__stats {
        .typography-display-md();

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 16px 18px 72px;
        border-top: 1px solid @color-gray-lightest;
        &-block {
            flex: 0 0 auto;
            width: 50%;
            &:first-child {
                padding-right: 18px;
            }
        }
    }
    &__clipons {
        border-top: 1px solid @color-gray-lightest;
    }
    &__lens {
        padding: 16px 48px 42px 16px;
        border-top: 1px solid @color-gray-lightest;
        &-title {
            .typography-display-md();

            margin: 0 0 32px;

            font-weight: normal;
            &._mobile {
                display: none;
            }
        }
        &-block {
            .typography-body-md();

            margin-bottom: 30px;
        }
        &-features {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            width: 100%;
            &-item {
                flex: 1 1 100%;
                max-width: 750px;
                margin: 0 32px 32px;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                &-icon {
                    height: 40px;
                    margin-bottom: 12px;
                }
            }
        }
        &-button {
            width: fit-content;
            margin: 0 auto 0 0;
        }
    }
    @media @media-lg-down {
        &__lens {
            padding-right: 16px;
            padding-bottom: 10px;
            &-features {
                flex-wrap: wrap;
                &-item {
                    flex: 0 0 auto;
                    width: 50%;
                    margin: 0 0 28px;
                    &:nth-child(2n) {
                        padding-left: 16px;
                    }
                    &:nth-child(2n + 1) {
                        padding-right: 16px;
                    }
                    &:first-child:last-child {
                        width: 100%;
                    }
                }
            }
        }
    }
    @media @media-md-down {
        &__description {
            .typography-display-sm();

            padding-bottom: 40px;
        }
        &__stats {
            .typography-display-sm();
        }
        &__lens {
            &-title {
                .typography-display-sm();

                display: none;
                &._mobile {
                    display: block;
                }
            }
        }
    }
    @media @media-sm-down {
        &__stats {
            display: block;
            padding-bottom: 40px;
            &-block {
                width: 100%;
                &:first-child {
                    padding-right: 0;
                    margin-bottom: 24px;
                }
            }
        }
        &__image {
            display: block;
            width: 100%;
            box-sizing: content-box;
            border-top: 1px solid @color-gray-lightest;
        }
    }
}
</style>
