export const pagination = ({ pageSize = 10, page = 1, ...data }) => {
    return {
        ...data,
        page_size: pageSize,
        page,
    };
};

export default {
    pagination,
};
