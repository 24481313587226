<template>
    <div class="page-checkout-step"
        v-bind:class="{
            _filled: !isActive,
            _inactive: !isActive,
        }"
        >
        <div class="page-checkout-step__header">
            <div class="page-checkout-step__header-number" />
            <div class="page-checkout-step__header-title"
                v-text="isActive ? step.title : (step.customizedTitle || step.title)"
                >
            </div>
            <div class="page-checkout-step__header-edit"
                v-if="!isActive && step.isFilled"
                v-on:click="change"
                >
                изменить
            </div>
        </div>
        <div class="page-checkout-step__body">
            <component
                v-bind:is="`page-checkout-step-${step.component}`"
                v-bind:step="step"
                v-bind:is-active="isActive"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-checkout-step',
    inject: [ 'pageCheckoutEventBus' ],
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        step: {
            type: Object,
        },
    },
    methods: {
        change() {
            this.pageCheckoutEventBus.$emit('set-step', this.step);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step {
    .typography-body-md();

    position: relative;
    &__header {
        position: relative;

        display: flex;
        justify-content: flex-start;
        align-items: baseline;

        transition: color @duration-fastest @easing-default;
        ._filled > & {
            color: @color-gray-dark;
        }
        &-number {
            flex: 0 0 auto;
            min-width: 16px;
            &:before {
                content: counter(step)".";
                counter-increment: step;
            }
        }
        &-title {
            flex: 0 0 auto;
            text-transform: uppercase;
        }
        &-edit {
            flex: 0 0 auto;
            padding-left: 20px;
            margin-right: 0;
            margin-left: auto;

            color: @color-gray-darkest;

            cursor: pointer;
        }
    }
    &__body {
        width: 100%;
        max-width: 500px;
        padding-top: 20px;
        ._inactive > & {
            padding-top: 16px;
            border-bottom: 1px solid @color-gray-dark;
        }
    }
}
</style>
