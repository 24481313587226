<template>
    <div class="notifications-root"
        v-if="items.length > 0"
        id="notifications-root"
        >
        <div class="notifications-root__item"
            v-for="(item, index) in items"
            v-bind:key="index"
            >
            <notifications-item
                v-bind="item"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'notifications-root',
    computed: {
        items() {
            return this.$store.state.notifications.items;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.notifications-root {
}
</style>
