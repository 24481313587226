<template>
    <section class="page-giftcards-items-form">
        <transition name="fade">
            <div class="page-giftcards-items-form__loader"
                v-if="isLoading"
                >
                <div class="page-giftcards-items-form__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="page-giftcards-items-form__content">
            <div class="page-giftcards-items-form__content-form">
                <ui-form
                    v-bind:model="fields"
                    v-bind:validation="$v"
                    v-on:update="updateHandler"
                    v-bind:submit-handler="submitHandler"
                />
            </div>
            <div class="page-giftcards-items-form__content-button">
                <ui-button
                    v-on:click="submitHandler"
                    v-bind:class="{ _disabled: isLoading }"
                    v-bind:disabled="isLoading"
                    is-uppercase
                    type="submit"
                    >
                    добавить в корзину
                </ui-button>
            </div>
        </div>
    </section>
</template>

<script>
import config from '~/config';
import forms from '~/forms';
import { productDetailsView } from '~/utils/ecommerce';

export default {
    name: 'page-giftcards-items-form',
    inject: ['type', 'requestUrl'],
    props: [ 'selectedItem' ],
    data: () => ({
        isSubmitted: false,
        fieldsData: {
            giftCardRecipientName: { ...forms.fields.giftCardRecipientName, isHalf: window.innerWidth >= 568 },
            giftCardSenderName: { ...forms.fields.giftCardSenderName, isHalf: window.innerWidth >= 568 },
            giftCardAmount: { ...forms.fields.giftCardAmount },
            giftCardSenderEmail: { ...forms.fields.giftCardSenderEmail },
            giftCardRecipientEmail: { ...forms.fields.giftCardRecipientEmail },
        },
    }),
    validations() {
        const result = {
            fields: {
                giftCardRecipientName: {
                    value: {
                        ...forms.validations.defaultRequired.value,
                        ...forms.validations.maxLength(forms.fields.giftCardRecipientName.maxLength).value,
                    },
                },
                giftCardSenderName: {
                    value: {
                        ...forms.validations.defaultRequired.value,
                        ...forms.validations.maxLength(forms.fields.giftCardSenderName.maxLength).value,
                    },
                },
            },
        };
        if (this.type === 'digital') {
            result.fields.giftCardSenderEmail = {
                value: {
                    ...forms.validations.defaultRequired.value,
                    ...forms.validations.email.value,
                },
            };
            if (this.fieldsData.giftCardRecipientEmail.value) {
                result.fields.giftCardRecipientEmail = {
                    value: {
                        ...forms.validations.email.value,
                        ...forms.validations.defaultRequired.value,
                    },
                };
            }
        }
        if (this.selectedItem.isCustom) {
            result.fields.giftCardAmount = {
                value: {
                    ...forms.validations.defaultRequired.value,
                    invalidAmount: value => parseInt(value, 10) >= config.customGiftcardsLimits[this.type].min && parseInt(value, 10) <= config.customGiftcardsLimits[this.type].max,
                },
            };
        }
        return result;
    },
    computed: {
        isLoading() {
            return this.$store.getters['cart/isLoading'];
        },
        result() {
            return !this.isLoading && this.isSubmitted && this.$store.state.cart.getCartResult && !this.$store.state.cart.addToCartError;
        },
        fields: {
            get() {
                const result = {
                    giftCardRecipientName: this.fieldsData.giftCardRecipientName,
                    giftCardSenderName: this.fieldsData.giftCardSenderName,
                };
                if (this.selectedItem.isCustom) {
                    result.giftCardAmount = this.fieldsData.giftCardAmount;
                    const minFormatted = config.customGiftcardsLimits[this.type].min.toLocaleString('ru-RU');
                    const maxFormatted = config.customGiftcardsLimits[this.type].max.toLocaleString('ru-RU');
                    result.giftCardAmount.labelHint = `От ${minFormatted} до ${maxFormatted} ₽`;
                    result.giftCardAmount.errors = {
                        required: `Введите сумму от ${minFormatted} до ${maxFormatted} ₽`,
                        invalidAmount: `Введите сумму от ${minFormatted} до ${maxFormatted} ₽`,
                    };
                }
                if (this.type === 'digital') {
                    result.giftCardSenderEmail = this.fieldsData.giftCardSenderEmail;
                    result.giftCardRecipientEmail = this.fieldsData.giftCardRecipientEmail;
                }
                return result;
            },
            set(newData) {
                this.fieldsData = { ...this.fieldsData, ...newData };
            },
        },
    },
    methods: {
        updateHandler(newData) {
            this.fields = {
                ...this.fields,
                ...newData,
            };
        },
        submitHandler() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let description = {
                    name_to: this.fieldsData.giftCardRecipientName.value,
                    name_from: this.fieldsData.giftCardSenderName.value,
                };
                if (this.type === 'digital') {
                    description.email_from = this.fieldsData.giftCardSenderEmail.value;
                    if (this.fieldsData.giftCardRecipientEmail.value) {
                        description.email_to = this.fieldsData.giftCardRecipientEmail.value;
                    }
                }
                const params = {
                    quantity: this.selectedItem.isCustom ? this.fieldsData.giftCardAmount.value : 1,
                    description,
                    product: this.selectedItem,
                };
                if (params.product?.ecommerce) {
                    params.product.ecommerce.listId = 'catalog_gift_cards';
                    params.product.ecommerce.listTitle = 'Подарочные сертификаты';
                }
                this.isSubmitted = true;
                this.$store.dispatch('cart/addToCart', params);
            }
        },
    },
    mounted() {
        this.selectedItem.ecommerce && productDetailsView({
            ...this.selectedItem.ecommerce,
            listId: 'catalog_gift_cards',
            listTitle: 'Подарочные сертификаты',
        });
    },
    watch: {
        result(newVal) {
            if (newVal !== null) {
                this.$store.commit('modals/push', {
                    name: 'common',
                    props: {
                        title: 'Успешно!',
                        text: 'Сертификат добавлен в корзину!',
                        buttons: [
                            { text: 'Перейти в корзину', callback: () => { window.location.href = config.urls.cart; } },
                        ],
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-giftcards-items-form {
    position: relative;

    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 40px 60px;

    background: @color-gray-main;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: @color-gray-main;
        &-container {
            width: 100px;
        }
    }
    &__content {
        max-width: 500px;
        width: 100%;
        &-form {
            margin-bottom: 48px;
        }
    }
}
</style>
