<template>
    <div class="page-checkout-step-shipping-address">
        <div class="page-checkout-step-shipping-address__title"
            v-text="'адрес доставки'"
        />
        <div class="page-checkout-step-shipping-address__fields">
            <div class="page-checkout-step-shipping-address__fields-item">
                <page-checkout-step-shipping-address-street />
            </div>
            <template v-if="isDadataAvailable">
                <div class="page-checkout-step-shipping-address__fields-item _half"
                    v-if="!checkoutData.specialAddress"
                    >
                    <page-checkout-step-shipping-address-apartment />
                </div>
                <div class="page-checkout-step-shipping-address__fields-item _half">
                    <page-checkout-step-shipping-address-postcode />
                </div>
            </template>
            <div class="page-checkout-step-shipping-address__fields-item">
                <page-checkout-step-shipping-address-comment />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-checkout-step-shipping-address',
    computed: {
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        isDadataAvailable() {
            return this.$store.getters['checkout/isDadataAvailable'];
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-address {
    &__title {
        margin-bottom: 20px;

        text-transform: uppercase;
    }
    &__fields {
        margin: -10px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        &-item {
            flex: 0 0 auto;
            width: 100%;
            padding: 10px;
            &._half {
                width: 50%;
            }
        }
    }
}
</style>
