<template>
    <div class="plugin-infopage-cta">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'plugin-infopage-cta',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-cta {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-height: 360px;
    & + & {
        margin-top: 16px;
    }
    &__image {
        order: -1;
        flex: 0 0 50%;
    }
    &__content {
        order: 1;
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 32px;

        text-align: center;

        background-color: @color-gray-lighter;
        & /deep/ .plugin-infopage-title._h2,
        & /deep/ .plugin-infopage-title._h3 {
            max-width: 450px;
            margin: 0 auto 8px;
        }
        & /deep/ .plugin-infopage-text {
            max-width: 450px;
            margin: 0 auto 40px !important;
        }
        & /deep/ .plugin-infopage-buttongroup {
            width: 100%;
            max-width: 450px;
            justify-content: center;
            margin-top: 0 !important;
        }
    }
    @media @media-md-down {
        flex-direction: column;
        align-items: stretch;
        & + & {
            margin-top: 0;
        }
        .plugin-infopage-container._padded & {
            width: calc(100% + 40px);
            margin: 0 -20px;
        }
        &__image {
            flex: 0 0 auto;
        }
        &__content {
            flex: 0 0 auto;
            padding: 24px 20px 48px;
            & /deep/ .plugin-infopage-title._h2,
            & /deep/ .plugin-infopage-title._h3 {
                margin: 0 auto 4px;
            }
            & /deep/ .plugin-infopage-text {
                margin: 0 auto 24px !important;
            }
            & /deep/ .plugin-infopage-buttongroup {
                max-width: none;
                margin-top: 0 !important;
            }
        }
    }
}
</style>
