<template>
    <div class="common-intersection-observer"
        ref="root"
    />
</template>

<script>
export default {
    name: 'common-intersection-observer',
    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
        selfDestruct: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        observer: null,
    }),
    mounted() {
        const options = this.options || {};
        this.observer = new IntersectionObserver(([entry]) => {
            if (entry) {
                if (entry.isIntersecting) {
                    this.$emit('intersect');
                    if (this.selfDestruct) {
                        this.observer.disconnect();
                    }
                } else {
                    this.$emit('disjoint');
                }
            }
        }, options);

        this.observer.observe(this.$refs.root);
    },
    destroyed() {
        this.observer.disconnect();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-intersection-observer {
    width: 100%;
    height: 100%;

    pointer-events: none;
    opacity: 0;
}
</style>
