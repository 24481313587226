<template>
    <div class="notifications-item"
        v-bind:style="backgroundColor ? { backgroundColor } : null"
        >
        <div class="notifications-item__container">
            <div class="notifications-item__text"
                v-bind:style="textColor ? { color: textColor } : null"
                v-html="text"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'notifications-item',
    props: {
        textColor: {
            type: String,
            default: null,
        },
        backgroundColor: {
            type: String,
            default: null,
        },
        text: {
            type: String,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.notifications-item {
    .typography-body-md();

    padding: 12px 0;

    color: @color-gray-darkest;

    background: @color-gray-lightest;
    &__container {
        .container();
        .container-paddings();
    }
    &__text {
        /deep/ p {
            .typography-body-md();

            margin: 0;
        }
    }
    @media @media-md-down {
        padding: 7px;
    }
}
</style>
