<template>
    <div class="common-header-mobile-menu">
        <div class="common-header-mobile-menu__menu">
            <div class="common-header-mobile-menu__menu-item"
                v-for="(item, index) in items"
                v-bind:key="index"
                >
                <common-header-mobile-menu-item
                    v-bind:item="item"
                />
            </div>
        </div>
        <div class="common-header-mobile-menu__auth"
            v-if="user === null"
            v-on:click="$emit('toggle-auth')"
            >
            Войти
        </div>
        <a v-bind:href="cabinetHref" class="common-header-mobile-menu__auth"
            v-else
            v-text="user.firstName ? user.firstName : 'Личный кабинет'"
            >
        </a>
        <div class="common-header-mobile-menu__prefooter">
            <common-header-mobile-menu-prefooter />
        </div>
        <div class="common-header-mobile-menu__footer">
            <common-header-mobile-menu-footer
                v-bind:items="footer"
            />
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'common-header-mobile-menu',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        footer: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        cabinetHref: config.urls.cabinet,
    }),
    computed: {
        user() {
            return this.$store.getters['user/user'];
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-header-mobile-menu {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    padding: 38px 20px 70px;
    overflow: auto;

    background-color: @color-gray-lightest;
    &__menu {
        flex: 0 0 auto;
        margin-bottom: 36px;
    }
    &__auth {
        flex: 0 0 auto;
        width: fit-content;
        margin-bottom: 45px;

        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    &__footer {
        flex: 0 0 auto;
    }
}
</style>
