<template>
    <section class="page-dashboard">
        <div class="page-dashboard__loader"
            v-if="state == 'loading'"
            >
            <div class="page-dashboard__loader-wrapper">
                <ui-loader />
            </div>
        </div>
        <div class="page-dashboard__menu">
            <div class="page-dashboard__menu-list">
                <div class="page-dashboard__menu-list-item"
                    v-for="(item, index) in menu.items"
                    v-bind:key="index"
                    v-bind:class="{ '_active': index == menu.activeIndex }"
                    v-on:click="setActiveIndex(index)"
                    v-text="item.title"
                />
                <div class="page-dashboard__menu-list-item"
                    v-on:click="logout"
                    >
                    Выйти
                </div>
            </div>
        </div>
        <div class="page-dashboard__content">
            <component
                v-bind:is="menu.items[menu.activeIndex].component"
                v-bind:given-order="menu.items[menu.activeIndex].component === 'page-dashboard-history' ? givenOrder : null"
            />
        </div>
    </section>
</template>

<script>
export default {
    name: 'page-dashboard',
    data: () => ({
        state: 'loaded',
        menu: {
            activeIndex: 0,
            items: [
                {
                    title: 'Мои данные',
                    component: 'page-dashboard-info',
                },
                {
                    title: 'История заказов',
                    component: 'page-dashboard-history',
                },
                {
                    title: 'Сертификаты',
                    component: 'page-dashboard-certificates',
                },
            ],
        },
        givenOrder: null,
    }),
    computed: {
        user() {
            return this.$store.getters['user/user'];
        },
    },
    methods: {
        setActiveIndex(newActiveIndex) {
            this.menu.activeIndex = newActiveIndex;
            const hash = this.menu.items[newActiveIndex].component.replace('page-dashboard-', '');
            window.history.replaceState('', '', window.location.origin + window.location.pathname + window.location.search + '#' + hash);
        },
        logout() {
            this.state = 'loading';
            this.$store.dispatch('user/logout');
        },
        checkHash() {
            let hash = window.location.hash;
            if (!hash) {
                return;
            }
            hash = hash.substring(1);
            const subpagesHashes = this.menu.items.map(item => item.component.replace('page-dashboard-', ''));
            const index = subpagesHashes.findIndex(x => hash.indexOf(x) > -1);
            if (hash.startsWith('history-')) {
                this.givenOrder = hash.substring(8);
            }
            if (index > -1 && index !== this.menu.activeIndex) {
                this.setActiveIndex(index);
            }
        },
    },
    beforeMount() {
        this.checkHash();
    },
    watch: {
        user(newVal, oldVal) {
            if (newVal === null && oldVal !== null) {
                window.location.pathname = '/';
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-dashboard {
    .container();
    .typography-body-md();

    position: relative;
    z-index: 1;

    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1 0 auto;
    width: 100%;
    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        width: 100%;
        height: 100%;

        background: @color-gray-lightest;
        &-wrapper {
            position: sticky;
            top: 100px;
            bottom: 100px;

            width: 100px;
            margin: auto;
        }
    }
    &__menu {
        flex: 0 0 auto;
        width: 180px;
        border-right: 1px solid @color-gray-darkest;
        &-list {
            padding-top: 30px;
            &-item {
                display: block;
                padding: 10px 20px;

                text-decoration: none;

                cursor: pointer;
                &._active {
                    text-decoration: underline;
                }
            }
        }
    }
    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        padding: 38px 0;
    }
    @media @media-lg-down {
    }
    @media @media-md-down {
    }
    @media @media-sm-down {
        flex-direction: column;
        &__menu {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid @color-gray-darkest;
            &-list {
                padding: 20px 0;
                &-item {
                    width: fit-content;
                }
            }
        }
        &__content {
            width: 100%;
        }
    }
}
</style>
