import config from '~/config';
import client from '../_client';
import transforms from './transforms';

export const getAddressSuggest = (data = {}) => {
    return client.request({
        url: config.api.dadata.suggest,
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        method: 'POST',
        data,
        transformRequest: transforms.requests.getAddressSuggest,
        transformResponse: transforms.responses.getAddressSuggest,
    });
};
