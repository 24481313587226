<template>
    <section class="plugin-home-about">
        <h1 class="plugin-home-about__title"
            v-if="title"
            v-text="title"
        />
        <div class="plugin-home-about__sliders">
            <slot />
        </div>
    </section>
</template>

<script>
export default {
    name: 'plugin-home-about',
    props: {
        title: {
            typs: String,
            default: null,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-about {
    padding: 32px 32px 64px;

    background-color: @color-gray-lighter;
    &__title {
        .typography-display-md();

        margin: 0 0 32px;

        font-weight: normal;
        text-transform: uppercase;
    }
    &__sliders {
        /deep/ .plugin-home-about-slider + .plugin-home-about-slider {
            margin-top: 80px;
        }
    }
    @media @media-sm-down {
        padding: 32px 20px;
        &__title {
            margin-bottom: 48px;
        }
        &__sliders {
            /deep/ .plugin-home-about-slider + .plugin-home-about-slider {
                margin-top: 64px;
            }
        }
    }
}
</style>
