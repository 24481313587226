<template>
    <div>
        plugin-home-categories-item
    </div>
</template>

<script>
export default {
    name: 'plugin-home-categories-item',
    data: () => ({
        isActive: false,
    }),
    methods: {
        activate(e) {
            const image = this.$refs.image;
            const links = this.$refs.links;
            if (image && links) {
                if (e.target === image) {
                    let linksWidth = links.clientWidth;
                    let imageWidth = image.clientWidth;
                    let linksHeight = links.clientHeight;
                    let imageHeight = image.clientHeight;
                    links.style.left = Math.min(e.offsetX, imageWidth - linksWidth) + 'px';
                    links.style.top = Math.min(e.offsetY, imageHeight - linksHeight) + 'px';
                    this.isActive = true;
                } else {
                    this.deactivate();
                }
            }
        },
        deactivate() {
            this.isActive = false;
        },
    },
    mounted() {
        document.addEventListener('click', this.activate);
        window.addEventListener('resize', this.deactivate);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.activate);
        window.removeEventListener('resize', this.deactivate);
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.plugin-home-categories-item {
    position: relative;

    flex: 1 1 auto;
    height: 100%;
    &__image {
        position: relative;

        width: 100%;
        height: 100%;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        cursor: pointer;
    }
    &__title {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 1;

        display: block;

        font-size: 1.4rem;
        text-transform: uppercase;
        text-decoration: none;

        pointer-events: none;
    }
    &__links {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        background: #ffe500;

        opacity: 0;
        pointer-events: none;

        transition: opacity @duration-fastest ease-out;
        &._active {
            opacity: 1;
            pointer-events: all;
        }
        &-item {
            display: block;
            padding: 5px 20px;

            font-size: 1.4rem;
            line-height: 2rem;
            text-decoration: none;

            transition: background-color @duration-fastest ease-out;
            &:first-child {
                padding-top: 15px;
            }
            &:last-child {
                padding-bottom: 12px;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
    @media @media-md-down {
        &__image {
            height: 240px;
        }
        &__title {
            position: static;

            width: 100%;
            height: 40px;

            line-height: 40px;
            text-align: center;

            pointer-events: all;
        }
        &__links {
            background: @color-accent-warm;

            box-shadow: 0 0 10px 10px @color-accent-warm;
            &-item {
                display: block;
                padding: 5px 10px;

                color: @color-gray-darkest;

                &:first-child {
                    padding-top: 5px;
                }
                &:last-child {
                    padding-bottom: 5px;
                }
                &:hover {
                    background: transparent;
                }
            }
        }
    }
}
</style>
