import { productImpression } from '~/utils/ecommerce';

export const checkAnalyticsAvailability = (label = null, data = null) => {
    if (window.location.hostname === 'localhost' ||
        window.location.hostname === 'staging.pyeoptics.com') {
        console.warn(`analytics${label ? (' "' + label + '" ') : ' '}prevented for ${window.location.hostname}`);
        if (data) {
            console.warn('data to send:', JSON.parse(JSON.stringify(data)));
        }
        return false;
    }
    return true;
};

export const getCategoryByHost = () => {
    if (window.location.hostname === 'localhost') {
        return 'Localhost Enhanced Ecommerce';
    }
    if (window.location.hostname === 'staging.pyeoptics.com') {
        return 'Test Enhanced Ecommerce';
    }
    return 'Enhanced Ecommerce';
};

export class ImpressionController {
    constructor(element, ecommerceData) {
        this.element = element;
        this.observer = new IntersectionObserver(([entry]) => {
            if (entry) {
                if (entry.isIntersecting) {
                    productImpression(ecommerceData);
                    this.destroy();
                }
            }
        }, { threshold: 0.9 });
        this.observer.observe(this.element);
    }
    destroy() {
        this.observer.disconnect(this.element);
        this.element = null;
    }
}

export default {
    checkAnalyticsAvailability,
    getCategoryByHost,
    ImpressionController,
};
