export const chatraOpen = ({ callback = null } = {}) => {
    const checkChatra = () => {
        const isChatraReady = !!window.Chatra && !!window.Chatra.openChat;
        if (isChatraReady) {
            window.Chatra.openChat();
            if (callback) {
                setTimeout(callback, 1000);
            }
        } else {
            setTimeout(checkChatra, 1000);
        }
    };
    checkChatra();
};

export const chatraSendAutoMessage = (message) => {
    window.Chatra('sendAutoMessage', message);
};

export const getCookie = (name) => {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    } else {
        console.warn(`Cookie '${name}' not found`);
        return '';
    }
};

export const updateDjangoScriptTags = () => {
    const cmsScripts = document.querySelectorAll('script[data-cms]');
    for (let i = 0; i < cmsScripts.length; i++) {
        const script = cmsScripts[i];
        if (!script.hasAttribute('type')) {
            script.setAttribute('type', 'application/javascript');
        }
    }
};

export class updateDjangoTamplateTags {
    constructor(instance) {
        this.instance = instance;
        this.init();
    };

    _canBePatched() {
        return window.CMS !== undefined && window.CMS.config.mode === 'draft';
    };

    _replaceTemplateTags() {
        var templates = document.querySelectorAll('template.cms-plugin');
        for (var i = 0; i < templates.length; i++) {
            var template = templates[i];
            var cmsTemplate = document.createElement('cms-template');
            cmsTemplate.className = template.className;
            template.parentNode.insertBefore(cmsTemplate, template);
            template.parentNode.removeChild(template);
        }
    };

    _moveScriptTags() {
        var scripts = this.instance.$options.el.querySelectorAll('script[data-cms]');
        for (var i = 0; i < scripts.length; i++) {
            scripts[i].parentNode.removeChild(scripts[i]);
            document.body.appendChild(scripts[i]);
        }
    };

    _cleanTemplateTags() {
        var cmsTemplates = document.querySelectorAll('cms-template');
        for (var i = 0; i < cmsTemplates.length; i++) {
            cmsTemplates[i].parentNode.removeChild(cmsTemplates[i]);
        }
    };

    refresh() {
        if (this._canBePatched()) {
            this.instance.$destroy();
            delete this.instance.$options.render; // Force re-render.
            this.instance = new this.instance.constructor(this.instance.$options);
            window.CMS.Plugin._initializeTree();
        }
    };

    patch() {
        if (this._canBePatched()) {
            this._replaceTemplateTags();
            this._moveScriptTags();
            window.CMS.$(document).on('ready', this._cleanTemplateTags.bind(this));
            // window.CMS.$(window).on('cms-content-refresh', this.refresh.bind(this));
        }
    };

    init() {
        if (this._canBePatched() && !this.instance.$options._cmsPatched) {
            this.patch();
            this.instance.$options._cmsPatched = true;
        }
    };
};

export const toggleFullscreen = (element, callback = function() { }) => {
    function onFullScreenChange() {
        var fullScreenElement =
            document.fullscreenElement ||
            document.msFullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement;
        // eslint-disable-next-line standard/no-callback-literal
        callback(!!fullScreenElement);
    };

    if (document.onfullscreenchange === null) {
        document.onfullscreenchange = onFullScreenChange;
    } else if (document.onmsfullscreenchange === null) {
        document.onmsfullscreenchange = onFullScreenChange;
    } else if (document.onmozfullscreenchange === null) {
        document.onmozfullscreenchange = onFullScreenChange;
    } else if (document.onwebkitfullscreenchange === null) {
        document.onwebkitfullscreenchange = onFullScreenChange;
    }

    if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
    ) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    } else {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    }
};

export const injectResize = (url, resize) => {
    if (!url || !resize) {
        return url;
    }
    const lastSlashIndex = url.lastIndexOf('/');
    if (lastSlashIndex < 0) {
        return url;
    }
    const result = url.slice(0, lastSlashIndex) + '/' + resize + url.slice(lastSlashIndex);
    return result;
};

export const getVideoResize = ({ targetWidth } = { targetWidth: null }) => {
    const sizesMap = {
        480: 'small',
        960: 'medium',
        1440: 'large',
        1920: 'extra_large',
    };
    const sizes = Object.keys(sizesMap).map(x => parseInt(x, 10)).sort((a, b) => a > b ? 1 : -1);
    const widthToCompare = (targetWidth || window.innerWidth) * window.devicePixelRatio;
    let result = 'extra_large';
    for (let i = 0; i < sizes.length; i++) {
        if (sizes[i] >= widthToCompare) {
            result = sizesMap[sizes[i]];
            break;
        }
    }
    return result;
};

export const getResize = ({ breakpoints = null, width = null, height = null, aspectRatio = null } = { width: null, height: null, aspectRatio: null, breakpoints: null }) => {
    // v 2023.02.23
    //  base params list:
    //  width: null, // can be Number (percent), String (pixels)
    //  height: null, // can be Number (percent), String (pixels)
    //  aspectRatio: null, // Number (height / width)

    //  breakpoints: null, // dictionary of breakpoints-params like:
    //  breakpoints: {
    //      1920: params1,
    //      1023: params2,
    //  }
    //  params1, params2 - same as base params list

    // percents are always calculated from window width
    // even for height

    if (!breakpoints && !width && !height && !aspectRatio) {
        return '1x1';
    }

    // getting default params
    let params = { width, height, aspectRatio };
    let validBreakpoint = null;
    // checking if there are breakpoints params
    if (breakpoints) {
        // build sorted array of breakpoints
        const keys = Object.keys(breakpoints).map(x => parseInt(x, 10)).sort((a, b) => a > b ? 1 : -1);
        for (let i = 0; i < keys.length; i++) {
            // looking for smallest breakpoint that is bigger than current window width
            if (keys[i] >= window.innerWidth) {
                // if breakpoint was found before,
                // and it has some params that are not defined in current breakpoint
                // then we need to merge it with higher breakpoint
                if (validBreakpoint) {
                    params = {
                        width: params.width || breakpoints[keys[i]].width || null,
                        height: params.height || breakpoints[keys[i]].height || null,
                        aspectRatio: params.aspectRatio || breakpoints[keys[i]].aspectRatio || null,
                    };
                // if breakpoint was not found before,
                // then we need to merge it with default params
                } else {
                    validBreakpoint = keys[i];
                    params = {
                        width: breakpoints[keys[i]].width || params.width || null,
                        height: breakpoints[keys[i]].height || params.height || null,
                        aspectRatio: breakpoints[keys[i]].aspectRatio || params.aspectRatio || null,
                    };
                }
                // if all params are defined, then we can stop
                if (params.width && params.height && params.aspectRatio) {
                    break;
                }
            }
        }
    }

    // if width or height is defined as a percent
    // then we need to calculate it
    // based on window.innerWidth
    if (params.width) {
        if (typeof params.width === 'string') {
            params.width = parseInt(params.width, 10);
        } else if (typeof params.width === 'number') {
            params.width = window.innerWidth * params.width / 100;
        }
    }
    if (params.height) {
        if (typeof params.height === 'string') {
            params.height = parseInt(params.height, 10);
        } else if (typeof params.height === 'number') {
            params.height = window.innerWidth * params.height / 100;
        }
    }

    const baseSizes = [
        320,
        360,
        375,
        576,
        768,
        1024,
        1280,
        1440,
        1600,
        1920,
        2560,
        2880,
        3200,
        3840,
        4880,
    ];
    const devicePixelRatio = window.devicePixelRatio || 1;

    let resultWidth = null;
    if (params.width) {
        const value = params.width * devicePixelRatio;
        resultWidth = baseSizes.find(x => x >= value) || baseSizes[baseSizes.length - 1];
    }
    let resultHeight = null;
    if (params.height) {
        const value = params.height * devicePixelRatio;
        resultHeight = baseSizes.find(x => x >= value) || baseSizes[baseSizes.length - 1];
    }

    // if aspect ratio is defined along with width or height
    // then we need to calculate missing value
    if (params.aspectRatio) {
        if (resultWidth && !resultHeight) {
            resultHeight = Math.round(resultWidth * params.aspectRatio);
        } else if (resultHeight && !resultWidth) {
            resultWidth = Math.round(params.height / params.aspectRatio);
        }
    }
    return `${resultWidth || 'AUTO'}x${resultHeight || 'AUTO'}`;
};

export const getCSRFToken = () => {
    const token = document.querySelector('[name="csrfmiddlewaretoken"]');
    if (!token) {
        console.error('CSRF token not found');
        return '';
    }
    return document.querySelector('[name="csrfmiddlewaretoken"]').value;
};

export const getDeclension = (number, wordForms) => {
    // getDeclension(1, ['минута', 'минуты', 'минут'])
    const lastTwoDigits = Math.abs(number) % 100;
    const lastDigit = Math.abs(number) % 10;
    if (lastTwoDigits > 10 && lastTwoDigits < 20) {
        return wordForms[2];
    }
    if (lastDigit > 1 && lastDigit < 5) {
        return wordForms[1];
    }
    if (lastDigit === 1) {
        return wordForms[0];
    }
    return wordForms[2];
};

export const getObjectValueByPath = (objToSearh = {}, path = '', forceNewObject = false) => {
    let obj = forceNewObject ? JSON.parse(JSON.stringify(objToSearh)) : objToSearh;
    if (!path || path === '.') {
        return obj;
    }
    for (var i = 0, levels = path.split('.'); i < levels.length; i++) {
        const nextLevel = obj[levels[i]];
        if (nextLevel === undefined) {
            return undefined;
        }
        obj = nextLevel;
    };
    return obj;
};

const _getColorLuma = (colorString) => {
    let rgb = parseInt(colorString, 16); // convert rrggbb to decimal
    let r = (rgb >> 16) & 0xff; // extract red
    let g = (rgb >> 8) & 0xff; // extract green
    let b = (rgb >> 0) & 0xff; // extract blue
    let luma = r * 0.2126 + g * 0.7152 + b * 0.0722; // per ITU-R BT.709 // 0 - 255
    return luma;
};

export const isColorDark = (colorString) => {
    return _getColorLuma(colorString) < 120;
};

export const isColorLight = (colorString) => {
    return _getColorLuma(colorString) > 200;
};

export const parseUrl = ({ url = null, onlyWithValues = true } = {}) => {
    if (url === null) {
        url = decodeURI(window.location.href);
    }
    const searchIndex = url.indexOf('?');
    if (searchIndex < 0) {
        return [];
    }
    let search = url.substring(searchIndex + 1);
    const complexFiltersSignature = 'complex_filters';
    const complexFiltersStart = search.indexOf(complexFiltersSignature);
    const complexFiltersEnd = search.lastIndexOf(')') + 1;
    const result = [];
    if (complexFiltersStart > -1 && complexFiltersEnd > -1) {
        const complexFilters = search.substring(complexFiltersStart + complexFiltersSignature.length + 1, complexFiltersEnd);
        search = search.substring(0, complexFiltersStart) + search.substring(complexFiltersEnd);
        result.push({ title: complexFiltersSignature, value: complexFilters });
    }
    const queries = search.split('&');
    if (queries) {
        queries.forEach(q => {
            const [ query, value ] = q.split('=');
            if (value) {
                result.push({ title: query, value });
            } else if (!onlyWithValues) {
                result.push({ title: query });
            }
        });
    }
    return result;
};

export const buildUrl = ({ url = null, queries = [] } = {}) => {
    if (url === null) {
        url = decodeURI(window.location.href);
    }
    const searchIndex = url.indexOf('?');
    if (searchIndex > -1) {
        url = url.substring(0, searchIndex);
    }
    if (url[url.length - 1] !== '/') {
        url += '/';
    }
    if (queries.length === 0) {
        return url;
    }
    url += '?';
    url += queries.map(q => `${q.title}${q.value !== undefined ? ('=' + q.value) : ''}`).join('&');
    return url;
};

export default {
    chatraOpen,
    chatraSendAutoMessage,
    getCookie,
    getCSRFToken,
    getDeclension,
    getObjectValueByPath,
    getVideoResize,
    getResize,
    injectResize,
    isColorDark,
    isColorLight,
    parseUrl,
    buildUrl,
    toggleFullscreen,
    updateDjangoScriptTags,
    updateDjangoTamplateTags,
};
