<template>
    <div class="ui-image"
        v-bind:class="{ _cover: isCover }"
        >
        <img class="ui-image__image"
            v-if="!aspectRatio && !isCover"
            v-bind:class="{ _visible: isLoaded }"
            v-bind:src="image"
            loading="lazy"
            ref="image"
        />
        <common-lazy-background
            v-else
            v-bind:aspect-ratio="isCover ? null : aspectRatio"
            v-bind:image="image"
        />
    </div>
</template>

<script>
export default {
    name: 'ui-image',
    props: {
        image: String,
        aspectRatio: {
            type: Number,
            default: null,
        },
        isCover: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isLoaded: false,
    }),
    mounted() {
        const img = this.$refs.image;
        if (!img) {
            return;
        }
        img.addEventListener('load', () => {
            this.isLoaded = true;
        });
    },
    beforeUnmount() {
        const img = this.$refs.image;
        if (!img) {
            return;
        }
        img.removeEventListener('load', () => {
            this.isLoaded = true;
        });
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-image {
    width: 100%;
    &._cover {
        height: 100%;
    }
    &__image {
        display: block;
        width: 100%;

        opacity: 0;

        transition: opacity @duration-fast @easing-default;
        &._visible {
            opacity: 1;
        }
    }
}
</style>
