<template>
    <div class="sideblock-availability-confirm">
        <!-- <div class="sideblock-availability-confirm__back"
            v-if="stores.length > 1"
            v-on:click="$emit('back')"
            >
            <span class="sideblock-availability-confirm__back-arrow">←</span><span class="sideblock-availability-confirm__confirm-back-text">Выбрать другой магазин</span>
        </div> -->
        <div class="sideblock-availability-confirm__store">
            <div class="sideblock-availability-confirm__store-header">
                <transition name="fade">
                    <div class="sideblock-availability-confirm__store-header-map"
                        v-if="isMapActive"
                        >
                        <iframe frameborder="0"
                            style="border:0"
                            v-bind:src="store.map_src"
                            allowfullscreen
                        />
                    </div>
                </transition>
                <transition name="fade">
                    <div class="sideblock-availability-confirm__store-header-cover"
                        v-if="!isMapActive"
                        v-bind:style="shopCoverStyle"
                    />
                </transition>
            </div>
            <div class="sideblock-availability-confirm__store-title">
                <span v-text="store.name"></span>
            </div>
            <div class="sideblock-availability-confirm__store-contacts">
                <div class="sideblock-availability-confirm__store-contacts-area">
                    <span v-text="store.area.name"></span>
                </div>
                <div class="sideblock-availability-confirm__store-contacts-info">
                    <span v-text="store.address"></span>
                    <br />
                    <span v-text="store.work_time"></span>
                    <br />
                    <a v-bind:href="storePhoneLink" v-text="store.contacts" />
                </div>
            </div>
            <div class="sideblock-availability-confirm__store-actions">
                <div class="sideblock-availability-confirm__store-actions-toggler"
                    v-on:click="toggleMap"
                    >
                    <span class="sideblock-availability-confirm__store-actions-toggler-text"
                        v-text="isMapActive ? 'Фото' : 'На карте'"
                    />
                </div>
                <div class="sideblock-availability-confirm__store-actions-more">
                    <a class="sideblock-availability-confirm__store-actions-more-link"
                        v-bind:href="storePageLink"
                        target="_blank"
                        >
                        Узнать о магазине больше →
                    </a>
                </div>
            </div>
        </div>
        <div class="sideblock-availability-confirm__item">
            <div class="sideblock-availability-confirm__item-cover">
                <div class="sideblock-availability-confirm__item-cover-image"
                    v-bind:style="itemCoverStyle"
                />
            </div>
            <div class="sideblock-availability-confirm__item-info">
                <template v-if="store.availableStock >= 4">
                    <div class="sideblock-availability-confirm__item-info-availability">
                        В{{ '\xa0' }}наличии в{{ '\xa0' }}магазине
                    </div>
                    <div class="sideblock-availability-confirm__item-info-hint">
                        Оформите заказ с{{ '\xa0' }}самовывозом и{{ '\xa0' }}они точно дождутся вас в{{ '\xa0' }}магазине!
                    </div>
                </template>
                <template v-else>
                    <div class="sideblock-availability-confirm__item-info-availability">
                        ⚑{{ '\xa0' }}Осталось мало в{{ '\xa0' }}магазине!
                    </div>
                    <div class="sideblock-availability-confirm__item-info-hint">
                        Перед визитом в{{ '\xa0' }}магазин уточните наличие <ui-trigger v-on:click="$store.commit('common/chatraOpen')">в{{ '\xa0' }}чате</ui-trigger> или оформите заказ с{{ '\xa0' }}самовывозом
                    </div>
                </template>
            </div>
        </div>
        <div class="sideblock-availability-confirm__button">
            <div class="sideblock-availability-confirm__button-title"
                v-if="isInCart"
                v-text="'Уже в корзине!'"
            />
            <div class="sideblock-availability-confirm__button-container">
                <ui-button
                    v-if="isInCart"
                    v-bind:href="checkoutUrl"
                    v-text="'Перейти к оформлению'"
                    is-uppercase
                />
                <ui-button
                    v-else
                    v-on:click="addToCart"
                    v-text="'Добавить в корзину'"
                    is-uppercase
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
export default {
    name: 'sideblock-availability-confirm',
    props: {
        item: {
            type: Object,
        },
        store: {
            type: Object,
        },
    },
    data: () => ({
        isMapActive: false,
        checkoutUrl: config.urls.cart,
    }),
    computed: {
        ...mapState('cart', [ 'getCartResult' ]),
        shopCoverStyle() {
            return {
                backgroundImage: `url(${this.store.image})`,
            };
        },
        storePhoneLink() {
            return 'tel:' + this.store.contacts.replace(/[^0-9+]/g, '');
        },
        storePageLink() {
            return `/shop/stores/${this.store.id}/`;
        },
        itemCoverStyle() {
            if (!this.item.images || this.item.images.length === 0) {
                return null;
            }
            return {
                backgroundImage: `url(${this.item.images[0].small})`,
            };
        },
        isInCart() {
            if (!this.getCartResult) {
                return false;
            }
            return this.getCartResult.lines.some(x => x.product.id === this.item.id);
        },
    },
    methods: {
        toggleMap() {
            this.isMapActive = !this.isMapActive;
        },
        addToCart() {
            const params = {
                quantity: 1,
                product: this.item,
            };
            this.$store.dispatch('cart/addToCart', params);
            this.$store.commit('sideblocks/pop');
            this.$store.commit('sideblocks/push', 'cart');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-availability-confirm {
    .mixin-sideblock();

    min-height: 100%;
    padding: 0;
    &__store {
        &-header {
            position: relative;

            padding-top: 69.4%;
            margin-bottom: 24px;
            &-map,
            &-cover {
                .transition-fade();

                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;

                width: 100%;
                height: 100%;
            }
            &-map {
                & > iframe {
                    width: 100%;
                    height: 100%;
                }
            }
            &-cover {
                .mixin-background-image();

                background-color: @color-gray-light;
            }
        }
        &-title {
            .typography-display-md();

            padding: 0 20px;
            margin-bottom: 24px;

            text-transform: uppercase;
        }
        &-contacts {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            padding: 0 20px;
            margin-bottom: 16px;
            &-area {
                flex: 0 0 auto;
                width: 140px;
                margin-right: 16px;
            }
            &-info {
                flex: 1 1 auto;
            }
        }
        &-actions {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            width: 100%;
            padding: 0 20px;
            margin-bottom: 58px;
            &-toggler {
                flex: 0 0 auto;
                width: 140px;
                margin-right: 16px;
                &-text {
                    text-decoration: underline;

                    cursor: pointer;
                }
            }
            &-more {
                flex: 1 1 auto;
            }
        }
    }
    &__item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 20px;
        margin-bottom: 20px;
        &-cover {
            flex: 0 0 auto;
            width: 140px;
            margin-right: 16px;
            &-image {
                .mixin-background-image();

                width: 100%;
                padding-top: 51.43%;

                background-color: @color-gray-light;
            }
        }
        &-info {
            flex: 1 1 auto;
            &-availability {
                margin-bottom: 16px;
            }
        }
    }
    &__button {
        padding: 0 20px 20px;
        &-title {
            margin-bottom: 16px;

            text-align: center;
        }
    }
    @media @media-sm-down {
        padding-bottom: 60px;
        &__store {
            &-contacts {
                display: block;
                margin-bottom: 24px;
                &-area {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 8px;
                }
            }
            &-actions {
                flex-direction: row-reverse;
                justify-content: space-between;
                &-toggler {
                    flex: 0 0 auto;
                    width: auto;

                    text-align: right;
                }
                &-more {
                    flex: 0 1 auto;
                    padding-right: 16px;
                }
            }
        }
        &__item {
            display: block;
            margin-bottom: 16px;
            &-cover {
                margin-right: auto;
                margin-bottom: 8px;
            }
        }
    }
}
</style>
