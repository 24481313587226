var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.component,{tag:"component",staticClass:"ui-button",class:{
            _accented: _vm.isAccented && !_vm.isDisabled,
            _bordered: _vm.isBordered,
            _disabled: _vm.isDisabled,
            _uppercase: _vm.isUppercase,
            _inverted: _vm.isInverted,
            _colored: _vm.isColored,
            _borderless: _vm.isBorderless,
            _gray: _vm.variant === 'gray',
            _yellow: _vm.variant === 'yellow',
        },attrs:{"type":_vm.type ? _vm.type : null,"href":_vm.href ? _vm.href : null},on:{"click":_vm.clickHandler}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }