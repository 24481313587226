<template>
    <label class="common-catalog-filters-fitting">
        <div class="common-catalog-filters-fitting__checkbox">
            <ui-input-base-checkbox
                v-bind:value="isFittingOnlyActive"
                v-on:change="updateDiscountsFilter"
            />
        </div>
        <div class="common-catalog-filters-fitting__text"
            v-text="'Доступны для примерки дома'"
        />
    </label>
</template>

<script>
export default {
    name: 'common-catalog-filters-fitting',
    computed: {
        activeFilters() {
            return this.$store.getters['catalog/filters/activeFilters'];
        },
        isFittingOnlyActive() {
            return this.activeFilters.discounts && this.activeFilters.discounts[0] === false;
        },
        isDiscountsFilterActive() {
            return this.activeFilters.discounts && !!this.activeFilters.discounts[0];
        },
    },
    methods: {
        updateDiscountsFilter(newVal) {
            this.$store.commit('catalog/filters/setFilter', { setCode: 'discounts', filterIds: [ newVal ? false : null ] });
            this.$store.commit('catalog/filters/setFilter', { setCode: 'fitting_allowed', filterIds: [ newVal ? true : null ] });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-catalog-filters-fitting {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    cursor: pointer;
    &__checkbox {
        flex: 0 0 auto;
        margin-right: 16px;
    }
    &__text {
        flex: 0 0 auto;

        text-transform: uppercase;
    }
}
</style>
