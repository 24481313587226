<template>
    <div class="sideblock-materials">
        <div class="sideblock-materials__title">
            Ацетат
        </div>
        <div class="sideblock-materials__text">
            Ацетат целлюлозы. Полимерный термопластик, самый практичный и гипоаллергенный материал.
        </div>
        <div class="sideblock-materials__spacer" />
        <div class="sideblock-materials__title">
            Металл
        </div>
        <div class="sideblock-materials__text">
            Нержавеющая сталь. Гигиеничный, легкий, но прочный и устойчивый к коррозии материал. В избранных моделях могут присутстовать сплавы других металлов в составных частях.
        </div>
        <div class="sideblock-materials__spacer" />
        <div class="sideblock-materials__title">
            Комбинированый
        </div>
        <div class="sideblock-materials__text">
            При изготовлении используются как ацетат целлюлозы, так и нержавеющая сталь высокого качества.
        </div>
        <div class="sideblock-materials__spacer" />
    </div>
</template>

<script>
export default {
    name: 'sideblock-materials',
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-materials {
    .mixin-sideblock();

    padding: 20px 0;
    &__title {
        flex: 0 0 auto;
        padding: 0 20px;
        margin-bottom: 16px;

        text-transform: uppercase;
    }
    &__text {
        flex: 0 0 auto;
        padding: 0 20px;
        margin-bottom: 20px;

        &-trigger {
            text-decoration: underline;

            cursor: pointer;
        }
    }
    &__spacer {
        margin-top: 40px;
    }
}
</style>
