<template>
    <div class="common-placeholder-collection-list">
        <div class="common-placeholder-collection-list__item" />
        <div class="common-placeholder-collection-list__item" />
        <div class="common-placeholder-collection-list__item" />
        <div class="common-placeholder-collection-list__item" />
    </div>
</template>

<script>
export default {
    name: 'common-placeholder-collection-list',
};
</script>

<style scoped lang="less">
@import "~theme";

.common-placeholder-collection-list {
    .container-paddings();

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1px;
    width: 100%;
    min-height: 165px;
    padding-top: 22px;
    padding-bottom: 23px;

    overflow: hidden;
    &__item {
        flex: 0 0 auto;
        display: block;
        width: 128px;

        text-decoration: none;
        &:nth-child(2) {
            opacity: 0.66;
        }
        &:nth-child(3) {
            opacity: 0.33;
        }
        &:nth-child(4) {
            opacity: 0.11;
        }
        &:after {
            content: '';

            display: block;
            height: 82px;
            margin-bottom: 8px;

            background-color: fade(@color-gray-main, 50%);
        }
    }
}
</style>
