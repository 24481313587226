<template>
    <div class="page-checkout-step-shipping-country">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-bind:submit-handler="submitHandler"
            v-on:update="updateFormData"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import forms from '~/forms';

export default {
    name: 'page-checkout-step-shipping-country',
    inject: [ 'pageCheckoutEventBus', 'mode' ],
    data: () => ({
        innerFields: {
            country: { ...forms.fields.country, noAsterisk: true },
            customCountry: { ...forms.fields.customCountry, noAsterisk: true },
        },
    }),
    validations() {
        const result = {
            fields: {
                country: { ...forms.validations.defaultRequired },
            },
        };
        if (this.isCustomCountry) {
            result.fields.customCountry = { ...forms.validations.defaultRequired };
        }
        return result;
    },
    computed: {
        ...mapState('checkout', [ 'isShippingDirty' ]),
        ...mapState('areas', [ 'getAreasResult', 'currentId' ]),
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        isSelfPickup() {
            return this.$store.getters['checkout/isSelfPickup'];
        },
        countries() {
            if (this.mode === 'fitting') {
                return this.getAreasResult.filter(x => config.fitting.validCountries.indexOf(x.name) > -1);
            }
            return this.getAreasResult;
        },
        isCustomCountry() {
            return this.$store.getters['checkout/isCustomCountry'];
        },
        fields() {
            const result = {
                country: {
                    ...this.innerFields.country,
                    options: this.countries,
                    value: this.checkoutData.country?.value || '',
                },
            };
            if (this.isCustomCountry) {
                result.customCountry = { ...this.innerFields.customCountry };
                result.customCountry.value = this.checkoutData.customCountry;
            }
            return result;
        },
    },
    methods: {
        updateFormData(newData) {
            const result = {
                country: this.countries.find(x => x.value === newData.country.value) || null,
                city: null,
            };
            if (newData.customCountry) {
                result.customCountry = newData.customCountry.value;
            }
            this.$store.commit('checkout/updateData', result);
        },
        submitHandler() {
            this.pageCheckoutEventBus.$emit('shipping-submit');
        },
    },
    mounted() {
        if (!this.checkoutData.country) {
            this.$store.commit('checkout/updateData', {
                country: this.countries.find(x => x.title === 'Россия') || null,
            });
        }
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$emit('shipping-validation', { country: true });
    },
    watch: {
        '$v.$invalid': {
            immediate: true,
            handler(newVal) {
                this.pageCheckoutEventBus.$emit('shipping-validation', { country: !newVal });
                if (this.isShippingDirty) {
                    this.$v.$touch();
                }
            },
        },
        isShippingDirty: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                } else {
                    this.$v.$reset();
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-country {
}
</style>
