<template>
    <div class="page-dashboard-info-form-data">
        <div class="page-dashboard-info-form-data__loader"
            v-if="isLoading"
            >
            <div class="page-dashboard-info-form-data__loader-wrapper">
                <ui-loader />
            </div>
        </div>
        <div class="page-dashboard-info-form-data__title"
            v-text="'Личные данные'"
        />
        <div class="page-dashboard-info-form-data__fields">
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
            />
        </div>
        <div class="page-dashboard-info-form-data__error"
            v-if="error !== null"
            v-text="error"
        />
        <div class="page-dashboard-info-form-data__button">
            <ui-button
                v-on:click="submitHandler"
                >
                Сохранить изменения
            </ui-button>
        </div>
    </div>
</template>

<script>
import forms from '~/forms';

export default {
    name: 'page-dashboard-info-form-data',
    data: () => ({
        fields: {
            email: { ...forms.fields.email },
            firstName: { ...forms.fields.firstName },
            lastName: { ...forms.fields.lastName },
        },
    }),
    validations: {
        fields: {
            email: { ...forms.validations.email },
            firstName: { ...forms.validations.defaultRequired },
            lastName: { ...forms.validations.defaultRequired },
        },
    },
    computed: {
        isLoading() {
            return !this.user || this.$store.getters['user/state'].updateIsLoading;
        },
        error() {
            const error = this.$store.getters['user/state'].updateError;
            if (error === 'Email exists') {
                return 'Введенный email уже используется';
            }
            return this.$store.getters['user/state'].updateError;
        },
        user() {
            return this.$store.getters['user/user'];
        },
    },
    methods: {
        updateFormData(newData) {
            this.fields = {
                ...this.fields,
                ...newData,
            };
        },
        submitHandler() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                const result = {
                    email: this.fields.email.value,
                    firstName: this.fields.firstName.value,
                    lastName: this.fields.lastName.value,
                };
                this.$store.dispatch('user/update', result);
            }
        },
    },
    watch: {
        user: {
            immediate: true,
            handler(newVal) {
                if (newVal !== null) {
                    this.fields.email.value = newVal.email;
                    this.fields.firstName.value = newVal.firstName;
                    this.fields.lastName.value = newVal.lastName;
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-dashboard-info-form-data {
    position: relative;

    &__loader {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        width: 100%;
        height: 100%;

        background: @color-gray-lightest;
        &-wrapper {
            width: 50px;
            margin: auto;
        }
    }
    &__title {
        margin-bottom: 20px;

        text-transform: uppercase;
    }
    &__error {
        margin-top: 20px;

        color: @color-accent-warm;
    }
    &__button {
        margin-top: 20px;
        height: 40px;
    }
}
</style>
