import { mainClient } from '~/network';

const state = {
    subscribeResult: null,
    subscribeIsLoading: false,
    subscribeError: null,
};

const getters = {};

const mutations = {};

const actions = {
    async subscribe({ state }, { onSuccess = null, onError = null, ...payload }) {
        if (state.subscribeIsLoading) {
            return;
        }

        state.subscribeIsLoading = true;

        const { error } = await mainClient.common.subscribe(payload);

        if (error) {
            state.subscribeError = error;
            state.subscribeResult = null;
            if (onError) {
                onError(state.subscribeError);
            }
        } else {
            state.subscribeError = null;
            state.subscribeResult = 'success';
            if (onSuccess) {
                onSuccess(state.subscribeResult);
            }
        }

        state.subscribeIsLoading = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
