<template>
    <div class="common-expandable-item"
        v-bind:class="{ '_expanded': isExpanded }"
        >
        <div class="common-expandable-item__headline"
            v-on:click="toggle"
            >
            <div class="common-expandable-item__headline-title"
                v-text="title"
            />
            <div class="common-expandable-item__headline-arrow">
                ↓
            </div>
        </div>
        <div class="common-expandable-item__content"
            v-if="isExpanded"
            >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'common-expandable-item',
    props: {
        title: {
            type: String,
            default: '',
        },
        isOpened: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isExpanded: false,
    }),
    methods: {
        toggle() {
            this.isExpanded = !this.isExpanded;
        },
    },
    beforeMount() {
        if (this.isOpened) {
            this.isExpanded = true;
        }
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-expandable-item {
    &__headline {
        display: flex;
        width: 100%;
        padding: 11px 20px 9px;

        text-transform: uppercase;

        cursor: pointer;
        &-title {
            flex: 1 1 auto;
        }
        &-arrow {
            flex: 0 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;

            transform: rotate(-90deg);

            transition: transform @duration-faster @easing-default;
        }
        ._expanded > & &-arrow {
            transform: rotate(0);
        }
    }
    &__content {
        padding: 0 20px;
    }
}
</style>
