<template>
    <ui-input-base-text
        v-bind:on-input="formatValue"
        v-bind:is-invalid="isInvalid"
        v-bind="model"
        v-on:input="inputHandler"
    />
</template>

<script>
export default {
    name: 'ui-input-email',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    methods: {
        formatValue(e) {
            e.target.value = e.target.value.toLowerCase();
        },
        inputHandler(newValue) {
            const formattedValue = newValue.toLowerCase();
            this.$emit('input', formattedValue);
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";
</style>
