<template>
    <div class="modal-common">
        <div class="modal-common__title"
            if="title"
            v-text="title"
        />
        <div class="modal-common__text"
            v-text="text"
        />
        <div class="modal-common__buttons"
            v-if="buttons !== null"
            >
            <div class="modal-common__buttons-item"
                v-for="(button, i) in buttons"
                v-bind:key="i"
                >
                <ui-button
                    v-on:click="buttonClickHandler(i)"
                    v-bind="button"
                    v-text="button.text"
                />
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'modal-common',
    props: {
        title: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: 'Ошибка',
        },
        buttons: {
            type: Array,
            default: null,
        },
    },
    methods: {
        buttonClickHandler(i) {
            const callback = this.buttons[i].callback;
            if (callback !== undefined) {
                callback();
            }
            this.$store.commit('modals/pop');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-common {
    .mixin-modal();

    box-shadow: 0 0 60px 0 @color-accent-cold;
    &__title {
        margin-bottom: 40px;
        padding-right: 40px;

        text-transform: uppercase;
    }
    &__text {
        max-width: 425px;
        margin-bottom: 20px;

        white-space: pre-line;
    }
    &__buttons {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 425px;
        margin: 20px -10px 0;
        &-item {
            height: 40px;
            padding: 0 10px;
        }
    }

    @media @media-md-down {
        &__title {
            margin-bottom: 9px;
        }
        &__text {
            font-size: 1.2rem;
            line-height: 2rem;
        }
    }
}
</style>
