/* eslint-disable camelcase */

export const item = ({ id, sync_id, name, ...data }) => {
    const result = {
        id,
        syncId: sync_id,
        userLabel: name,
        isDefault: data.default,
    };
    return result;
};

export const list = ({ count, results }) => {
    return {
        count,
        items: results.map((x) => item(x)),
    };
};

export default {
    item,
    list,
};
