<template>
    <div class="page-dashboard-info">
        <div class="page-dashboard-info__form">
            <page-dashboard-info-user />
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-dashboard-info',
};
</script>

<style scoped lang="less">
@import '~theme';

.page-dashboard-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    &__form {
        width: 100%;
        max-width: 360px;
    }
    @media @media-xs-down {
        flex-direction: column;
        &__form {
            max-width: 100%;
            padding-bottom: 60px;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
</style>
