<template>
    <component class="plugin-home-about-slider-standard-item swiper-lazy"
        v-on:click="rootClickHandler"
        v-bind:is="url ? 'a' : 'div'"
        v-bind:href="url ? url : null"
        v-bind:class="url ? '_link' : null"
        ref="root"
        >
        <div class="plugin-home-about-slider-standard-item__observer">
            <common-intersection-observer
                v-on:intersect="triggerImpressionAnalytics"
                v-bind:options="{ threshold: 0.8 }"
                self-destruct
            />
        </div>
        <div class="plugin-home-about-slider-standard-item__image"
            v-bind:style="image ? { backgroundImage: `url(${resizedImage})` } : null"
        />
        <div class="plugin-home-about-slider-standard-item__text"
            v-if="text"
            v-html="text"
        />
    </component>
</template>

<script>
import utils from '~/utils';
import ecommerce from '~/utils/ecommerce';

export default {
    name: 'plugin-home-about-slider-standard-item',
    props: {
        image: { type: String, default: null },
        text: { type: String, default: null },
        url: { type: String, default: null },
        ecommerceData: { type: Object, default: null },
    },
    computed: {
        resizedImage() {
            return utils.common.injectResize(this.image, '670xAUTO');
        },
    },
    methods: {
        triggerImpressionAnalytics() {
            if (this.ecommerceData.promotionId &&
                this.ecommerceData.promotionName &&
                this.ecommerceData.creativeName &&
                this.ecommerceData.creativeSlot
            ) {
                ecommerce.promotionImpression(this.ecommerceData);
            }
        },
        rootClickHandler(e) {
            if (!this.url) {
                return;
            }
            e.preventDefault();
            ecommerce.promotionClick(this.ecommerceData);
            if (e.metaKey || e.ctrlKey) {
                window.open(this.url, '_blank');
            } else {
                window.location.href = this.url;
            }
        },
        linkClickHandler(e) {
            e.preventDefault();
            ecommerce.promotionClick(this.ecommerceData);
            if (e.metaKey || e.ctrlKey) {
                window.open(e.target.attributes.href.baseURI, '_blank');
            } else {
                window.location.href = e.target.attributes.href.baseURI;
            }
        },
        bindLinksHandlers() {
            if (!this.$refs.root) {
                return;
            }
            const links = this.$refs.root.getElementsByTagName('a');
            for (let i = 0; i < links.length; i++) {
                links[i].addEventListener('click', this.linkClickHandler);
            }
        },
        unbindLinksHandlers() {
            if (!this.$refs.root) {
                return;
            }
            const links = this.$refs.root.getElementsByTagName('a');
            for (let i = 0; i < links.length; i++) {
                links[i].removeEventListener('click', this.linkClickHandler);
            }
        },
    },
    mounted() {
        this.$nextTick(this.bindLinksHandlers);
    },
    beforeDestroy() {
        this.unbindLinksHandlers();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-about-slider-standard-item {
    .typography-body-md();

    position: relative;

    width: 100%;

    will-change: transform;
    &._link {
        text-decoration: none;

        cursor: pointer;
        &:active {
            cursor: grabbing;
        }
    }
    &__observer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        pointer-events: none;
        opacity: 0;
    }
    &__image {
        .mixin-background-image();

        min-height: 462px;
        width: 100%;

        background-color: @color-gray-main;
    }
    &__text {
        width: 100%;
        padding: 13px 20px 13px 0;
        /deep/ p {
            .typography-body-md();

            margin: 0;
        }
    }
    @media @media-sm-down {
        &__text {
            opacity: 0;
            transform: translateY(calc(100% + 15px));

            transition: transform 0.35s 0.5s cubic-bezier(0.35, 1.19, 0.65, 1),
                opacity 0.35s 0.33s ease-out;

            .swiper-slide-active &,
            .swiper-slide-visible & {
                opacity: 1;

                transform: translateY(0);

                transition-delay: 0.33s;
            }
        }
    }
}
</style>
