<template>
    <ui-input-base-text
        v-bind:on-input="formatDown"
        v-bind:is-invalid="isInvalid"
        v-bind="{ ...model }"
        v-bind:type="'text'"
        v-on:input="inputHandler"
        inputmode="numeric"
        pattern="\\d*"
    />
</template>

<script>
export default {
    name: 'ui-input-numeric',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    methods: {
        formatValue(value) {
            let formattedValue = value;
            formattedValue = formattedValue.replace(/[^0-9]/g, '');
            if (formattedValue.length === 0) {
                return '';
            }
            if (this.model.maxLength) {
                formattedValue = formattedValue.substring(0, this.model.maxLength);
            }
            return formattedValue;
        },
        formatDown(e) {
            e.target.value = this.formatValue(e.target.value);
        },
        inputHandler(newValue) {
            this.$emit('input', this.formatValue(newValue));
        },
    },
    mounted() {
        this.value && this.$emit('input', this.formatValue(this.value));
    },
    watch: {
        'model.maxLength'() {
            this.$emit('input', this.formatValue(this.value));
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";
</style>
