/* eslint-disable */
require('./appstore')
require('./arrow-big')
require('./arrow')
require('./badge-clipon')
require('./badge-sale-light')
require('./badge-sale')
require('./bag')
require('./calendar')
require('./caret-bottom')
require('./caret-left-solid')
require('./caret-left')
require('./caret-right_')
require('./caret-right-solid')
require('./caret-right')
require('./caret-top')
require('./cart_old')
require('./cart-tryon')
require('./cart')
require('./check')
require('./chubrik')
require('./close-thin')
require('./close')
require('./cost')
require('./facebook-round')
require('./facebook')
require('./file')
require('./instagram-round')
require('./instagram')
require('./lens-antiglare')
require('./lens-computer')
require('./lens-feature-anti-blue')
require('./lens-feature-anti-glare')
require('./lens-feature-anti-reflection')
require('./lens-feature-hydrophobic')
require('./lens-feature-one-touch')
require('./lens-feature-solid')
require('./lens-feature-ultra-thin')
require('./lens-feature-uv')
require('./lens-forms')
require('./lens-photochromic')
require('./list')
require('./logo')
require('./m')
require('./menu-arrow')
require('./menu')
require('./minus-round')
require('./note')
require('./pause')
require('./pdf')
require('./play-round')
require('./play')
require('./plus-round')
require('./plus')
require('./question')
require('./search-thick')
require('./search')
require('./share')
require('./sound')
require('./telegram')
require('./time')
require('./twitter')
require('./upload')
require('./user-image')
require('./user')
require('./vkontakte-round')
require('./vkontakte')
require('./whatsapp')
require('./youtube')
