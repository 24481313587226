<template>
    <div class="page-checkout-step-shipping-contacts-communication">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-bind:submit-handler="submitHandler"
            v-on:update="updateFormData"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-checkout-step-shipping-contacts-communication',
    inject: [ 'pageCheckoutEventBus' ],
    data: () => ({
        innerFields: {
            phone: {
                ...forms.fields.phone,
                isHalf: true,
                noAsterisk: true,
            },
            email: {
                ...forms.fields.email,
                isHalf: true,
                noAsterisk: true,
            },
        },
    }),
    validations() {
        const result = {
            fields: {
                phone: { ...forms.validations.phone },
                email: { ...forms.validations.email },
            },
        };
        // if (this.isRussia) {
        //     result.fields.phone = { ...forms.validations.phoneStrict };
        // }
        result.fields.phone.value.isValidForBackend = () => !this.phoneError;
        return result;
    },
    computed: {
        ...mapState('checkout', [ 'isShippingDirty' ]),
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        user() {
            return this.$store.getters['user/user'];
        },
        isRussia() {
            return this.checkoutData.country?.title === 'Россия';
        },
        fields() {
            const result = { ...this.innerFields };
            result.phone.value = this.checkoutData.phone || '';
            // if (this.isRussia) {
            //     result.phone.isStrict = true;
            // }
            result.email.value = this.checkoutData.email || '';
            return result;
        },
        phoneError() {
            return this.$store.state.checkout.error === 'The phone number entered is not valid.';
        },
    },
    methods: {
        updateFormData(newData) {
            if (newData.phone.value !== this.checkoutData.phone && this.phoneError) {
                this.$store.commit('checkout/setEntity', { entity: 'error', value: null });
            }
            this.$store.commit('checkout/updateData', {
                phone: newData.phone.value,
                email: newData.email.value,
            });
        },
        submitHandler() {
            this.pageCheckoutEventBus.$emit('shipping-submit');
        },
        checkSize() {
            const isHalf = window.innerWidth >= 768;
            this.innerFields.phone.isHalf = isHalf;
            this.innerFields.email.isHalf = isHalf;
        },
    },
    beforeMount() {
        this.checkSize();
        window.addEventListener('resize', this.checkSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkSize);
        this.pageCheckoutEventBus.$emit('shipping-validation', { communication: true });
    },
    watch: {
        user: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    const result = {};
                    if (!this.checkoutData.phone && newVal.phone) {
                        result.phone = newVal.phone;
                    }
                    if (!this.checkoutData.email && newVal.email) {
                        result.email = newVal.email;
                    }
                    this.$store.commit('checkout/updateData', result);
                }
            },
        },
        '$v.$invalid': {
            immediate: true,
            handler(newVal) {
                this.pageCheckoutEventBus.$emit('shipping-validation', { communication: !newVal });
            },
        },
        isShippingDirty: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                } else {
                    this.$v.$reset();
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-contacts-communication {
}
</style>
