<template>
    <div>
        layout-default
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'layout-default',
    computed: {
        ...mapState({
            isHeaderFixed: state => state.common.isHeaderFixed && state.common.bodyFixedCounter === 0,
        }),
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.layout-default {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr auto;
    &__header {
        &._fixed {
            position: sticky;
            top: 0;
            left: 0;
            right: 0;
            z-index: 100;
            .cms-toolbar-expanded & {
                top: @cms-toolbar-height !important;
            }
        }
    }
    &__main {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }
    @media @media-md-down {
        &__header {
            position: sticky;
            top: 0;
            left: 0;
            right: 0;
            z-index: 100;
        }
    }
}
</style>
