<template>
    <div class="common-placeholder-card-product" />
</template>

<script>
export default {
    name: 'common-placeholder-card-product',
};
</script>

<style scoped lang="less">
@import "~theme";

.common-placeholder-card-product {
    display: block;
    width: 100%;
    padding-top: 125%;

    pointer-events: none;
}
</style>
