<template>
    <div class="plugin-infopage-slider-item-text">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'plugin-infopage-slider-item-text',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-slider-item-text {
    .plugin-infopage-slider-item._type_text & {
    }
}
</style>
