import { mainClient } from '~/network';
import utils from '~/utils';

const state = {
    bodyFixedCounter: 0,
    isHeaderFixed: false,
    subscribe: {
        isLoading: false,
        error: null,
        isSubscribed: false,
    },
    isLowPowerMode: false,
    isCatalogSliderHintShown: false,
};

const getters = {
    isBodyFixed(state) {
        return state.bodyFixedCounter > 0;
    },
    subscribe(state) {
        return state.subscribe;
    },
};

const actions = {
    async subscribe({ state }, payload) {
        if (state.subscribe.isLoading === 'loading') {
            return;
        }

        state.subscribe.isSubscribed = false;
        state.subscribe.isLoading = true;

        const { error } = await mainClient.common.subscribe(payload);

        if (error) {
            state.error = error;
        } else {
            state.error = null;
            state.subscribe.isSubscribed = true;
        }

        state.subscribe.isLoading = false;
    },
    checkQueryTriggers({ commit }) {
        const queryies = utils.common.parseUrl({ onlyWithValues: false });
        const chatraTrigger = queryies.find(item => item.title === 'chatra_trigger');
        const chatraOrder = queryies.find(item => item.title === 'chatra_order');
        if (chatraTrigger) {
            const checkChatra = () => {
                const isChatraReady = !!window.Chatra && !!window.Chatra.openChat;
                if (isChatraReady) {
                    utils.common.chatraOpen();
                    if (chatraOrder && chatraOrder.value) {
                        utils.common.chatraSendAutoMessage('Здравствуйте! Готовы помочь по заказу ' + chatraOrder.value);
                    }
                    window.history.replaceState('', '', window.location.pathname);
                } else {
                    setTimeout(checkChatra, 1000);
                }
            };
            checkChatra();
        }
        const cabinetRedirect = queryies.find(item => item.title === 'next' && item.value.indexOf('/cabinet/') > -1);
        if (cabinetRedirect) {
            commit(
                'modals/push',
                {
                    name: 'auth',
                    props: {
                        onSuccess: () => {
                            window.location.assign(cabinetRedirect.value);
                        },
                    },
                },
                { root: true }
            );
        }
    },
    checkHashTriggers() {
        let hash = window.location.hash;
        if (!hash) {
            return;
        }
        hash = hash.substring(1);
        if (hash === 'chatra_trigger') {
            utils.common.chatraOpen();
            window.history.replaceState('', '', window.location.href.replace('#chatra_trigger', ''));
        }
    },
};

const mutations = {
    increaseBodyFixedCounter(state) {
        state.bodyFixedCounter++;
    },
    modifyBodyFixedCounter(state, payload) {
        state.bodyFixedCounter += payload;
    },
    decreaseBodyFixedCounter(state) {
        state.bodyFixedCounter--;
    },
    nullifyBodyFixedCounter(state) {
        state.bodyFixedCounter = 0;
    },
    setLowPoverMode(state, payload) {
        state.isLowPowerMode = payload;
    },
    setIsCatalogSliderHintShown(state, payload) {
        state.isCatalogSliderHintShown = payload;
    },
    chatraOpen() {
        utils.common.chatraOpen();
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
