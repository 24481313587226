<template>
    <div class="page-checkout-step-shipping">
        <template v-if="isReady">
            <div class="page-checkout-step-shipping__content"
                v-if="isActive"
                >
                <template v-if="isShippingNeeded">
                    <div class="page-checkout-step-shipping__content-block">
                        <page-checkout-step-shipping-country />
                    </div>
                    <div class="page-checkout-step-shipping__content-block"
                        v-if="checkoutData.country"
                        >
                        <page-checkout-step-shipping-city />
                    </div>
                    <div class="page-checkout-step-shipping__content-block"
                        v-if="isLocationFilled"
                        >
                        <page-checkout-step-shipping-method />
                    </div>
                    <div class="page-checkout-step-shipping__content-block"
                        v-if="isLocationFilled && isSelfPickup"
                        >
                        <page-checkout-step-shipping-store />
                    </div>
                    <div class="page-checkout-step-shipping__content-block"
                        v-if="isLocationFilled && isShipping"
                        >
                        <page-checkout-step-shipping-address />
                    </div>
                </template>
                <div class="page-checkout-step-shipping__content-block"
                    v-if="!isShippingNeeded"
                    >
                    Доставка не требуется, цифровой товар будет отправлен вам на email.
                </div>
                <div class="page-checkout-step-shipping__content-block"
                    v-if="(isLocationFilled && isShippingFilled) || !isShippingNeeded"
                    >
                    <page-checkout-step-shipping-contacts />
                </div>
                <div class="page-checkout-step-shipping__content-block _error"
                    v-if="getShippingPriceError"
                    >
                    Не удалось рассчитать параметры доставки
                    <br />
                    Проверьте введенный адрес и попробуйте снова, либо <span class="ui-trigger">свяжитесь с нами</span> для помощи с оформлением заказа
                </div>
                <div class="page-checkout-step-shipping__content-button">
                    <ui-button
                        v-on:click="submitHandler"
                        v-bind:is-disabled="($v.$dirty && $v.$invalid) || !!getShippingPriceError"
                        is-uppercase
                        >
                        продолжить
                    </ui-button>
                </div>
            </div>
            <div class="page-checkout-step-shipping__result"
                v-if="!isActive && !$v.$invalid"
                >
                <template v-if="isShippingNeeded">
                    <template v-if="isSelfPickup">
                        <span v-text="checkoutData.store.name"></span>
                        <br/>
                        <span v-text="checkoutData.store.address"></span>
                        <br/>
                        <span v-text="checkoutData.store.work_time"></span>
                        <br/>
                    </template>
                    <template v-else>
                        <span v-if="isCustomCountry">
                            <span v-text="checkoutData.customCountry" />
                        </span>
                        <span v-else>
                            <span v-text="checkoutData.country.title" />
                        </span>
                        <span v-if="isCustomCity">
                            <span v-text="', ' + checkoutData.customCity" />
                            <br />
                        </span>
                        <span v-else-if="checkoutData.city">
                            <span v-text="', ' + checkoutData.city.title" />
                            <br />
                        </span>
                        <span v-text="fullAddress" />
                        <br />
                    </template>
                </template>
                <span>
                    {{ checkoutData.firstName }} {{ checkoutData.lastName }}
                    <br />
                    {{ checkoutData.phone }}
                    <br />
                    {{ checkoutData.email }}
                    <br />
                </span>
            </div>
        </template>
        <span v-else-if="isActive">Загрузка...</span>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'page-checkout-step-shipping',
    inject: [ 'pageCheckoutEventBus', 'cartModuleName' ],
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        step: {
            type: Object,
        },
    },
    data: () => ({
        innerValidations: {
            country: true,
            city: true,
            method: true,
            name: true,
            communication: true,
            contactsComment: true,
            streetStandard: true,
            streetDadata: true,
            house: true,
            apartment: true,
            addressComment: true,
            postcode: true,
        },
    }),
    validations() {
        const result = {
            innerValidations: {
                isValid: value => Object.values(value).every(x => !!x),
            },
        };
        return result;
    },
    computed: {
        ...mapState('areas', [ 'getAreasResult' ]),
        ...mapState('shippingMethods', [
            'getShippingMethodsResult',

            'getShippingPriceIsLoading',
            'getShippingPriceResult',
            'getShippingPriceError',
        ]),
        ...mapState('stores', [ 'getStoresResult' ]),
        ...mapGetters('checkout', [
            'isCustomCountry',
            'isCustomCity',
            'isSelfPickup',
            'isShipping',
            'customerData',
        ]),
        cart() {
            return this.$store.state[this.cartModuleName].getCartResult;
        },
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        isReady() {
            return this.getAreasResult &&
                this.getShippingMethodsResult &&
                this.getStoresResult;
        },
        isShippingNeeded() {
            return !this.cart || this.cart.isShippingNeeded;
        },
        isLocationFilled() {
            const isCountryFilled = this.isCustomCountry
                ? this.checkoutData.customCountry
                : this.checkoutData.country;
            const isCityFilled = this.isCustomCity
                ? this.checkoutData.customCity
                : this.checkoutData.city;
            return isCountryFilled && isCityFilled;
        },
        isShippingFilled() {
            return !this.isShippingNeeded ||
                (this.isSelfPickup && this.checkoutData.store) ||
                (this.isShipping && this.checkoutData.shippingMethod);
        },
        fullAddress() {
            return this.$store.getters['checkout/fullAddress'] + (this.checkoutData.postcode
                ? `, ${this.checkoutData.postcode}`
                : ''
            );
        },
    },
    methods: {
        validationHandler(newVal) {
            this.innerValidations = { ...this.innerValidations, ...newVal };
        },
        submitHandler() {
            this.$v.$touch();
            if (this.$v.$invalid || this.getShippingPriceError) {
                return;
            }
            this.pageCheckoutEventBus.$emit('step-completed', this.step);
        },
    },
    beforeMount() {
        this.pageCheckoutEventBus.$on('shipping-submit', this.submitHandler);
        this.pageCheckoutEventBus.$on('shipping-validation', this.validationHandler);

        this.$store.dispatch('communication/getCommunicationList');
        this.$store.dispatch('shippingMethods/getShippingMethods', {
            basket_key: this.cartModuleName,
        });
        if (!this.getAreasResult) {
            this.$store.dispatch('areas/getAreas');
        }
        if (!this.getStoresResult) {
            this.$store.dispatch('stores/getStores');
        }
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$off('shipping-submit');
        this.pageCheckoutEventBus.$off('shipping-validation');
    },
    watch: {
        '$v.$dirty'(newVal) {
            this.$store.commit('checkout/setEntity', { entity: 'isShippingDirty', value: newVal });
        },
        isShippingNeeded: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    this.$store.state.checkout.data.shippingMethod = null;
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping {
    &__content {
        &-block {
            margin-bottom: 20px;
            &._error {
                color: @color-accent-warm;
            }
        }
        &-button {
            height: 48px;
            margin-top: 24px;
        }
    }
    &__result {
        padding-left: 16px;
        padding-bottom: 16px;

        color: @color-gray-dark;
    }
}
</style>
