<template>
    <section class="modal-render-giftcard">
        <div class="modal-render-giftcard__loader">
            <div class="modal-render-giftcard__loader-container">
                <ui-loader />
            </div>
        </div>
        <div class="modal-render-giftcard__wrapper _hidden"
            v-if="giftcardData"
            >
            <div class="modal-render-giftcard__container"
                v-bind:style="backgroundStyle"
                >
                <div class="modal-render-giftcard__headline">
                    <div class="modal-render-giftcard__headline-text">
                        Поздравляем! Поздравляем! Поздравляем!
                    </div>
                </div>
                <div class="modal-render-giftcard__logo">
                    <img class="modal-render-giftcard__logo-image"
                        v-bind:src="images.logo"
                    />
                </div>
                <div class="modal-render-giftcard__title">
                    <div class="modal-render-giftcard__title-text">
                        Подарочный
                        <br/>
                        сертификат
                    </div>
                </div>
                <div class="modal-render-giftcard__stat">
                    <div class="modal-render-giftcard__stat-title">
                        <div class="modal-render-giftcard__stat-title-text">
                            на сумму:
                        </div>
                    </div>
                    <div class="modal-render-giftcard__stat-value">
                        <div class="modal-render-giftcard__stat-value-text">
                            {{ giftcardData.nominal }} ₽
                        </div>
                    </div>
                </div>
                <div class="modal-render-giftcard__info">
                    <div class="modal-render-giftcard__info-item"
                        v-if="giftcardData.to"
                        >
                        <div class="modal-render-giftcard__info-item-title">
                            <div class="modal-render-giftcard__info-item-title-text">
                                кому:
                            </div>
                        </div>
                        <div class="modal-render-giftcard__info-item-value">
                            <div class="modal-render-giftcard__info-item-value-text">
                                {{ giftcardData.to }}
                            </div>
                        </div>
                    </div>
                    <div class="modal-render-giftcard__info-item"
                        v-if="giftcardData.from"
                        >
                        <div class="modal-render-giftcard__info-item-title">
                            <div class="modal-render-giftcard__info-item-title-text">
                                от кого:
                            </div>
                        </div>
                        <div class="modal-render-giftcard__info-item-value">
                            <div class="modal-render-giftcard__info-item-value-text">
                                {{ giftcardData.from }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-render-giftcard__stat">
                    <div class="modal-render-giftcard__stat-title">
                        <div class="modal-render-giftcard__stat-title-text">
                            № сертификата:
                        </div>
                    </div>
                    <div class="modal-render-giftcard__stat-value">
                        <div class="modal-render-giftcard__stat-value-text _code">
                            {{ giftcardData.code }}
                        </div>
                    </div>
                </div>
                <div class="modal-render-giftcard__description">
                    <div class="modal-render-giftcard__description-qr">
                        <img class="modal-render-giftcard__description-qr-image"
                            v-bind:src="images.qr"
                        />
                    </div>
                    <div class="modal-render-giftcard__description-info">
                        <div class="modal-render-giftcard__description-info-text">
                            Это бессрочный сертификат, который действует на все товары на сайте и в магазинах P.Y.E.
                            <br />
                            Вы можете использовать его частично или полностью. Если после оплаты сертификатом останется разница — вы сможете потратить ее в другой раз.
                        </div>
                    </div>
                </div>
                <div class="modal-render-giftcard__buttons">
                    <a class="modal-render-giftcard__buttons-item"
                        v-bind:style="{ backgroundImage: 'url(' + images.button + ')' }"
                        href="https://pyeoptics.com"
                        target="_blank"
                        >
                        <div class="modal-render-giftcard__buttons-item-text">
                            pyeoptics.com
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'modal-render-giftcard',
    inject: [ 'staticPathRoot' ],
    props: {
        mode: {
            type: String,
            default: 'png',
        },
        url: {
            type: String,
            default: null,
        },
        givenData: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        giftcardData: null,
        modalOpeningDelay: 2000,
        retryDelay: 3000,
    }),
    computed: {
        ...mapState('orders', [
            'getOrderGiftCardResult',
            'getOrderGiftCardIsLoading',
            'getOrderGiftCardError',
        ]),
        backgroundStyle() {
            return { backgroundImage: `url(${this.staticPathRoot}/images/gift-cards/generator/bg.jpg)` };
        },
        images() {
            return {
                logo: `${this.staticPathRoot}/images/giftcard-logo.svg`,
                qr: `${this.staticPathRoot}/images/qr.svg`,
                button: `${this.staticPathRoot}/images/gift-cart-btn-bg.png`,
            };
        },
    },
    methods: {
        checkAndInjectResources() {
            const scripts = [
                `${this.staticPathRoot}/scripts/html2canvas.min.js`,
                `${this.staticPathRoot}/scripts/jspdf.umd.min.js`,
            ];
            const fonts = [
                {
                    id: 'panama-demo-font',
                    path: `${this.staticPathRoot}/fonts/Panama Demo/Regular.otf`,
                },
            ];
            scripts.forEach((script) => {
                if (!document.querySelector(`script[src="${script}"]`)) {
                    const scriptEl = document.createElement('script');
                    scriptEl.setAttribute('src', script);
                    document.head.appendChild(scriptEl);
                }
            });
            fonts.forEach((font) => {
                if (document.getElementById(font.id)) {
                    return;
                }
                const cssText = `@font-face {
                    font-family: 'Panama Demo';
                    src: url("${font.path}") format("opentype");
                }`;
                const style = document.createElement('style');
                style.setAttribute('type', 'text/css');
                style.setAttribute('id', 'panama-demo-font');
                style.appendChild(document.createTextNode(cssText));
                document.head.appendChild(style);
            });
        },
        prerenderCheck() {
            setTimeout(() => {
                if (document.fonts?.ready !== undefined) {
                    document.fonts.ready.then(this.renderAndSave);
                } else {
                    this.renderAndSave();
                }
            }, this.modalOpeningDelay);
        },
        renderAndSave() {
            if (!window.html2canvas || !window.jspdf) {
                setTimeout(this.renderAndSave, 1000);
                return;
            }
            const options = {
                windowWidth: 1200,
                windowHeight: 2000,
                width: 1000,
                height: 1408,
                scale: this.mode === 'pdf' ? 1.5 : 2,
                useCORS: true,
                onclone: (document) => {
                    document.querySelector('.modal-render-giftcard__wrapper').classList.remove('_hidden');
                },
            };
            if (this.mode === 'pdf') {
                options.ignoreElements = (element) => {
                    return element.classList.contains('_code');
                };
            }
            window.html2canvas(
                document.querySelector('.modal-render-giftcard__container'),
                options
            ).then(canvas => {
                if (this.mode === 'png') {
                    const link = document.createElement('a');
                    link.download = 'Ваш подарок в Оптике PYE.png';
                    link.href = canvas.toDataURL('image/png');
                    canvas.toBlob((blob) => {
                        const blobUrl = URL.createObjectURL(blob);
                        window.open(blobUrl, '_blank');
                    }, 'image/png');
                    document.body.appendChild(link);
                    link.click();
                    setTimeout(() => {
                        document.body.removeChild(link);
                    }, 1000);
                } else if (this.mode === 'pdf') {
                    // eslint-disable-next-line new-cap
                    const pdf = new window.jspdf.jsPDF({
                        unit: 'px',
                        format: [1500, 2112],
                    });
                    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 1500, 2112);
                    pdf.setFontSize(54);
                    pdf.text(this.giftcardData.code, 750, 1509, { align: 'center' });
                    pdf.link(548, 1811, 413, 150, { url: 'https://pyeoptics.com' });
                    pdf.save('Ваш подарок в Оптике PYE.pdf');
                }
                this.$store.commit('modals/pop');
            });
        },
    },
    beforeMount() {
        this.checkAndInjectResources();
        if (this.givenData) {
            this.giftcardData = this.givenData;
            this.prerenderCheck();
        } else {
            this.$store.dispatch('orders/getOrderGiftCard', { url: this.url });
        }
    },
    watch: {
        getOrderGiftCardResult(newVal) {
            if (newVal) {
                this.giftcardData = newVal;
                this.prerenderCheck();
            }
        },
        getOrderGiftCardError(newVal) {
            this.$store.state.orders.getOrderGiftCardError = null;
            if (newVal) {
                setTimeout(() => {
                    this.$store.dispatch('orders/getOrderGiftCard', { url: this.url });
                }, this.retryDelay);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-render-giftcard {
    .mixin-modal();

    position: relative;

    width: fit-content;
    padding: 40px;
    &__loader {
        padding: 40px;
        &-container {
            width: 100px;
        }
    }
    &__wrapper {
        .typography-body-md();

        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;

        width: 100vw;
        height: 100vh;
        &._hidden {
            position: absolute;

            width: 0;
            height: 0;
            overflow: hidden;
            visibility: hidden;

            opacity: 0;
            pointer-events: none;
        }
    }
    &__container {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 1000px;
        max-width: 100%;
        aspect-ratio: 1000 / 1408;
        overflow: hidden;

        font-size: ~'calc(min(1000px, 100vw - 80px) / 1000)';
        text-align: center;

        background-color: @color-accent-bronze;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__headline {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 110em;
        padding: 15em 0;

        line-height: 80em;
        text-transform: uppercase;
        white-space: nowrap;

        background-color: #00E08F;
        &-text {
            padding-bottom: 0.35em;

            font-size: 80em;
            font-family: 'Panama Demo';
        }
    }
    &__logo {
        flex: 0 0 auto;
        margin-top: 149em;
        &-image {
            display: block;
            width: 198em;
            height: 62em;
        }
    }
    &__title {
        flex: 0 0 auto;
        height: 144em;
        margin-top: 31em;
        margin-bottom: 50em;

        line-height: 72em;
        &-text {
            padding-bottom: 0.35em;

            font-size: 72em;
            font-family: 'Panama Demo';
            text-transform: uppercase;
        }
    }
    &__stat {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &-title {
            height: 22em;
            margin-bottom: 12em;

            line-height: 22em;
            &-text {
                margin-top: -0.35em;

                font-size: 28em;
                font-family: 'Panama Demo';
            }
        }
        &-value {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 263em;
            height: 46em;

            line-height: 46em;

            background-color: @color-gray-lightest;

            box-shadow: 0 0 20px 0 @color-gray-lightest;
            &-text {
                padding-bottom: 0.35em;

                font-size: 28em;
            }
        }
    }
    &__info {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &-item {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 22em 0;
            &-title {
                height: 22em;
                margin-bottom: 12em;

                line-height: 22em;
                &-text {
                    margin-top: -0.35em;

                    font-size: 28em;
                    font-family: 'Panama Demo';
                }
            }
            &-value {
                height: 32em;

                line-height: 32em;
                &-text {
                    margin-top: -0.35em;

                    font-size: 28em;
                }
            }
        }
    }
    &__description {
        flex: 0 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 44em;
        &-qr {
            flex: 0 0 auto;
            margin-right: 28em;
            margin-top: 4em;
            &-image {
                display: block;
                width: 96em;
            }
        }
        &-info {
            flex: 0 0 auto;
            height: 96em;
            max-width: 373em;

            line-height: 17em;
            &-text {
                margin-top: -0.35em;

                font-size: 17em;
                font-family: 'Panama Demo';
                text-align: left;
            }
        }
    }
    &__buttons {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 40em;
        margin-bottom: 92em;
        &-item {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 110em;
            width: 274em;
            padding-bottom: 8em;

            line-height: 110em;
            text-decoration: none;

            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            &-text {
                margin-top: -0.6em;

                font-size: 32em;
                font-family: 'Panama Demo';
            }
        }
    }
    @media @media-md-down {
        padding: 40px;
    }
}
</style>
