<template>
    <div class="ui-input-dadata">
        <div class="ui-input-dadata__input">
            <ui-input-base-text
                v-bind:is-invalid="isInvalid"
                v-bind="enrichedModel"
                v-on:input="debouncedRequest"
            />
            <!-- v-bind:handlers="enterHandler ? { 'keydown.enter.stop': enterHandler } : null" -->
        </div>
        <div class="ui-input-dadata__loader"
            v-if="isLoading"
            >
            <div class="ui-input-dadata__loader-container">
                <ui-loader />
            </div>
        </div>
        <div class="ui-input-dadata__options"
            v-if="options"
            >
            <template v-if="options.length > 0">
                <div class="ui-input-dadata__options-item"
                    v-for="(item, index) in options"
                    v-bind:key="index"
                    v-on:click="setValue(item)"
                    >
                    <div class="ui-input-dadata__options-item-texts">
                        <div class="ui-input-dadata__options-item-texts-title"
                            v-text="item.suggestTitle"
                        />
                        <div class="ui-input-dadata__options-item-texts-region"
                            v-if="item.suggestRegion && item.showRegion"
                            v-text="item.suggestRegion"
                        />
                    </div>
                </div>
            </template>
            <div class="ui-input-dadata__options-item _exceptional"
                v-else-if="model.exceptionalCallback"
                v-on:click="setValue(enrichedModel.value, true)"
                >
                {{ enrichedModel.value }}
                <div class="ui-input-dadata__options-item-warning">
                    Не удалось распознать введенный адрес!<br />
                    Если вы уверены, что ввели данные верно, выберите этот пункт.<br />
                    Любую дополнительную информацию по адресу доставки вы можете указать в комментарии для курьера.
                </div>
            </div>
        </div>
        <div class="ui-input-dadata__error"
            v-if="isEmptyOrError"
            >
            Не удалось найти подходящие варианты города для доставки, свяжитесь с нами и мы поможем оформить заказ
        </div>
    </div>
</template>

<script>
import { mainClient } from '~/network';

export default {
    name: 'ui-input-dadata',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    data: () => ({
        toid: null,
        isLoading: false,
        options: null,
        error: null,
        tempValue: null,
    }),
    computed: {
        isEmptyOrError() {
            return !this.isInvalid && this.error;
            // return !this.isInvalid &&
            //     (this.error ||
            //         (this.options && this.options.length === 0)
            //     );
        },
        enrichedModel() {
            const result = {
                ...this.model,
                // disabled: this.isDisabled,
            };
            if (this.tempValue) {
                result.value = this.tempValue;
            }
            return result;
        },
        isDisabled() {
            return this.isLoading || this.model.disabled;
        },
    },
    methods: {
        debouncedRequest(newData) {
            if (this.toid !== null) {
                clearTimeout(this.toid);
            }
            if (!newData) {
                this.tempValue = null;
                this.options = null;
                this.error = null;
                this.$emit('input', '');
                return;
            }
            this.toid = setTimeout(() => {
                this.requestDadata(newData);
                clearTimeout(this.toid);
                this.toid = null;
            }, 700);
        },
        async requestDadata(newData) {
            if (this.isLoading) {
                return;
            }
            this.tempValue = newData;
            const payload = { ...this.model.dadataParams, query: newData };
            this.isLoading = true;
            const { data, error, status } = await mainClient.dadata.getAddressSuggest(payload);
            if (error) {
                console.error(error, status);
                this.options = null;
                this.error = true;
            } else if (data) {
                this.options = this.model.dadataTransform
                    ? this.model.dadataTransform(data)
                    : data;
                this.error = null;
            }
            this.isLoading = false;
        },
        setValue(value, isExceptional = false) {
            this.tempValue = null;
            this.options = null;
            this.error = null;
            if (isExceptional) {
                this.model.exceptionalCallback(value);
                return;
            } else if (this.model.resetExceptionCallback) {
                this.model.resetExceptionCallback(value);
            }
            this.$emit('input', this.model.dadataInputParser ? this.model.dadataInputParser(value) : value);
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";
.ui-input-dadata {
    position: relative;
    &__loader {
        position: absolute;
        top: 1px;
        right: 1px;
        left: 1px;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;

        background-color: @color-gray-lightest;
        &-container {
            width: 28px;
        }
    }
    &__error {
        margin-top: 8px;
    }
    &__options {
        border-right: 1px solid @color-gray-darkest;
        border-bottom: 1px solid @color-gray-darkest;
        border-left: 1px solid @color-gray-darkest;

        background-color: @color-gray-lightest;
        &-item {
            .typography-body-md();

            width: 100%;
            min-height: 40px;
            padding: 12px 12px 8px;

            cursor: pointer;
            &:hover {
                background-color: @color-gray-main;
            }
            &:not(:last-child) {
                border-bottom: 1px solid @color-gray-main;
            }
            &-texts {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                height: 100%;
                &-title {
                    flex: 0 0 auto;
                }
                &-region {
                    flex: 0 1 auto;
                    overflow: hidden;
                    padding-left: 2ch;

                    color: @color-gray-dark;
                    font-style: italic;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            &-warning {
                color: @color-accent-warm;
                white-space: pre-line;
            }
        }
    }
}
</style>
