<template>
    <div class="page-dashboard-history">
        <div class="page-dashboard-history__loader"
            v-if="getOrdersIsLoading && items.length === 0"
            >
            <div class="page-dashboard-history__loader-wrapper">
                <ui-loader />
            </div>
        </div>
        <div class="page-dashboard-history__title"
            v-text="'История заказов'"
        />
        <div class="page-dashboard-history__table">
            <div class="page-dashboard-history__table-header">
                <div class="page-dashboard-history__table-header-item"
                    v-text="'Заказ'"
                />
                <div class="page-dashboard-history__table-header-item"
                    v-text="'Сумма'"
                />
                <div class="page-dashboard-history__table-header-item"
                    v-text="'Дата создания'"
                />
                <div class="page-dashboard-history__table-header-item"
                    v-text="'Статус'"
                />
                <div class="page-dashboard-history__table-header-item" />
            </div>
            <div class="page-dashboard-history__table-list"
                v-if="items.length > 0"
                >
                <page-dashboard-history-order
                    v-for="(order, index) in items"
                    v-bind:key="index"
                    v-bind:order="order"
                    v-bind:pre-expanded="order.number === givenOrder"
                />
            </div>
            <div class="page-dashboard-history__table-empty"
                v-if="getOrdersResult && getOrdersResult.length === 0"
                v-text="'Заказы не найдены'"
            />
            <div class="page-dashboard-history__table-empty"
                v-if="getOrdersError"
                v-text="'Ошибка загрузки истории заказов'"
            />
        </div>
        <div class="page-dashboard-history__more"
            v-if="isMoreAvailable"
            >
            <div class="page-dashboard-history__more-loader"
                v-if="getOrdersIsLoading"
                >
                <ui-loader />
            </div>
            <div class="page-dashboard-history__more-trigger"
                v-else
                v-on:click="getMoreOrders"
                v-text="'Показать все заказы'"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-dashboard-history',
    props: {
        givenOrder: {
            type: [ Number, String ],
            default: null,
        },
    },
    data: () => ({
        page: 1,
        pageSize: 10,
        total: 0,
        items: [],
    }),
    computed: {
        ...mapState('orders', [
            'getOrdersResult',
            'getOrdersIsLoading',
            'getOrdersError',
        ]),
        isMoreAvailable() {
            return this.items.length < this.total;
        },
    },
    methods: {
        getOrders() {
            this.$store.dispatch('orders/getOrders', {
                page: this.page,
                pageSize: this.pageSize,
            });
        },
        getMoreOrders() {
            this.pageSize = this.total;
        },
    },
    beforeMount() {
        this.getOrders();
    },
    watch: {
        pageSize() {
            this.getOrders();
        },
        getOrdersResult(newVal) {
            if (!newVal) {
                return;
            }
            this.total = this.getOrdersResult.count;
            this.items = this.getOrdersResult.results;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-dashboard-history {
    position: relative;
    z-index: 1;

    flex: 1 1 auto;
    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        display: flex;
        width: 100%;
        height: 100%;

        background: @color-gray-lightest;
        &-wrapper {
            width: 50px;
            margin: auto;
        }
    }
    &__title {
        padding-left: 20px;

        text-transform: uppercase;
    }
    &__table {
        width: 100%;
        max-width: 1080px;
        &-header {
            display: flex;
            width: 100%;
            &-item {
                flex: 0 0 16.66%;
                padding: 20px;
                border-bottom: 1px solid @color-gray-darkest;
                &:first-child {
                    flex: 0 0 33.33%;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        &-list {

        }
        &-empty {
            padding: 20px;
        }
    }
    &__more {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 32px 20px;
        &-trigger {
            text-decoration: underline;

            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
        &-loader {
            width: 50px;
            margin: auto;
        }
    }
    @media @media-sm-down {
        &__table {
            &-header {
                &-item {
                    &:last-child {
                        border-bottom: 1px solid @color-gray-darkest;
                    }
                }
            }
        }
    }
    @media @media-xs-down {
        &__title {
            padding-bottom: 40px;
            border-bottom: 1px solid @color-gray-darkest;
        }
        &__table {
            &-header {
                display: none;
            }
        }
    }
}
</style>
