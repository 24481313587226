<template>
    <div class="page-checkout-step-shipping-contacts-name">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-bind:submit-handler="submitHandler"
            v-on:update="updateFormData"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-checkout-step-shipping-contacts-name',
    inject: [ 'pageCheckoutEventBus' ],
    data: () => ({
        innerFields: {
            firstName: { ...forms.fields.firstName, noAsterisk: true, isHalf: true },
            lastName: { ...forms.fields.lastName, noAsterisk: true, isHalf: true },
        },
    }),
    validations() {
        return {
            fields: {
                firstName: { ...forms.validations.defaultRequired },
                lastName: { ...forms.validations.defaultRequired },
            },
        };
    },
    computed: {
        ...mapState('checkout', [ 'isShippingDirty' ]),
        user() {
            return this.$store.getters['user/user'];
        },
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        fields() {
            const result = { ...this.innerFields };
            result.firstName.value = this.checkoutData.firstName;
            result.lastName.value = this.checkoutData.lastName;
            return result;
        },
    },
    methods: {
        updateFormData(newData) {
            this.$store.commit('checkout/updateData', {
                firstName: newData.firstName.value,
                lastName: newData.lastName.value,
            });
        },
        submitHandler() {
            this.pageCheckoutEventBus.$emit('shipping-submit');
        },
        checkSize() {
            const isHalf = window.innerWidth >= 768;
            this.innerFields.firstName.isHalf = isHalf;
            this.innerFields.lastName.isHalf = isHalf;
        },
    },
    beforeMount() {
        this.checkSize();
        window.addEventListener('resize', this.checkSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkSize);
        this.pageCheckoutEventBus.$emit('shipping-validation', { name: true });
    },
    watch: {
        user: {
            immediate: true,
            handler(newVal) {
                const result = {};
                if (!this.checkoutData.firstName && newVal && newVal.firstName) {
                    result.firstName = newVal.firstName;
                }
                if (!this.checkoutData.lastName && newVal && newVal.lastName) {
                    result.lastName = newVal.lastName;
                }
                if (Object.keys(result).length > 0) {
                    this.$store.commit('checkout/updateData', result);
                }
            },
        },
        '$v.$invalid': {
            immediate: true,
            handler(newVal) {
                this.pageCheckoutEventBus.$emit('shipping-validation', { name: !newVal });
            },
        },
        isShippingDirty: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                } else {
                    this.$v.$reset();
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-contacts-name {
}
</style>
