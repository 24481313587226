<template>
    <div class="plugin-infopage-slider-item"
        v-bind:class="'_type_' + type"
        >
        <div class="plugin-infopage-slider-item__content"
            v-bind:style="style"
            >
            <slot />
        </div>
        <common-customizable-link class="plugin-infopage-slider-item__overlay"
            v-if="url"
            v-bind:href="url"
        />
    </div>
</template>

<script>
export default {
    name: 'plugin-infopage-slider-item',
    props: {
        type: String,
        url: {
            type: String,
            default: null,
        },
        textColor: String,
        backgroundColor: String,
    },
    computed: {
        style() {
            if (this.type === 'square') {
                return null;
            }
            return { color: this.textColor, backgroundColor: this.backgroundColor };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-slider-item {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100%;
    width: 100%;
    &__content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        ._type_text > & {
            justify-content: flex-end;
            padding: 24px 20px;
            border-radius: 8px;
            overflow: hidden;
        }
        ._type_image > & {
            border-radius: 8px;
            overflow: hidden;
        }
    }
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: block;
        width: 100%;
        height: 100%;

        text-decoration: none;

        opacity: 0;
    }
}
</style>
