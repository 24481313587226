<template>
    <component class="ui-button-new"
        v-bind:is="component"
        v-bind:type="type ? type : null"
        v-bind:href="href ? href : null"
        v-bind:target="isNewWindowNeeded || null"
        v-on:click="clickHandler"
        v-bind:class="[
            '_theme_' + theme,
            { _disabled: isDisabled },
        ]"
        >
        <slot />
    </component>
</template>

<script>
export default {
    name: 'ui-button-new',
    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: 'primary',
        },
        type: {
            type: String,
            default: 'button',
        },
        href: {
            type: String,
            default: null,
        },
        isNewWindowNeeded: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        component() {
            if (this.href) {
                return 'common-customizable-link';
            }
            if (this.type === 'submit' || this.type === 'button') {
                return 'button';
            }
            return 'div';
        },
    },
    methods: {
        clickHandler(e) {
            this.$emit('click', e);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-button-new {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 16px 24px;
    border-radius: 4px;

    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: center;
    text-decoration: none;

    cursor: pointer;
    user-select: none;
    appearance: none;

    transition: all @duration-fast @easing-default;
    &._theme_primary {
        color: @color-gray-lightest;

        background-color: @color-gray-darkest;
        &:hover {
            background-color: #333;
        }
        &:disabled {
            color: fade(@color-gray-lightest, 50%);

            background-color: #333;
        }
    }
    &._theme_secondary {
        box-shadow: inset 0 0 0 1px currentColor;

        color: @color-gray-darkest;

        background-color: transparent;
        &:hover {
            box-shadow: inset 0 0 0 1px transparent;

            background-color: rgba(0, 0, 0, 0.12);
        }
        &:disabled {
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);

            color: rgba(0, 0, 0, 0.5);

            background-color: transparent;
        }
    }
    &._theme_primary-inverted {
        color: @color-gray-darkest;

        background-color: @color-gray-lightest;
        &:hover {
            background-color: @color-gray-main;
        }
        &:disabled {
            color: fade(@color-gray-darkest, 50%);

            background-color: @color-gray-lightest;
        }
    }
    &._theme_secondary-inverted {
        box-shadow: inset 0 0 0 1px currentColor;

        color: @color-gray-lightest;

        background-color: transparent;
        &:hover {
            box-shadow: inset 0 0 0 1px transparent;

            background-color: rgba(255, 255, 255, 0.14);
        }
        &:disabled {
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.14);

            color: rgba(255, 255, 255, 0.5);

            background-color: transparent;
        }
    }
}
</style>
