<template>
    <div class="common-header-main-menu-submenu">
        <div class="common-header-main-menu-submenu__list">
            <div class="common-header-main-menu-submenu__list-item"
                v-for="(item, index) in items"
                v-bind:key="index"
                >
                <div class="common-header-main-menu-submenu__list-item-link"
                    v-bind:is="item.url ? 'a' : 'div'"
                    v-bind:class="{
                        _active: index === overridedIndex,
                        _with_link: item.url,
                    }"
                    v-bind:href="item.url ? item.url : null"
                    v-bind:target="item.target ? item.target : null"
                    v-on:mouseenter="mouseenterHandler(index)"
                    v-on:mouseleave="mouseleaveHandler"
                    >
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="common-header-main-menu-submenu__sublist">
            <div class="common-header-main-menu-submenu__sublist-item"
                v-for="(item, index) in currentChildren"
                v-bind:key="index"
                v-bind:class="{ _with_children: item.items && item.items.length > 0 }"
                >
                <div class="common-header-main-menu-submenu__sublist-item-link"
                    v-bind:is="item.url ? 'a' : 'div'"
                    v-bind:class="{ _with_link: item.url }"
                    v-bind:href="item.url ? item.url : null"
                    v-bind:target="item.target ? item.target : null"
                    >
                    {{ item.name }}
                </div>
                <div class="common-header-main-menu-submenu__sublist-item-list"
                    v-if="item.items && item.items.length > 0"
                    >
                    <div class="common-header-main-menu-submenu__sublist-item-list-item"
                        v-for="(item, index) in item.items"
                        v-bind:key="index"
                        >
                        <a class="common-header-main-menu-submenu__sublist-item-list-item-link"
                            v-bind:is="item.url ? 'a' : 'div'"
                            v-bind:class="{ _with_link: item.url }"
                            v-bind:href="item.url ? item.url : null"
                            v-bind:target="item.target ? item.target : null"
                            >
                            {{ item.name }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="common-header-main-menu-submenu__promo"
            v-if="promo"
            >
            <div class="common-header-main-menu-submenu__promo-text">
                <a class="common-header-main-menu-submenu__promo-text-link"
                    v-bind:href="promo.url"
                    v-text="promo.text"
                />
            </div>
            <a class="common-header-main-menu-submenu__promo-image"
                v-bind:href="promo.url"
                >
                <img class="common-header-main-menu-submenu__promo-image-content"
                    v-bind:src="imageResized"
                />
            </a>
        </div>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'common-header-main-menu-submenu',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        activeIndex: 0,
        overridedIndex: null,
    }),
    computed: {
        currentChildren() {
            return this.getChildrenByIndex(this.activeIndex);
        },
        activeItem() {
            return this.items.length > (this.activeIndex + 1) ? this.items[this.activeIndex] : null;
        },
        promo() {
            if (this.activeItem &&
                this.activeItem.promo_image &&
                this.activeItem.promo_text &&
                this.activeItem.promo_url
            ) {
                return {
                    image: this.activeItem.promo_image,
                    text: this.activeItem.promo_text,
                    url: this.activeItem.promo_url,
                };
            }
            return null;
        },
        imageResized() {
            if (!this.promo?.image) {
                return null;
            }
            const resize = utils.common.getResize({ width: '320px' });
            return utils.common.injectResize(this.promo.image, resize);
        },
    },
    methods: {
        mouseenterHandler(index) {
            if (this.getChildrenByIndex(index) !== null) {
                this.activeIndex = index;
                this.overridedIndex = null;
            } else {
                this.overridedIndex = index;
            }
        },
        mouseleaveHandler() {
            this.overridedIndex = null;
        },
        getChildrenByIndex(index) {
            if (this.items[index] && this.items[index].items && this.items[index].items.length > 0) {
                return this.items[index].items;
            }
            return null;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-header-main-menu-submenu {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    &__list {
        .typography-display-sm();

        flex: 0 1 auto;
        width: 320px;
        margin-right: 40px;

        text-transform: uppercase;
        &-item {
            &:not(:last-child) {
                margin-bottom: 21px;
            }
            &-link {
                text-decoration: none;

                cursor: default;
                &._with_link {
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &._active {
                    text-decoration: underline;
                }
            }
        }
    }
    &__sublist {
        flex: 0 1 auto;
        width: 320px;
        &-item {
            font-size: 2rem;
            line-height: 2.8rem;

            &:not(:first-child) {
                margin-top: 20px;
            }
            & + &._with_children {
                margin-top: 48px;
            }
            &-link {
                text-transform: uppercase;
                text-decoration: none;

                cursor: default;
                &._with_link {
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            &-list {
                margin-top: 20px;
                &-item {
                    text-transform: none;

                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                    &-link {
                        text-decoration: none;
                        cursor: default;
                        &._with_link {
                            cursor: pointer;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
    &__promo {
        flex: 0 1 auto;
        width: 320px;
        margin-right: 0;
        margin-left: auto;

        &-text {
            margin-bottom: 20px;
            &-link {
                font-size: 2rem;
                line-height: 2.8rem;
                text-transform: none;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &-image {
            display: block;

            width: 100%;
            max-width: 320px;

            text-decoration: none;

            &-content {
                display: block;

                width: 100%;

                background-color: @color-gray-lighter;
            }
        }
    }
    @media @media-md-down {
    }
}
</style>
