<template>
    <div>
        page-info
    </div>
</template>

<script>
export default {
    name: 'page-info',
    data: () => ({
        isActive: false,
    }),
    methods: {
        toggle() {
            this.isActive = !this.isActive;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-info {
    .typography-body-md();
    .container();

    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    &__menu {
        flex: 0 0 auto;
        width: 180px;
        padding-top: 32px;
        padding-bottom: 20px;
        border-right: 1px solid @color-gray-darkest;
        &-trigger {
            display: none;
        }
        &-list {
            display: block;
            padding: 0;
            margin: 0;

            list-style: none;
            & > li {
                .container-paddings();

                display: block;
                & > a {
                    display: inline-block;
                    padding: 10px 0;

                    text-decoration: none;
                }
                &.selected > a {
                    text-decoration: underline;
                }
                & > ul {
                    display: none;
                }
            }
        }
    }
    &__content {
        flex: 1 1 auto;
        max-width: 820px;
        padding: 40px 20px;
        margin-right: auto;
    }
    @media @media-lg-down {
    }
    @media @media-md-down {
        flex-direction: column;
        &__menu {
            width: 100%;
            padding-top: 0;
            padding-bottom: 0;
            border: none;
            &-trigger {
                .container-paddings();

                display: block;
                width: fit-content;
                padding-top: 10px;
                padding-bottom: 10px;
                &:hover {
                    text-decoration: underline;

                    cursor: pointer;
                }
            }
            &-list {
                & > li {
                    display: none;
                    &.selected {
                        display: block;
                    }
                }
            }
            &._active & {
                &-list {
                    & > li {
                        display: block;
                    }
                }
            }
        }
        &__content {
            max-width: none;
            padding-top: 0;
        }
    }
}
</style>
