<template>
    <div class="ui-loader"
        v-bind:class="{
            _cold: variant === 'cold',
            _dark: variant === 'dark',
            _light: variant === 'light',
        }"
        >
    </div>
</template>

<script>
export default {
    name: 'ui-loader',
    props: {
        variant: {
            type: String,
            default: null,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-loader {
    position: relative;

    display: block;
    width: 100%;
    padding-bottom: 100%;

    color: @color-gray-darkest;
    &:after {
        content: '';

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid currentColor;
        border-color: currentColor currentColor transparent transparent;
        border-radius: 50%;

        animation: loader 1s linear infinite;
    }
    &._cold {
        color: @color-accent-cold;
    }
    &._dark {
        color: @color-gray-dark;
    }
    &._light {
        color: @color-gray-light;
    }
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
