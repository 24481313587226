var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"plugin-infopage-container",class:[
        '_direction_' + _vm.direction,
        {
            _to_end: _vm.offset === 'end',
            _to_start: _vm.offset === 'start',
            _padded: !_vm.noHorizontalPaddings,
            _no_rearrangement: _vm.isMobileRearrangementDisabled && _vm.direction === 'horizontal',
            _centred: _vm.justifyContent === 'center',
        }
    ],style:({ justifyContent: _vm.justifyContent, alignItems: _vm.alignItems, backgroundColor: _vm.backgroundColor })},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }