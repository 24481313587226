import areas from './areas';
import callbacks from './callbacks';
import catalog from './catalog';
import cart from './cart';
import checkout from './checkout';
import certificates from './certificates';
import collections from './collections';
import common from './common';
import communication from './communication';
import dadata from './dadata';
import filters from './filters';
import fitting from './fitting';
import lenses from './lenses';
import menu from './menu';
import modals from './modals';
import notifications from './notifications';
import orders from './orders';
import pages from './pages';
import paymentMethods from './paymentMethods';
import sideblocks from './sideblocks';
import site from './site';
import shippingMethods from './shippingMethods';
import sms from './sms';
import stores from './stores';
import subscription from './subscription';
import user from './user';

export default {
    areas,
    callbacks,
    catalog,
    cart,
    checkout,
    certificates,
    collections,
    common,
    communication,
    dadata,
    filters,
    fitting,
    lenses,
    menu,
    modals,
    notifications,
    orders,
    pages,
    paymentMethods,
    sideblocks,
    site,
    shippingMethods,
    sms,
    stores,
    subscription,
    user,
};
