<template>
    <div class="sideblock-cart">
        <div class="sideblock-cart__loader"
            v-if="isCartLoading"
            >
            <div class="sideblock-cart__loader-container">
                <ui-loader />
            </div>
        </div>
        <div class="sideblock-cart__list">
            <div class="sideblock-cart__list-item"
                v-for="(item, index) in cartItems"
                v-bind:key="index"
                >
                <common-card-cart
                    v-bind:product="item"
                    v-bind:ecommerce-location="'CartPopup'"
                />
            </div>
        </div>
        <div class="sideblock-cart__features">
            <div class="sideblock-cart__features-item"
                v-if="cartTotal < freeShippingThreshold"
                >
                Бесплатная курьерская доставка по России для заказов от {{ freeShippingThresholdFormatted }}
            </div>
            <div class="sideblock-cart__features-item"
                v-else
                >
                Бесплатная курьерская доставка по России
            </div>
        </div>
        <div class="sideblock-cart__total">
            <div class="sideblock-cart__total-title">
                Итого:
            </div>
            <div class="sideblock-cart__total-value">
                <span v-text="cartTotal" /> <span v-text="cartCurrency" />
            </div>
        </div>
        <div class="sideblock-cart__buttons">
            <a class="sideblock-cart__buttons-item"
                href="/shop/cart/cart"
                >
                <ui-button is-uppercase>
                    Перейти к оформлению
                </ui-button>
            </a>
        </div>
        <div class="sideblock-cart__info">
            <div class="sideblock-cart__info-item">
                Гарантия и лёгкий возврат
                <span class="sideblock-cart__info-item-hint">
                    <ui-hint>
                        Год гарантии на все оправы и возможность вернуть в течение 30 дней после покупки. Возврат с курьером по России – бесплатно!
                    </ui-hint>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import config from '~/config';
import utils from '~/utils';
import { checkoutStepCart } from '~/utils/ecommerce';

export default {
    name: 'sideblock-cart',
    props: {
        isFirstAutocloseDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isAnalyticsTriggered: false,
        isFirstAutocloseResolved: false,
        freeShippingThreshold: config.cart.freeShippingThreshold,
        freeShippingThresholdFormatted: utils.formats.formatNumerWithSpaces(config.cart.freeShippingThreshold) + ' ₽',
    }),
    computed: {
        ...mapState('cart', [
            'addToCartIsLoading',
        ]),
        ...mapGetters('cart', [
            'productsEcommerceData',
        ]),
        cart() {
            return this.$store.state.cart.getCartResult;
        },
        cartItems() {
            return this.$store.getters['cart/items'];
        },
        cartTotal() {
            return this.$store.getters['cart/total'];
        },
        cartCurrency() {
            return this.$store.getters['cart/currency'];
        },
        isCartLoading() {
            return this.$store.getters['cart/isLoading'];
        },
    },
    watch: {
        isCartLoading: {
            immediate: true,
            handler(newVal) {
                if (!newVal && !this.isAnalyticsTriggered) {
                    checkoutStepCart({
                        total: this.cart.total,
                        products: this.productsEcommerceData,
                    });
                    this.isAnalyticsTriggered = true;
                }
            },
        },
        cartItems(newVal) {
            if (this.isFirstAutocloseDisabled && !this.isFirstAutocloseResolved) {
                this.isFirstAutocloseResolved = true;
                return;
            }
            if (newVal.length === 0) {
                this.$store.commit('sideblocks/pop');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-cart {
    .mixin-sideblock();

    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 50vw;
    padding: 20px 0 0;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 100px;
            margin: auto;
        }
    }
    &__list {
        padding: 0 0 40px 20px;
        &-item {
            margin-bottom: 20px;
        }
    }
    &__features {
        &-item {
            min-height: 40px;
            padding: 10px 20px;
            border-top: 1px solid @color-gray-darkest;
            margin-bottom: 20px;
            &:before {
                content: '✦';
            }
            &:last-child {
                border-bottom: 1px solid @color-gray-darkest
            }
        }
    }
    &__total {
        flex: 0 0 auto;
        padding: 0 20px;
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 20px;
        &-title {
            flex: 0 0 auto;

            text-transform: uppercase;
        }
        &-value {
            flex: 1 1 auto;
            padding-left: 20px;

            text-align: right;
        }
    }
    &__buttons {
        flex: 0 0 auto;
        padding: 0 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 20px;
        &-item {
            flex: 1 1 100%;
            display: block;
            height: 48px;

            text-decoration: none;
        }
    }
    &__info {
        flex: 0 0 auto;
        padding: 0 20px;
        margin-bottom: auto;
        &-item {
            margin-bottom: 5px;
            max-width: ~'calc(50vw - 300px)';
        }
    }
    @media @media-md-down {
        &__info {
            &-item {
                max-width: 100%;
            }
        }
    }
}
</style>
