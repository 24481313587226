<template>
    <div class="common-header-mobile-menu-item"
        v-bind:class="{ _active: isActive }"
        >
        <div class="common-header-mobile-menu-item__title"
            v-bind:is="item.url && (!item.items || item.items.length === 0) ? 'a' : 'div'"
            v-bind:class="{ _with_children: item.items && item.items.length > 0 }"
            v-bind:href="item.url && (!item.items || item.items.length === 0) ? item.url : null"
            v-bind:target="item.target ? item.target : null"
            v-on:click="toggle"
            >
            <div class="common-header-mobile-menu-item__title-text"
                v-text="item.name"
            />
            <div class="common-header-mobile-menu-item__title-icon"
                v-if="item.items && item.items.length > 0"
                >
                <icon name="caret-bottom" />
            </div>
        </div>
        <div class="common-header-mobile-menu-item__list"
            v-if="item.items && item.items.length > 0"
            >
            <div class="common-header-mobile-menu-item__list-column">
                <div class="common-header-mobile-menu-item__list-item"
                    v-for="(item, index) in itemsWithoutChildren"
                    v-bind:key="index"
                    >
                    <div class="common-header-mobile-menu-item__list-item-title"
                        v-bind:is="item.url ? 'a' : 'div'"
                        v-bind:class="{ _with_link: item.url }"
                        v-bind:href="item.url ? item.url : null"
                        v-bind:target="item.target ? item.target : null"
                        >
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="common-header-mobile-menu-item__list-column">
                <div class="common-header-mobile-menu-item__list-item"
                    v-for="(item, index) in itemsWithChildren"
                    v-bind:key="index"
                    >
                    <div class="common-header-mobile-menu-item__list-item-title"
                        v-bind:is="item.url ? 'a' : 'div'"
                        v-bind:class="{ _with_children: item.items && item.items.length > 0 }"
                        v-bind:href="item.url ? item.url : null"
                        v-bind:target="item.target ? item.target : null"
                        >
                        {{ item.name}}
                    </div>
                    <div class="common-header-mobile-menu-item__list-item-list"
                        v-if="item.items && item.items.length > 0"
                        >
                        <div class="common-header-mobile-menu-item__list-item-list-item"
                            v-for="(item, index) in item.items"
                            v-bind:key="index"
                            >
                            <a class="common-header-mobile-menu-item__list-item-list-item-title"
                                v-bind:is="item.url ? 'a' : 'div'"
                                v-bind:class="{ _with_link: item.url }"
                                v-bind:href="item.url ? item.url : null"
                                v-bind:target="item.target ? item.target : null"
                                >
                                {{ item.name }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'common-header-mobile-menu-item',
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        isActive: 0,
    }),
    computed: {
        itemsWithChildren() {
            if (!this.item.items) {
                return [];
            }
            return this.item.items.filter(x => x.items && x.items.length > 0);
        },
        itemsWithoutChildren() {
            if (!this.item.items) {
                return [];
            }
            return this.item.items.filter(x => !x.items || x.items.length === 0);
        },
    },
    methods: {
        toggle() {
            if (this.item.items) {
                this.isActive = !this.isActive;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-header-mobile-menu-item {
    padding-bottom: 24px;

    font-size: 2rem;
    line-height: 2.8rem;

    &__title {
        display: inline-block;

        text-decoration: none;

        cursor: pointer;
        user-select: none;
        &._with_children {
            display: inline-flex;
            align-items: flex-start;
            justify-content: flex-start;
        }
        &-text {
            text-transform: uppercase;

            ._active > * > & {
                text-decoration: underline;
            }
        }
        &-icon {
            margin-left: 12px;
            margin-top: 3px;

            font-size: 1.8rem;

            transition: transform @duration-fast @easing-default;
            ._active > * > & {
                transform: scaleY(-1);
            }
        }
    }
    &__list {
        display: none;
        padding: 33px 0 28px;
        margin-top: 20px;
        ._active > & {
            display: flex;
        }
        &-column {
            flex: 1 1 100%;
            &:nth-child(odd) {
                padding-right:8px;
            }
            &:nth-child(even) {
                padding-left:8px;
            }
        }
        &-item {
            font-size: 1.4rem;
            line-height: 2rem;
            text-transform: none;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
            &-title {
                display: inline-block;

                text-decoration: none;
                text-transform: uppercase;
            }
            &-list {
                margin-top: 16px;
                &-item {
                    text-transform: none;

                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                    &-title {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    @media @media-md-down {
    }
}
</style>
