<template>
    <div class="page-dashboard-history-order-item">
        <template v-if="item.product">
            <div class="page-dashboard-history-order-item__content">
                <div class="page-dashboard-history-order-item__content-title"
                    v-if="item.product.images.length > 0 && item.product.images[0].small"
                    >
                    <a class="page-dashboard-history-order-item__content-title-link"
                        v-bind:href="item.product.url"
                        target="_blank"
                        v-text="item.product.title + ' ' + color"
                    /><span v-if="quantityText" v-text="quantityText" />
                </div>
                <div class="page-dashboard-history-order-item__content-title"
                    v-else
                    v-text="item.product.title + ' ' + color + quantityText"
                />
                <a class="page-dashboard-history-order-item__content-image"
                    v-if="item.product.images.length > 0 && item.product.images[0].small"
                    v-bind:href="item.product.url"
                    target="_blank"
                    >
                    <img class="page-dashboard-history-order-item__content-image-content"
                        v-bind:src="item.product.images[0].small"
                    />
                </a>
                <div class="page-dashboard-history-order-item__content-downloads"
                    v-if="order.giftcard && order.order_information.is_paid && order.order_information.gift_voucher_order && item.product.isCertificate"
                    >
                    <br />
                    <br />
                    <span class="page-dashboard-history-order-item__content-downloads-trigger"
                        v-on:click="download('png')"
                        v-text="'Скачать PNG'"
                    />
                    <br />
                    <span class="page-dashboard-history-order-item__content-downloads-trigger"
                        v-on:click="download('pdf')"
                        v-text="'Скачать PDF'"
                    />
                </div>
            </div>
            <div class="page-dashboard-history-order-item__price" v-text="price" />
        </template>
        <template v-else-if="rawPrice !== 0">
            <div class="page-dashboard-history-order-item__content" />
            <div class="page-dashboard-history-order-item__price" v-text="price" />
        </template>
    </div>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-dashboard-history-order-item',
    props: ['item', 'order'],
    data: () => ({
        freeText: 'Бесплатно',
    }),
    computed: {
        rawPrice() {
            return parseFloat(this.item.price_incl_tax);
        },
        price() {
            if (this.rawPrice === 0) {
                return this.freeText;
            } else {
                return `${utils.formats.formatNumerWithSpaces(this.rawPrice)} ${config.currencies.rub.symbol}`;
            }
        },
        color() {
            if (!this.item || !this.item.product) {
                return '';
            }
            const color = this.item.product.attributes.find(x => x.code === 'Цвет оправы');
            return color !== undefined ? color.value : '';
        },
        quantityText() {
            if (this.item.quantity <= 1) {
                return '';
            }
            if (!this.item.product || this.item.product.isAccessory) {
                return ` ${this.item.quantity} шт.`;
            }
            return '';
        },
    },
    methods: {
        download(mode) {
            this.$store.commit('modals/push', {
                name: 'render-giftcard',
                props: {
                    mode,
                    url: this.order.giftcard,
                },
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-dashboard-history-order-item {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    width: 100%;
    &__content {
        width: 66.66%;
        padding: 20px;
        border-right: 1px solid @color-gray-darkest;
        &-image {
            display: block;

            text-decoration: none;
            &-content {
                display: block;
                max-height: 80px;
                max-width: 100%;
            }
        }
        &-downloads {
            &-trigger {
                text-decoration: underline;

                cursor: pointer;
            }
        }
    }
    &__price {
        width: 33.33%;
        padding: 20px;
    }
}
</style>
