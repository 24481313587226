<template>
    <div class="page-dashboard-certificates">
        <div class="page-dashboard-certificates__loader"
            v-if="isLoading"
            >
            <div class="page-dashboard-certificates__loader-container">
                <ui-loader />
            </div>
        </div>
        <div class="page-dashboard-certificates__title">
            Проверить остаток на сертификате
        </div>
        <div class="page-dashboard-certificates__description">
            Здесь вы можете проверить, осталась ли на вашем сертификате неиспользованная сумма.
            <br>
            <br>
            Все наши сертификаты бессрочные и многоразовые.
        </div>
        <div class="page-dashboard-certificates__form">
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-on:update="updateHandler"
                v-bind:submit-handler="submitHandler"
            />
        </div>
        <div class="page-dashboard-certificates__button">
            <ui-button
                v-on:click="submitHandler"
                v-bind:class="{ _disabled: isLoading }"
                v-bind:disabled="isLoading"
                is-uppercase
                type="submit"
                >
                проверить
            </ui-button>
        </div>
        <template v-if="checkCertificateBalanceError">
            <div class="page-dashboard-certificates__stat">
                Не можем применить этот код. Скорее всего он уже недействителен. <a href="#chatra_trigger">Напишите в чат</a>, если у вас есть вопросы.
            </div>
        </template>
        <template v-else-if="checkCertificateBalanceResult">
            <div class="page-dashboard-certificates__stat">
                Статус:
                <br>
                {{ checkCertificateBalanceResult.status.title }}
            </div>
            <div class="page-dashboard-certificates__stat">
                Остаток:
                <br>
                {{ checkCertificateBalanceResult.balanceFormatted }}
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-dashboard-certificates',
    data: () => ({
        fields: {
            code: { ...forms.fields.certificate },
        },
        code: null,
    }),
    validations: {
        fields: {
            code: { ...forms.validations.defaultRequired },
        },
    },
    computed: {
        ...mapState('certificates', [
            'checkCertificateBalanceResult',
            'checkCertificateBalanceIsLoading',
            'checkCertificateBalanceError',
        ]),
        isLoading() {
            return this.checkCertificateBalanceIsLoading;
        },
    },
    methods: {
        updateHandler(newData) {
            this.fields = {
                ...this.fields,
                ...newData,
            };
        },
        submitHandler() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.dispatch('certificates/checkCertificateBalance', {
                code: this.fields.code.value,
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-dashboard-certificates {
    .typography-body-md();

    position: relative;

    width: 100%;
    max-width: 360px;
    padding: 0 20px;
    &__loader {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        width: 100%;
        height: 100%;

        background: @color-gray-lightest;
        &-container {
            width: 50px;
            margin: auto;
        }
    }
    &__title {
        margin-bottom: 12px;

        text-transform: uppercase;
    }
    &__description {
        margin-bottom: 36px;
    }
    &__form {
        margin-bottom: 12px;
    }
    &__button {
        height: 48px;
    }
    &__button + &__stat {
        margin-top: 32px;
    }
    &__stat {
        margin-bottom: 24px;
    }
    @media @media-xs-down {
        max-width: 100%;
    }
}
</style>
