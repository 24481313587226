<template>
    <div class="plugin-collections-listing-description">
        <div class="plugin-collections-listing-description__main">
            <h1 class="plugin-collections-listing-description__main-title"
                v-if="description.title"
                v-html="description.title"
            />
            <div class="plugin-collections-listing-description__main-description"
                v-if="description.text"
                v-html="description.text"
            />
            <div class="plugin-collections-listing-description__main-buttons"
                v-if="description.buttonUrl"
                >
                <div class="plugin-collections-listing-description__main-buttons-item">
                    <ui-button
                        v-bind:href="description.buttonUrl"
                        target="_blank"
                        is-uppercase
                        is-bordered
                        >
                        {{ (description.buttonText || 'узнать больше') + ' ↗' }}
                    </ui-button>
                </div>
            </div>
            <div class="plugin-collections-listing-description__main-buttons"
                v-else-if="description.isGenderFilterVisible"
                >
                <div class="plugin-collections-listing-description__main-buttons-item">
                    <ui-button
                        v-bind:class="{ _bordered: !isFemaleFilterActive }"
                        v-on:click="toggleGender('female')"
                        is-uppercase
                        >
                        Женские
                    </ui-button>
                </div>
                <div class="plugin-collections-listing-description__main-buttons-item">
                    <ui-button
                        v-bind:class="{ _bordered: !isMaleFilterActive }"
                        v-on:click="toggleGender('male')"
                        is-uppercase
                        >
                        Мужские
                    </ui-button>
                </div>
            </div>
        </div>
        <div class="plugin-collections-listing-description__image"
            v-if="cover"
            v-bind:style="cover"
            >
        </div>
    </div>
</template>

<script>
import config from '~/config';
// import { mainClient } from '~/network';
import utils from '~/utils';

export default {
    name: 'plugin-collections-listing-description',
    props: {
        description: {
            type: Object,
        },
    },
    data: () => ({
        isLoading: false,
        items: [],
        pageUrl: window.location.pathname,
        isFilterListReady: false,
        genderFilterConfig: config.filters.gender,
    }),
    computed: {
        cover() {
            if (!this.description.image) {
                return null;
            }
            if (window.innerWidth >= 1600) {
                return `background-image: url(${utils.common.injectResize(this.description.image, '3200xAUTO')})`;
            }
            if (window.innerWidth >= 540) {
                return `background-image: url(${utils.common.injectResize(this.description.image, '1600xAUTO')})`;
            }
            return `background-image: url(${utils.common.injectResize(this.description.image, '1080xAUTO')})`;
        },
        isFemaleFilterActive() {
            return this.$store.getters['catalog/filters/isFemaleFilterActive'];
        },
        isMaleFilterActive() {
            return this.$store.getters['catalog/filters/isMaleFilterActive'];
        },
    },
    methods: {
        toggleGender(gender) {
            const genderValueConfig = this.genderFilterConfig.values[gender];
            if (genderValueConfig === undefined) {
                return;
            }
            let commitPayload = [];
            if ((gender === 'female' && !this.isFemaleFilterActive) ||
                (gender === 'male' && !this.isMaleFilterActive)) {
                commitPayload = [ genderValueConfig.id ];
            }
            this.$store.commit('catalog/filters/setFilter', {
                setCode: this.genderFilterConfig.code,
                filterIds: commitPayload,
            });
            this.$store.state.catalog.page = 1;
        },
        getPillCover(item) {
            return { backgroundImage: 'url(' + utils.common.injectResize(item.image || item.collection.image, '224x128') + ')' };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-collections-listing-description {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: 1px;
    &__main {
        .container-paddings();

        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 24px;
        padding-bottom: 48px;

        background-color: @color-gray-lightest;
        &-title {
            margin: 0 0 auto;

            font-size: 2.8rem;
            font-weight: normal;
            line-height: 3.6rem;
            text-transform: uppercase;
            white-space: pre-line;
        }
        &-description {
            max-width: 520px;
            margin-top: 24px;
            margin-bottom: 0;
        }
        &-buttons {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            width: 100%;
            margin-top: 32px;
            &-item {
                flex: 0 0 auto;
                min-width: 120px;
                height: 48px;

                text-transform: uppercase;
                & ~ & {
                    margin-left: -1px;
                }
            }
        }
    }
    &__image {
        flex: 0 0 auto;
        width: 50%;
        padding-top: 27.778%;

        background-color: @color-gray-light;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    @media @media-sm-down {
        flex-direction: column-reverse;
        &__main {
            width: 100%;
        }
        &__image {
            width: 100%;
            padding-top: 55.5556%;
        }
    }
}
</style>
