import { mainClient } from '~/network';

const state = {
    getLensesResult: null,
    getLensesIsLoading: false,
    getLensesError: null,

    uploadPrescriptionResult: null,
    uploadPrescriptionIsLoading: false,
    uploadPrescriptionError: null,
};

const getters = {};

const actions = {
    async getLenses({ state }) {
        if (state.getLensesIsLoading) {
            return;
        }
        state.getLensesIsLoading = true;

        const { data, error, status } = await mainClient.shop.getLenses();
        if (error !== null) {
            console.error(error, status);
            state.getLensesResult = null;
            state.getLensesError = error;
        } else {
            state.getLensesResult = data;
            state.getLensesError = null;
        }
        state.getLensesIsLoading = false;
    },
    async uploadPrescription({ state }, payload) {
        if (state.uploadPrescriptionIsLoading) {
            return;
        }
        state.uploadPrescriptionIsLoading = true;

        const { data, error, status } = await mainClient.shop.uploadPrescription(payload);
        if (error !== null) {
            console.error(error, status);
            console.error('Error above relates to vuex store lenses module uploadPrescription action');
            state.uploadPrescriptionResult = null;
            state.uploadPrescriptionError = error;
        } else {
            state.uploadPrescriptionResult = data;
            state.uploadPrescriptionError = null;
        }
        state.uploadPrescriptionIsLoading = false;
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
