import { mainClient } from '~/network';
import config from '~/config';
import ecommerce from '~/utils/ecommerce';

const state = {
    getCartResult: null,
    getCartIsLoading: false,
    getCartError: null,

    addToCartResult: null,
    addToCartIsLoading: false,
    addToCartError: null,

    removeFromCartResult: null,
    removeFromCartIsLoading: false,
    removeFromCartError: null,

    decreaseLineQuantityResult: null,
    decreaseLineQuantityIsLoading: false,
    decreaseLineQuantityError: null,

    applyVoucherResult: null,
    applyVoucherIsLoading: false,
    applyVoucherError: null,

    removeVoucherResult: null,
    removeVoucherIsLoading: false,
    removeVoucherError: null,
};

const getters = {
    count(state) {
        if (state.getCartResult === null) {
            return 0;
        }
        return state.getCartResult.lines.reduce((acc, curr) => acc + curr.quantity, 0);
    },
    currency(state) {
        if (state.getCartResult === null) {
            return '';
        }
        return state.getCartResult.currency;
    },
    total(state) {
        if (state.getCartResult === null) {
            return 0;
        }
        return state.getCartResult.total;
    },
    totalExcludeDiscounts(state, getters) {
        if (state.getCartResult === null) {
            return 0;
        }
        return state.getCartResult.total_incl_tax_excl_discounts - getters.vouchersSum;
    },
    items(state) {
        const result = [];
        if (!state.getCartResult) {
            return result;
        }
        state.getCartResult.lines.filter((x, _, lines) => lines.every(l => l.child_id !== x.id)).forEach(line => {
            if (line.product.isAccessory) {
                for (let i = 0; i < line.quantity; i++) {
                    result.push({ ...line.product, quantity: 1 });
                }
            } else {
                result.push({ ...line.product, quantity: line.quantity });
            }
        });
        return result;
    },
    productsEcommerceData(state) {
        if (!state.getCartResult?.lines) {
            return [];
        }
        return state.getCartResult.lines.map(line => ({
            ...line.product.ecommerce,
            quantity: line.quantity,
        }));
    },
    offers(state) {
        if (state.getCartResult === null) {
            return [];
        }
        return (state.getCartResult.offer_discounts || []);
    },
    offersSum(state, getters) {
        return getters.offers.reduce((acc, curr) => acc + parseFloat(curr.amount), 0);
    },
    vouchers(state) {
        if (state.getCartResult === null) {
            return [];
        }
        return state.getCartResult.voucher_discounts;
    },
    vouchersSum(state, getters) {
        return getters.vouchers.reduce((acc, curr) => acc + parseFloat(curr.amount), 0);
    },
    discountsSum(state, getters) {
        return getters.offersSum + getters.vouchersSum;
    },
    certificates(state) {
        if (state.getCartResult === null) {
            return [];
        }
        // eslint-disable-next-line camelcase
        return state.getCartResult.voucher_discounts.filter(x => x.voucher?.is_gift);
    },
    promocodes(state) {
        if (state.getCartResult === null) {
            return [];
        }
        // eslint-disable-next-line camelcase
        return state.getCartResult.voucher_discounts.filter(x => !x.voucher?.is_gift);
    },
    isLoading(state) {
        return state.getCartIsLoading ||
            state.addToCartIsLoading ||
            state.removeFromCartIsLoading ||
            state.decreaseLineQuantityIsLoading ||
            state.applyVoucherIsLoading ||
            state.removeVoucherIsLoading;
    },
};

const actions = {
    async getCart({ state, getters }, { onSuccess } = {}) {
        if (getters.isLoading) {
            return;
        }
        state.getCartIsLoading = true;
        const { data = {}, error, status } = await mainClient.shop.getBasket({ basket_key: config.cart.keys.common });
        if (error !== null) {
            console.error(error, status);
            console.error('Error above relates to vuex store cart module getCart action');
            state.getCartResult = null;
            state.getCartError = error;
        } else {
            state.getCartResult = data;
            state.getCartError = null;
        }
        state.getCartIsLoading = false;
        if (!error && onSuccess) {
            onSuccess();
        }
    },
    async addToCart({ state, getters, dispatch, commit, rootState }, params) {
        if (getters.isLoading) {
            return;
        }
        if (params.product.isCertificate && state.getCartResult?.lines.length > 0) {
            const props = {
                title: 'НЕ МОЖЕМ ДОБАВИТЬ СЕРТИФИКАТ, КОГДА В КОРЗИНЕ ЕСТЬ ДРУГИЕ ТОВАРЫ',
                text: 'Извините, в корзине может быть либо один сертификат либо другие товары. Пожалуйста, удалите другие товары из корзины перед добавлением сертификата.',
            };
            if (rootState.sideblocks.list.some(x => x.name === 'cart')) {
                props.buttons = [
                    {
                        text: 'Закрыть',
                    },
                ];
            } else {
                props.buttons = [
                    {
                        text: 'Открыть корзину',
                        callback: () => { commit('sideblocks/push', 'cart', { root: true }); },
                    },
                ];
            }
            commit('modals/push', {
                name: 'common',
                props,
            }, { root: true });
            return;
        } else if (state.getCartResult?.lines.some(x => x.product.isCertificate)) {
            const props = {
                title: 'В КОРЗИНЕ УЖЕ ЕСТЬ СЕРТИФИКАТ',
                text: 'Извините, в корзине может быть либо один сертификат либо другие товары. Пожалуйста, удалите сертификат из корзины перед добавлением новых товаров.',
            };
            if (rootState.sideblocks.list.some(x => x.name === 'cart')) {
                props.buttons = [
                    {
                        text: 'Закрыть',
                    },
                ];
            } else {
                props.buttons = [
                    {
                        text: 'Открыть корзину',
                        callback: () => { commit('sideblocks/push', 'cart', { root: true }); },
                    },
                ];
            }
            commit('modals/push', {
                name: 'common',
                props,
            }, { root: true });
            return;
        }
        const ecommerceData = params.product.ecommerce;
        params.product = params.product.id;
        state.addToCartIsLoading = true;
        const { data = {}, error } = await mainClient.shop.addToCart({ ...params, basket_key: config.cart.keys.common });
        if (error !== null) {
            state.addToCartResult = null;
            state.addToCartError = error;
        } else {
            state.addToCartResult = data;
            state.addToCartError = null;
            ecommerce.addToCart(ecommerceData);
        }
        state.addToCartIsLoading = false;
        error === null && dispatch('getCart');
    },
    updateLine({ state, dispatch }, product) {
        if (state.getCartResult === null) {
            return;
        }
        const line = state.getCartResult.lines[product.lineIndex];
        const newQuantity = line.quantity - (product.quantity || 1);
        const params = {
            url: line.url,
            product,
            quantity: newQuantity,
        };
        if (newQuantity > 0) {
            dispatch('decreaseLineQuantity', params);
        } else {
            dispatch('removeFromCart', params);
        }
    },
    async decreaseLineQuantity({ state, getters, dispatch }, params) {
        if (getters.isLoading) {
            return;
        }
        const lensLine = params.product.lensLine;
        if (lensLine) {
            delete params.product.lensLine;
        }
        const ecommerceData = params.product.ecommerce;
        delete params.product;
        state.decreaseLineQuantityIsLoading = true;
        const { data = {}, error, status } = await mainClient.shop.updateLine(params);
        if (error !== null) {
            console.error(error, status);
            state.decreaseLineQuantityResult = null;
            state.decreaseLineQuantityError = error;
        } else {
            state.decreaseLineQuantityResult = data;
            state.decreaseLineQuantityError = null;
            ecommerce.removeFromCart(ecommerceData);
            if (lensLine) {
                lensLine.product.ecommerce.quantity = 2;
                ecommerce.removeFromCart(lensLine.product.ecommerce);
            }
        }
        state.decreaseLineQuantityIsLoading = false;
        error === null && dispatch('getCart');
    },
    async removeFromCart({ state, getters, dispatch }, params) {
        if (getters.isLoading) {
            return;
        }
        const lensLine = params.product.lensLine;
        if (lensLine) {
            delete params.product.lensLine;
        }
        const ecommerceData = params.product.ecommerce;
        delete params.product;
        state.removeFromCartIsLoading = true;
        const { data = {}, error, status } = await mainClient.shop.removeLine(params);
        if (error !== null) {
            console.error(error, status);
            state.removeFromCartResult = null;
            state.removeFromCartError = error;
        } else {
            state.removeFromCartResult = data;
            state.removeFromCartError = null;
            ecommerce.removeFromCart(ecommerceData);
            if (lensLine) {
                lensLine.product.ecommerce.quantity = 2;
                ecommerce.removeFromCart(lensLine.product.ecommerce);
            }
        }
        state.removeFromCartIsLoading = false;
        error === null && dispatch('getCart');
    },
    async applyVoucher({ state, getters, dispatch }, params) {
        if (getters.isLoading) {
            return;
        }
        state.applyVoucherIsLoading = true;
        const { data = {}, error } = await mainClient.shop.applyPromocode(params);
        if (error !== null) {
            state.applyVoucherResult = null;
            state.applyVoucherError = error;
        } else {
            state.applyVoucherResult = data;
            state.applyVoucherError = null;
        }
        state.applyVoucherIsLoading = false;
        error === null && dispatch('getCart');
    },
    async removeVoucher({ state, getters, dispatch }, params) {
        if (getters.isLoading) {
            return;
        }
        state.removeVoucherIsLoading = true;
        const { data = {}, error } = await mainClient.shop.removePromocode(params);
        if (error !== null) {
            state.removeVoucherResult = null;
            state.removeVoucherError = error;
        } else {
            state.removeVoucherResult = data;
            state.removeVoucherError = null;
        }
        state.removeVoucherIsLoading = false;
        error === null && dispatch('getCart');
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
