<template>
    <div class="plugin-stores-phototext"
        v-bind:class="{ _reversed: isReversed }"
        >
        <div class="plugin-stores-phototext__image"
            v-if="photo !== null"
            v-bind:style="imageStyle"
        >
        </div>
        <div class="plugin-stores-phototext__info">
            <div class="plugin-stores-phototext__info-top"
                v-if="label !== null"
                v-text="label"
            />
            <div class="plugin-stores-phototext__info-bottom" v-if="text !== null">
                <common-ckeditor v-html="text" />
            </div>
        </div>
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-stores-phototext',
    props: {
        isReversed: {
            type: Boolean,
            default: false,
        },
        photo: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
    },
    computed: {
        imageStyle() {
            if (!this.photo) return null;

            const resize = utils.common.getResize({
                width: 50,
                breakpoints: {
                    1023: { width: 100 },
                },
            });
            return {
                backgroundImage: `url('${utils.common.injectResize(this.photo, resize)}')`,
            };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-stores-phototext {
    .typography-body-md();

    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    &._reversed {
        flex-direction: row-reverse;
    }
    &__image {
        width: 50%;

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: @color-gray-lighter;
    }
    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 50%;
        min-height: 480px;
        padding: 40px;
        &-top {
            padding-bottom: 80px;
            margin-top: 0;
            margin-bottom: auto;
        }
        &-bottom {
            width: 100%;
            max-width: 490px;
            margin-top: auto;
            margin-bottom: 0;
            p {
                margin: 0;
            }
        }
    }
    @media @media-md-down {
        flex-direction: column;
        &._reversed {
            flex-direction: column-reverse;
        }
        &__image {
            width: 100%;
            height: auto;
            padding-top: 66.66%;
        }
        &__info {
            width: 100%;
            min-height: 0;
            padding: 40px 20px;
            &-top {
                padding-bottom: 20px;
            }
        }
    }
}
</style>
