<template>
    <div class="page-stores" data-page-stores>
        <div class="page-stores__loader"
            v-if="state === 'loading'"
            >
            <ui-loader />
        </div>
        <div class="page-stores__error"
            v-else-if="state == 'error'"
            >
            Ошибка загрузки данных
        </div>
        <div class="page-stores__list"
            v-else
            >
            <div class="page-stores__list-item"
                v-for="(store, index) in stores"
                v-bind:key="index"
                >
                <page-stores-item v-bind:item="store" />
            </div>
        </div>
    </div>
</template>

<script>
import { mainClient } from '~/network';
export default {
    name: 'page-stores',
    data: () => ({
        state: 'loading',
        stores: [],
    }),
    methods: {
        async getData() {
            this.state = 'loading';
            const { data, error } = await mainClient.shop.getStores();
            if (error !== null) {
                this.state = 'error';
                console.error(error, status);
                console.error('Error above relates to component page-stores');
            } else if (data !== null) {
                this.stores = data.results;
                this.state = 'loaded';
            }
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-stores {
    .typography-body-md();
    .container();

    position: relative;
    z-index: 1;

    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1 0 auto;
    width: 100%;
    &__loader {
        width: 100px;
        margin: auto;
    }
    &__error {
        padding: 20px;
        margin: auto;

        font-size: 2rem;
        color: @color-accent-warm;
    }
    &__list {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex: 1 0 auto;
        width: 100%;
        &-item {
            width: 50%;
        }
    }
    @media @media-md-down {
        &__list {
            &-item {
                width: 100%;
            }
        }
    }
}
</style>
