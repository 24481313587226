<template>
    <div class="page-checkout-step-shipping-contacts">
        <div class="page-checkout-step-shipping-contacts__title"
            v-text="'ваши контакты'"
        />
        <div class="page-checkout-step-shipping-contacts__field _name">
            <page-checkout-step-shipping-contacts-name />
        </div>
        <div class="page-checkout-step-shipping-contacts__field _communication">
            <page-checkout-step-shipping-contacts-communication />
        </div>
        <page-checkout-step-shipping-contacts-comment
            v-if="isShippingNeeded"
        />
        <div class="page-checkout-step-shipping-contacts__field _messenger">
            <page-checkout-step-shipping-contacts-messenger />
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-checkout-step-shipping-contacts',
    inject: [ 'cartModuleName' ],
    computed: {
        cart() {
            return this.$store.state[this.cartModuleName].getCartResult;
        },
        isShippingNeeded() {
            return !this.cart || this.cart.isShippingNeeded;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-contacts {
    &__title {
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    &__field._messenger {
        margin-top: 20px;
    }
    @media @media-sm-down {
        &__field._name,
        &__field._communication,
        &__field._messenger {
            margin-bottom: 20px;
        }
    }
}
</style>
