<template>
    <component class="common-header-tryon"
        v-bind:is="href ? 'a' : 'div'"
        v-on:click="href ? null : showPopup()"
        v-bind:href="href"
        >
        <div class="common-header-tryon__icon">
            <icon name="cart-tryon" />
        </div>
        <div class="common-header-tryon__counter"
            v-if="count > 0"
            v-text="count"
        />
    </component>
</template>

<script>
import config from '~/config';

export default {
    name: 'common-header-tryon',
    computed: {
        isEmpty() {
            return this.count === 0;
        },
        href() {
            if (this.isEmpty) {
                return null;
            } else {
                return config.urls.fitting;
            }
        },
        count() {
            return this.$store.getters['fitting/count'];
        },
    },
    methods: {
        showPopup() {
            this.$store.commit('modals/push', {
                name: 'common',
                props: {
                    title: 'Нет товаров',
                    text: 'У вас нет товаров для примерки!',
                    buttons: [
                        {
                            text: 'Перейти в каталог',
                            callback: () => { window.location.href = config.urls.catalog; },
                        },
                    ],
                },
            });
        },
    },
    mounted() {
        this.$store.dispatch('fitting/getCart');
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-header-tryon {
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 2px;

    text-decoration: none;
    &__icon {
        position: relative;
        z-index: 2;

        flex: 0 0 auto;

        font-size: 2.2rem;

        cursor: pointer;
    }
    &__counter {
        .typography-body-md();

        position: relative;
        z-index: 2;

        flex: 0 0 auto;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-left: 5px;

        text-align: center;

        background-color: @color-accent-yellow-3;
    }
    &__effect {
        position: absolute;
        top: 0;
        right: -15px;
        bottom: 0;
        left: -15px;
        z-index: 1;
        &_add {
            animation: effectAdd 2s ease-out 1 forwards;
        }
        &_remove {
            animation: effectRemove 2s ease-out 1 forwards;
        }
    }
    @media @media-md-down {
    }
}
@keyframes effectAdd {
    0% {
        background-color: @color-accent-warm;
    }
    100% {
        background-color: fade(@color-accent-warm, 0%);
    }
}
@keyframes effectRemove {
    0% {
        background-color: @color-accent-cold;
    }
    100% {
        background-color: fade(@color-accent-cold, 0%);
    }
}
</style>
