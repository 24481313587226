import config from '~/config';
import client from '../_client';
import transforms from './transforms';

export const checkCertificateBalance = ({ code, ...params } = {}) => {
    return client.request({
        url: config.api.certificates.checkBalance + code + '/',
        method: 'GET',
        params,
        // transformRequest: transforms.requests.getItems,
        transformResponse: transforms.responses.checkCertificateBalance,
    });
};
