<template>
    <form class="common-footer-subscribe"
        v-bind:class="{
            _loading: isLoading,
            _invalid: $v.$dirty && $v.$invalid,
        }"
        v-on:submit.prevent="submit"
        >
        <div class="common-footer-subscribe__description">
            Узнавайте о скидках первыми! Только самые важные новости + гид по выбору оправ и линз в подарок
        </div>
        <input class="common-footer-subscribe__input"
            type="email"
            placeholder="E-mail"
            v-model="email.value"
        />
        <button class="common-footer-subscribe__button"
            type="button"
            v-on:click="submit"
            >
            Подписаться
        </button>
        <div class="common-footer-subscribe__loader">
            <div class="common-footer-subscribe__loader-container">
                <ui-loader />
            </div>
        </div>
        <div class="common-footer-subscribe__validation"
            v-if="$v.$invalid && $v.$dirty"
            >
            Проверьте введенный E-mail
        </div>
        <div class="common-footer-subscribe__success"
            v-if="isSuccess"
            >
            Спасибо! Вы успешно подписаны ;-)
        </div>
    </form>
</template>

<script>
import forms from '~/forms';

export default {
    name: 'common-footer-subscribe',
    data: () => ({
        email: { ...forms.fields.email },
    }),
    validations: {
        email: { ...forms.validations.email },
    },
    computed: {
        isLoading() {
            return this.$store.getters['common/subscribe'].isLoading;
        },
        isSuccess() {
            return this.$store.getters['common/subscribe'].isSubscribed;
        },
    },
    methods: {
        submit() {
            this.email.value = this.email.value.toLowerCase();
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$store.dispatch('common/subscribe', {
                    email: this.email.value,
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-footer-subscribe {
    position: relative;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-width: 520px;
    margin-bottom: 32px;
    &._loading & {
        &__loader {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        background: @color-gray-light;

        opacity: 0;
        pointer-events: none;

        transition: opacity @duration-fast @easing-default;
        &-container {
            width: 50px;
        }
    }
    &._invalid & {
        &__input {
            border-color: @color-accent-warm;
        }
    }
    &__validation {
        width: 100%;
        margin-top: 14px;

        color: @color-accent-warm;
    }
    &__success {
        width: 100%;
        margin-top: 14px;

        color: @color-accent-cold;
    }
    &__description {
        width: 100%;
        padding: 5px 0 19px;
    }
    &__input {
        flex: 1 1 auto;
        width: ~'calc(100% - 164px)';
        height: 48px;
        padding: 11px 11px 9px;
        border: 1px solid @color-gray-darkest;
        border-radius: 0;
        margin-right: 8px;

        font-size: 1.6rem;

        background: transparent;

        appearance: none;

        transition: background @duration-fast @easing-default, border @duration-fast @easing-default;
    }
    &__button {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 156px;
        height: 48px;
        padding-top: 3px;

        color: @color-gray-light;
        text-transform: uppercase;

        background: @color-gray-darkest;

        cursor: pointer;
    }
    &__bonus {
        width: 100%;
    }
    @media @media-md-down {
        .container-paddings();

        max-width: none;
        padding-bottom: 24px;

        background: @color-accent-yellow;
        &__description {
            padding-top: 25px;
        }
        &__loader {
            background: @color-accent-yellow;
        }
        &__input {
            max-width: 460px;
        }
        &__button {
            width: 140px;
        }
    }
}
</style>
