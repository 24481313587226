import { mainClient } from '~/network';

const state = {
    getPaymentMethodsResult: null,
    getPaymentMethodsIsLoading: false,
    getPaymentMethodsError: null,
};

const getters = {};

const actions = {
    async getPaymentMethods({ state }, payload) {
        if (state.getPaymentMethodsIsLoading) {
            return;
        }

        state.getPaymentMethodsIsLoading = true;

        const { data, error, status } = await mainClient.shop.getPaymentMethods(payload);
        if (error !== null) {
            console.error(error, status);
            state.getPaymentMethodsError = error;
            state.getPaymentMethodsResult = null;
        } else {
            state.getPaymentMethodsError = null;
            state.getPaymentMethodsResult = data;
        }

        state.getPaymentMethodsIsLoading = false;
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
