<template>
    <div class="plugin-infopage-iconed-list-item">
        <img class="plugin-infopage-iconed-list-item__icon"
            v-bind:src="icon"
        />
        <div class="plugin-infopage-iconed-list-item__body">
            <div class="plugin-infopage-iconed-list-item__body-title"
                v-text="title"
            />
            <div class="plugin-infopage-iconed-list-item__body-text">
                <plugin-infopage-text v-html="text" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-infopage-iconed-list-item',
    props: {
        icon: String,
        title: String,
        text: String,
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-iconed-list-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    & + & {
        margin-top: 56px;
    }
    &__icon {
        flex: 0 0 auto;
        display: block;
        width: 64px;
        margin-right: 24px;
    }
    &__body {
        flex: 1 1 auto;
        &-title {
            .typography-display-sm();

            margin-bottom: 12px;

            text-transform: uppercase;
        }
    }
    @media @media-md-down {
        & + & {
            margin-top: 48px;
        }
        &__icon {
            margin-right: 8px;
        }
        &__body {
            &-title {
                margin-bottom: 8px;
            }
        }
    }
}
</style>
