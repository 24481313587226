<template>
    <div class="plugin-infopage-buttongroup-item">
        <ui-button-new
            v-text="text"
            v-bind:href="url"
            v-bind:theme="theme"
            v-bind:is-new-window-needed="isNewWindowNeeded"
        />
    </div>
</template>

<script>
export default {
    name: 'plugin-infopage-buttongroup-item',
    props: {
        text: String,
        url: String,
        theme: String,
        isNewWindowNeeded: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-buttongroup-item {
    flex: 1 1 100%;
    & ~ & {
        padding-left: 8px;
    }
    &:first-child:last-child {
        flex: 0 0 auto;
    }
    @media @media-md-down {
        &:first-child:last-child {
            flex: 1 1 100%;
        }
        & ~ & {
            padding-top: 8px;
            padding-left: 0;
        }
    }
}
</style>
