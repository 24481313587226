<template>
    <div class="page-checkout-step-shipping-address-apartment">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-bind:submit-handler="submitHandler"
            v-on:update="updateFormData"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-checkout-step-shipping-address-apartment',
    inject: [ 'pageCheckoutEventBus' ],
    data: () => ({
        innerFields: {
            apartment: {
                ...forms.fields.apartment,
                noAsterisk: true,
            },
        },
    }),
    validations() {
        return {
            fields: {
                apartment: {
                    ...forms.validations.defaultRequired,
                },
            },
        };
    },
    computed: {
        ...mapState('checkout', [ 'isShippingDirty' ]),
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        fields() {
            const result = { ...this.innerFields };
            result.apartment.value = this.checkoutData.apartment;
            return result;
        },
    },
    methods: {
        updateFormData(newData) {
            this.$store.commit('checkout/updateData', {
                apartment: newData.apartment.value,
            });
        },
        submitHandler() {
            this.pageCheckoutEventBus.$emit('shipping-submit');
        },
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$emit('shipping-validation', { apartment: true });
    },
    watch: {
        '$v.$invalid': {
            immediate: true,
            handler(newVal) {
                this.pageCheckoutEventBus.$emit('shipping-validation', { apartment: !newVal });
            },
        },
        isShippingDirty: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                } else {
                    this.$v.$reset();
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-address-apartment {
}
</style>
