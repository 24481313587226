<template>
    <div class="page-checkout-step-shipping-address-street">
        <page-checkout-step-shipping-address-street-dadata
            v-if="isDadataAvailable"
        />
        <page-checkout-step-shipping-address-street-standard
            v-else
        />
    </div>
</template>

<script>
export default {
    name: 'page-checkout-step-shipping-address-street',
    computed: {
        isDadataAvailable() {
            return this.$store.getters['checkout/isDadataAvailable'];
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-address-street {
}
</style>
