<template>
    <section class="page-item-recommended">
        <div class="page-item-recommended__title">
            Вам также может понравиться
        </div>
        <div class="page-item-recommended__loader"
            v-if="state === 'loading'"
            >
            <ui-loader />
        </div>
        <div class="page-item-recommended__error"
            v-if="state === 'error'"
            >
            Ошибка загрузки данных
        </div>
        <div class="page-item-recommended__list"
            v-if="state === 'loaded'"
            >
            <div class="page-item-recommended__list-item"
                v-for="(item, i) in items"
                v-bind:key="i"
                >
                <common-card-product
                    v-bind:product="item"
                    v-bind:ecommerce-list-data="{
                        listId: 'product_recommendations',
                        listTitle: 'Вам также может понравиться',
                        index: i,
                    }"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { mainClient } from '~/network';

export default {
    name: 'page-item-recommended',
    props: {
        reference: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        state: 'loading',
        items: [],
    }),
    methods: {
        async getItems() {
            const recommendedParams = {
                pageSize: 4,
                recommended: this.reference.id,
            };
            this.state = 'loading';
            const { data, error, status } = await mainClient.shop.getProducts(recommendedParams);
            if (error !== null) {
                console.error(status, error);
            } else {
                this.items = data.items;
            }
            this.state = 'loaded';
        },
    },
    mounted() {
        this.getItems();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-item-recommended {
    &__loader {
        width: 50px;
        margin: 100px auto;
    }
    &__error {
        margin: 100px auto;

        text-align: center;
    }
    &__title {
        .container-paddings();

        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 40px;
        padding-top: 4px;

        text-transform: uppercase;

        background: @color-accent-gold;
    }
    &__list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        &-item {
            position: relative;
            z-index: 1;

            width: 25%;

            box-shadow: -1px 0 0 0 @color-gray-lightest;
        }
    }
    @media @media-md-down {
        &__list {
            &-item {
                width: 50%;
                &:nth-child(2) ~ & {
                    border-top: 1px solid @color-gray-lightest;
                }
            }
        }
    }
    @media @media-sm-down {
        &__list {
            &-item {
                width: 100%;
                &:nth-child(1) ~ & {
                    border-top: 1px solid @color-gray-lightest;
                }
            }
        }
    }
}
</style>
