<template>
    <div class="plugin-stores-info" v-bind:id="'plugin-stores-info-' + store.id">
        <div class="plugin-stores-info__info">
            <h1 class="plugin-stores-info__info-title"
                v-text="store.title"
            />
            <div class="plugin-stores-info__info-bottom">
                <span class="plugin-stores-info__info-bottom-area"
                    v-text="store.area"
                />
                <br/>
                <br/>
                {{ store.address }}
                <br/>
                {{ store.workTime }}
                <br/>
                <a v-if="phoneLink"
                    v-bind:href="phoneLink"
                    v-text="store.contacts"
                />
                <span v-else>{{ store.contacts }}</span>
                <div class="plugin-stores-info__info-bottom-eyeexam"
                    v-if="store.eyeExamUrl"
                    >
                    <br/>
                    <div class="plugin-stores-info__info-bottom-eyeexam-button"
                        >
                        <ui-button is-bordered
                            v-bind:href="store.eyeExamUrl"
                            target="_blank"
                            >
                            Записаться на проверку зрения
                        </ui-button>
                    </div>
                    <div class="plugin-stores-info__info-bottom-eyeexam-info">
                        <a class="plugin-stores-info__info-bottom-eyeexam-info-link"
                            v-bind:href="eyeExamInfoHref"
                            target="_blank"
                            >
                            Что такое проверка зрения?
                        </a>
                    </div>
                </div>
                <div class="plugin-stores-info__info-bottom-eyeexam"
                    v-else-if="store.no_eye_exam && store.no_eye_exam !== 'None'"
                    >
                    <div v-html="store.no_eye_exam" class="common-ckeditor"></div>
                </div>
            </div>
        </div>
        <div class="plugin-stores-info__map">
            <iframe class="plugin-stores-info__map-iframe"
                v-bind:src="store.mapSrc"
                frameborder="0"
                allowfullscreen
            />
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'plugin-stores-info',
    props: {
        store: {
            type: Object,
            required: true,
        },
    },
    computed: {
        eyeExamInfoHref() {
            return config.urls.eyeExamInfo;
        },
        phoneLink() {
            const digits = this.store.contacts?.replace(/\D/g, '');
            if (digits.length !== 11) return null;
            return `tel:+${digits}`;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-stores-info {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 50%;
        min-height: 480px;
        padding: 40px;
        &-title {
            padding-bottom: 80px;
            margin-top: 0;
            margin-bottom: auto;

            font-size: 2.8rem;
            font-weight: normal;
            text-transform: uppercase;
        }
        &-bottom {
            .typography-body-md();

            width: 100%;
            max-width: 490px;
            margin-top: auto;
            margin-bottom: 0;
            &-area {
                text-transform: uppercase;
            }
            &-eyeexam {
                &-button {
                    width: fit-content;
                }
                &-info {
                    margin-top: 12px;
                }
            }
        }
    }
    &__map {
        width: 50%;
        padding: 20px;

        &-iframe {
            width: 100%;
            height: 100%;
            border: 0;

            background-color: @color-gray-lighter;
        }
    }
    @media @media-md-down {
        flex-direction: column;
        &__info {
            width: 100%;
            height: auto;
            min-height: 0;
            padding: 40px 20px 20px;
            &-title {
                padding-bottom: 20px;
            }
        }
        &__map {
            position: relative;
            z-index: 1;

            width: 100%;
            padding: 66.66% 0 0;
            &-iframe {
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 1;

                width: ~'calc(100% - 40px)';
                height: ~'calc(100% - 40px)';
            }
        }
    }
}
</style>
