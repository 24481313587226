<template>
    <div class="plugin-stores-photo"
        v-if="photo !== null"
        v-bind:style="imageStyle"
    />
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-stores-photo',
    props: {
        photo: {
            type: String,
            default: null,
        },
    },
    computed: {
        imageStyle() {
            if (!this.photo) return null;

            const resize = utils.common.getResize({ width: 100 });
            return {
                backgroundImage: `url('${utils.common.injectResize(this.photo, resize)}')`,
            };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-stores-photo {
    width: 100%;
    height: ~'calc(100vh - 48px)';

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: @color-gray-lighter;
    @media @media-md-down {
        height: auto;
        padding-top: 66.66%;
    }
}
</style>
