import { mainClient } from '~/network';
import config from '~/config';

const state = {
    filterItemsIsLoading: false,
    filterItemsError: null,
    filterItems: null,

    // объект с массивами id выбранных сейчас фильтров
    // ключи объектра - filteritem_set.id
    activeFilters: {},
    searchQuery: null,

    basicFilterSetsCodes: [ 'form', 'material', 'size', 'color' ],
};

const getters = {
    state(state) {
        return state;
    },
    filterItems(state) {
        return state.filterItems;
    },
    activeFilters(state) {
        return state.activeFilters;
    },
    activeBasicFiltersCount(state) {
        return Object.keys(state.activeFilters).reduce((acc, key) => {
            if (state.basicFilterSetsCodes.indexOf(key) > -1) {
                return acc + state.activeFilters[key].length;
            } else {
                return acc;
            }
        }, 0);
    },
    searchQuery(state) {
        return state.searchQuery;
    },
    isFemaleFilterActive(state) {
        return !!Object.keys(state.activeFilters).find(key => state.activeFilters[key].indexOf(config.filters.gender.values.female.id) > -1);
    },
    isMaleFilterActive(state) {
        return !!Object.keys(state.activeFilters).find(key => state.activeFilters[key].indexOf(config.filters.gender.values.male.id) > -1);
    },
    isFilterActive: state => (setCode, id) => {
        const filterGroup = state.activeFilters[setCode];
        return filterGroup !== undefined && filterGroup.indexOf(id) > -1;
    },
};

const mutations = {
    toggleFilter(state, { setCode, filterId }) {
        const result = { ...state.activeFilters };
        if (result[setCode] === undefined) {
            result[setCode] = [];
        }
        if (result[setCode].indexOf(filterId) < 0) {
            result[setCode].push(filterId);
        } else {
            result[setCode] = result[setCode].filter(x => x !== filterId);
        }
        state.activeFilters = result;
    },
    setActiveFilters(state, value) {
        state.activeFilters = value;
    },
    setFilter(state, { setCode, filterIds }) {
        state.activeFilters = { ...state.activeFilters, [setCode]: filterIds };
    },
    setSearchQuery(state, value) {
        state.searchQuery = value;
    },
};

const actions = {
    async getFilterItems({ state }) {
        if (state.filterItemsIsLoading) {
            return;
        }

        state.filterItemsIsLoading = true;

        const { data, error } = await mainClient.shop.getFilters();
        if (error) {
            state.filterItems = null;
        } else if (data) {
            state.filterItems = data;
        }

        state.filterItemsIsLoading = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
