<template>
    <div class="page-password-set">
        <div class="page-password-set__spacer" />
        <div class="page-password-set__form"
            v-if="!isReset && !customError"
            >
            <div class="page-password-set__title">
                придумайте новый пароль
            </div>
            <div class="page-password-set__form-fields">
                <ui-form
                    v-bind:model="fields"
                    v-bind:validation="$v"
                    v-on:update="updateHandler"
                    v-bind:submit-handler="submitHandler"
                />
            </div>
            <div class="page-password-reset__form-error"
                v-if="error !== null"
                v-text="error"
            />
            <div class="page-password-set__form-button">
                <ui-button
                    v-on:click="submitHandler"
                    v-bind:class="{ _disabled: isLoading }"
                    v-bind:disabled="isLoading"
                    is-uppercase
                    type="submit"
                    >
                    сохранить новый пароль
                </ui-button>
            </div>
        </div>
        <div class="page-password-set__result"
            v-if="isReset"
            >
            <div class="page-password-set__title">
                новый пароль успешно сохранён!
            </div>
            <div class="page-password-set__result-buttons">
                <a class="page-password-set__result-buttons-item"
                    v-if="quantityCart > 0"
                    v-bind:href="hrefs.cart"
                    >
                    <ui-button is-uppercase>
                        перейти в корзину
                    </ui-button>
                </a>
                <a class="page-password-set__result-buttons-item"
                    v-bind:href="hrefs.catalog"
                    v-else
                    >
                    <ui-button is-uppercase>
                        Перейти в каталог
                    </ui-button>
                </a>
                <a class="page-password-set__result-buttons-item"
                    v-bind:href="hrefs.cabinet"
                    >
                    <ui-button is-bordered>
                        Перейти в Личный кабинет
                    </ui-button>
                </a>
            </div>
        </div>
        <div class="page-password-set__result"
            v-if="error !== null"
            >
            <div class="page-password-set__title">
                Ошибка!
            </div>
            <div class="page-password-set__text _center">
                <span v-text="error" />
                <br>
                Повторите <a v-bind:href="hrefs.reset">сброс пароля</a>
            </div>
        </div>
    </div>
</template>

<script>
import config from '~/config';
import forms from '~/forms';

export default {
    name: 'page-password-set',
    props: {
        email: {
            type: String,
        },
    },
    data: () => ({
        hrefs: {
            reset: config.urls.passwordReset,
            cart: config.urls.cart,
            catalog: config.urls.catalog,
            cabinet: config.urls.cabinet,
        },
        uid: '',
        token: '',
        customError: null,
        fields: {
            password: { ...forms.fields.passwordNew },
        },
    }),
    validations: {
        fields: {
            password: { ...forms.validations.password },
        },
    },
    computed: {
        isLoading() {
            return this.$store.getters['user/state'].setPasswordIsLoading;
        },
        user() {
            return this.$store.getters['user/user'];
        },
        error() {
            if (this.customError) {
                return this.customError;
            }
            if (!this.$v.$dirty || !this.$v.$invalid) {
                return null;
            }
            const error = this.$store.getters['user/state'].setPasswordError;
            if (error) {
                return 'Ссылка устарела';
            }
            return null;
        },
        isReset() {
            return this.$store.getters['user/state'].setPasswordIsReset;
        },
        quantityCart() {
            return this.$store.getters['cart/count'];
        },
    },
    methods: {
        updateHandler(newData) {
            this.fields = {
                ...this.fields,
                ...newData,
            };
        },
        submitHandler() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$store.dispatch('user/setPassword', {
                    uid: this.uid,
                    token: this.token,
                    password1: this.fields.password.value,
                    password2: this.fields.password.value,
                });
            }
        },
    },
    mounted() {
        if (!this.email) {
            this.customError = 'Email не найден';
            return;
        }
        const rawHref = window.location.href;
        const formattedHref = rawHref[rawHref.length - 1] === '/' ? rawHref.substring(0, rawHref.length - 1) : rawHref;
        const signature = '/reset/';
        const resetPosition = formattedHref.indexOf(signature);
        if (resetPosition === -1) {
            this.customError = 'Неверная ссылка';
            return;
        }
        const parsedHref = formattedHref.substring(resetPosition + signature.length).split('/');
        if (parsedHref.length !== 2) {
            this.customError = 'Неверная ссылка';
            return;
        }
        this.uid = parsedHref[0];
        this.token = parsedHref[1];
    },
    watch: {
        isReset(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.$store.dispatch('user/login', {
                    username: this.email,
                    password: this.fields.password.value,
                });
            }
        },
        user() {
            this.$store.dispatch('cart/getCart');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-password-set {
    .typography-body-md();

    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: flex-start;
    width: 100%;
    min-height: ~'calc(100vh - 48px)';
    padding: 40px 0;
    &__spacer {
        flex: 1 1 auto;
        max-height: 132px;
    }
    &__title {
        margin-bottom: 20px;

        text-align: center;
        text-transform: uppercase;
    }
    &__text {
        &._center {
            text-align: center;
        }
    }
    &__form {
        width: 100%;
        max-width: 460px;
        margin: 0 auto;

        flex: 0 0 auto;
        &-fields {
            margin-bottom: 20px;
        }
        &-error {
            margin-bottom: 20px;
        }
        &-button {
            height: 48px;
        }
    }
    &__result {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;

        flex: 0 0 auto;
        &-buttons {
            &-item {
                display: block;
                height: 48px;

                text-decoration: none;
                & ~ & {
                    margin-top: 8px;
                }
            }
        }
    }
    @media @media-xs-down {
        &__form,
        &__result {
            max-width: 100%;
            padding: 0 20px;
        }
    }
}
</style>
