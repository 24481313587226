<template>
    <section class="plugin-collections-looks">
        <section class="swiper-container plugin-collections-looks__gallery"
            ref="gallery"
            >
            <div class="swiper-wrapper plugin-collections-looks__gallery-wrapper">
                <a class="plugin-collections-looks__gallery-item swiper-slide"
                    v-for="(slide, index) in slider.slides"
                    v-bind:key="index"
                    v-bind:href="slide.url"
                    >
                    <div class="plugin-collections-looks__gallery-item-image"
                        v-bind:style="{ backgroundImage: slide.image ? `url(${slide.image})` : 'none' }"
                        >
                    </div>
                </a>
            </div>
        </section>
    </section>
</template>

<script>
import Swiper from 'swiper';
import utils from '~/utils';

export default {
    name: 'plugin-collections-looks',
    props: {
        looksData: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        slider: {
            swiper: null,
            slideWidth: 244,
            spaceBetween: 0,
            slides: [],
        },
    }),
    computed: {
        isCatalogLoading() {
            return this.$store.getters['catalog/state'].getProductsIsLoading || this.$store.getters['catalog/state'].getProductsDebouncedIsLoading;
        },
        groups() {
            return this.looksData.map((look, i) => ({
                ...look,
                index: i,
                filters: look.query.split('&').map(filter => {
                    let [ code, id ] = filter.split('=');
                    return { code, id: parseInt(id, 10) };
                }),
            }));
        },
    },
    methods: {
        checkFilterActivity(filter) {
            return this.$store.getters['filters/isFilterActive'](filter.code, filter.id);
        },
        initSlider() {
            if (!this.$refs.gallery) {
                return;
            }
            if (this.slider.swiper !== null) {
                this.slider.swiper.destroy();
            }
            let slides = this.groups.find(group => group.filters.every(filter => this.checkFilterActivity(filter)));
            if (slides === undefined) {
                slides = [];
                let counter = 0;
                while (counter < 2 && slides.length < 4) {
                    for (let i = 0; i < this.groups.length; i++) {
                        const group = this.groups[i];
                        if (group.items.length > counter) {
                            slides.push(group.items[counter]);
                        }
                    }
                    counter++;
                }
                this.slider.slides = slides;
            } else {
                this.slider.slides = slides.items;
            }
            this.slider.swiper = new Swiper(this.$refs.gallery, {
                slidesPerView: 'auto',
                spaceBetween: this.slider.spaceBetween,
                shortSwipes: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    575: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                },
            });
        },
    },
    mounted() {
        utils.dom.addLoadedCallback(this.initSlider);
    },
    beforeDestroy() {
        utils.dom.removeLoadedCallback(this.initSlider);
    },
    watch: {
        isCatalogLoading(newVal) {
            if (newVal) {
                this.initSlider();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-collections-looks {
    .container();
    &__gallery {
        border-bottom: 1px solid @color-gray-lightest;
        &-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
        }
        &-item {
            position: relative;
            z-index: 1;

            flex: 0 0 auto;
            display: block;
            width: 25vw;

            text-decoration: none;

            box-shadow: -1px 0 0 0 #fff;
            cursor: pointer;
            &-image {
                display: block;
                width: 100%;
                padding-top: 125%;

                background-color: @color-gray-main;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
    }
    @media @media-md-down {
        &__gallery {
            &-item {
                width: 33.33vw;
            }
        }
    }
    @media @media-sm-down {
        &__gallery {
            &-item {
                width: 50vw;
            }
        }
    }
    @media @media-xs-down {
        &__gallery {
            &-item {
                width: 244px;
            }
        }
    }
}
</style>
