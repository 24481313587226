import { mainClient } from '~/network';
import config from '~/config';
import filters from './filters';

const state = {
    collectionId: null,

    page: 1,
    pageSize: 24,
    count: 0,

    isFilteringEnabled: true,

    items: [],

    analyticsData: null,

    getItemsResult: null,
    getItemsIsLoading: false,
    getItemsError: null,
};

const getters = {
};

const mutations = {
};

const actions = {
    async getItems({ state, rootGetters }, {
        onSuccess = null,
        isBackground = false,
        ...payload
    } = {}) {
        if (!isBackground) {
            if (state.getItemsIsLoading) {
                return;
            }
            state.getItemsIsLoading = true;
        }
        const requestParams = {
            page: state.page,
            pageSize: state.pageSize,
        };

        if (state.collectionId) {
            requestParams.collection = state.collectionId;
        }

        const filters = [];
        const activeFilters = rootGetters['catalog/filters/activeFilters'];
        Object.keys(activeFilters).forEach(filtersGroupKey => {
            const values = activeFilters[filtersGroupKey];
            if (values.length > 0) {
                // проверяем на шаблон стандартного фильтра
                // и в массиве стандартных фильтров каталога
                const standardCatalogFilters = [ 'color', 'gender', 'lense-color', ...config.filters.basicFilterSetsCodes ];
                if (/^filter[0-9]+$/.test(filtersGroupKey) || standardCatalogFilters.indexOf(filtersGroupKey) > -1) {
                    filters.push(values);
                } else {
                    // в другом случае добавляем к запросу как особый именной фильтр
                    requestParams[filtersGroupKey] = values.join(',');
                }
            }
        });
        if (filters.length > 0) {
            requestParams.filters = filters;
        }
        const searchQuery = rootGetters['catalog/filters/searchQuery'];
        if (searchQuery !== null) {
            requestParams.q = searchQuery;
        }

        const { data, error } = await mainClient.shop.getProducts(requestParams);
        if (error) {
            state.getItemsResult = null;
            state.getItemsError = error;
        } else if (data) {
            state.getItemsResult = data;
            state.getItemsError = null;
            if (onSuccess) {
                onSuccess(data);
            }
        }

        if (!isBackground) {
            state.getItemsIsLoading = false;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: {
        filters,
    },
};
