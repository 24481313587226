<template>
    <div class="page-item-gallery-full"
        v-if="items.length > 0"
        >
        <div class="swiper-container page-item-gallery-full__container"
            ref="slider"
            >
            <div class="swiper-wrapper page-item-gallery-full__wrapper">
                <div class="swiper-slide page-item-gallery-full__slide swiper-lazy"
                    v-for="(item, index) in items"
                    v-bind:key="index"
                    v-bind:data-background="item.image"
                    >
                    <ui-video
                        v-if="item.video"
                        v-bind:video="item.video"
                        v-bind:poster="item.image"
                        v-bind:aspect-ratio="item.aspectRatio"
                    />
                    <div class="page-item-gallery-full__slide-loader">
                        <ui-loader />
                    </div>
                </div>
            </div>
        </div>
        <div class="page-item-gallery-full__navigation _prev"
            ref="prev"
            >
            <icon name="caret-left" />
        </div>
        <div class="page-item-gallery-full__navigation _next"
            ref="next"
            >
            <icon name="caret-right" />
        </div>
        <div class="page-item-gallery-full__close"
            v-on:click="close"
            >
            <icon name="close-thin" v-bind:fill="false" />
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';

export default {
    name: 'page-item-gallery-full',
    data: () => ({
        slider: null,
        key: 0,
    }),
    computed: {
        initialIndex() {
            return this.$store.state.pages.item.galleryIndex;
        },
        items() {
            return this.$store.state.pages.item.galleryItems;
        },
    },
    methods: {
        initSlider() {
            const prevEl = this.$refs.prev;
            const nextEl = this.$refs.next;
            if (this.items.length === 0 || !prevEl || !nextEl) {
                return;
            }
            if (this.slider !== null) {
                this.slider.update();
                return;
            }
            this.slider = new Swiper(this.$refs.slider, {
                slidesPerView: 'auto',
                spaceBetween: 1,
                speed: 500,
                preloadImages: false,
                initialSlide: this.initialIndex || 0,
                lazy: {
                    loadPrevNext: true,
                },
                mousewheel: true,
                loop: this.items.length > 1,
                watchOverflow: true,
                watchSlidesVisibility: true,
                navigation: {
                    prevEl,
                    nextEl,
                },
            });
        },
        destroySlider() {
            if (this.slider !== null) {
                this.slider.destroy();
            }
        },
        close() {
            this.$store.state.pages.item.galleryIndex = null;
            this.$store.state.pages.item.galleryItems = null;
        },
        keydownHandler(e) {
            if (e.code === 'Escape' || e.key === 'Escape') {
                this.close();
            } else if (this.slider !== null) {
                if (e.code === 'ArrowLeft' || e.key === 'ArrowLeft') {
                    this.slider.slidePrev();
                } else if (e.code === 'ArrowRight' || e.key === 'ArrowRight') {
                    this.slider.slideNext();
                }
            }
        },
    },
    mounted() {
        this.$store.commit('common/increaseBodyFixedCounter');
        this.initSlider();
        document.addEventListener('keydown', this.keydownHandler);
    },
    beforeDestroy() {
        this.$store.commit('common/decreaseBodyFixedCounter');
        this.destroySlider();
        document.removeEventListener('keydown', this.keydownHandler);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-item-gallery-full {
    position: relative;

    width: 100%;
    height: 100%;

    background-color: @color-gray-lightest;
    &__container {
        width: 100%;
        height: 100%;
    }
    &__slide {
        position: relative;
        z-index: 1;

        width: calc(100vh * 0.78);
        height: 100%;

        background-color: @color-gray-main;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        &-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;

            width: 100px;

            opacity: 0.3;

            transform: translate(-50%, -50%);
            .swiper-lazy-loaded > & {
                display: none;
            }
        }
    }
    &__navigation {
        position: absolute;
        top: 50%;
        z-index: 2;

        padding: 40px;

        font-size: 4rem;

        cursor: pointer;
        outline: none;

        transform: translateY(-50%);
        &._prev {
            left: 0;
        }
        &._next {
            right: 0;
        }
    }
    &__close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;

        padding: 32px;

        font-size: 5.6rem;

        cursor: pointer;
    }
}
</style>
