import client from '../_client';
import config from '~/config';

export const requestCode = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        url: config.api.sms.get,
        method: 'POST',
        data,
    });
};

export const checkCode = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').value,
        },
        url: config.api.sms.check,
        method: 'POST',
        data,
    });
};
