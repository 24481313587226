<template>
    <div class="page-item-gallery-desktop"
        ref="root"
        >
        <div class="page-item-gallery-desktop__list">
            <div class="page-item-gallery-desktop__list-item"
                v-for="(item, i) in items"
                v-bind:key="i"
                v-bind:class="{ _placeholder: item.image === null }"
                v-on:click="(item.image || item.video) ? clickHandler(i) : null"
                >
                <div class="page-item-gallery-desktop__list-item-content">
                    <ui-video
                        v-if="item.video"
                        v-bind:video="item.video"
                        v-bind:poster="item.image"
                        v-bind:aspect-ratio="item.aspectRatio"
                    />
                    <common-lazy-background
                        v-else-if="item.image"
                        v-bind:image="item.image"
                    />
                    <common-diagonal-placeholder
                        v-else
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-item-gallery-desktop',
    props: {
        currentChild: {
            type: Object,
        },
    },
    data: () => ({
        elem: null,
    }),
    computed: {
        isChildHasVideo() {
            return this.currentChild?.video;
        },
        items() {
            if (!this.currentChild?.images || this.currentChild.images.length === 0 || (this.currentChild.video && !this.elem)) {
                return null;
            }
            let result = [];
            const isLargeScreen = window.innerWidth >= 1920;
            if (this.isChildHasVideo) {
                const targetWidth = this.elem.getBoundingClientRect().width / 3 * 2;
                const videoSize = utils.common.getVideoResize({ targetWidth });
                result.push({
                    image: isLargeScreen ? this.currentChild.images[0].extra_large : this.currentChild.images[0].large,
                });
                result.push({
                    image: this.currentChild.videoPreview[videoSize],
                    video: this.currentChild.video[videoSize],
                    aspectRatio: config.media.defaultAspectRatio,
                });
                result = result.concat(this.currentChild.images
                    .slice(1)
                    .map((x, i) => {
                        if ((i + 2) % 3 === 0) {
                            return { image: isLargeScreen ? x.extra_large : x.large };
                        } else {
                            return { image: isLargeScreen ? x.large : x.medium };
                        }
                    })
                );
            } else {
                result = result.concat(this.currentChild.images
                    .map((x, i) => {
                        if ((i + 2) % 3 === 0) {
                            return { image: isLargeScreen ? x.extra_large : x.large };
                        } else {
                            return { image: isLargeScreen ? x.large : x.medium };
                        }
                    })
                );
            }
            if (result.length % 3 !== 0) {
                result.push({ image: null });
            }
            return result;
        },
    },
    methods: {
        clickHandler(i) {
            this.$store.state.pages.item.galleryIndex = i;
            if (!this.items) {
                return;
            }
            let result = this.items.filter(x => x.image !== null || !!x.video);
            if (this.isChildHasVideo) {
                result = result.map(x => x.video ? { ...x, video: this.currentChild?.video.original } : x) || null;
            }
            this.$store.state.pages.item.galleryItems = result;
        },
    },
    mounted() {
        this.elem = this.$refs.root;
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-item-gallery-desktop {
    position: relative;
    &__list {
        display: grid;
        gap: 1px;
        grid-template-columns: 1fr 2fr;
        width: 100%;
        &-item {
            position: relative;

            width: 100%;
            padding-top: 122.7%;

            background-color: @color-gray-main;

            cursor: pointer;
            &:nth-child(3n + 2) {
                grid-row: span 2;
                &._placeholder {
                    height: 100%;
                    padding-top: 0;
                    grid-row: span 1;
                }
            }
            &._placeholder {
                cursor: auto;
            }
            &-content {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
