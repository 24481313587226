<template>
    <div class="common-ckeditor">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'common-ckeditor',
};
</script>

<style lang="less">
@import "~theme";

.common-ckeditor {
    & /deep/ {
        p {
            .typography-body-md();

            margin: 0 0 20px;
        }
        ul {
            padding: 0;
            margin: 0 0 20px;

            list-style-type: disc;
            list-style-position: inside;
        }
        ol {
            padding: 0;
            margin: 0 0 20px;

            list-style-position: inside;
        }
        h1, h2, h3, h4, h5, h6 {
            margin: 0 0 20px;

            font-size: inherit;
            font-weight: normal;
            text-transform: uppercase;
        }
        h1 {
            .typography-display-sm();
        }
    }
}
</style>
