export const item = (data) => {
    const result = {
        ...data,
        title: data.name,
        value: '_' + data.id,
        cdekCode: data.cdek_code,
    };
    if (data.children) {
        result.children = data.children.map(x => item(x));
    }
    // eslint-disable-next-line camelcase
    result.countryUrl = `/shop/countries/${data.country?.iso_3166_1_a2 ? data.country.iso_3166_1_a2 : 'RU'}/`;
    return result;
};

export const getItems = (data) => {
    const result = data.results.map(item);
    return result;
};
