<template>
    <div class="common-catalog"
        ref="root"
        >
        <div class="common-catalog__search"
            v-if="searchQuery"
            >
            <div class="common-catalog__search-wrapper">
                <div class="common-catalog__search-query">
                    Результаты для «<span v-text="searchQuery" />»
                </div>
                <div class="common-catalog__search-remove"
                    v-on:click="removeSearch"
                    >
                    <icon name="close" />
                </div>
            </div>
        </div>
        <div class="common-catalog__empty"
            v-if="!isLoading && items && items.length === 0"
            >
            Ничего не найдено. Попробуй другие комбинации в <span class="common-catalog__empty-trigger" v-on:click="openFilters">фильтре</span>
        </div>
        <div class="common-catalog__list"
            v-if="isLoading || (items && items.length > 0)"
            >
            <common-product-list
                v-bind:analytics-data="analyticsData"
                v-bind:is-loading="isLoading"
                v-bind:items="items"
                v-on:bottom-reached="listEndReachedHandler"
            />
        </div>
        <div class="common-catalog__filters"
            v-if="isFilteringEnabled"
            v-bind:class="{ _active: $store.state.catalog.filters.isActive }"
            >
            <common-catalog-filters />
        </div>
    </div>
</template>

<script>
export default {
    name: 'common-catalog',
    data: () => ({
        isLoading: false,
        lastRequestID: 0,
        timeout: null,
    }),
    computed: {
        isFilteringEnabled() {
            return this.$store.state.catalog.isFilteringEnabled;
        },
        items() {
            return this.$store.state.catalog.items;
        },
        analyticsData() {
            return this.$store.state.catalog.analyticsData;
        },
        activeFilters() {
            return this.$store.getters['catalog/filters/activeFilters'];
        },
        searchQuery() {
            return this.$store.state.catalog.filters.searchQuery;
        },
    },
    methods: {
        // parseUrl() {
        //     const queries = new URLSearchParams(window.location.search);
        //     if (!queries) {
        //         return;
        //     }
        //     if (queries.has('q')) {
        //         this.$store.state.catalog.filters.searchQuery = queries.get('q');
        //     }
        // },
        openFilters() {
            this.$store.state.catalog.filters.isActive = true;
        },
        removeSearch() {
            this.$store.state.catalog.filters.searchQuery = null;
            const newQueryParams = new URLSearchParams(window.location.search);
            newQueryParams.delete('q');
            window.history.replaceState({}, '', `${window.location.pathname}?${newQueryParams}`);
            this.debouncedRequestHandler();
        },
        getItems() {
            const requestId = ++this.lastRequestID;
            this.isLoading = true;
            this.$store.dispatch('catalog/getItems', {
                isBackground: true,
                onSuccess: data => {
                    if (requestId !== this.lastRequestID) {
                        return;
                    }
                    this.isLoading = false;
                    this.$store.state.catalog.items = this.$store.state.catalog.items.concat(data.items);
                    this.$store.state.catalog.count = data.count;
                },
            });
        },
        listEndReachedHandler() {
            const maxPage = Math.ceil(this.$store.state.catalog.count / this.$store.state.catalog.pageSize);
            const nextPage = this.$store.state.catalog.page + 1;
            if (nextPage > maxPage) {
                return;
            }
            this.$store.state.catalog.page = nextPage;
            this.getItems();
        },
        debouncedRequestHandler() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.timeout = null;
                this.filtersChangedHandler();
            }, 750);
        },
        filtersChangedHandler() {
            this.$store.state.catalog.page = 1;
            this.$store.state.catalog.count = 0;
            this.$store.state.catalog.items = [];
            this.getItems();
        },
    },
    beforeMount() {
        this.$store.dispatch('catalog/filters/getFromUrl');
        if (this.items.length === 0) {
            this.getItems();
        }
    },
    watch: {
        activeFilters: {
            handler() {
                this.debouncedRequestHandler();
                this.$store.dispatch('catalog/filters/saveToUrl');
            },
        },
        items: {
            handler(newVal, oldVal) {
                if (window.innerWidth < 768 && newVal?.length <= oldVal?.length) {
                    window.scrollTo(0, 0);
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-catalog {
    .typography-body-md();

    display: flex;
    flex-direction: column;
    align-items: stretch;
    &__filters {
        order: -1;
        width: 100%;
    }
    &__search {
        order: -2;
        min-height: 40px;

        background-color: @color-accent-cold;
        &-wrapper {
            .container();
            .container-paddings();

            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-right: 10px;
        }
        &-query {
            padding: 13px 20px 13px 0;
        }
        &-remove {
            padding: 10px;

            cursor: pointer;
        }
    }
    &__empty {
        padding: 22px 20px;
        &-trigger {
            text-decoration: underline;

            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
    }
    @media @media-sm-down {
        &__filters {
            position: sticky;
            bottom: 0;
            z-index: 2;

            order: 10;
            overflow: auto;
            &._active {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10001;
                .cms-toolbar-expanded & {
                    top: @cms-toolbar-height;
                }
            }
        }
    }
}
</style>
