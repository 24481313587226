<template>
    <div class="page-checkout-step-shipping-address-street-dadata">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-on:update="updateFormData"
            v-bind:submit-handler="submitHandler"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-checkout-step-shipping-address-street-dadata',
    inject: [ 'pageCheckoutEventBus' ],
    data: () => ({
        innerFields: {
            street: {
                ...forms.fields.streetDadata,
                noAsterisk: true,
            },
        },
    }),
    validations() {
        return {
            fields: {
                street: {
                    value: {
                        ...forms.validations.defaultRequired.value,
                        isHouseValid: () => !!this.checkoutData.house || !!this.checkoutData.specialAddress,
                    },
                },
            },
        };
    },
    computed: {
        ...mapState('checkout', [ 'isShippingDirty' ]),
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        fields() {
            const result = { ...this.innerFields };
            result.street.dadataParams = {};
            if (this.checkoutData.country) {
                result.street.dadataParams.locations = [{ country: this.checkoutData.country.name }];
                if (this.checkoutData.city?.city) {
                    result.street.dadataParams.locations[0].city = this.checkoutData.city.city.name;
                } else if (this.checkoutData.customCity) {
                    result.street.dadataParams.locations[0].city = this.checkoutData.customCity;
                }
                result.street.dadataParams.restrict_value = true;
            }
            result.street.value = this.checkoutData.street;
            return result;
        },
    },
    methods: {
        updateFormData(newData) {
            let result = {
                street: null,
                building: null,
                house: null,
                postcode: null,
            };
            if (newData.street.value) {
                result = {
                    street: newData.street.value.value,
                    building: newData.street.value.data.block,
                    house: newData.street.value.data.house,
                    apartment: newData.street.value.data.flat,
                    postcode: newData.street.value.data.postal_code || null,
                };
                if (newData.street.value.data.flat) {
                    result.apartment = newData.street.value.data.flat;
                }
            }
            this.$store.commit('checkout/updateData', result);
        },
        exceptionalHandler(value) {
            this.$store.commit('checkout/updateData', {
                street: value,
                specialAddress: value,
            });
        },
        resetExceptionHandler() {
            this.$store.commit('checkout/updateData', {
                specialAddress: null,
            });
        },
        submitHandler() {
            this.pageCheckoutEventBus.$emit('shipping-submit');
        },
    },
    beforeMount() {
        this.innerFields.street.exceptionalCallback = this.exceptionalHandler;
        this.innerFields.street.resetExceptionCallback = this.resetExceptionHandler;
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$emit('shipping-validation', { streetDadata: true });
    },
    watch: {
        '$v.$invalid': {
            immediate: true,
            handler(newVal) {
                this.pageCheckoutEventBus.$emit('shipping-validation', { streetDadata: !newVal });
            },
        },
        isShippingDirty: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                } else {
                    this.$v.$reset();
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-address-street-dadata {
}
</style>
