<template>
    <div class="common-fitting-info">
        <div class="common-fitting-info__item">
            <div class="common-fitting-info__item-title">
                Закажите до 4 пар для примерки
            </div>
            <div class="common-fitting-info__item-text">
                Доставка курьерской службой СДЭК займет несколько дней и будет совершенно бесплатной.
            </div>
        </div>
        <div class="common-fitting-info__item">
            <div class="common-fitting-info__item-title">
                Выбирайте
            </div>
            <div class="common-fitting-info__item-text">
                Не торопитесь! У вас есть 4 дня на примерку. Не делайте это в одиночестве: посоветуйтесь с друзьями, выкладывайте в соцсети и отмечайте нас — мы тоже с радостью поможем.
            </div>
        </div>
        <div class="common-fitting-info__item">
            <div class="common-fitting-info__item-title">
                Оформите заказ
            </div>
            <div class="common-fitting-info__item-text">
                Определились? Свяжитесь с нами любым удобным способом, мы подскажем, что делать дальше. А еще подберем линзы, если нужно.
            </div>
        </div>
        <div class="common-fitting-info__item">
            <div class="common-fitting-info__item-title">
                Верните оправы
            </div>
            <div class="common-fitting-info__item-text">
                Отправьте обратно всё, что не подошло. Это легко! В посылке будет инструкция. Вернуть посылку можно через любой ПВЗ СДЭК. Вам не нужно будет ничего заполнять.
            </div>
            <br />
            <br />
            <div class="common-fitting-info__item-title _uncounted">
                В КАКИЕ ГОРОДА МЫ СМОЖЕМ ОТПРАВИТЬ ОЧКИ НА ПРИМЕРКУ?
            </div>
            <div class="common-fitting-info__item-text">
                Мы сможем доставить заказ во многие крупные города России – в Москву, Санкт-Петербург, Казань, Екатеринбург, Новосибирск, Краснодар, Нижний Новгород и другие.
                <br />
                <br />
                Заказы отправляем курьерской службой СДЭК с номером для отслеживания.
            </div>
            <br />
            <br />
            <div class="common-fitting-info__item-title _uncounted">
                В КАКИХ ГОРОДАХ ПРИМЕРКА НЕДОСТУПНА?
            </div>
            <div class="common-fitting-info__item-text">
                Мы не сможем отправить примерку в Калининград – это связано с таможенным оформлением возврата. В зону доставки не входит Иркутская область и Дальний Восток, а также небольшие города, сильно удаленные от административных центров.
                <br />
                <br />
                Если у вас есть сомнения относительно доступности услуги для вашего города, напишите нам точный адрес доставки в <a href="#chatra_trigger">чат поддержки</a>.
            </div>
            <br />
            <br />
            <a class="common-fitting-info__item-text-link"
                v-bind:href="fittingInfoHref"
                >
                Подробнее о примерке
            </a>
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'common-fitting-info',
    data: () => ({
        fittingInfoHref: config.urls.fittingInfo,
    }),
};
</script>

<style scoped lang="less">
@import '~theme';

.common-fitting-info {
    counter-reset: item;
    &__item {
        margin-bottom: 16px;
        &-title {
            text-transform: uppercase;
            &:not(._uncounted):before {
                counter-increment: item;
                content: counter(item) '. ';
            }
        }
    }
}
</style>
