<template>
    <div class="plugin-common-catalog-list"
        ref="list"
        >
        <div class="plugin-common-catalog-list__item"
            v-for="(product, i) in productsEnriched"
            v-bind:key="i"
            >
            <component
                v-bind:is="isSmallScreen ? 'common-product-slider' : 'common-card-product'"
                v-bind:product="product"
                v-bind:ecommerce-list-data="{ ...ecommerceListData, index: i }"
            />
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'plugin-common-catalog-list',
    props: {
        page: {
            type: Number,
        },
        pageSize: {
            type: Number,
        },
        lastViewedItem: {
            type: Object,
            default: null,
        },
        isSearch: Boolean,
    },
    data: () => ({
        isSmallScreen: false,
    }),
    computed: {
        ecommerceListData() {
            return this.$store.state.catalog.currentCollectionEcommerceData ||
                (this.isSearch
                    ? {
                        listId: 'search_results',
                        listTitle: 'Результаты поиска',
                    }
                    : {
                        listId: 'default_catalog',
                        listTitle: 'Общий каталог',
                    }
                );
        },
        isFemaleFilterActive() {
            return this.$store.getters['filters/isFemaleFilterActive'];
        },
        isMaleFilterActive() {
            return this.$store.getters['filters/isMaleFilterActive'];
        },
        products() {
            return this.$store.getters['catalog/state'].products;
        },
        productsEnriched() {
            if (this.products === null) {
                return null;
            }
            let genderFilter = null;
            if (this.isMaleFilterActive) {
                genderFilter = config.media.markers.genders.male;
            } else if (this.isFemaleFilterActive) {
                genderFilter = config.media.markers.genders.female;
            }
            return this.products.map((x, i) => {
                if (x.images.length > 1) {
                    if (genderFilter !== null) {
                        const imageByGender = x.images.find(image => image.medium && image.gender === genderFilter);
                        const imagesWithNoGender = x.images.filter(image => image.medium && !image.gender);
                        x.imageHover = imageByGender
                            ? imageByGender.medium
                            : imagesWithNoGender.length > 1 ? imagesWithNoGender[1].medium : x.images[1].medium;
                    } else {
                        x.imageHover = x.images[1].medium;
                    }
                } else {
                    x.imageHover = null;
                }
                return x;
            });
        },
        isLoading() {
            return this.$store.getters['catalog/state'].getProductsIsLoading ||
                this.$store.getters['catalog/state'].getProductsDebouncedIsLoading;
        },
    },
    methods: {
        checkScreen() {
            if (window.innerWidth <= 575 && !this.isSmallScreen) {
                this.isSmallScreen = true;
                return;
            }
            if (window.innerWidth > 575 && this.isSmallScreen) {
                this.isSmallScreen = false;
            }
        },
        resizeFixingHandler() {
            if (window.innerWidth < 768) {
                this.$store.commit('common/increaseBodyFixedCounter');
                window.removeEventListener('resize', this.resizeFixingHandler);
                window.addEventListener('resize', this.resizeUnfixingHandler);
            }
        },
        resizeUnfixingHandler() {
            if (window.innerWidth >= 768) {
                this.$store.commit('common/decreaseBodyFixedCounter');
                window.removeEventListener('resize', this.resizeUnfixingHandler);
                window.addEventListener('resize', this.resizeFixingHandler);
            }
        },
        scrollToItem() {
            if (!this.lastViewedItem) {
                return;
            }
            const relatedItemIndex = this.products.findIndex(x => x.id === this.lastViewedItem.itemId);
            const list = this.$refs.list;
            this.$emit('last-viewed-item-clear');
            if (relatedItemIndex < 0 || !list) {
                return;
            }
            const boundings = list.children[relatedItemIndex].getBoundingClientRect();
            window.scroll(0, window.scrollY + boundings.top - boundings.height);
        },
    },
    beforeMount() {
        this.checkScreen();
        window.addEventListener('resize', this.checkScreen);
    },
    mounted() {
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreen);
    },
    watch: {
        isLoading: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    this.$nextTick(() => {
                        this.scrollToItem();
                    });
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-common-catalog-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    &__item {
        position: relative;
        z-index: 1;

        width: 25%;

        box-shadow: -1px 0 0 0 @color-gray-lightest;

        &:nth-child(4) ~ & {
            border-top: 1px solid @color-gray-lightest;
        }
    }
    @media @media-md-down {
        &__item {
            width: 33.3%;
            &:nth-child(3) ~ & {
                border-top: 1px solid @color-gray-lightest;
            }
        }
    }
    @media @media-sm-down {
        &__item {
            width: 50%;
            &:nth-child(2) ~ & {
                border-top: 1px solid @color-gray-lightest;
            }
        }
    }
    @media @media-xs-down {
        &__item {
            width: 100%;
            &:nth-child(1) ~ & {
                border-top: 1px solid @color-gray-lightest;
            }
        }
    }
}
</style>
