<template>
    <component class="plugin-infopage-title"
        v-bind:class="'_' + titleType"
        v-bind:is="titleType"
        >
        <div class="plugin-infopage-title__text-wrapper">
            <span class="plugin-infopage-title__text"
                v-html="text"
            />
        </div>
    </component>
</template>

<script>
export default {
    name: 'plugin-infopage-title',
    props: {
        text: String,
        titleType: String,
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-title {
    text-transform: uppercase;
    &._h2 &__text-wrapper {
        max-width: 566px;
    }
    &._h2 {
        .typography-display-md();

        margin: 0 0 32px;

        font-weight: normal;
    }
    &._h3 {
        .typography-display-sm();

        font-weight: normal;
        &:first-child {
            margin: 0 0 16px;
        }
        &:not(:first-child) {
            margin: 56px 0 16px;
        }
    }
    .plugin-infopage-slider &._h2,
    .plugin-infopage-slider &._h3 {
        margin-bottom: 0;
    }
    @media @media-md-down {
        &._h2 {
            margin: 0 0 24px;
        }
        &._h3 {
            &:not(:first-child) {
                margin: 48px 0 16px;
            }
        }
        .plugin-infopage-slider &._h2,
        .plugin-infopage-slider &._h3 {
            margin-bottom: 0;
        }
        .plugin-infopage-container._no_rearrangement &._h3 {
            .typography-body-md();

            padding-right: 4px;
            margin-bottom: 4px;
        }
        .plugin-infopage-container._no_rearrangement .plugin-infopage-image + &._h3 {
            margin-top: 16px;
        }
    }
}
</style>
