<template>
    <component
        v-if="component !== null"
        v-bind:is="component.name"
        v-bind="component.props"
    />
</template>

<script>
export default {
    name: 'common-resize-controller',
    props: {
        components: {
            type: Array,
            default: null,
        },
    },
    data: () => ({
        windowWidth: window.innerWidth,
    }),
    computed: {
        component() {
            if (this.components === null) {
                return null;
            }
            const result = this.components.find(x =>
                (!x.minWidth || x.minWidth <= this.windowWidth) &&
                (!x.maxWidth || x.maxWidth > this.windowWidth));
            return result !== undefined ? result : null;
        },
    },
    methods: {
        updateWidth() {
            this.windowWidth = window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWidth);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-display-controller {
    &__content {
        .transition-fade();
    }
}
</style>
