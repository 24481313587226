<template>
    <section class="plugin-collections-listing">
        <div class="plugin-collections-listing__headline"
            v-if="!hideHeader"
            >
            <plugin-collections-listing-header
                v-bind:collection-id="collection.id"
            />
        </div>
        <div class="plugin-collections-listing__description">
            <plugin-collections-listing-description
                v-bind:description="description"
            />
        </div>
        <div class="plugin-collections-listing__catalog">
            <common-catalog />
        </div>
    </section>
</template>

<script>
import { product as productTransform } from '~/network/mainClient/shop/transforms/responses';

export default {
    name: 'plugin-collections-listing',
    props: {
        collection: {
            type: Object,
        },
        description: {
            type: Object,
        },
        givenItems: {
            type: Array,
        },
        givenCount: {
            type: Number,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        isFilteringEnabled: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        collectionType: null,
    }),
    beforeMount() {
        this.$store.state.catalog.items = this.givenItems?.map(x => productTransform(x)) || [];
        this.$store.state.catalog.count = this.givenCount || 0;
        this.$store.state.catalog.isFilteringEnabled = this.isFilteringEnabled;
        this.$store.state.catalog.collectionId = this.collection.id;
        this.$store.state.collections.currentCollection = this.collection;

        this.collectionType = this.$store.getters['collections/currentCollectionOpticsType'];
        this.$store.state.catalog.analyticsData = {
            listId: `catalog_${this.collectionType}_${this.collection.slug}`,
            listTitle: `${this.collectionType === 'optics' ? 'Оптика' : this.collectionType === 'sun' ? 'Солнце' : 'Все'} - ${this.collection.name}`,
        };
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-collections-listing {
    .typography-body-md();

    width: 100%;
}
</style>
