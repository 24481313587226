<template>
    <div>
        plugin-common-seo-block
    </div>
</template>

<script>
export default {
    name: 'plugin-common-seo-block',
};
</script>

<style scoped lang="less">
@import '~theme';
.plugin-common-seo-block {
    max-width: 50%;
    padding: 80px 20px;
    margin: 0 auto;
    &__title {
        margin: 0;

        font-size: 2.8rem;
        font-weight: 400;
        line-height: 1.43;
        text-align: center;
        text-transform: uppercase;
    }
    &__subtitle {
        margin-bottom: 12px;

        font-size: 2.8rem;
        font-weight: 400;
        line-height: 1.43;
        text-align: center;
    }
    &__text {
        font-size: 1.4rem;
        line-height: 1.43;
        text-align: center;
        /deep/ & > * {
            margin: 0 0 1.34em;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    @media @media-md-down {
        max-width: 100%;
        padding: 64px 20px;
    }
}
</style>
