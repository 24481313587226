<template>
    <video class="sideblock-select-lens-cover-video"
        v-bind:poster="poster"
        ref="video"
        playsinline
        autoplay
        loop
        muted
        >
        <source v-bind:src="src" type="video/mp4" />
    </video>
</template>

<script>

export default {
    name: 'sideblock-select-lens-cover-video',
    props: {
        src: {
            type: String,
        },
        poster: {
            type: String,
        },
    },
    mounted() {
        const video = this.$refs.video;
        if (!video) {
            return;
        }
        video.play();
        video.controls = false;
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-select-lens-cover-video {
    position: relative;

    display: block;
    width: 100%;
    height: 100%;
    @media @media-sm-down {
    }
}
</style>
