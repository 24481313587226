import client from '../_client';
import config from '~/config';
import transforms from './transforms';

export const getList = (params = {}) => {
    return client.request({
        url: config.api.communication.list,
        method: 'GET',
        params,
        transformResponse: transforms.responses.list,
    });
};
