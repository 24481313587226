<template>
    <div class="sideblock-delivery-old">
        <div class="sideblock-delivery-old__title">
            курьерская доставка
        </div>
        <div class="sideblock-delivery-old__text">
            Отправляем заказы курьерской службой СДЭК с номером для отслеживания. Доставка для заказов на сумму меньше {{ freeShippingThresholdFormatted }} рассчитывается в корзине после выбора города.
        </div>
        <div class="sideblock-delivery-old__table">
            <div class="sideblock-delivery-old__table-item">
                <div class="sideblock-delivery-old__table-item-column">
                    МОСКВА И САНКТ-ПЕТЕРБУРГ
                </div>
                <div class="sideblock-delivery-old__table-item-column">
                    Бесплатно от {{ freeShippingThresholdFormatted }}
                    <br/>
                    <span class="sideblock-delivery-old__table-item-column-comment">2-3 рабочих дня</span>
                </div>
            </div>
            <div class="sideblock-delivery-old__table-item">
                <div class="sideblock-delivery-old__table-item-column">
                    по регионам россии
                </div>
                <div class="sideblock-delivery-old__table-item-column">
                    Бесплатно от {{ freeShippingThresholdFormatted }}
                    <br/>
                    <span class="sideblock-delivery-old__table-item-column-comment">4-6 рабочих дня</span>
                </div>
            </div>
            <div class="sideblock-delivery-old__table-item">
                <div class="sideblock-delivery-old__table-item-column">
                    Беларусь, Казахстан И Армения
                </div>
                <div class="sideblock-delivery-old__table-item-column">
                    Бесплатно от {{ freeShippingThresholdFormatted }}
                    <br/>
                    <span class="sideblock-delivery-old__table-item-column-comment">5-7 рабочих дней</span>
                </div>
            </div>
            <div class="sideblock-delivery-old__table-item">
                <div class="sideblock-delivery-old__table-item-column">
                    по миру
                </div>
                <div class="sideblock-delivery-old__table-item-column">
                    <span class="sideblock-delivery-old__table-item-column-comment">Временно недоступна</span>
                </div>
            </div>
        </div>
        <div class="sideblock-delivery-old__title">
            самовывоз
        </div>
        <div class="sideblock-delivery-old__text">
            Вы можете забрать заказ в одном из наших магазинов в Москве или Санкт-Петербурге.
        </div>
        <br />
        <div class="sideblock-delivery-old__title">
            гарантия
        </div>
        <div class="sideblock-delivery-old__text">
            Все наши оправы и очки имеют гарантию один год. <a v-bind:href="config.urls.warranty" target="_blank">Подробнее</a>
        </div>
        <br />
        <div class="sideblock-delivery-old__title">
            возврат
        </div>
        <div class="sideblock-delivery-old__text">
            Так случается, что заказанные онлайн вещи могут не подойти нам по ряду причин. Если вам не подошли или не понравились заказанные очки, ничего страшного! Возврат оправ и очков с курьером по России – бесплатно!
            <br />
            <br />
            Информацию о возврате аксессуаров и предметов серии Units можно узнать на странице <a v-bind:href="config.urls.refund" target="_blank">Возврат и обмен</a>.
        </div>
    </div>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'sideblock-delivery-old',
    data: () => ({
        config,
    }),
    computed: {
        freeShippingThresholdFormatted() {
            return utils.formats.formatNumerWithSpaces(config.cart.freeShippingThreshold) + ' ₽';
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-delivery-old {
    .mixin-sideblock();

    padding: 20px 0 0;
    &__title {
        padding: 0 20px;
        margin-bottom: 8px;

        text-transform: uppercase;
    }
    &__text {
        padding: 0 20px;
        margin-bottom: 20px;
        &-trigger {
            text-decoration: underline;

            cursor: pointer;
        }
    }
    &__table {
        margin-bottom: 40px;
        &-item {
            display: flex;
            width: 100%;
            border-top: 1px solid @color-gray-darkest;
            &:last-child {
                border-bottom: 1px solid @color-gray-darkest;
            }
            &-column {
                flex: 0 0 auto;
                padding: 18px 20px 13px;
                border-left: 1px solid @color-gray-darkest;
                width: 50%;

                text-transform: uppercase;
                &:first-child {
                    border-left: none;
                }
                &-comment {
                    text-transform: none;
                }
            }
        }
    }
}
</style>
