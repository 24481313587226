<template>
    <div class="common-header-mobile-menu-footer">
        <div class="common-header-mobile-menu-footer__menu">
            <div class="common-header-mobile-menu-footer__menu-item"
                v-for="(item, index) in items"
                v-bind:key="index"
                v-bind:is="item.url ? 'a' : 'div'"
                v-bind:href="item.url ? item.url : null"
                v-bind:target="item.target ? item.target : null"
                >
                {{ item.name }}
            </div>
            <a class="common-header-mobile-menu-footer__menu-item _idented"
                v-bind:href="'tel:' + contacts.phone.value"
                v-text="contacts.phone.valueFormatted"
            />
            <div class="common-header-mobile-menu-footer__menu-item"
                v-text="contacts.phone.worktime"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'common-header-mobile-menu-footer',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        isActive: 0,
    }),
    computed: {
        contacts() {
            return this.$store.getters['site/contacts'];
        },
    },
    methods: {
        toggle() {
            if (this.item.items) {
                this.isActive = !this.isActive;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-header-mobile-menu-footer {
    padding-top: 48px;
    &__menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 -8px;
        &-item {
            flex: 0 0 auto;
            padding: 0 8px;

            font-size: 1.4rem;
            line-height: 2rem;
            text-decoration: none;

            &:not(:nth-last-child(2)),
            &:not(:last-child) {
                margin-bottom: 16px;
            }
            &._idented {
                margin-top: 20px;
            }
        }
    }
    @media @media-md-down {
    }
}
</style>
