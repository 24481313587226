var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.url ? 'a' : 'div',{ref:"root",tag:"component",staticClass:"plugin-home-hero-item",class:{
        _half: _vm.isHalf,
        _uppercase: _vm.isUppercase,
    },attrs:{"target":(_vm.url && _vm.isNewTab) ? '_blank' : null,"href":_vm.url},on:{"click":_vm.rootClickHandler}},[_c('div',{staticClass:"plugin-home-hero-item__observer"},[_c('common-intersection-observer',{attrs:{"options":{ threshold: 0.8 },"self-destruct":""},on:{"intersect":_vm.triggerImpressionAnalytics}})],1),(_vm.currentVideo && !_vm.isLowPowerMode)?_c('div',{staticClass:"plugin-home-hero-item__placeholder"},[_c('ui-video',{attrs:{"video":_vm.currentVideo,"poster":_vm.currentImage}})],1):_vm._e(),(_vm.isLowPowerMode || !_vm.videoDesktop)?_c('div',{staticClass:"plugin-home-hero-item__placeholder _desktop"},[_c('ui-image',{attrs:{"image":_vm.currentImage}})],1):_vm._e(),(_vm.isLowPowerMode || !_vm.videoMobile)?_c('div',{staticClass:"plugin-home-hero-item__placeholder _mobile"},[_c('ui-image',{attrs:{"image":_vm.currentImage}})],1):_vm._e(),_c('div',{staticClass:"plugin-home-hero-item__content"},[_c('div',{staticClass:"plugin-home-hero-item__content-text _top",class:("_" + _vm.topTextAlign),style:({ color: _vm.topTextColor }),domProps:{"innerHTML":_vm._s(_vm.topText)}}),_c('div',{staticClass:"plugin-home-hero-item__content-text _middle",class:("_" + _vm.middleTextAlign),style:({ color: _vm.middleTextColor }),domProps:{"innerHTML":_vm._s(_vm.middleText)}}),_c('div',{staticClass:"plugin-home-hero-item__content-text _bottom",class:("_" + _vm.bottomTextAlign),style:({ color: _vm.bottomTextColor }),domProps:{"innerHTML":_vm._s(_vm.bottomText)}}),(_vm.buttonText)?_c('div',{staticClass:"plugin-home-hero-item__content-button",class:[ ("_" + _vm.buttonAlign), {
                '_no-border': _vm.buttonNoBorder,
            }],style:({
                color: _vm.isHovered ? _vm.buttonMainHoverColor : _vm.buttonMainColor,
                borderColor: _vm.isHovered ? _vm.buttonBorderHoverColor : _vm.buttonBorderColor,
                backgroundColor: _vm.isHovered ? _vm.buttonBackgroundHoverColor : _vm.buttonBackgroundColor,
            }),domProps:{"innerHTML":_vm._s(_vm.buttonText)},on:{"mouseenter":_vm.mouseEnterHandler,"mouseleave":_vm.mouseLeaveHandler}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }