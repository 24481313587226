const state = {
    galleryIndex: null,
    galleryItems: null,
};

const getters = {
};

const mutations = {
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
