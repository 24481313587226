<template>
    <component class="plugin-home-hero-item"
        v-bind:is="url ? 'a' : 'div'"
        v-bind:class="{
            _half: isHalf,
            _uppercase: isUppercase,
        }"
        v-bind:target="(url && isNewTab) ? '_blank' : null"
        v-bind:href="url"
        v-on:click="rootClickHandler"
        ref="root"
        >
        <div class="plugin-home-hero-item__observer">
            <common-intersection-observer
                v-on:intersect="triggerImpressionAnalytics"
                v-bind:options="{ threshold: 0.8 }"
                self-destruct
            />
        </div>
        <div class="plugin-home-hero-item__placeholder"
            v-if="currentVideo && !isLowPowerMode"
            >
            <ui-video
                v-bind:video="currentVideo"
                v-bind:poster="currentImage"
            />
        </div>
        <div class="plugin-home-hero-item__placeholder _desktop"
            v-if="isLowPowerMode || !videoDesktop"
            >
            <ui-image v-bind:image="currentImage" />
        </div>
        <div class="plugin-home-hero-item__placeholder _mobile"
            v-if="isLowPowerMode || !videoMobile"
            >
            <ui-image v-bind:image="currentImage" />
        </div>
        <div class="plugin-home-hero-item__content">
            <div class="plugin-home-hero-item__content-text _top"
                v-bind:class="`_${topTextAlign}`"
                v-bind:style="{ color: topTextColor }"
                v-html="topText"
            />
            <div class="plugin-home-hero-item__content-text _middle"
                v-bind:class="`_${middleTextAlign}`"
                v-bind:style="{ color: middleTextColor }"
                v-html="middleText"
            />
            <div class="plugin-home-hero-item__content-text _bottom"
                v-bind:class="`_${bottomTextAlign}`"
                v-bind:style="{ color: bottomTextColor }"
                v-html="bottomText"
            />
            <div class="plugin-home-hero-item__content-button"
                v-if="buttonText"
                v-bind:class="[ `_${buttonAlign}`, {
                    '_no-border': buttonNoBorder,
                }]"
                v-bind:style="{
                    color: isHovered ? buttonMainHoverColor : buttonMainColor,
                    borderColor: isHovered ? buttonBorderHoverColor : buttonBorderColor,
                    backgroundColor: isHovered ? buttonBackgroundHoverColor : buttonBackgroundColor,
                }"
                v-on:mouseenter="mouseEnterHandler"
                v-on:mouseleave="mouseLeaveHandler"
                v-html="buttonText"
            />
        </div>
    </component>
</template>

<script>
import utils from '~/utils';
import ecommerce from '~/utils/ecommerce';

export default {
    name: 'plugin-home-hero-item',
    props: {
        videoDesktop: { type: String, default: null },
        imageDesktop: { type: String },
        videoMobile: { type: String, default: null },
        imageMobile: { type: String },
        topTextAlign: { type: String, default: 'center' },
        topTextColor: { type: String, default: '#ffffff' },
        topText: { type: String, default: null },
        middleTextAlign: { type: String, default: 'center' },
        middleTextColor: { type: String, default: '#ffffff' },
        middleText: { type: String, default: null },
        bottomTextAlign: { type: String, default: 'center' },
        bottomTextColor: { type: String, default: '#ffffff' },
        bottomText: { type: String, default: null },
        buttonAlign: { type: String, default: 'center' },
        buttonMainColor: { type: String, default: '#ffffff' },
        buttonMainHoverColor: { type: String, default: '#000000' },
        buttonBorderColor: { type: String, default: 'currentColor' },
        buttonBorderHoverColor: { type: String, default: 'currentColor' },
        buttonBackgroundColor: { type: String, default: 'transparent' },
        buttonBackgroundHoverColor: { type: String, default: 'transparent' },
        buttonText: { type: String, default: null },
        buttonNoBorder: { type: Boolean, default: false },
        url: { type: String, default: null },
        isNewTab: { type: Boolean, default: false },
        isHalf: { type: Boolean, default: false },
        isUppercase: { type: Boolean, default: false },
        ecommerceData: { type: Object, default: null },
    },
    data: () => ({
        isHovered: false,
        currentVideo: null,
        currentImage: null,
    }),
    computed: {
        isLowPowerMode() {
            return this.$store.state.common.isLowPowerMode;
        },
    },
    methods: {
        updateMedia() {
            const resize = utils.common.getResize(
                this.isHalf
                    ? {
                        width: 50,
                        breakpoints: {
                            1023: { width: 100 },
                        },
                    }
                    : {
                        width: 100,
                    }
            );
            if (window.innerWidth >= 1024) {
                this.currentVideo = this.videoDesktop;
                this.currentImage = utils.common.injectResize(this.imageDesktop, resize);
            } else {
                this.currentVideo = this.videoMobile;
                this.currentImage = utils.common.injectResize(this.imageMobile, resize);
            }
        },
        mouseEnterHandler() {
            this.isHovered = true;
        },
        mouseLeaveHandler() {
            this.isHovered = false;
        },
        triggerImpressionAnalytics() {
            if (this.ecommerceData.promotionId &&
                this.ecommerceData.promotionName &&
                this.ecommerceData.creativeName &&
                this.ecommerceData.creativeSlot
            ) {
                ecommerce.promotionImpression(this.ecommerceData);
            }
        },
        rootClickHandler(e) {
            if (!this.url) {
                return;
            }
            e.preventDefault();
            ecommerce.promotionClick(this.ecommerceData);
            if (e.metaKey || e.ctrlKey || this.isNewTab) {
                window.open(this.url, '_blank');
            } else {
                window.location.href = this.url;
            }
        },
        linkClickHandler(e) {
            e.preventDefault();
            ecommerce.promotionClick(this.ecommerceData);
            if (e.metaKey || e.ctrlKey) {
                window.open(e.target.attributes.href.baseURI, '_blank');
            } else {
                window.location.href = e.target.attributes.href.baseURI;
            }
        },
        bindLinksHandlers() {
            if (!this.$refs.root) {
                return;
            }
            const links = this.$refs.root.getElementsByTagName('a');
            for (let i = 0; i < links.length; i++) {
                links[i].addEventListener('click', this.linkClickHandler);
            }
        },
        unbindLinksHandlers() {
            if (!this.$refs.root) {
                return;
            }
            const links = this.$refs.root.getElementsByTagName('a');
            for (let i = 0; i < links.length; i++) {
                links[i].removeEventListener('click', this.linkClickHandler);
            }
        },
    },
    mounted() {
        this.updateMedia();
        window.addEventListener('resize', this.updateMedia);
        this.$nextTick(this.bindLinksHandlers);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateMedia);
        this.unbindLinksHandlers();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-hero-item {
    .typography-display-md();

    position: relative;

    flex: 1 1 auto;
    width: 100%;

    text-decoration: none;

    background-color: @color-gray-main;
    &._half {
        width: 50%;
    }
    &._uppercase {
        text-transform: uppercase;
        /deep/ p {
            text-transform: uppercase;
        }
    }
    &__observer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        pointer-events: none;
        opacity: 0;
    }
    &__placeholder {
        display: block;
        width: 100%;
        height: 100%;
        &._desktop {
            display: block;
        }
        &._mobile {
            display: none;
        }
        &._hidden {
            position: fixed;
            top: 0;
            left: 0;
            z-index: -10;

            width: 1px;
            height: 1px;

            opacity: 0;
            pointer-events: none;
        }
    }
    &__content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        padding: 36px 32px;
        /deep/ p {
            margin: 0 auto;

            font-size: inherit;
        }
        &-text {
            max-width: 656px;
            &._top {
                margin: 0 0 auto;
            }
            &._middle {
                margin: auto 0 auto;
            }
            &._bottom {
                margin: auto 0 0;
            }
        }
        &-button {
            padding: 6px 24px;
            border: 1px solid currentColor;
            margin: auto auto 0;

            transition: all @duration-fast @easing-default;
            &._no-border {
                border: none;
            }
        }
        &-text._bottom + &-button {
            margin-top: 8px;
        }
        &-text,
        &-button {
            &._left {
                margin-right: auto;
                margin-left: 0;
            }
            &._center {
                margin-right: auto;
                margin-left: auto;
            }
            &._right {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
    @media @media-md-down {
        &._half {
            width: 100%;
        }
        &__placeholder {
            &._desktop {
                display: none;
            }
            &._mobile {
                display: block;
            }
        }
        &__content {
            padding: 32px 20px;
            &-text {
                max-width: none;
            }
        }
    }
}
</style>
