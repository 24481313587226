<template>
    <div class="plugin-infopage-expandable-list-item">
        <div class="plugin-infopage-expandable-list-item__headline"
            v-on:click="toggleBody"
            >
            <div class="plugin-infopage-expandable-list-item__headline-title"
                v-text="title"
            />
            <div class="plugin-infopage-expandable-list-item__headline-icon">
                <icon v-bind:name="isExpanded ? 'minus-round' : 'plus-round'" original />
            </div>
        </div>
        <div class="plugin-infopage-expandable-list-item__body"
            v-bind:class="{ '_expanded': isExpanded }"
            >
            <div class="plugin-infopage-expandable-list-item__body-container">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'plugin-infopage-expandable-list-item',
    props: {
        title: String,
    },
    data: () => ({
        isExpanded: false,
    }),
    methods: {
        toggleBody() {
            this.isExpanded = !this.isExpanded;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-expandable-list-item {
    border-top: 1px solid @color-gray-darkest;
    &__headline {
        display: flex;
        align-items: center;
        padding: 16px 0;

        cursor: pointer;
        &-title {
            .typography-body-lg();

            flex: 1 1 auto;

            text-transform: uppercase;
        }
        &-icon {
            margin-left: 12px;

            font-size: 2.3rem;
        }
    }
    &__body {
        max-height: 0;
        width: auto;
        padding: 0 4px;
        margin: 0 -4px;
        overflow: hidden;
        &._expanded {
            max-height: unset;
        }
        &-container {
            padding-bottom: 16px;
        }
    }
}
</style>
