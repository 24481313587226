<template>
    <div class="sideblock-select-lens"
        v-bind:class="{ _loading: isLoading }"
        >
        <transition name="fade">
            <div class="sideblock-select-lens__loader"
                v-if="isLoading"
                >
                <div class="sideblock-select-lens__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="sideblock-select-lens__headline">
            <div class="sideblock-select-lens__headline-progress">
                <div class="sideblock-select-lens__headline-progress-value"
                    v-bind:style="progressStyles"
                />
            </div>
            <div class="sideblock-select-lens__headline-back"
                v-on:click="goBack"
                >
                ←
            </div>
            <div class="sideblock-select-lens__headline-title"
                v-text="title"
            />
            <div class="sideblock-select-lens__headline-close"
                v-on:click="close"
                >
                <icon name="close" />
            </div>
        </div>
        <div class="sideblock-select-lens__step">
            <component
                v-bind:is="component"
                v-on:set-node="setNode"
                v-bind:items="items"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'sideblock-select-lens',
    props: {
        glasses: {
            type: Object,
        },
        lineId: {
            type: [ Number, String ],
            default: null,
        },
        isShowCartRequired: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        currentNodeId: null,
        isLensAppendRequired: false,
        isCloseRequired: false,
        givenLineId: null,
        isOldLensRemoved: false,
        finalLens: null,
    }),
    computed: {
        ...mapState('lenses', [
            'getLensesResult',
            'getLensesIsLoading',
            'getLensesError',
        ]),
        ...mapState('cart', [
            'getCartResult',
            'getCartIsLoading',
            'getCartError',

            'addToCartResult',
            'addToCartIsLoading',
            'addToCartError',

            'removeFromCartIsLoading',

            'decreaseLineQuantityIsLoading',
        ]),
        isLoading() {
            return this.getLensesIsLoading ||
            this.getCartIsLoading ||
            this.addToCartIsLoading ||
            this.removeFromCartIsLoading ||
            this.decreaseLineQuantityIsLoading;
        },
        currentNode() {
            if (!this.currentNodeId) {
                return null;
            }
            const findNodeById = (id, items) => {
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    if (item.id === id) {
                        return item;
                    }
                    if (item.children) {
                        const result = findNodeById(id, item.children);
                        if (result) {
                            return result;
                        }
                    }
                }
                return null;
            };
            return findNodeById(this.currentNodeId, this.getLensesResult);
        },
        isLastNode() {
            return this.currentNode?.children.every(x => !!x.product);
        },
        items() {
            if (!this.currentNode) {
                return this.getLensesResult || [];
            }
            return this.currentNode.children;
        },
        component() {
            if (!this.currentNode) {
                return 'sideblock-select-lens-type';
            }
            if (this.isLastNode) {
                return 'sideblock-select-lens-cover';
            }
            return 'sideblock-select-lens-thickness';
        },
        title() {
            if (!this.currentNode) {
                return 'Выберите тип линз';
            }
            if (this.isLastNode) {
                return 'Вам подойдут';
            }
            return 'Выберите тонкость линз';
        },
        progress() {
            if (!this.currentNode) {
                return 0;
            }
            return this.currentNode.currentDepth / this.currentNode.maxDepth * 100;
        },
        progressStyles() {
            return {
                transform: `translate(-${100 - this.progress}%)`,
            };
        },
        currentLensLineProduct() {
            if (!this.getCartResult) {
                return null;
            }
            const glassesLine = this.getCartResult.lines.find(x => {
                return x.id === this.lineId ||
                    x.id === this.givenLineId ||
                    x.product.id === this.glasses.id;
            });
            if (!glassesLine) {
                return null;
            }
            const lensLine = this.getCartResult.lines.find(x => x.id === glassesLine.child_id);
            if (lensLine) {
                return lensLine.product;
            } else {
                return null;
            }
        },
    },
    methods: {
        setNode(node) {
            if (node.product) {
                this.finalLens = node.product;
                this.addToCart();
            } else {
                this.finalLens = null;
                this.currentNodeId = node.id;
            }
        },
        goBack() {
            if (this.currentNode) {
                this.currentNodeId = this.currentNode.parentId;
            } else {
                this.close();
            }
        },
        addLensToCart() {
            if (this.isLensAppendRequired) {
                this.isLensAppendRequired = false;
            }
            if (this.isOldLensRemoved) {
                this.isOldLensRemoved = false;
            }
            const product = this.finalLens;
            const params = {
                parent_id: this.lineId || this.givenLineId,
                quantity: 2,
                product,
            };
            params.product.ecommerce.quantity = 2;
            params.product.ecommerce.listId = 'sidebar_lenses';
            params.product.ecommerce.listTitle = 'Линзы';

            this.isCloseRequired = true;
            this.$store.dispatch('cart/addToCart', params);
        },
        addGlassesToCart() {
            this.isLensAppendRequired = true;
            const params = {
                quantity: 1,
                product: this.glasses,
            };
            this.$store.dispatch('cart/addToCart', params);
        },
        removeOldLens() {
            this.isOldLensRemoved = true;
            const params = {
                ...this.currentLensLineProduct,
                quantity: 2,
            };
            params.ecommerce.quantity = 2;
            this.$store.dispatch('cart/updateLine', params);
        },
        addToCart() {
            if (this.currentLensLineProduct && !this.isOldLensRemoved) {
                this.removeOldLens();
                return;
            }
            if (this.lineId) {
                this.addLensToCart();
            } else {
                this.addGlassesToCart();
            }
        },
        close() {
            this.$store.commit('sideblocks/pop');
        },
    },
    mounted() {
        if (!this.getLensesResult && !this.getLensesIsLoading) {
            this.$store.dispatch('lenses/getLenses');
        }
    },
    watch: {
        addToCartResult(newVal) {
            if (newVal && this.isLensAppendRequired) {
                this.givenLineId = newVal.lines.find(x => x.product.id === this.glasses.id)?.id;
            }
            if (newVal && this.isCloseRequired) {
                this.$store.commit('sideblocks/pop');
                if (this.isShowCartRequired) {
                    this.$store.commit('sideblocks/push', 'cart');
                }
            }
        },
        getCartResult(newVal) {
            if (newVal) {
                if (this.isLensAppendRequired || this.isOldLensRemoved) {
                    this.addLensToCart();
                }
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-select-lens {
    .mixin-sideblock();

    position: relative;

    flex: 1 1 auto;
    width: 720px;
    padding: 0;
    &._loading {
        max-height: 100vh;
        overflow: hidden;
    }
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;

        background: @color-gray-lightest;
        &-container {
            width: 100px;
        }
    }
    &__headline {
        position: sticky;
        z-index: 1;
        top: 0;

        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 52px;
        padding-top: 4px;
        border-bottom: 1px solid @color-gray-darkest;

        background-color: @color-gray-lightest;
        &-progress {
            position: absolute;
            top: 0;
            left: 0;

            overflow: hidden;
            width: 100%;
            height: 4px;

            background-color: @color-gray-main;
            &-value {
                width: 100%;
                height: 100%;

                background-color: @color-accent-cold;

                transition: transform @duration-fast @easing-default;
            }
        }
        &-back {
            flex: 0 0 auto;
            padding-left: 20px;
            padding-right: 20px;

            font-size: 2.4rem;
            line-height: 2.4rem;

            cursor: pointer;
        }
        &-title {
            flex: 1 1 auto;
            padding: 0 62px 0 0;

            text-align: center;
            text-transform: uppercase;
        }
        &-close {
            display: none;
        }
    }
    @media @media-md-down {
        &__headline {
            &-title {
                padding-right: 16px;
            }
            &-close {
                flex: 0 0 auto;
                align-self: stretch;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                min-height: 48px;

                background-color: @color-gray-main;

                font-size: 1rem;
                text-align: center;

                cursor: pointer;
            }
        }
    }
}
</style>
