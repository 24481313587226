<template>
    <div class="page-checkout-step-shipping-address-postcode">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-bind:submit-handler="submitHandler"
            v-on:update="updateFormData"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-checkout-step-shipping-address-postcode',
    inject: [ 'pageCheckoutEventBus' ],
    data: () => ({
        innerFields: {
            postcode: {
                ...forms.fields.postcode,
                noAsterisk: true,
            },
        },
    }),
    validations() {
        return {
            fields: {
                postcode: this.isRussia
                    ? { ...forms.validations.postcode }
                    : { ...forms.validations.defaultRequired },
            },
        };
    },
    computed: {
        ...mapState('checkout', [ 'isShippingDirty' ]),
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        fields() {
            const result = { ...this.innerFields };
            if (!this.isRussia) {
                result.postcode.maxLength = undefined;
                result.postcode.minLength = undefined;
                result.postcode.type = 'text';
            }
            result.postcode.value = this.checkoutData.postcode;
            return result;
        },
        isRussia() {
            return this.checkoutData?.country?.title === 'Россия';
        },
    },
    methods: {
        updateFormData(newData) {
            this.$store.commit('checkout/updateData', {
                postcode: newData.postcode.value,
            });
        },
        submitHandler() {
            this.pageCheckoutEventBus.$emit('shipping-submit');
        },
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$emit('shipping-validation', { postcode: true });
    },
    watch: {
        '$v.$invalid': {
            immediate: true,
            handler(newVal) {
                this.pageCheckoutEventBus.$emit('shipping-validation', { postcode: !newVal });
            },
        },
        isShippingDirty: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                } else {
                    this.$v.$reset();
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-address-postcode {
}
</style>
