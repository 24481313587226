import { mainClient } from '~/network';
import config from '~/config';

const basicFilterSetsCodes = config.filters.basicFilterSetsCodes;

const allowedParams = [
    ...basicFilterSetsCodes,
    'q',
    'filter',
    'gender',
    'discounts',
    'fitting_allowed',
];

const state = {
    filterItemsIsLoading: false,
    filterItemsError: null,
    filterItems: null,

    isActive: false,

    // объект с массивами id выбранных сейчас фильтров
    // ключи объектра - filteritem_set.id
    activeFilters: {},
    searchQuery: null,

    basicFilterSetsCodes,
};

const getters = {
    state(state) {
        return state;
    },
    filterItems(state) {
        return state.filterItems;
    },
    activeFilters(state) {
        return state.activeFilters;
    },
    activeBasicFiltersCount(state) {
        return Object.keys(state.activeFilters).reduce((acc, key) => {
            if (state.basicFilterSetsCodes.indexOf(key) > -1) {
                return acc + state.activeFilters[key].length;
            } else {
                return acc;
            }
        }, 0);
    },
    searchQuery(state) {
        return state.searchQuery;
    },
    isFemaleFilterActive(state) {
        return state.activeFilters[config.filters.gender.code]?.indexOf(config.filters.gender.values.female.id) > -1 || false;
    },
    isMaleFilterActive(state) {
        return state.activeFilters[config.filters.gender.code]?.indexOf(config.filters.gender.values.male.id) > -1 || false;
    },
    isFilterActive: state => (setCode, id) => {
        const filterGroup = state.activeFilters[setCode];
        return filterGroup !== undefined && filterGroup.indexOf(id) > -1;
    },
};

const mutations = {
    toggleFilter(state, { setCode, filterId }) {
        const result = { ...state.activeFilters };
        if (result[setCode] === undefined) {
            result[setCode] = [];
        }
        if (result[setCode].indexOf(filterId) < 0) {
            result[setCode].push(filterId);
        } else {
            result[setCode] = result[setCode].filter(x => x !== filterId);
        }
        state.activeFilters = result;
    },
    setActiveFilters(state, value) {
        state.activeFilters = value;
    },
    setFilter(state, { setCode, filterIds }) {
        state.activeFilters = { ...state.activeFilters, [setCode]: filterIds };
    },
    setSearchQuery(state, value) {
        state.searchQuery = value;
    },
};

const actions = {
    async getFilterItems({ state }, { collectionId }) {
        if (state.filterItemsIsLoading) {
            return;
        }

        state.filterItemsIsLoading = true;

        const { data, error } = await mainClient.shop.getFilters({ collectionId });
        if (error) {
            state.filterItems = null;
        } else if (data) {
            state.filterItems = data;
        }

        state.filterItemsIsLoading = false;
    },
    resetBasicFilters({ state }) {
        const result = { ...state.activeFilters };
        for (const key of state.basicFilterSetsCodes) {
            result[key] = [];
        }
        state.activeFilters = result;
    },
    getFromUrl({ state }) {
        const params = new URLSearchParams(window.location.search);

        const result = {};
        params.forEach((value, key) => {
            if (allowedParams.indexOf(key) < 0) {
                return;
            }
            if (key === 'q') {
                state.searchQuery = value;
            } else {
                const values = value.split(',').filter(x => x !== '');
                if (values.length > 0) {
                    result[key] = values.map(x => {
                        if (isNaN(x)) {
                            return x === 'true';
                        }
                        return parseInt(x, 10);
                    });
                }
            }
        });
        const paramsAmount = Object.keys(result).length;
        if (paramsAmount === 0) {
            return;
        }
        state.activeFilters = result;
    },
    saveToUrl({ state }) {
        const result = {};
        Object.keys(state.activeFilters).forEach(key => {
            const values = state.activeFilters[key].filter(x => x !== '' && x !== null && x !== undefined);
            if (values.length > 0) {
                result[key] = values.join(',');
            }
        });
        if (state.searchQuery) {
            result.search = state.searchQuery;
        }
        const otherParams = new URLSearchParams(window.location.search);
        const paramsAsURI = new URLSearchParams(result);
        otherParams.forEach((value, key) => {
            if (allowedParams.indexOf(key) < 0) {
                paramsAsURI.append(key, value);
            }
        });
        window.history.replaceState({}, '', '?' + paramsAsURI.toString().replace(/%2C/g, ','));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
