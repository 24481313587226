<template>
    <div class="common-catalog-filters"
        v-bind:class="{ _active: isActive }"
        >
        <div class="common-catalog-filters__headline">
            <div class="common-catalog-filters__headline-amount">
                Оправ: <span class="common-catalog-filters__headline-amount-value" v-text="productsCount !== null ? productsCount : '∞'" />
            </div>
            <div class="common-catalog-filters__headline-trigger"
                v-on:click="toggle"
                >
                <span class="common-catalog-filters__headline-trigger-title" v-text="'фильтр'" />
                <span class="common-catalog-filters__headline-trigger-count" v-if="count > 0" v-text="count" />
                <span class="common-catalog-filters__headline-trigger-arrow" v-text="'↓'" />
            </div>
            <div class="common-catalog-filters__headline-fitting"
                v-if="!isFittingCheckboxDisabled"
                >
                <common-catalog-filters-fitting />
            </div>
        </div>
        <transition name="fade">
            <div class="common-catalog-filters__content"
                v-if="filterItems !== null && isActive"
                >
                <div class="common-catalog-filters__content-list"
                    v-bind:class="{ _extended: isListExtended }"
                    >
                    <div class="common-catalog-filters__content-list-item"
                        v-for="(filterItem, i) in filterItems"
                        v-bind:key="i"
                        v-bind:class="{
                            _wide: filterItem.code === 'color',
                            _small: filterItem.code === 'size',
                        }"
                        >
                        <div class="common-catalog-filters__content-list-item-title"
                            v-text="filterItem.title"
                        />
                        <div class="common-catalog-filters__content-list-item-options">
                            <div class="common-catalog-filters__content-list-item-options-item"
                                v-for="(value, i) in filterItem.values"
                                v-bind:key="i"
                                v-bind:class="{
                                    _light: value.isLight,
                                    _active: activeFilters[filterItem.code] && activeFilters[filterItem.code].indexOf(value.id) > -1,
                                }"
                                v-on:click="toggleValue(filterItem.code, value.id)"
                                >
                                <div class="common-catalog-filters__content-list-item-options-item-background"
                                    v-bind:style="{
                                        backgroundColor: value.color !== null ? `#${value.color}` : null,
                                        backgroundImage: value.image !== null ? `url(${value.image})` : null,
                                    }"
                                />
                                <span class="common-catalog-filters__content-list-item-options-item-text"
                                    v-text="value.title"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="common-catalog-filters__content-fitting"
                    v-if="!isFittingCheckboxDisabled"
                    >
                    <common-catalog-filters-fitting />
                </div>
                <div class="common-catalog-filters__content-actions">
                    <div class="common-catalog-filters__content-actions-reset"
                        v-on:click="resetFilters"
                        >
                        <span class="common-catalog-filters__content-actions-reset-text">Очистить фильтры</span><span class="common-catalog-filters__content-actions-reset-count" v-if="count > 0" v-text="count" />
                    </div>
                    <div class="common-catalog-filters__content-actions-apply"
                        v-on:click="toggle"
                        >
                        Применить фильтры
                    </div>
                </div>
                <div class="common-catalog-filters__content-close"
                    v-on:click="toggle"
                    >
                    <icon name="close" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'common-catalog-filters',
    data: () => ({
        isFittingCheckboxDisabled: false,
    }),
    computed: {
        isActive() {
            return this.$store.state.catalog.filters.isActive;
        },
        activeFilters() {
            return this.$store.getters['catalog/filters/activeFilters'];
        },
        filterItems() {
            const items = this.$store.getters['catalog/filters/filterItems'];
            if (!items) {
                return items;
            }
            if (items.length === 5) {
                return [ items[4], ...items.slice(0, 4) ];
            } else {
                return items;
            }
        },
        count() {
            return this.$store.getters['catalog/filters/activeBasicFiltersCount'];
        },
        productsCount() {
            return this.$store.state.catalog.count;
        },
        isFittingOnlyActive() {
            return this.activeFilters.discounts && this.activeFilters.discounts[0] === false;
        },
        isDiscountsFilterActive() {
            return this.activeFilters.discounts && !!this.activeFilters.discounts[0];
        },
        isListExtended() {
            return this.filterItems.length === 5;
        },
    },
    methods: {
        toggle() {
            this.$store.state.catalog.filters.isActive = !this.$store.state.catalog.filters.isActive;
        },
        toggleValue(setCode, filterId) {
            this.$store.commit('catalog/filters/toggleFilter', { setCode, filterId });
        },
        updateDiscountsFilter(newVal) {
            this.$store.commit('catalog/filters/setFilter', { setCode: 'discounts', filterIds: [ newVal ? false : null ] });
        },
        resetFilters() {
            if (this.count > 0) {
                this.$store.dispatch('catalog/filters/resetBasicFilters');
            }
        },
        fixChatraStyles() {
            if (window.innerWidth <= 768) {
                document.body.classList.add('_chatra_fix');
            } else {
                document.body.classList.remove('_chatra_fix');
            }
        },
    },
    mounted() {
        if (this.isDiscountsFilterActive) {
            this.isFittingCheckboxDisabled = true;
        }
        this.fixChatraStyles();
        window.addEventListener('resize', this.fixChatraStyles);
    },
    beforeMount() {
        if (!this.filterItems) {
            this.$store.dispatch('catalog/filters/getFilterItems', { collectionId: this.$store.state.collections.currentCollection?.id });
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.fixChatraStyles);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-catalog-filters {
    &__headline {
        .container();
        .container-paddings();

        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 41px;
        border-top: 1px solid @color-gray-lightest;

        background: @color-gray-lighter;
        ._active > & &-trigger-arrow {
            transform: scaleY(-1);
        }
        &-amount {
            flex: 0 1 auto;

            white-space: nowrap;
            &-value {
                display: inline-block;
                min-width: 35px;
            }
        }
        &-trigger {
            flex: 0 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 0 25px;
            margin-left: 25px;
            margin-right: 25px;

            text-align: center;
            text-transform: uppercase;

            cursor: pointer;
            user-select: none;
            &:hover & {
                &-title {
                    text-decoration: none;
                }
            }
            &-title {
                padding-top: 3px;

                text-decoration: underline;
            }
            &-count {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 21px;
                padding: 3px 8px 0;
                border-radius: 11px;
                margin-left: 8px;
                margin-right: auto;

                text-align: center;

                background-color: @color-accent-cold;
            }
            &-arrow {
                padding-top: 3px;
                margin-left: 5px;

                transition: transform @duration-fast @easing-default;
            }
        }
        &-fitting {
            flex: 0 0 auto;
            margin: auto 0 auto auto;
        }
    }
    &__content {
        .transition-fade();

        position: relative;
        z-index: 1;

        background-color: @color-gray-lightest;
        &-list {
            display: grid;
            grid-template: auto / minmax(auto, 141px) minmax(auto, 141px) 41px minmax(auto, 561px);
            justify-items: stretch;
            align-items: stretch;
            justify-content: space-between;
            align-content: stretch;
            grid-auto-flow: column;
            gap: 40px;
            width: 100%;
            max-width: 1040px;
            padding: 24px 20px 44px;
            margin: 0 auto;
            &._extended {
                max-width: 1082px;
                grid-template: auto /  minmax(auto, 141px) minmax(auto, 141px) minmax(auto, 141px) 41px minmax(auto, 561px);
            }
            &-item {
                user-select: none;
                &._wide &-options {
                    grid-template-columns: repeat(auto-fit, minmax(139px, 1fr));
                }
                &-title {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 18px;

                    text-align: center;
                }
                &-options {
                    display: grid;
                    grid-template: auto / auto;
                    grid-auto-flow: auto;
                    width: 100%;
                    border-left: 1px solid @color-gray-darkest;
                    border-top: 1px solid @color-gray-darkest;
                    &-item {
                        position: relative;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        min-height: 40px;
                        border-right: 1px solid @color-gray-darkest;
                        border-bottom: 1px solid @color-gray-darkest;

                        text-align: center;

                        cursor: pointer;
                        &:hover &-text {
                            text-decoration: underline;
                        }
                        &._active & {
                            &-background {
                                opacity: 1;
                            }
                        }
                        &._active:not(._light) & {
                            &-text {
                                color: @color-gray-lightest;
                            }
                        }
                        &-background {
                            position: absolute;
                            top: 0;
                            left: 0;

                            width: 100%;
                            height: 100%;

                            background-color: @color-gray-darkest;
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;

                            opacity: 0;

                            transition: opacity @duration-fast @easing-default;
                        }
                        &-text {
                            position: relative;

                            transition: color @duration-fast @easing-default;
                        }
                    }
                }
            }
        }
        &-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            padding: 0 20px;
            margin-bottom: 24px;
            &-reset {
                cursor: pointer;
                &:hover > &-text {
                    text-decoration: none;
                }
                &-text {
                    text-decoration: underline;
                }
                &-count {
                    margin-left: 5px;
                    &:before {
                        content: '(';
                    }
                    &:after {
                        content: ')';
                    }
                }
            }
            &-apply {
                display: none;
            }
        }
        &-fitting {
            display: none;
        }
        &-close {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 5;

            padding: 23px 27px;

            font-size: 1rem;

            cursor: pointer;
        }
    }
    @media @media-sm-down {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        min-height: 100%;

        background-color: @color-accent-cold;

        transition: background-color @duration-fast @easing-default;
        &._active {
            background: @color-gray-lightest;
        }
        &__headline {
            flex: 0 0 auto;
            height: 48px;
            border-top: none;

            background: transparent;
            &-amount {
                width: ~'calc(50% - 28px)';
            }
            ._active > & &-trigger-arrow {
                transform: scaleY(1);
            }
            &-trigger {
                flex: 1 1 auto;
                justify-content: space-between;
                width: ~'calc(50% + 28px)';
                padding: 0;
                margin: 0;
                &-count {
                    background-color: @color-gray-lightest;
                }
                &-arrow {
                    transform: scaleY(-1);
                }
            }
            &-fitting {
                display: none;
            }
        }
        &__content {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            width: 100%;

            background: transparent;
            &-list {
                flex: 0 0 auto;
                column-gap: 24px;
                row-gap: 50px;
                grid-template-columns: 1fr 41px 1fr;
                grid-template-rows: auto;
                margin: 0;
                padding: 20px 0;
                overflow: hidden;
                &._extended {
                    grid-template-columns: 1fr 41px 1fr;
                }
                &-item {
                    grid-row: 1;
                    grid-column: auto;
                    &:first-child,
                    &:last-child {
                        margin-left: -1px;
                    }
                    &:nth-child(2),
                    &:last-child {
                        margin-right: -1px;
                    }
                    &._small {
                        grid-column: 2;
                        grid-row: 1;
                    }
                    &._wide {
                        grid-column: ~'1 / 4';
                        grid-row: 2;
                    }
                }
                &._extended &-item:first-child,
                &._extended &-item:last-child {
                    grid-column: ~'1 / 4';
                }
                &._extended &-item {
                    grid-row: 2;
                }
                &._extended &-item:first-child {
                    grid-row: 1;
                }
                &._extended &-item:last-child {
                    grid-row: 3;
                }
                &._extended &-item:first-child &-item-options {
                    grid-template-columns: repeat(auto-fit, minmax(139px, 1fr));
                }
            }
            &-fitting {
                display: block;
                margin: 0 auto 40px;
            }
            &-actions {
                position: sticky;
                bottom: 0;
                z-index: 2;

                flex: 0 0 auto;
                align-items: stretch;
                min-height: 48px;
                padding: 0;
                margin: auto 0 0;

                background: @color-gray-lightest;
                &-reset {
                    flex: 1 1 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    text-align: center;
                }
                &-apply {
                    flex: 1 1 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    text-align: center;

                    background-color: @color-accent-cold;

                    cursor: pointer;
                }
            }
            &-close {
                top: auto;
                bottom: 100%;
                padding: 19px;

                background-color: @color-gray-main;
            }
        }
    }
}
</style>
