<template>
    <common-ckeditor>
        <slot />
    </common-ckeditor>
</template>

<script>
export default {
    name: 'plugin-common-text',
};
</script>

<style scoped lang="less">
@import '~theme';
</style>
