<template>
    <section class="page-checkout"
        v-bind:class="'_' + mode"
        >
        <section class="page-checkout__headline"
            v-text="mode === 'checkout' ? 'Оформление заказа' : 'Бесплатная примерка на дому'"
        />
        <div class="page-checkout__content">
            <transition name="fade">
                <div class="page-checkout__content-loader"
                    v-if="!cart"
                    >
                    <div class="page-checkout__content-loader-container">
                        <ui-loader />
                    </div>
                </div>
            </transition>
            <page-checkout-content
                v-if="cart"
                v-bind:mode="mode"
            />
        </div>
    </section>
</template>

<script>
export default {
    name: 'page-checkout',
    props: {
        mode: {
            type: String,
            default: 'checkout',
        },
    },
    computed: {
        cartModuleName() {
            return this.mode === 'fitting' ? 'fitting' : 'cart';
        },
        cart() {
            return this.$store.state[this.cartModuleName].getCartResult;
        },
    },
    mounted() {
        if (!this.cart && !this.$store.state[this.cartModuleName].getCartIsLoading) {
            this.$store.dispatch(this.cartModuleName + '/getCart');
        }
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout {
    .typography-body-md();
    .container();

    @headline-height: 48px;

    flex: 1 0 auto;
    &__headline {
        .container-paddings();

        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: @headline-height;
        padding-top: 4px;

        background-color: @color-accent-gold;

        text-transform: uppercase;
        ._fitting > & {
            background-color: @color-accent-yellow-2;
        }
    }
    &__content {
        position: relative;

        height: calc(100vh - @container-header-height - @headline-height);
        &-loader {
            .transition-fade();

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;

            display: flex;

            background-color: @color-gray-lightest;
            &.fade-enter {
                opacity: 1;
            }
            &-container {
                width: 100px;
                margin: auto;
            }
        }
    }
    @media @media-md-down {
        &__content {
            height: auto;
        }
    }
}
</style>
