<template>
    <section class="page-error">
        <h1 class="page-error__title" v-text="title" />
        <h2 class="page-error__subtitle" v-text="subtitle" />
    </section>
</template>

<script>
export default {
    name: 'page-error',
    props: {
        title: {
            type: String,
            default: 'Это 404',
        },
        subtitle: {
            type: String,
            default: 'И тут ничего нет :-(',
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    width: 100%;
    padding: 20px;
    &__title {
        margin: auto auto 8px;

        font-size: 2.8rem;
        line-height: 4rem;
        text-align: center;
    }
    &__subtitle {
        margin: 0 auto auto;

        font-size: 1.4rem;
        line-height: 2rem;
        text-align: center;
    }
}
</style>
