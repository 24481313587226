const state = {
    items: [],
};

const getters = {
};

const actions = {
};

const mutations = {
    push(state, item) {
        state.items = [ ...state.items, item ];
    },
    pop(state) {
        state.items = state.items.slice(0, state.items.length - 1);
    },
    purge(state) {
        state.items = [];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
