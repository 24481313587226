<template>
    <div class="page-item-info-clipons">
        <div class="page-item-info-clipons__content">
            <div class="page-item-info-clipons__content-title">
                <a class="page-item-info-clipons__content-title-link"
                    v-bind:href="dataObject.url"
                    v-text="dataObject.title + '↗\uFE0E'"
                />
            </div>
            <div class="page-item-info-clipons__content-description"
                v-text="description"
            />
            <a class="page-item-info-clipons__content-button"
                v-bind:href="dataObject.url"
                >
                <ui-button
                    isUppercase
                    >
                    Смотреть
                </ui-button>
            </a>
        </div>
        <div class="page-item-info-clipons__images"
            v-if="images !== null"
            >
            <a class="page-item-info-clipons__images-item"
                v-for="(item, i) in images"
                v-bind:key="i"
                v-bind:style="{ backgroundImage: `url(${item})` }"
                v-bind:href="dataObject.url"
            />
        </div>
    </div>
</template>

<script>
// import utils from '~/utils';

export default {
    name: 'page-item-info-clipons',
    props: {
        item: {
            type: Object,
        },
        type: {
            type: String,
        },
    },
    computed: {
        dataObject() {
            if (this.type === 'clipon') {
                return this.item.clipon_related_product;
            }
            return this.item.clipon;
        },
        description() {
            if (this.type === 'clipon') {
                return 'К этим клипонам идеально подойдет оправа ' + this.dataObject.title;
            }
            return 'Превратите эти очки в солнцезащитные c клипонами PYE';
        },
        images() {
            if (!this.dataObject.images || this.dataObject.images.length < 2) {
                return null;
            }
            return [ this.dataObject.images[0].medium, this.dataObject.images[1].medium ];
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-item-info-clipons {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    &__content {
        flex: 0 0 auto;
        width: 33.33%;
        padding: 16px;
        &-title {
            .typography-display-md();

            margin-bottom: 36px;

            text-transform: uppercase;
        }
        &-description {
            .typography-display-md();

            margin-bottom: 24px;
        }
        &-button {
            display: block;
            width: 140px;

            text-decoration: none;
        }
    }
    &__images {
        flex: 1 1 100%;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        &-item {
            width: 50%;
            padding-top: 61.25%;
            border-left: 1px solid @color-gray-lightest;

            background-position: center;
            background-size: cover;
        }
    }
    @media @media-lg-down {
        &__content {
            flex: 0 0 auto;
            width: 33.33%;
            padding: 16px;
            &-title {
                .typography-display-sm();

                margin-bottom: 28px;
            }
            &-description {
                .typography-display-sm();

                margin-bottom: 16px;
            }
        }
    }
    @media @media-md-down {
        flex-direction: column-reverse;
        &__content {
            width: 100%;
            border-top: 1px solid @color-gray-lightest;
            &-description {
                max-width: 335px;
            }
        }
        &__images {
        }
    }
}
</style>
