<template>
    <div class="sideblock-fitting">
        <div class="sideblock-fitting__container">
            <div class="sideblock-fitting__info"
                v-if="isReady"
                >
                <common-expandable-item v-bind:title="'Как это работает'">
                    <common-fitting-info />
                </common-expandable-item>
            </div>
            <div class="sideblock-fitting__selection">
                <div class="sideblock-fitting__selection-item"
                    v-for="(n, i) in itemsAmount"
                    v-bind:key="i"
                    >
                    <div class="sideblock-fitting__selection-item-content">
                        <sideblock-fitting-slot
                            v-if="!cart.lines[i]"
                            v-bind:is-last-item-optical="isLastItemOptical"
                        />
                        <sideblock-fitting-product
                            v-else
                            v-bind:line="cart.lines[i]"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="sideblock-fitting__button">
            <a class="sideblock-fitting__button-link"
                v-bind:href="fittingHref"
                >
                <ui-button is-uppercase variant="yellow">
                    Заказать бесплатную примерку
                </ui-button>
            </a>
        </div>
        <transition name="fade">
            <div class="sideblock-fitting__loader"
                v-if="isLoading"
                >
                <div class="sideblock-fitting__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import config from '~/config';
import { checkoutStepCart } from '~/utils/ecommerce';

export default {
    name: 'sideblock-fitting',
    data: () => ({
        fittingHref: config.urls.fitting,
        itemsAmount: 4,
        isReady: false,
        isAnalyticsTriggered: false,
    }),
    computed: {
        ...mapState('fitting', [
            'addToCartIsLoading',
        ]),
        ...mapGetters('fitting', [
            'productsEcommerceData',
        ]),
        isLoading() {
            return this.$store.getters['fitting/isLoading'];
        },
        cart() {
            return this.$store.state.fitting.getCartResult;
        },
        items() {
            return this.$store.getters['fitting/items'];
        },
        isLastItemOptical() {
            return this.items && this.items.length > 0 && this.items[this.items.length - 1].isOptical;
        },
    },
    methods: {
        readyCheck() {
            if (this.cart !== null && !this.isReady) {
                this.isReady = true;
            }
        },
    },
    mounted() {
        this.readyCheck();
    },
    watch: {
        cart: {
            immediate: true,
            handler(newVal) {
                if (newVal && !this.isAnalyticsTriggered && !this.addToCartIsLoading) {
                    checkoutStepCart({
                        total: this.cart.total,
                        products: this.productsEcommerceData,
                    }, true);
                    this.isAnalyticsTriggered = true;
                }
            },
        },
        items(newVal) {
            this.readyCheck();
            if (newVal.length === 0) {
                this.$store.commit('sideblocks/pop');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-fitting {
    .mixin-sideblock();

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    padding: 0 0 20px;
    &__container {
        flex: 1 1 auto;
        overflow: auto;
    }
    &__warning {
        padding: 20px;
        margin: 20px;

        background-color: @color-accent-yellow;
    }
    &__info {
        border-bottom: 1px solid @color-gray-darkest;
        margin-bottom: 24px;
    }
    &__selection {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        &-item {
            position: relative;
            flex: 0 0 auto;
            width: calc(50% - 0.5px);
            padding-top: 54.4%;
            &:nth-child(2n) {
                margin-left: 1px;
            }
            &:nth-child(2) ~ & {
                margin-top: 1px;
            }
            &-content {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                background-color: @color-gray-main;
            }
        }
    }
    &__button {
        flex: 0 0 auto;
        padding: 16px 23px 0;
        &-link {
            display: block;

            text-decoration: none;
        }
    }
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background-color: fade(@color-gray-lightest, 33%);
        &-container {
            width: 100px;
        }
    }
    @media @media-md-down {
        padding-top: 22px;
        &__info {
            margin-left: 9px;
        }
        &__button {
            padding-bottom: 0;
        }
    }
}
</style>
