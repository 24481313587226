<template>
    <div class="common-loader">
        <div class="common-loader__container">
            <ui-loader />
        </div>
    </div>
</template>

<script>
export default {
    name: 'common-loader',
};
</script>

<style scoped lang="less">
@import '~theme';

.common-loader {
    .transition-fade();

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    &__container {
        width: 100px;
    }
}
</style>
