import { render, staticRenderFns } from "./slider-item-text.vue?vue&type=template&id=5e550937&scoped=true&"
import script from "./slider-item-text.vue?vue&type=script&lang=js&"
export * from "./slider-item-text.vue?vue&type=script&lang=js&"
import style0 from "./slider-item-text.vue?vue&type=style&index=0&id=5e550937&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e550937",
  null
  
)

export default component.exports