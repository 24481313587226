<template>
    <div class="page-checkout-step-shipping-store">
        <div class="page-checkout-step-shipping-store__title"
            v-text="'Выберите магазин для самовывоза:'"
        />
        <div class="page-checkout-step-shipping-store__options"
            v-if="availableStores.length > 0"
            v-bind:class="{ _invalid: $v.$invalid && $v.$dirty }"
            >
            <div class="page-checkout-step-shipping-store__options-item"
                v-for="(item, index) in availableStores"
                v-bind:key="index"
                v-on:click="setValue(item)"
                >
                <page-checkout-option
                    v-bind:is-invalid="$v.$invalid && $v.$dirty"
                    v-bind:item="item"
                    v-bind:is-active="checkoutData.store && item.id === checkoutData.store.id"
                />
            </div>
        </div>
        <div class="page-checkout-step-shipping-store__error"
            v-if="$v.$dirty && availableStores.length === 0"
            >
            Не найден подходящий магазин для самовывоза!<br />
            Пожалуйста, свяжитесь с нами для решения этой проблемы.
        </div>
        <div class="page-checkout-step-shipping-store__error"
            v-else-if="$v.$invalid && $v.$dirty"
            >
            Выберите один из вариантов
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-checkout-step-shipping-store',
    inject: [ 'mode', 'pageCheckoutEventBus' ],
    validations() {
        return {
            checkoutData: {
                isValid: value => !!value.store,
            },
        };
    },
    computed: {
        ...mapState('checkout', [ 'isShippingDirty' ]),
        ...mapState('stores', [ 'getStoresResult' ]),
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        availableStores() {
            const result = this.getStoresResult.filter(x => x.area.id === this.checkoutData.city?.id);

            result.forEach(x => {
                x.optionTitle = x.name;
                x.optionDescription = `${x.address}
                ${x.work_time}`;
            });

            return result;
        },
    },
    methods: {
        setValue(newValue) {
            this.$store.commit('checkout/updateData', {
                store: newValue,
            });
            this.pageCheckoutEventBus.$emit('analytics-triggered', {
                step: 1,
                option: `User selected pickup point - ${newValue?.name}`,
            });
        },
    },
    mounted() {
        if (this.checkoutData.store) {
            this.pageCheckoutEventBus.$emit('analytics-triggered', {
                step: 1,
                option: `User selected pickup point - ${this.checkoutData.store.name}`,
            });
        }
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$emit('shipping-validation', { store: true });
    },
    watch: {
        availableStores: {
            immediate: true,
            handler(newVal) {
                if (this.availableStores.length === 0) {
                    this.$store.commit('checkout/updateData', {
                        method: null,
                    });
                }
                if (this.availableStores.length === 1 &&
                    (!this.checkoutData.store || this.checkoutData.store.id === newVal[0].id)) {
                    this.$store.commit('checkout/updateData', {
                        store: newVal[0],
                    });
                    return;
                }
                if (this.checkoutData.store && !newVal.some(x => x.id === this.checkoutData.store.id)) {
                    this.$store.commit('checkout/updateData', {
                        store: null,
                    });
                }
            },
        },
        '$v.$invalid': {
            immediate: true,
            handler(newVal) {
                this.pageCheckoutEventBus.$emit('shipping-validation', { store: !newVal });
            },
        },
        isShippingDirty: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                } else {
                    this.$v.$reset();
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-store {
    &__title {
        margin-top: 4px;
        padding-bottom: 16px;

        text-transform: uppercase;
    }
    &__options {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
        margin-top: 8px;
        margin-bottom: 20px;
        border-top: 1px solid @color-gray-darkest;
        border-left: 1px solid @color-gray-darkest;
        &._invalid {
            border-color: @color-accent-warm;
        }
    }
    &__error {
        margin-top: 8px;

        color: @color-accent-warm;
    }
}
</style>
