<template>
    <div class="common-header-search"
        v-on:submit.prevent="searchSubmit"
        >
        <form class="common-header-search__form">
            <input class="common-header-search__form-input"
                ref="searchInput"
                type="text"
                name="query"
                autofocus
                v-model="form.fields.query.value"
                v-on:input="debounceSearch"
                v-bind:class="{ '_invalid': !form.fields.query.valid }"
            />
            <button class="common-header-search__form-action"
                type="submit"
                >
                <icon name="search-thick" />
            </button>
            <div class="common-header-search__form-action"
                v-on:click="$emit('toggle')">
                <icon name="close" />
            </div>
        </form>
        <div class="common-header-search__form-error"
            v-if="!form.fields.query.valid"
            >
            <span>
                {{ form.fields.query.error }}
            </span>
        </div>
        <div class="common-header-search__form-error"
            v-if="!form.valid && form.commonErrors.length > 0"
            >
            <span
                v-for="(error, i) in form.commonErrors"
                v-bind:key="i"
                >
                {{ error }}<br />
            </span>
        </div>
        <div class="common-header-search__results"
            v-if="results !== null && !loader"
            >
            <div class="common-header-search__results-list"
                v-if="results.length > 0"
                >
                <div class="common-header-search__results-list-item"
                    v-for="(result, i) in results"
                    v-bind:key="i"
                    >
                    <a class="common-header-search__results-list-item-image"
                        v-if="result.isProduct"
                        v-on:click.prevent="e => { resultClickHandler(e, result) }"
                        v-bind:href="result.url"
                        v-bind:style="`background-image: url(${ result.isProduct ? result.images && result.images.length > 0 && result.images[0].small : result.image });`"
                        v-bind:ref="'item' + i"
                    />
                    <div class="common-header-search__results-list-item-info">
                        <div class="common-header-search__results-list-item-info-line"
                            v-text="result.title"
                        />
                        <div class="common-header-search__results-list-item-info-line"
                            v-text="result.description"
                        />
                        <div class="common-header-search__results-list-item-info-line"
                            v-if="result.isProduct"
                            >
                            <span class="common-header-search__results-list-item-info-line-price _old"
                                v-if="result.price.oldText"
                                v-text="result.price.oldText"
                            />
                            <span class="common-header-search__results-list-item-info-line-price _current"
                                v-text="result.price.text"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="common-header-search__results-more"
                v-if="total > displayAmount"
                >
                <a class="common-header-search__results-more-link"
                    v-bind:href="`/shop/catalogue/?q=${form.fields.query.value}`"
                    >
                    Показать все результаты
                </a>
            </div>
            <div class="common-header-search__results-empty"
                v-if="results.length === 0"
                >
                Ничего не найдено :’—(
            </div>
        </div>
        <div class="common-header-search__loader"
            v-if="loader"
            >
            <div class="common-header-search__loader-wrapper">
                <ui-loader />
            </div>
        </div>
        <div class="common-header-search__error"
            v-if="error.length > 0"
            >
        </div>
    </div>
</template>

<script>
import { mainClient } from '~/network';
import { productClick } from '~/utils/ecommerce';
import { ImpressionController } from '~/utils/analytics';

export default {
    name: 'common-header-search',
    props: {
        hrefFacebook: {
            type: String,
            default: '',
        },
        hrefVkontakte: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        loader: false,
        error: '',
        timeout: false,
        form: {
            fields: {
                query: {
                    value: '',
                    name: 'query',
                    type: 'text',
                    valid: true,
                    error: 'Введите запрос',
                    validation: [
                        {
                            name: 'min-length',
                            value: 1,
                        },
                    ],
                },
            },
            valid: true,
            commonErrors: [],
        },
        results: null,
        total: 0,
        displayAmount: 3,
        toid: null,
        impressionControllers: [],
    }),
    methods: {
        searchReset() {
            this.form.fields.query.value = '';
            this.form.fields.query.valid = true;
            this.form.valid = true;
            this.$refs.searchInput.focus();
        },
        getSearchResultColor(attributes) {
            let attribute = attributes.find(x => x.code === 'Цвет оправы');
            return attribute !== undefined ? attribute.value : '';
        },
        async searchSubmit() {
            if (this.form.fields.query.value) {
                this.loader = true;
                this.destroyImpressionControllers();
                const { data, error, status } = await mainClient.shop.search({
                    query: this.form.fields.query.value,
                });
                if (error != null) {
                    this.results = [];
                    console.error(`Search error: ${status}`);
                    console.error(error);
                } else if (data != null) {
                    this.total = data.length;
                    this.results = data.slice(0, this.displayAmount);
                    this.$nextTick(this.activateImpressionControllers);
                } else {
                    this.results = [];
                    console.warn('no response for the request');
                }
                this.loader = false;
            } else {
                if (!this.results || (this.results && this.results.length === 0)) {
                    this.results = null;
                }
            }
        },
        debounceSearch() {
            if (this.toid !== null) {
                clearTimeout(this.toid);
            }
            this.toid = setTimeout(() => {
                this.searchSubmit();
                clearTimeout(this.toid);
                this.toid = null;
            }, 700);
        },
        resultClickHandler(e, result) {
            productClick({
                listId: 'search_suggestions',
                listTitle: 'Поисковые подсказки',
                product: result.ecommerce,
            });
            const url = result.url + `?tgm_list_id=search_suggestions&tgm_list_title=Поисковые подсказки`;
            if (e.metaKey || e.ctrlKey) {
                window.open(url, '_blank');
            } else {
                window.location.href = url;
            }
        },
        activateImpressionControllers() {
            if (!this.results) {
                return;
            }
            this.results.forEach((x, i) => {
                this.impressionControllers.push(new ImpressionController(this.$refs['item' + i][0], {
                    listId: 'search_suggestions',
                    listTitle: 'Поисковые подсказки',
                    product: x.ecommerce,
                }));
            });
        },
        destroyImpressionControllers() {
            this.impressionControllers.forEach(x => {
                x.destroy();
            });
            this.impressionControllers = [];
        },
    },
    mounted() {
        this.$refs.searchInput.focus();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-header-search {
    width: 100%;
    &__loader {
        display: flex;
        width: 100%;
        margin: 50px auto;

        &-wrapper {
            width: 100px;
            margin: auto;
        }
    }
    &__title {
        margin-bottom: 20px;
    }
    &__form {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 41px;
        padding: 0px 9px 0 11px;

        background-color: @color-accent-cold;
        &-input {
            .typography-input();

            flex: 1 1 100%;
            height: 20px;
            padding-left: 8px;
            padding-right: 10px;
            border-left: 1px solid @color-gray-darkest;
        }
        &-action {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 31px;
            height: 40px;

            text-align: center;

            cursor: pointer;
        }
        &-error {
            padding: 10px 40px;

            color: @color-accent-warm;
        }
    }
    &__results {
        &-list {
            padding: 40px 40px 20px;
            &-item {
                display: flex;
                align-items: stretch;
                width: 100%;
                margin-bottom: 20px;
                &-image {
                    flex: 0 0 140px;
                    display: block;
                    height: 80px;
                    margin-right: 20px;

                    background-color: @color-gray-lighter;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                &-info {
                    flex: 1 1 auto;
                    display: flex;
                    flex-direction: column;
                    &-line {
                        &:last-child {
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: auto;
                            margin-bottom: 0;
                        }
                        &-price {
                            flex: 1 1 auto;

                            white-space: nowrap;
                            &._old {
                                margin-right: 4px;

                                text-decoration: line-through;
                            }
                        }
                    }
                }
            }
        }
        &-more {
            padding: 0 40px 40px;
        }
        &-empty {
            padding: 10px 20px;
        }
    }
}
</style>
