<template>
    <svg class="common-diagonal-placeholder" viewBox="0 0 1 1" preserveAspectRatio="none">
        <path d="M0,0 L0,0 1,1" class="common-diagonal-placeholder__line" />
    </svg>
</template>

<script>
export default {
    name: 'common-diagonal-placeholder',
};
</script>

<style scoped lang="less">
@import "~theme";

.common-diagonal-placeholder {
    width: 100%;
    height: 100%;
    &__line {
        stroke: @color-gray-lightest;
        stroke-width: 0.002px;
    }
}
</style>
