<template>
    <div class="plugin-common-spacer">
        <div class="plugin-common-spacer__desktop"
            v-bind:style="desktopStyles"
        />
        <div class="plugin-common-spacer__mobile"
            v-bind:style="mobileStyles"
        />
    </div>
</template>

<script>
export default {
    name: 'plugin-common-spacer',
    props: {
        preset: {
            type: String,
            default: null,
        },
        desktop: {
            type: Number,
            default: null,
        },
        mobile: {
            type: Number,
            default: null,
        },
    },
    data: () => ({
        presets: {
            h2: {
                desktop: '120px',
                mobile: '88px',
            },
            h3: {
                desktop: '56px',
                mobile: '48px',
            },
            paragraph: {
                desktop: '40px',
                mobile: '32px',
            },
        },
    }),
    computed: {
        desktopStyles() {
            if (this.desktop || this.mobile) {
                return { marginTop: this.desktop + 'px' };
            }
            return { marginTop: this.presets[this.preset].desktop };
        },
        mobileStyles() {
            if (this.desktop || this.mobile) {
                return { marginTop: this.mobile + 'px' };
            }
            return { marginTop: this.presets[this.preset].mobile };
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-common-spacer {
    pointer-events: none;
    &__desktop {
        display: block;
    }
    &__mobile {
        display: none;
    }
    &::v-deep + * {
        margin-top: 0 !important;
    }
    @media @media-md-down {
        &__desktop {
            display: none;
        }
        &__mobile {
            display: block;
        }
    }
}
</style>
