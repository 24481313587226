<template>
    <div class="common-header-main-menu">
        <div class="common-header-main-menu__item"
            v-for="(item, index) in items"
            v-bind:key="index"
            >
            <div class="common-header-main-menu__item-title"
                v-bind:is="item.url ? 'a' : 'div'"
                v-bind:class="{
                    _with_link: item.url,
                    _has_submenu: item.items && item.items.length > 0,
                    _standalone: !item.items || item.items.length === 0,
                }"
                v-bind:href="item.url ? item.url : null"
                v-bind:target="item.target ? item.target : null"
                >
                {{ item.name }}
            </div>
            <div class="common-header-main-menu__item-submenu"
                v-if="item.items && item.items.length > 0"
                >
                <common-header-main-menu-submenu
                    v-bind:items="item.items"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'common-header-main-menu',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-header-main-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    &__item {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 12px;

        text-decoration: none;
        text-transform: uppercase;

        cursor: pointer;
        &:hover & {
            &-title._has_submenu {
                background-color: @color-accent-yellow-3;
            }
            &-title._standalone {
                text-decoration: underline;
            }
            &-submenu {
                pointer-events: all;
                opacity: 1;
            }
        }
        &-title {
            padding: 4px 4px 2px;

            text-decoration: none;
            text-transform: uppercase;
        }
        &-submenu {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            z-index: 10;

            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            padding: 80px 256px 104px 144px;

            background: @color-gray-lightest;

            opacity: 0;
            pointer-events: none;

            transition: opacity @duration-fast @easing-default;
            &:after {
                content: '';

                position: absolute;
                top: 100%;
                left: 0;
                right: 0;

                display: block;
                height: 80px;
                overflow: hidden;

                box-shadow: inset 0px 60px 60px -60px @color-accent-cold;
                pointer-events: none;
            }
            &-list {
                position: relative;

                flex: 0 1 auto;

                font-size: 2.8rem;
                line-height: 3.6rem;
                text-transform: uppercase;
                &-item {
                    &:not(:last-child) {
                        margin-bottom: 32px;
                    }
                    &-sublist {
                        position: absolute;
                        top: 0;
                        right: 0;

                        width: 320px;
                        opacity: 0;
                        pointer-events: none;

                        transition: opacity @duration-fast @easing-default;
                    }
                }
            }
        }
    }
    @media @media-lg-down {
        &__item {
            &-submenu {
                padding-right: 144px;
            }
        }
    }
    @media @media-md-down {
        display: none;
    }
}
</style>
