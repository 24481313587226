<template>
    <div class="common-product-list">
        <transition name="fade">
            <div class="common-product-list__loader"
                v-if="isLoading"
                >
                <div class="common-product-list__loader-container">
                    <common-loader />
                </div>
            </div>
        </transition>
        <div class="common-product-list__container"
            v-if="items.length === 0"
            >
            <common-placeholder-card-product v-if="isLargeScreen" />
            <common-placeholder-product-slider v-else />
        </div>
        <div class="common-product-list__container"
            v-if="isLargeScreen"
            >
            <div class="common-product-list__item"
                v-for="(product, i) in items"
                v-bind:key="i"
                >
                <common-card-product
                    v-bind:product="product"
                    v-bind:is-focused="focusedItemId == product.id"
                    v-bind:ecommerce-list-data="{ ...analyticsData, index: i }"
                />
            </div>
        </div>
        <div class="common-product-list__container"
            v-else
            >
            <div class="common-product-list__item"
                v-for="(product, i) in items"
                v-bind:key="i"
                >
                <common-product-slider
                    v-bind:product="product"
                    v-bind:is-focused="focusedItemId == product.id"
                    v-bind:ecommerce-list-data="{ ...analyticsData, index: i }"
                />
            </div>
        </div>
        <div class="common-product-list__bottom"
            ref="bottom"
        />
        <div class="common-product-list__topscroll"
            v-bind:class="{ _visible: isScrollButtonVisible }"
            v-on:click="scrollToTop"
            v-text="`↑ НАВЕРХ`"
        />
    </div>
</template>

<script>
const isScreenLarge = () => window.innerWidth > 575;
let bodyObserver = null;

export default {
    name: 'common-product-list',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        analyticsData: {
            type: Object,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        focusedItemId: {
            type: [ Number, String ],
            default: null,
        },
    },
    data: () => ({
        isLargeScreen: isScreenLarge(),
        bottomObserver: null,
        isBottomReached: false,
        isScrollButtonVisible: false,
        lastScrollPosition: 0,
    }),
    computed: {
        isFemaleFilterActive() {
            return this.$store.getters['catalog/filters/isFemaleFilterActive'];
        },
        isMaleFilterActive() {
            return this.$store.getters['catalog/filters/isMaleFilterActive'];
        },
    },
    methods: {
        handleScroll() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScrollPosition < this.lastScrollPosition) {
                this.isScrollButtonVisible = true;
                if (currentScrollPosition === 0) {
                    this.isScrollButtonVisible = false;
                }
            }
            this.lastScrollPosition = currentScrollPosition;
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
    mounted() {
        this.bottomObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (
                    entry.isIntersecting &&
                    !this.isLoading &&
                    !this.isBottomReached &&
                    this.items.length > 0
                ) {
                    this.isBottomReached = true;
                    this.$emit('bottom-reached');
                }
            });
        });
        window.addEventListener('scroll', this.handleScroll);

        this.bottomObserver.observe(this.$refs.bottom);
    },
    beforeMount() {
        bodyObserver = new ResizeObserver(() => {
            this.isLargeScreen = isScreenLarge();
        });
        bodyObserver.observe(document.body);
        window.removeEventListener('scroll', this.handleScroll);
        this.$store.state.common.isHeaderFixed = true;
    },
    beforeDestroy() {
        bodyObserver.disconnect();
        this.bottomObserver.disconnect();
        this.$store.state.common.isHeaderFixed = false;
    },
    watch: {
        items() {
            this.isBottomReached = false;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-product-list {
    position: relative;

    background-color: @color-gray-lighter-2;
    &__loader {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        background-color: fade(@color-gray-lighter-2, 50%);
        &-container {
            position: sticky;
            top: 0;

            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            max-height: 100vh;
        }
    }
    &__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1px;

        background-color: @color-gray-lightest;
    }
    &__bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;

        height: 1px;

        opacity: 0;

        pointer-events: none;
    }
    &__topscroll {
        position: fixed;
        bottom: 20px;
        left: 50%;
        z-index: 1;

        width: 97px;
        height: 40px;
        padding: 0 13px;
        border-radius: 12px;

        font-size: 14px;
        line-height: 40px;
        text-align: center;

        background-color: @color-gray-lightest;

        opacity: 0;
        cursor: default;
        pointer-events: none;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1),
            0 0 10px rgba(0, 0, 0, 0.3);

        transform: translate(-50%, 20px);

        transition: opacity @duration-normal,
            box-shadow @duration-normal,
            transform @duration-normal;

        &._visible {
            opacity: 1;
            cursor: pointer;
            pointer-events: auto;

            transform: translate(-50%, 0);
        }
        &:hover {
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.1),
            0 0 15px rgba(0, 0, 0, 0.4);
        }
    }
    @media @media-md-down {
        &__container {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media @media-sm-down {
        &__container {
            grid-template-columns: repeat(2, 1fr);
        }
        &__topscroll {
            display: none;
        }
    }
    @media @media-xs-down {
        &__container {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
</style>
