<template>
    <div class="page-item-gallery-mobile"
        v-if="items.length > 0"
        >
        <div class="swiper-container page-item-gallery-mobile__swiper-container"
            ref="slider"
            >
            <div class="swiper-wrapper page-item-gallery-mobile__swiper-wrapper">
                <div class="swiper-slide page-item-gallery-mobile__swiper-slide swiper-lazy"
                    v-for="(item, index) in items"
                    v-bind:key="index"
                    v-bind:data-background="item.image"
                    >
                    <div class="page-item-gallery-mobile__swiper-slide-video"
                        v-if="item.video && activeSlideIndex === index"
                        >
                        <ui-video
                            v-bind:video="item.video"
                            v-bind:poster="item.image"
                            v-bind:aspect-ratio="item.aspectRatio"
                        />
                    </div>
                    <div class="page-item-gallery-mobile__swiper-slide-loader">
                        <ui-loader />
                    </div>
                </div>
            </div>
        </div>
        <div class="page-item-gallery-mobile__swiper-pagination"
            ref="pagination"
        />
    </div>
</template>

<script>
import Swiper from 'swiper';
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-item-gallery-mobile',
    props: {
        currentChild: {
            type: Object,
        },
    },
    data: () => ({
        slider: null,
        key: 0,
        activeSlideIndex: 0,
    }),
    computed: {
        items() {
            let result = [];
            if (this.currentChild.video) {
                const videoSize = utils.common.getVideoResize();
                result.push({
                    image: this.currentChild.videoPreview[videoSize],
                    video: this.currentChild.video[videoSize],
                    aspectRatio: config.media.defaultAspectRatio,
                });
            }
            result = result.concat(this.currentChild.images.map(x => ({ image: x.large })));
            return result;
        },
        isVideoAtTheBeginning() {
            return this.items.length > 0 && !!this.items[0].video;
        },
    },
    methods: {
        initSlider() {
            if (this.items.length === 0) {
                return;
            }
            if (this.slider !== null) {
                this.slider.update();
                return;
            }
            this.slider = new Swiper(this.$refs.slider, {
                slidesPerView: 1,
                speed: 500,
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                },
                mousewheel: {
                    forceToAxis: true,
                },
                loop: this.items.length > 1,
                watchOverflow: true,
                watchSlidesVisibility: true,
                pagination: {
                    el: this.$refs.pagination,
                    type: 'bullets',
                },
                on: {
                    transitionEnd: () => {
                        if (!this.slider) {
                            this.activeSlideIndex = 0;
                            return;
                        }
                        if (this.isVideoAtTheBeginning && this.slider.activeIndex === this.slider.slides.length - 1) {
                            this.slider.params.speed = 0;
                            this.slider.slideTo(1);
                            this.slider.params.speed = 500;
                        }
                        this.activeSlideIndex = this.slider.realIndex;
                    },
                },
            });
        },
        destroySlider() {
            if (this.slider !== null) {
                this.slider.destroy();
            }
        },
    },
    mounted() {
        this.initSlider();
    },
    beforeDestroy() {
        this.destroySlider();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-item-gallery-mobile {
    position: relative;

    width: 100%;
    aspect-ratio: 1500 / 1834;
    &__swiper-container {
        width: 100%;
        height: 100%;
    }
    &__swiper-slide {
        position: relative;
        z-index: 1;

        width: 100%;
        height: 100%;

        background-color: @color-gray-main;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        &-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;

            width: 100px;

            opacity: 0.3;

            transform: translate(-50%, -50%);
            .swiper-lazy-loaded > & {
                display: none;
            }
        }
        &-video {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 0;

            width: 100%;

            pointer-events: none;

            transform: translateY(-50%);
        }
    }
    &__swiper-pagination {
        position: absolute;
        bottom: 21px;
        left: 0;
        z-index: 2;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        pointer-events: none;
        & /deep/ .swiper-pagination-bullet {
            flex: 0 0 auto;
            display: block;
            width: 6px;
            height: 6px;
            margin: 3px;

            background-color: @color-gray-dark;

            opacity: 0.2;
            transition: opacity @duration-fastest @easing-default;
            &-active {
                opacity: 0.85;
            }
        }
    }
}
</style>
