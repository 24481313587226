import { pagination } from '~/network/mainClient/common/transforms/requests';

export const getProducts = ({ pageSize = 24, structure = 'child,standalone', ...data }) => {
    return {
        structure,
        page_size: pageSize,
        ...data,
    };
};

export const getOrders = (data) => {
    return pagination(data);
};

export const getShippingPrice = ({ cdekCode, ...data }) => {
    return { cdek_code: cdekCode, ...data };
};

export const getShippingCityCdekCode = ({ fiasId }) => {
    return { fias_id: fiasId };
};

export default {
    getProducts,
};
