<template>
    <div class="common-instagram">
        <div class="common-instagram__title">
            <a href="https://www.instagram.com/pyeoptics/" target="_blank" class="common-instagram__title-link">наш INSTAGRAM</a>
        </div>
        <div class="common-instagram__gallery">
            <div class="common-instagram__gallery-item">
                <a class="common-instagram__gallery-item-background"
                    href="https://www.instagram.com/p/Btd-vUkHsNo/"
                    style="background-image: url(https://d202gjd61j8fp4.cloudfront.net/custom_media/instagram-6.jpg);"
                    >
                </a>
            </div>
            <div class="common-instagram__gallery-item">
                <a class="common-instagram__gallery-item-background"
                    href="https://www.instagram.com/p/BzvtRyro7me/"
                    style="background-image: url(https://d202gjd61j8fp4.cloudfront.net/custom_media/instagram-5.jpg);"
                    >
                </a>
            </div>
            <div class="common-instagram__gallery-item">
                <a class="common-instagram__gallery-item-background"
                    href="https://www.instagram.com/p/BuomSfhnw-2/"
                    style="background-image: url(https://d202gjd61j8fp4.cloudfront.net/custom_media/instagram-4.jpg);"
                    >
                </a>
            </div>
            <div class="common-instagram__gallery-item">
                <a class="common-instagram__gallery-item-background"
                    href="https://www.instagram.com/p/BwSI9W4nZz_/"
                    style="background-image: url(https://d202gjd61j8fp4.cloudfront.net/custom_media/instagram-3.jpg);"
                    >
                </a>
            </div>
            <div class="common-instagram__gallery-item">
                <a class="common-instagram__gallery-item-background"
                    href="https://www.instagram.com/p/Bl8gSDNHKi7/"
                    style="background-image: url(https://d202gjd61j8fp4.cloudfront.net/custom_media/instagram-2.jpg);"
                    >
                </a>
            </div>
            <div class="common-instagram__gallery-item">
                <a class="common-instagram__gallery-item-background"
                    href="https://www.instagram.com/p/Bkk8gLdnYp9/"
                    style="background-image: url(https://d202gjd61j8fp4.cloudfront.net/custom_media/instagram-1.jpg);"
                    >
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'common-instagram',
};
</script>

<style scoped lang="less">
@import '~theme';

.common-instagram {
    width: 100%;
    &__title {
        padding: 0 20px;
        margin-bottom: 15px;

        text-align: center;
        &-link {
            text-transform: uppercase;
        }
    }
    &__gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        padding: 0 13px;
        &-item {
            width: ~'calc(33.33% - 14px)';
            margin: 0 7px 20px;
            &-background {
                display: block;
                width: 100%;
                padding-top: 100%;

                text-decoration: none;

                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}
</style>
