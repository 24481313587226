<template>
    <div class="plugin-infopage-image"
        v-bind:class="{ _cta: !isMobile && aspectRatioPreset === 'cta'}"
        >
        <ui-video
            v-if="currentVideo"
            v-bind:video="currentVideo"
            v-bind:poster="currentImage"
            v-bind:aspect-ratio="aspectRatio"
            v-bind:is-cover="!isMobile && aspectRatioPreset === 'cta'"
        />
        <ui-image
            v-else
            v-bind:image="currentImage"
            v-bind:aspect-ratio="aspectRatio"
            v-bind:is-cover="!isMobile && aspectRatioPreset === 'cta'"
        />
    </div>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'plugin-infopage-image',
    inject: {
        isMobileRearrangementDisabled: {
            default: false,
        },
        isSliderChild: {
            default: false,
        },
    },
    props: {
        imageDesktop: String,
        imageMobile: {
            type: String,
            default: null,
        },
        videoDesktop: {
            type: String,
            default: null,
        },
        videoMobile: {
            type: String,
            default: null,
        },
        aspectRatioPreset: {
            type: String,
            default: 'auto',
        },
    },
    data: () => ({
        isMobile: false,
        resize: null,
    }),
    computed: {
        aspectRatio() {
            const aspectRatioPresets = {
                cover: {
                    desktop: 0.406,
                    mobile: 1.248,
                },
                standard_w_100: {
                    desktop: 0.5,
                    mobile: 1.28,
                },
                standard_vertical: {
                    desktop: 1.22,
                    mobile: this.isSliderChild ? 1.21 : 1.242,
                },
                cta: {
                    desktop: 0.523,
                    mobile: 0.75,
                },
                auto: null,
            };
            return aspectRatioPresets[this.aspectRatioPreset]?.[this.isMobile ? 'mobile' : 'desktop'] || null;
        },
        currentImage() {
            if (window.location.hostname === 'localhost') {
                return this.resize ? `https://via.placeholder.com/${this.resize}/?text=${this.resize}` : 'https://via.placeholder.com/1440x720/?text=auto_image';
            } else {
                if (this.isMobile) {
                    const image = this.imageMobile || this.imageDesktop;
                    return this.resize ? utils.common.injectResize(image, this.resize) : image;
                } else {
                    return utils.common.injectResize(this.imageDesktop, this.resize);
                }
            }
        },
        currentVideo() {
            if (this.isMobile) {
                if (window.location.hostname === 'localhost') {
                    return this.videoMobile ? 'https://cdn.videvo.net/videvo_files/video/free/2013-08/large_watermarked/hd0983_preview.mp4' : null;
                } else {
                    return this.videoMobile;
                }
            } else {
                if (window.location.hostname === 'localhost') {
                    return this.videoDesktop ? 'https://cdn.videvo.net/videvo_files/video/free/2013-07/large_watermarked/hd0051_preview.mp4' : null;
                } else {
                    return this.videoDesktop;
                }
            }
        },
        style() {
            return null;
        },
    },
    methods: {
        getResize() {
            if (this.aspectRatioPreset === 'auto') {
                this.resize = null;
                return;
            }
            const resizes = {
                cover: 100,
                standard_w_100: 100,
                standard_vertical: {
                    desktop: 50,
                    mobile: this.isMobileRearrangementDisabled ? 50 : 100,
                },
                cta: {
                    desktop: 50,
                    mobile: 100,
                },
            };
            const selectedResize = resizes[this.aspectRatioPreset];
            const result = (selectedResize !== null && typeof selectedResize === 'object') ? selectedResize[this.isMobile ? 'mobile' : 'desktop'] : selectedResize;
            this.resize = utils.common.getResize({ width: result, aspectRatio: this.aspectRatio });
        },
        checkScreenSize() {
            if (window.innerWidth >= 1024) {
                this.isMobile = false;
            } else {
                this.isMobile = true;
            }
            this.getResize();
        },
    },
    beforeMount() {
        window.addEventListener('resize', this.checkScreenSize);
        this.checkScreenSize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-image {
    width: 100%;
    &._cta {
        height: 100%;
    }
    &:not(:first-child) {
        margin-top: 40px;
    }
    .plugin-infopage-container + &,
    .plugin-infopage-container + .plugin-infopage-container > &:first-child,
    ._direction_horizontal & + &,
    .plugin-infopage-title + & {
        margin-top: 0;
    }
    @media @media-md-down {
        &:not(:first-child) {
            margin-top: 32px;
        }
        .plugin-infopage-container + &,
        .plugin-infopage-container + .plugin-infopage-container > &:first-child,
        ._direction_horizontal & + &,
        .plugin-infopage-title + & {
            margin-top: 0;
        }
    }
}
</style>
