<template>
    <div class="sideblock-lens">
        <div class="sideblock-lens__text">
            Во всех оптических оправах по умолчанию установлены пластиковые демолинзы, не предназначенные для постоянного ношения.
        </div>
        <div class="sideblock-lens__spacer" />
        <div class="sideblock-lens__title">
            Оптика
        </div>
        <div class="sideblock-lens__text">Если вам нужны линзы с коррекцией зрения или компьютерные, добавьте понравившуюся оправу в корзину и нажмите на кнопку "Выбрать линзы". Там вы сможете посмотреть какие линзы можно заказать онлайн и их стоимость. Если у вас есть рецепт, вы сможете прикрепить его после выбора линз. Если вы не знаете какие линзы выбрать, смело пишите в чат на сайте или в другие социальные сети. В Москве в магазине в Потаповском переулке, 8/12 и в Петербурге на Рубинштейна, 10 доступна <a href="https://pyeoptics.com/pages/eye-test/" target="_blank">бесплатная проверка зрения</a> по предварительной записи.
            <br />
            <br />
            Если вы хотите носить очки в качестве имиджевых, мы рекомендуем установить стандартные линзы без коррекции зрения. Выбрать линзы без коррекции также можно после добавления оправы в корзину по кнопке "Выбрать линзы».
        </div>
        <div class="sideblock-lens__spacer" />
        <div class="sideblock-lens__title">
            Солнце
        </div>
        <div class="sideblock-lens__text">
            Все солнцезащитные очки уже включают в себя специальные полимерные линзы. Как правило это:
        </div>
        <div class="sideblock-lens__spacer" />
        <div class="sideblock-lens__title">
            CR-39
        </div>
        <div class="sideblock-lens__text">
            Классический полимерный материал с отличными оптическими свойствами, максимальной защитой от ультрафиолета (UV400)
        </div>
        <div class="sideblock-lens__spacer" />
        <div class="sideblock-lens__title">
            Нейлон
        </div>
        <div class="sideblock-lens__text">
            Легкий и прочный оптический материал с зеркальным покрытием и улучшенной защитой от ультрафиолета (UV400)
        </div>
        <div class="sideblock-lens__line" />
        <template v-if="lensType === 'Оптика'">
            <div class="sideblock-lens__title">
                Установка линз для коррекции
            </div>
            <div class="sideblock-lens__table">
                <div class="sideblock-lens__table-item">
                    <div class="sideblock-lens__table-item-column">
                        <div class="sideblock-lens__table-item-column-icon">
                            <icon name="lens-forms" v-bind:fill="false" width="auto" height="100%" />
                        </div>
                        <div class="sideblock-lens__table-item-column-text">
                            Классические, тонкие или ультратонкие линзы на выбор
                        </div>
                    </div>
                    <div class="sideblock-lens__table-item-column">
                        <div class="sideblock-lens__table-item-column-icon">
                            <icon name="lens-antiglare" v-bind:fill="false" width="auto" height="100%" />
                        </div>
                        <div class="sideblock-lens__table-item-column-text">
                            Три варианта покрытий с защитой от царапин, бликов и загрязнений
                        </div>
                    </div>
                </div>
                <div class="sideblock-lens__table-item">
                    <div class="sideblock-lens__table-item-column">
                        <div class="sideblock-lens__table-item-column-icon">
                            <icon name="lens-computer" v-bind:fill="false" width="auto" height="100%" />
                        </div>
                        <div class="sideblock-lens__table-item-column-text">
                            Компьютерные линзы с коррекцией и без
                        </div>
                    </div>
                    <div class="sideblock-lens__table-item-column">
                        <div class="sideblock-lens__table-item-column-icon">
                            <icon name="lens-photochromic" v-bind:fill="false" width="auto" height="100%" />
                        </div>
                        <div class="sideblock-lens__table-item-column-text">
                            Фотохромные, поляризационные или солнцезащитные линзы с коррекцией зрения (<ui-trigger v-on:click="$store.commit('common/chatraOpen')">напишите нам для заказа</ui-trigger>)
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="sideblock-lens__title">
                Нужны солнцезащитные линзы с коррекцией зрения?
            </div>
            <div class="sideblock-lens__table">
                <div class="sideblock-lens__table-item">
                    <div class="sideblock-lens__table-item-column">
                        <div class="sideblock-lens__table-item-column-icon">
                            <icon name="lens-photochromic" v-bind:fill="false" width="auto" height="100%" />
                        </div>
                        <div class="sideblock-lens__table-item-column-text">
                            2 в 1. Фотохромные, поляризационные или окрашенные линзы с коррекцией зрения. Укажите в комментарии к заказу, что вам нужны специальные линзы. Мы свяжемся с вами и подберем лучший вариант, исходя из рекомендаций врача, вашего рецепта на очки и бюджета.
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="sideblock-lens__text">
            Подробнее о линзах, стоимости и сроках установки вы можете узнать <a v-bind:href="lensInfoUrl" target="_blank">здесь</a>.
            <br />
            Если остались вопросы, <ui-trigger v-on:click="$store.commit('common/chatraOpen')">напишите нам в чат</ui-trigger>.
        </div>
    </div>
</template>

<script>
import config from '~/config';

export default {
    name: 'sideblock-lens',
    props: {
        lensType: {
            type: String,
            default: 'Оптика',
        },
    },
    data: () => ({
        lensInfoUrl: config.urls.lensInfo,
    }),
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-lens {
    .mixin-sideblock();

    padding: 20px 0 60px;
    &__table {
        margin-bottom: 40px;
        &-item {
            display: flex;
            width: 100%;
            &-column {
                flex: 0 0 auto;
                padding: 28px 20px 24px;
                width: 50%;
                &:first-child:last-child {
                    width: 100%;
                }
                &:first-child:last-child &-text {
                    max-width: 100%;
                }
                &-icon {
                    height: 4rem;
                    margin-bottom: 10px;
                }
                &-text {
                    max-width: 150px;
                }
            }
        }
    }
    &__title {
        flex: 0 0 auto;
        padding: 0 20px;
        margin-bottom: 8px;

        text-transform: uppercase;
    }
    &__text {
        flex: 0 0 auto;
        padding: 0 20px;
        margin-bottom: 20px;

        &-trigger {
            text-decoration: underline;

            cursor: pointer;
        }
    }
    &__trigger {
        text-decoration: underline;

        cursor: pointer;
    }
    &__spacer {
        margin-top: 40px;
    }
    &__line {
        width: 100%;
        border-top: 1px solid @color-gray-darkest;
        margin-bottom: 24px;
    }
    @media @media-sm-down {
        &__table {
            margin-bottom: 40px;
        }
    }
}
</style>
