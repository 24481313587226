<template>
    <component class="ui-button"
        v-bind:is="component"
        v-bind:type="type ? type : null"
        v-bind:href="href ? href : null"
        v-on:click="clickHandler"
        v-bind:class="{
                _accented: isAccented && !isDisabled,
                _bordered: isBordered,
                _disabled: isDisabled,
                _uppercase: isUppercase,
                _inverted: isInverted,
                _colored: isColored,
                _borderless: isBorderless,
                _gray: variant === 'gray',
                _yellow: variant === 'yellow',
            }"
        >
        <slot />
    </component>
</template>

<script>
export default {
    name: 'ui-button',
    props: {
        isAccented: {
            type: Boolean,
            default: false,
        },
        isBordered: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isUppercase: {
            type: Boolean,
            default: false,
        },
        isInverted: {
            type: Boolean,
            default: false,
        },
        isColored: {
            type: Boolean,
            default: false,
        },
        isBorderless: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'button',
        },
        href: {
            type: String,
            default: null,
        },
    },
    computed: {
        component() {
            if (this.href) {
                return 'a';
            }
            if (this.type === 'submit' || this.type === 'button') {
                return 'button';
            }
            return 'div';
        },
    },
    methods: {
        clickHandler(e) {
            this.$emit('click', e);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding: 5px 20px 3px;

    color: @color-gray-lightest;
    font-weight: normal;
    text-align: center;
    text-decoration: none;

    background: @color-gray-darkest;

    box-shadow: inset 0 0 0 1px rgba(0,0,0,1);
    cursor: pointer;
    user-select: none;
    appearance: none;

    transition: all @duration-fast @easing-default;
    // &:focus {
    //     box-shadow: inset 0 0 0 2px @color-gray-lightest, 0 0 0 1px @color-gray-darkest;
    // }
    // &:active {
    //     box-shadow: inset 0 0 0 4px @color-gray-lightest, 0 0 0 1px @color-gray-darkest;
    // }
    &._bordered {
        color: @color-gray-darkest;

        background: rgba(0,0,0,0);

        box-shadow: inset 0 0 0 1px @color-gray-darkest;
        // &:focus {
        //     box-shadow: inset 0 0 0 3px  @color-gray-darkest;
        //     &._borderless {
        //         box-shadow: 0 0 0 3px rgba(0,0,0,0.33);
        //     }
        // }
        // &:active {
        //     box-shadow: inset 0 0 0 6px  @color-gray-darkest;
        //     &._borderless {
        //         box-shadow: 0 0 0 6px rgba(0,0,0,0.33);
        //     }
        // }
    }
    &._disabled {
        pointer-events: none;

        background: @color-gray-main;

        box-shadow: inset 0 0 0 1px rgba(0,0,0,0);
    }
    &._uppercase {
        padding-top: 7px;
        padding-bottom: 3px;

        text-transform: uppercase;
    }
    &._inverted {
        color: @color-gray-darkest;

        background-color: @color-gray-lightest;
    }
    &._colored {
        color: currentColor;
    }

    &._borderless {
        box-shadow: none;
    }
    &._gray {
        color: @color-gray-darkest;

        background-color: @color-gray-main;

        box-shadow: none;
    }
    &._yellow {
        color: @color-gray-darkest;

        background-color: @color-accent-yellow-2;

        box-shadow: none;
    }
}
</style>
