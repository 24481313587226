export const userBasic = (data) => {
    const result = {
        name: [data.first_name, data.last_name].filter(x => !!x).join(' '),
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
    };
    if (data.phone) {
        result.phone = data.phone;
        result.username = data.phone;
    }
    if (data.username) {
        result.username = data.username;
    }
    return result;
};

export const getLogin = (data) => {
    return {
        ...userBasic(data.result),
        id: data.result.id,
    };
};

export const update = (data) => {
    return userBasic(data);
};

export const info = (data) => {
    return {
        phone: data.phone,
    };
};

export default {
    getLogin,
    update,
};
