import { required, email as emailv, sameAs, minLength, maxLength as maxLengthValidator } from 'vuelidate/lib/validators';

export const defaultRequired = {
    value: {
        required,
    },
};

export const email = {
    value: {
        required,
        emailv,
    },
};

export const phone = {
    value: {
        required,
        minLength: minLength(7),
        // ^([0-9+]{0,})$,
    },
};

export const phoneStrict = {
    value: {
        required,
        isValidStrict: value => /^(\+7)\d{10}$/.test(value.replace(/[^0-9+]/g, '')),
    },
};

export const postcode = {
    value: {
        required,
    },
};

export const name = {
    value: {
        required,
        twoWords: (value) => {
            const splitted = value.split(' ');
            if (splitted.length < 2) {
                return false;
            }
            return splitted[0].length > 0 && splitted[1].length > 0;
        },
    },
};

export const firstName = {
    value: {
        required,
    },
};

export const lastName = {
    value: {
        required,
    },
};

export const password = {
    value: {
        required,
    },
};

export const passwordRepeat = {
    value: {
        required,
        sameAsPassword: sameAs(function() { return this.fields.passwordNew.value; }),
    },
};

export const maxLength = (length) => ({
    value: {
        maxLength: maxLengthValidator(length),
    },
});

export const vouchercode = {
    value: {
        required,
    },
};
