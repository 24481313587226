<template>
    <div class="plugin-home-about-slider-text-item"
        v-bind:style="{ backgroundColor, color: textColor }"
        >
        <div class="plugin-home-about-slider-text-item__observer">
            <common-intersection-observer
                v-on:intersect="triggerImpressionAnalytics"
                v-bind:options="{ threshold: 0.8 }"
                self-destruct
            />
        </div>
        <div class="plugin-home-about-slider-text-item__title"
            v-if="title"
            v-html="title"
        />
        <div class="plugin-home-about-slider-text-item__text"
            v-if="text"
            v-html="text"
            ref="text"
        />
        <common-customizable-link class="plugin-home-about-slider-text-item__link"
            v-if="url && urlText"
            v-on:click.prevent="baseLinkClickHandler"
            v-bind:href="url"
            >
            {{ urlText }}
        </common-customizable-link>
    </div>
</template>

<script>
import ecommerce from '~/utils/ecommerce';

export default {
    name: 'plugin-home-about-slider-text-item',
    props: {
        title: { type: String, default: null },
        text: { type: String, default: null },
        textColor: { type: String, default: '#000000' },
        backgroundColor: { type: String, default: '#dfdfdf' },
        url: { type: String, default: null },
        urlText: { type: String, default: null },
        ecommerceData: { type: Object, default: null },
    },
    methods: {
        triggerImpressionAnalytics() {
            if (this.ecommerceData.promotionId &&
                this.ecommerceData.promotionName &&
                this.ecommerceData.creativeName &&
                this.ecommerceData.creativeSlot
            ) {
                ecommerce.promotionImpression(this.ecommerceData);
            }
        },
        baseLinkClickHandler(e) {
            if (!this.url) {
                return;
            }
            ecommerce.promotionClick(this.ecommerceData);
            if (e.metaKey || e.ctrlKey) {
                window.open(this.url, '_blank');
            } else {
                window.location.href = this.url;
            }
        },
        linkClickHandler(e) {
            e.preventDefault();
            ecommerce.promotionClick(this.ecommerceData);
            if (e.metaKey || e.ctrlKey) {
                window.open(e.target.attributes.href.baseURI, '_blank');
            } else {
                window.location.href = e.target.attributes.href.baseURI;
            }
        },
        bindLinksHandlers() {
            if (!this.$refs.text) {
                return;
            }
            const links = this.$refs.text.getElementsByTagName('a');
            for (let i = 0; i < links.length; i++) {
                links[i].addEventListener('click', this.linkClickHandler);
            }
        },
        unbindLinksHandlers() {
            if (!this.$refs.text) {
                return;
            }
            const links = this.$refs.text.getElementsByTagName('a');
            for (let i = 0; i < links.length; i++) {
                links[i].removeEventListener('click', this.linkClickHandler);
            }
        },
    },
    mounted() {
        this.$nextTick(this.bindLinksHandlers);
    },
    beforeDestroy() {
        this.unbindLinksHandlers();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-about-slider-text-item {
    .typography-body-md();

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    min-height: 462px;
    padding: 24px 20px;
    &__observer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        pointer-events: none;
        opacity: 0;
    }
    &__title {
        flex: 0 0 auto;

        text-transform: uppercase;
        /deep/ p {
            .typography-display-sm();

            margin: 0;
        }
    }
    &__text {
        flex: 0 0 auto;
        /deep/ p {
            .typography-body-md();

            margin: 0;
        }
    }
    &__title + &__text {
        margin-top: 28px;
    }
    &__link {
        .typography-body-md();

        flex: 0 0 auto;
    }
    &__title + &__link,
    &__text + &__link {
        margin-top: 20px;
    }
    @media @media-sm-down {
    }
}
</style>
