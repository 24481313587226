import { mainClient } from '~/network';

const state = {
    getCommunicationListIsLoading: false,
    getCommunicationListError: null,
    getCommunicationListIsReset: false,
};

const getters = {
};

const mutations = {
};

const actions = {
    async getCommunicationList({ state }) {
        if (state.getCommunicationListIsLoading) {
            return;
        }

        state.getCommunicationListIsLoading = true;

        const { data, error } = await mainClient.communication.getList();
        if (error) {
            state.getCommunicationListResult = null;
            state.getCommunicationListError = error;
        } else if (data) {
            state.getCommunicationListResult = data;
            state.getCommunicationListError = null;
        }

        state.getCommunicationListIsLoading = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
