<template>
    <section class="plugin-home-collections">
        <div class="plugin-home-collections__title"
            v-if="title"
            v-text="title"
        />
        <div class="plugin-home-collections__slider swiper-container"
            v-if="collections.length > 0"
            ref="container"
            >
            <div class="plugin-home-collections__slider-wrapper swiper-wrapper"
                ref="wrapper"
                >
                <a class="plugin-home-collections__slider-item swiper-slide"
                    v-for="(item, index) in collections"
                    v-bind:key="index"
                    v-on:click.prevent="e => { linkClickHandler(e, item, index) }"
                    v-bind:href="item.url"
                    >
                    <div class="plugin-home-collections__slider-item-image"
                    v-bind:style="{ backgroundImage: 'url(' + item.resizedImage + ')' }"
                    />
                    <div class="plugin-home-collections__slider-item-title"
                        v-text="item.name"
                    />
                    <div class="plugin-home-collections__slider-item-observer">
                        <common-intersection-observer
                            v-on:intersect="triggerImpressionAnalytics(item, index)"
                            v-bind:options="{ threshold: 0.8 }"
                            self-destruct
                        />
                    </div>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from 'swiper';
import utils from '~/utils';
import ecommerce from '~/utils/ecommerce';

export default {
    name: 'plugin-home-collections',
    props: {
        collectionsData: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
        ecommerceData: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        slider: null,
    }),
    computed: {
        collections() {
            return this.collectionsData.map(x => ({
                ...x,
                resizedImage: x.image ? utils.common.injectResize(x.image, '224xAUTO') : '',
                pageImage: x.image,
            }));
        },
    },
    methods: {
        initSlider() {
            if (this.slider !== null) {
                this.slider.update();
                return;
            }
            this.slider = new Swiper(this.$refs.container, {
                slidesPerView: 'auto',
                spaceBetween: 1,
                speed: 500,
                mousewheel: {
                    forceToAxis: true,
                    invert: true,
                },
                watchOverflow: true,
                watchSlidesVisibility: true,
            });
        },
        destroySlider() {
            if (this.slider !== null) {
                this.slider.destroy();
            }
        },
        triggerImpressionAnalytics(item, index) {
            if (this.ecommerceData.promotionId &&
                this.ecommerceData.creativeName &&
                this.ecommerceData.creativeSlot
            ) {
                const collectionLensIdentity = item.type;
                const collectionLensIdentityTitle = collectionLensIdentity === 'sun'
                    ? 'Солнце'
                    : collectionLensIdentity === 'optics'
                        ? 'Оптика'
                        : 'Все';
                ecommerce.promotionImpression({
                    ...this.ecommerceData,
                    promotionId: this.ecommerceData.promotionId.replace('_PLACEHOLDER', `-${index + 1}_PLACEHOLDER`).replace('PLACEHOLDER', item.slug),
                    promotionName: collectionLensIdentityTitle + ' – ' + item.name,
                    creativeSlot: `${this.ecommerceData.creativeSlot}-${index + 1}`,
                });
            }
        },
        linkClickHandler(e, item, index) {
            const collectionLensIdentity = item.type;
            const collectionLensIdentityTitle = collectionLensIdentity === 'sun'
                ? 'Солнце'
                : collectionLensIdentity === 'optics'
                    ? 'Оптика'
                    : 'Все';
            ecommerce.promotionClick({
                ...this.ecommerceData,
                promotionId: this.ecommerceData.promotionId.replace('_PLACEHOLDER', `-${index + 1}_${collectionLensIdentity}_${item.slug}`),
                promotionName: collectionLensIdentityTitle + ' – ' + item.name,
                creativeSlot: `${this.ecommerceData.creativeSlot}-${index + 1}`,
            });
            if (e.metaKey || e.ctrlKey) {
                window.open(item.url, '_blank');
            } else {
                window.location.href = item.url;
            }
        },
    },
    mounted() {
        this.initSlider();
    },
    beforeDestroy() {
        this.destroySlider();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-collections {
    width: 100%;
    padding: 0 32px;

    font-size: 1.4rem;
    line-height: 2rem;
    &__observer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        pointer-events: none;
        opacity: 0;
    }
    &__title {
        .typography-display-md();

        margin-bottom: 20px;

        text-transform: uppercase;
    }
    &__slider {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 -32px;
        padding-bottom: 24px;
        overflow: auto;
        cursor: grab;
        &:active {
            cursor: grabbing;
        }
        &-item {
            position: relative;

            flex: 0 0 auto;
            display: block;
            width: 128px;

            text-decoration: none;

            cursor: pointer;
            &:active {
                cursor: grabbing;
            }
            &:first-child {
                margin-left: 28px;
            }
            &:last-child {
                margin-right: 28px;
            }
            &-image {
                height: 82px;
                margin-bottom: 8px;

                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                background-color: @color-gray-lighter;

                box-shadow: inset 0 0 0 0 @color-gray-darkest;

                transition: box-shadow @duration-fast ease-in-out;
            }
            &:hover &-image {
                box-shadow: inset 0 0 0 1px @color-gray-darkest;
            }
            &-title {
                text-align: center;
            }
        }
    }
    @media @media-md-down {
        padding: 0 20px;
        &__slider {
            margin: 0 -20px;
            &-item {
                &:first-child {
                    margin-left: 12px;
                }
                &:last-child {
                    margin-right: 12px;
                }
            }
        }
    }
}
</style>
