import { mainClient } from '~/network';

const state = {
    getAreasResult: null,
    getAreasIsLoading: false,
    getAreasError: null,

    currentId: null,
};

const getters = {};

const mutations = {
    setCurrentId(state, id) {
        state.currentId = id;
    },
};

const actions = {
    async getAreas({ state }, payload = {}) {
        if (state.getAreasIsLoading) {
            return;
        }

        state.getAreasIsLoading = true;

        const { data, error, status } = await mainClient.areas.getItems(payload);
        if (error) {
            console.error(error, status);
            state.getAreasError = error;
            state.getAreasResult = null;
        } else if (data) {
            state.getAreasError = null;
            state.getAreasResult = data;
        }

        state.getAreasIsLoading = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
