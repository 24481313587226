<template>
    <div class="plugin-home-about-slider">
        <div class="plugin-home-about-slider__title"
            v-if="title"
            v-html="title"
        />
        <div class="plugin-home-about-slider__container swiper-container"
            ref="container"
            >
            <div class="plugin-home-about-slider__wrapper swiper-wrapper"
                ref="wrapper"
                >
                <slot />
            </div>
        </div>
        <div class="plugin-home-about-slider__pagination"
            ref="pagination"
            >
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';

export default {
    name: 'plugin-home-about-slider',
    props: {
        title: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        slider: null,
    }),
    methods: {
        initSlider() {
            // const wrapper = this.$refs.wrapper;
            // if (!wrapper) {
            //     return;
            // }
            // const itemsAmount = wrapper.children.length;
            // if (itemsAmount.length === 0) {
            //     return;
            // }
            if (this.slider !== null) {
                this.slider.update();
                return;
            }
            this.slider = new Swiper(this.$refs.container, {
                slidesPerView: 'auto',
                spaceBetween: 1,
                speed: 500,
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 3,
                },
                // loop: itemsAmount > 1,
                mousewheel: {
                    forceToAxis: true,
                    invert: true,
                },
                watchOverflow: true,
                watchSlidesVisibility: true,
                pagination: {
                    clickable: true,
                    el: this.$refs.pagination,
                },
            });
        },
        destroySlider() {
            if (this.slider !== null) {
                this.slider.destroy();
            }
        },
    },
    mounted() {
        this.initSlider();
    },
    beforeDestroy() {
        this.destroySlider();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-about-slider {
    &__title {
        max-width: 682px;
        margin-bottom: 24px;

        font-weight: normal;
        text-transform: uppercase;
        /deep/ p {
            .typography-display-sm();
            margin: 0;
        }
    }
    &__container {
        margin: 0 -32px;
        padding: 0 32px;

        cursor: grab;
        &:active {
            cursor: grabbing;
        }
    }
    &__item {
        width: 335px;
        max-width: calc(100vw - 40px);
        height: auto !important;

        transform: translate(0);
    }
    &__pagination {
        display: none;
    }
    @media @media-md-down {
        &__container {
            margin: 0 -20px;
            padding: 0 20px;
        }
        &__pagination {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            margin: 0 5px;
            /deep/ .swiper-pagination-bullet {
                flex: 1 1 auto;
                width: 100%;
                padding: 16px 0;
                margin: 0 3px;
                opacity: 0.1;

                background-color: transparent;

                cursor: pointer;

                transition: opacity @duration-fast @easing-default;
                &.swiper-pagination-bullet-active {
                    opacity: 1;
                }
                &::before {
                    content: '';

                    display: block;
                    height: 2px;
                    border-radius: 2px;

                    background-color: @color-gray-darkest;
                }
            }
        }
    }
}
</style>
