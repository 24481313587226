<template>
    <a v-bind:href="innerHref" v-bind="attributes">
        <slot />
    </a>
</template>

<script>
export default {
    name: 'common-customizable-link',
    props: {
        href: {
            type: String,
        },
    },
    data() {
        return {
            innerHref: this.href,
            attributes: {},
        };
    },
    mounted() {
        if (this.$el && this.href && this.href.startsWith('#custom_url__')) {
            const [ customizationItentificator, params ] = this.href.replace('#custom_url__', '').split('__');
            if (customizationItentificator === 'yclients') {
                this.innerHref = '#';
                this.attributes = {
                    ...this.$attrs,
                    'data-url': params,
                    class: this.$attrs.class ? this.$attrs.class + ' ms_booking' : 'ms_booking',
                };
            }
        }
    },
};
</script>

<style scoped lang="less">
</style>
