<template>
    <section class="page-item">
        <section class="page-item__main"
            v-bind:class="{ _loading: isLoading }"
            >
            <div class="page-item__main-gallery">
                <common-resize-controller
                    v-if="!isLoading && currentChild.images && currentChild.images.length > 0"
                    v-bind:key="currentChild.id"
                    v-bind:components="[
                        {
                            maxWidth: 768,
                            name: 'page-item-gallery-mobile',
                            props: {
                                currentChild,
                            },
                        },
                        {
                            minWidth: 768,
                            name: 'page-item-gallery-desktop',
                            props: {
                                currentChild,
                            },
                        },
                    ]"
                />
            </div>
            <div class="page-item__main-badges"
                v-if="badges.length > 0"
                >
                <div class="page-item__main-badges-item"
                    v-for="(badge, index) in badges"
                    v-bind:key="index"
                    >
                    <icon v-bind:name="'badge-' + badge" height="1em" width="auto" original />
                </div>
            </div>
            <div class="page-item__main-common">
                <page-item-common
                    v-if="!isLoading"
                    v-bind:current-child="currentChild"
                    v-bind:item="item"
                    v-bind:type="type"
                    v-bind:lens-type="lensType ? lensType.value : null"
                    v-on:switch-child="switchChild"
                />
            </div>
            <div class="page-item__main-info"
                v-if="type !== 'certificate'"
                >
                <page-item-info
                    v-if="!isLoading"
                    v-bind:current-child="currentChild"
                    v-bind:item="item"
                    v-bind:type="type"
                    v-bind:lens-type="lensType ? lensType.value : null"
                />
            </div>
            <transition name="fade">
                <div class="page-item__main-loader"
                    v-if="isLoading"
                    >
                    <div class="page-item__main-loader-container">
                        <ui-loader />
                    </div>
                </div>
            </transition>
        </section>
        <transition name="fade">
            <div class="page-item__gallery"
                v-if="galleryIndex !== null && !isLoading"
                >
                <common-resize-controller
                    v-bind:components="[
                        {
                            minWidth: 768,
                            name: 'page-item-gallery-full',
                        },
                    ]"
                />
            </div>
        </transition>
        <section class="page-item__recommended"
            v-if="item !== null && type !== 'certificate' && type !== 'accessory'"
            >
            <page-item-recommended
                v-bind:reference="item"
            />
        </section>
    </section>
</template>

<script>
import { mainClient } from '~/network';
import utils from '~/utils';

const queries = utils.common.parseUrl();
const listIdQuery = queries.find(x => x.title === 'tgm_list_id');
const listTitleQuery = queries.find(x => x.title === 'tgm_list_title');
let givenListData = null;
if (listIdQuery?.value && listTitleQuery?.value) {
    givenListData = { id: listIdQuery.value, title: listTitleQuery.value };
    const newQueries = queries.filter(x => x.title !== 'tgm_list_id' && x.title !== 'tgm_list_title');
    window.history.replaceState({}, '', utils.common.buildUrl({ queries: newQueries }));
}

export default {
    name: 'page-item',
    props: {
        currentChildId: {
            type: Number,
            default: null,
        },
        parentId: {
            type: Number,
            default: null,
        },
    },
    data: () => ({
        currentChild: null,
        item: null,
        sliders: {
            main: null,
            thumbs: null,
        },
        givenListData,
        isFittingRequested: false,
    }),
    provide() {
        return {
            givenListData,
        };
    },
    computed: {
        badges() {
            const result = [];
            if (this.type === 'clipon') {
                result.push('clipon');
            }
            if (this.isSale) {
                result.push('sale');
            }
            return result;
        },
        galleryIndex() {
            return this.$store.state.pages.item.galleryIndex;
        },
        isLoading() {
            return this.item === null ||
                this.currentChild === null ||
                this.$store.getters['fitting/isLoading'];
        },
        type() {
            let result = 'glasses';
            if (this.item) {
                // check if it is 'clipon' product
                if (this.item.clipon_related_product) {
                    return 'clipon';
                }
                if (this.item.isAccessory) {
                    return 'accessory';
                }
                const attributeType = this.item.attributes.find(x => x.code === 'Тип товара');
                if (this.item.isCertificate || (attributeType && attributeType.value === 'Сертификат')) {
                    result = 'certificate';
                }
            }
            return result;
        },
        lensType() {
            return this.currentChild?.lensType || null;
        },
        isSale() {
            return !!this.currentChild?.price.oldText;
        },
    },
    methods: {
        async getItem() {
            const { data, error, status } = await mainClient.shop.getProduct({ id: this.parentId });
            if (error !== null) {
                console.error(status, error);
            } else {
                this.item = data;
                if (this.item.children.length === 0) {
                    this.loadChild(this.item);
                } else {
                    this.loadChild(this.item.children.find(x => x.id === this.currentChildId));
                }
            }
        },
        loadChild(child) {
            this.currentChild = child;
            this.updateUrl();
        },
        switchChild(arrayId) {
            const newChild = this.item.children[arrayId];
            if (this.currentChild === null || (this.currentChild !== null && newChild.id !== this.currentChild.id)) {
                this.loadChild(newChild);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
        updateUrl() {
            if (window.location.pathname !== this.currentChild.url) {
                history.replaceState('', '', this.currentChild.url);
            }
        },
    },
    mounted() {
        this.getItem();
        this.$store.dispatch('fitting/getCart');
    },
    watch: {
        currentChild(newVal) {
            if (newVal) {
                if (this.givenListData) {
                    utils.ecommerce.productDetailsView({
                        ...newVal.ecommerce,
                        listId: this.givenListData.id,
                        listTitle: this.givenListData.title,
                    });
                } else {
                    utils.ecommerce.productDetailsView(newVal.ecommerce);
                }
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-item {
    .typography-body-md();
    .container();

    position: relative;

    flex: 1 0 auto;
    width: 100%;
    &__main {
        position: relative;

        display: grid;
        grid-template-rows: max-content;
        grid-template-columns: calc(100% - 360px) 360px;
        width: 100%;
        &._loading {
            min-height: calc(100vh - @container-header-height);
        }
        &-gallery {
            grid-column: 1;
            grid-row: 1;
            width: 100%;

            background-color: @color-gray-lightest;
        }
        &-badges {
            z-index: 1;

            grid-column: 1;
            grid-row: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            align-self: flex-start;
            justify-self: flex-end;
            padding: 12px 16px;
            &-item {
                padding: 12px 0;

                font-size: 4rem;
            }
        }
        &-common {
            grid-column: 2;
            grid-row: span 2;
            width: 100%;
        }
        &-info {
            grid-row: 2;
            grid-column: 1;
            width: 100%;
        }
        &-loader {
            .transition-fade();

            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding-bottom: 48px;

            background-color: @color-gray-lightest;
            &-container {
                width: 100px;
            }
        }
    }
    &__gallery {
        .transition-fade();

        position: fixed;
        top: 0;
        left: 0;
        z-index: @z-index-page-item-gallery;

        width: 100%;
        height: 100%;
    }
    @media @media-md-down {
        &__main {
            &-badges {
                &-item {
                    font-size: 2.4rem;
                }
            }
        }
    }
    @media @media-sm-down {
        &__main {
            grid-template-columns: 100%;
            &-badges {
                flex-direction: column-reverse;
                align-items: flex-start;
                align-self: flex-end;
                justify-self: flex-start;
            }
            &-common {
                position: static;

                grid-column: 1;
                grid-row: 2;
            }
            &-info {
                grid-row: 3;
            }
        }
    }
}
</style>
