<template>
    <section class="plugin-infopage-container"
        v-bind:class="[
            '_direction_' + direction,
            {
                _to_end: offset === 'end',
                _to_start: offset === 'start',
                _padded: !noHorizontalPaddings,
                _no_rearrangement: isMobileRearrangementDisabled && direction === 'horizontal',
                _centred: justifyContent === 'center',
            }
        ]"
        v-bind:style="{ justifyContent, alignItems, backgroundColor }"
        >
        <slot />
    </section>
</template>

<script>
export default {
    name: 'plugin-infopage-container',
    props: {
        direction: String,
        offset: String,
        justifyContent: String,
        alignItems: String,
        backgroundColor: {
            type: String,
            default: undefined,
        },
        noHorizontalPaddings: {
            type: Boolean,
            default: false,
        },
        isMobileRearrangementDisabled: {
            type: Boolean,
            default: false,
        },
    },
    provide() {
        return {
            isMobileRearrangementDisabled: this.isMobileRearrangementDisabled,
        };
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-container {
    flex: 1 1 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    &._centred {
        text-align: center;
    }
    &._padded {
        padding: 0 32px;
    }
    &._direction_vertical {
        flex-direction: column;
    }
    &._direction_horizontal {
        flex-direction: row;
    }
    &._to_start {
        padding: 0 50% 0 0;
        &._padded {
            padding: 0 calc(50% + 6px) 0 32px;
        }
    }
    &._to_end {
        padding: 0 0 0 50%;
        &._padded {
            padding: 0 32px 0 calc(50% + 6px);
        }
    }
    &._direction_horizontal > *:not(:first-child:last-child) /deep/ {
        flex: 0 0 50%;
    }
    &._direction_horizontal > *:first-child:last-child /deep/ {
        flex: 0 0 100%;
    }
    &:not(._padded)._direction_horizontal > &:not(._to_start):not(._to_end) /deep/ {
        &._padded:first-child:last-child {
            padding: 0 32px;
        }
        &._padded:first-child:not(:last-child) {
            padding: 0 6px 0 32px;
        }
        &._padded:last-child:not(:first-child) {
            padding: 0 32px 0 6px;
        }
    }
    &._padded._direction_horizontal > * /deep/ {
        &:first-child:last-child {
            padding: 0;
        }
        &:nth-child(2n + 1),
        &._to_start {
            padding: 0 6px 0 0;
        }
        &:nth-child(2n),
        &._to_end {
            padding: 0 0 0 6px;
        }
    }
    @media @media-md-down {
        &._padded {
            padding: 0 20px;
        }
        &._direction_horizontal._no_rearrangement {
            & > * /deep/ {
                flex: 0 0 50% !important;
            }
        }
        &._direction_horizontal._no_rearrangement._padded {
            & > * /deep/ {
                &:nth-child(odd) {
                    padding-right: 3px;
                }
                &:nth-child(even) {
                    padding-left: 3px;
                }
            }
        }
        &._direction_horizontal:not(._no_rearrangement) {
            flex-direction: column;
            align-items: stretch !important;
            & > * /deep/ {
                flex: 0 0 auto !important;
            }
        }
        &._to_start {
            padding: 0;
            &._padded {
                padding: 0 20px;
            }
        }
        &._to_end {
            padding: 0;
            &._padded {
                padding: 0 20px;
            }
        }
        &._direction_horizontal > *:not(:first-child:last-child) /deep/,
        &._direction_horizontal > *:first-child:last-child /deep/ {
            flex: 0 0 auto;
        }
        &:not(._padded)._direction_horizontal > &:not(._to_start):not(._to_end) /deep/ {
            &._padded:first-child:last-child {
                padding: 0 20px;
            }
            &._padded:first-child:not(:last-child) {
                padding: 0 20px;
            }
            &._padded:last-child:not(:first-child) {
                padding: 0 20px;
            }
        }
        &._padded._direction_horizontal > * /deep/ {
            &:first-child:last-child {
                padding: 0;
            }
            &:nth-child(2n + 1),
            &._to_start {
                padding: 0;
            }
            &:nth-child(2n),
            &._to_end {
                padding: 0;
            }
        }
    }
}
</style>
