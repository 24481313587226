<template>
    <div class="ui-video"
        v-bind:class="{ _absoluted: aspectRatio }"
        v-bind:style="aspectRatioStyles"
        ref="root"
        >
        <div class="ui-video__poster"
            v-bind:class="{ _hidden: isVideoVisible }"
            >
            <ui-image
                v-bind:image="computedPoster"
                v-bind:aspect-ratio="aspectRatio"
            />
        </div>
        <video class="ui-video__video"
            v-bind:class="{ _visible: isVideoVisible }"
            v-bind:style="videoWidth && videoHeight ? { width: videoWidth, height: videoHeight } : null"
            v-bind:loop="isLooped"
            v-bind:src="video"
            v-bind:autoplay="isAutoplaying"
            v-on:loadedmetadata="setMetadata"
            v-on:play="playHandler"
            v-on:suspend="suspendHandler"
            loading="lazy"
            ref="video"
            type="video/mp4"
            playsinline
            muted
        />
    </div>
</template>

<script>
export default {
    name: 'ui-video',
    props: {
        video: String,
        poster: String,
        isMuted: {
            type: Boolean,
            default: true,
        },
        isAutoplaying: {
            type: Boolean,
            default: true,
        },
        isLooped: {
            type: Boolean,
            default: true,
        },
        aspectRatio: {
            type: Number,
            default: null,
        },
    },
    data: () => ({
        isVideoPlaying: false,
        isLowPowerMode: false,
        isMetadataLoaded: false,
        canvasWidth: 1,
        canvasHeight: 1,
        videoOriginalWidth: 1,
        videoOriginalHeight: 1,
        videoWidth: null,
        videoHeight: null,
    }),
    computed: {
        computedPoster() {
            if (window.location.hostname === 'localhost') {
                return 'https://via.placeholder.com/1440x585/?text=video';
            }
            return this.poster;
        },
        aspectRatioStyles() {
            if (!this.aspectRatio) {
                return null;
            }
            return {
                paddingTop: `${this.aspectRatio * 100}%`,
                minHeight: 0,
            };
        },
        isVideoVisible() {
            return !this.isLowPowerMode && this.isVideoPlaying;
        },
    },
    methods: {
        suspendHandler() {
            if (this.$refs.video && this.$refs.video.readyState !== 4) {
                this.isLowPowerMode = true;
            }
        },
        playVideoManually() {
            if (!this.video || this.isVideoPlaying || !this.isAutoplaying) {
                return;
            }
            const video = this.$refs.video;
            if (this.isVideoPlaying || (video && !video.paused)) {
                return;
            }
            if (!video) {
                setTimeout(this.playVideoManually, 500);
                return;
            }
            video.play()
                .then(() => {
                    this.playHandler();
                    console.info(`playback success for ${this.video}`);
                })
                .catch(() => {
                    this.isVideoPlaying = false;
                    this.bindPlayHack();
                    console.error(`playback failed for ${this.video}`);
                });
        },
        playHandler() {
            this.isLowPowerMode = false;
            this.isVideoPlaying = true;
            this.unbindPlayHack();
        },
        getCanvasSize() {
            const root = this.$refs.root;
            if (!root) {
                setTimeout(this.getCanvasSize, 500);
                return;
            }
            const { width, height } = root.getBoundingClientRect();
            this.canvasWidth = width;
            this.canvasHeight = height;
            if (this.isMetadataLoaded) {
                this.syncSizes();
            }
        },
        syncSizes() {
            if (!this.aspectRatio) {
                return;
            }
            const verticalRatio = this.canvasHeight / this.videoOriginalHeight;
            const horizontalRatio = this.canvasWidth / this.videoOriginalWidth;
            if (verticalRatio < horizontalRatio) {
                this.videoWidth = this.canvasWidth + 'px';
                this.videoHeight = this.videoOriginalHeight * horizontalRatio + 'px';
            } else {
                this.videoWidth = this.videoOriginalWidth * verticalRatio + 'px';
                this.videoHeight = this.canvasHeight + 'px';
            }
        },
        setMetadata(data) {
            this.videoOriginalWidth = data.target.videoWidth;
            this.videoOriginalHeight = data.target.videoHeight;
            this.isMetadataLoaded = true;
            this.syncSizes();
        },
        bindPlayHack() {
            document.body.addEventListener('scroll', this.playVideoManually);
            document.body.addEventListener('click', this.playVideoManually);
            document.body.addEventListener('touchstart', this.playVideoManually);
        },
        unbindPlayHack() {
            document.body.removeEventListener('scroll', this.playVideoManually);
            document.body.removeEventListener('click', this.playVideoManually);
            document.body.removeEventListener('touchstart', this.playVideoManually);
        },
    },
    mounted() {
        this.bindPlayHack();
        if (this.aspectRatio) {
            this.getCanvasSize();
            window.addEventListener('resize', this.getCanvasSize);
        }
    },
    beforeDestroy() {
        this.unbindPlayHack();
        if (this.aspectRatio) {
            window.removeEventListener('resize', this.getCanvasSize);
        }
    },
    watch: {
        video(newVal) {
            this.isVideoPlaying = false;
            this.isMetadataLoaded = false;
            const video = this.$refs.video;
            if (video) {
                video.src = newVal;
            }
        },
        isLowPowerMode(newVal) {
            if (!newVal) {
                this.playVideoManually();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-video {
    position: relative;
    z-index: 0;
    min-height: 100%;
    min-width: 100%;

    background: transparent;
    &._absoluted {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    &._absoluted &__poster {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        width: 100%;
        height: 100%;
    }
    &._absoluted &__video {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;

        width: 0;
        height: 0;

        transform: translate(-50%, -50%);

        will-change: width, height;
    }
    &__poster {
        display: block;
        width: 100%;
        object-fit: cover;
        object-position: center;

        background: transparent;
        &._hidden {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
    &__video {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        display: block;
        width: 100%;

        opacity: 0;

        transition: opacity @duration-fastest @easing-default;
        &._visible {
            position: static;
            z-index: 2;

            opacity: 1;
        }
    }
}
</style>
