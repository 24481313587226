<template>
    <div class="common-display-controller"
        v-bind:class="{ _activated: isVisible }"
        >
        <div class="common-display-controller__observer"
            v-if="!isVisible"
            >
            <common-intersection-observer
                v-on:intersect="init"
            />
        </div>
        <transition name="fade">
            <div class="common-display-controller__content"
                v-if="isVisible"
                >
                <slot />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'common-display-controller',
    props: {
        noTransition: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isVisible: false,
    }),
    methods: {
        init() {
            this.isVisible = true;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-display-controller {
    position: relative;

    min-height: 33vh;
    &._activated {
        min-height: unset;
    }
    &__observer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        pointer-events: none;
        opacity: 0;
    }
    &__content {
        .transition-fade();
    }
}
</style>
