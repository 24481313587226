import $client from './_client';
import * as areas from './areas/areas';
import * as certificates from './certificates/certificates';
import * as collections from './collections/collections';
import * as common from './common/common';
import * as communication from './communication/communication';
import * as dadata from './dadata/dadata';
import * as shop from './shop/shop';
import * as sms from './sms';
import * as user from './user/user';

export default {
    $client,
    areas,
    certificates,
    collections,
    common,
    communication,
    dadata,
    shop,
    sms,
    user,
};
