import { mainClient } from '~/network';
import config from '~/config';

const requestDelay = 60000; // ms
const localStorageKey = config.localStorage.prefix + config.localStorage.affixes.sms.lastRequestDate;
const storedRequestCodeDate = window.localStorage.getItem(localStorageKey);
const storedRequestCodeDateParsed = storedRequestCodeDate ? parseInt(storedRequestCodeDate, 10) : null;
const requestCodeIsAvailableInitial = storedRequestCodeDateParsed ? Date.now() - storedRequestCodeDateParsed >= requestDelay : true;
let timer = null;

const checkTime = (state) => {
    let newTimerValue = state.requestCodeDate + state.requestCodeDelay - Date.now();
    if (newTimerValue <= 0) {
        newTimerValue = 0;
        state.requestCodeIsAvailable = true;
        clearInterval(timer);
        timer = null;
    }
    state.requestCodeTimer = newTimerValue;
};

const state = {
    requestCodeResult: null,
    requestCodeIsLoading: false,
    requestCodeError: null,
    requestCodeIsAvailable: requestCodeIsAvailableInitial,
    requestCodeDate: storedRequestCodeDateParsed,
    requestCodeTimer: 0,
    requestCodeDelay: requestDelay,

    checkCodeResult: null,
    checkCodeIsLoading: false,
    checkCodeError: null,
};

if (!requestCodeIsAvailableInitial) {
    checkTime(state);
    timer = setInterval(() => {
        checkTime(state);
    }, 1000);
}

const getters = {
};

const actions = {
    async requestCode({ state }, { onComplete = null, ...payload }) {
        if (state.requestCodeIsLoading || !state.requestCodeIsAvailable) {
            return;
        }

        state.requestCodeIsLoading = true;

        const { data, error, status } = await mainClient.sms.requestCode(payload);

        if (error) {
            console.error(error, status);
            state.requestCodeError = error;
        } else if (data) {
            state.requestCodeError = null;
            state.requestCodeResult = data;
            state.requestCodeIsAvailable = false;
            state.requestCodeDate = Date.now();
            window.localStorage.setItem(localStorageKey, Date.now());

            checkTime(state);
            timer = setInterval(() => {
                checkTime(state);
            }, 1000);
        }
        if (onComplete) {
            onComplete();
        }

        state.requestCodeIsLoading = false;
    },
    async checkCode({ state }, payload) {
        if (state.checkCodeIsLoading) {
            return;
        }

        state.checkCodeIsLoading = true;

        const { data, error, status } = await mainClient.sms.checkCode(payload);

        if (error) {
            console.error(error, status);
            state.checkCodeError = error;
            state.checkCodeResult = null;
        } else if (data) {
            state.checkCodeError = null;
            state.checkCodeResult = data || {};
        }

        state.checkCodeIsLoading = false;
    },
};

const mutations = {
    resetErrors(state) {
        state.requestCodeError = null;
        state.checkCodeError = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
