import client from '../_client';
import config from '~/config';
import utils from '~/utils';
import transforms from './transforms';

export const checkEmail = (params = {}) => {
    return client.request({
        url: config.api.user.checkEmail,
        method: 'GET',
        params,
    });
};

export const register = (data = {}) => {
    return client.request({
        headers: {
            'X-CSRFToken': utils.common.getCSRFToken(),
        },
        url: config.api.user.register,
        method: 'POST',
        data,
        transformResponse: transforms.responses.getLogin,
    });
};

export const auth = (data = {}) => {
    return client.request({
        url: config.api.user.auth,
        method: 'POST',
        data,
        transformResponse: transforms.responses.getLogin,
    });
};

export const logout = (data = {}) => {
    return client.request({
        url: config.api.user.logout,
        method: 'POST',
        data,
    });
};

export const checkLogin = (params = {}) => {
    return client.request({
        url: config.api.user.auth,
        method: 'GET',
        params,
        transformResponse: transforms.responses.getLogin,
    });
};

export const update = (data = {}) => {
    return client.request({
        url: config.api.user.update,
        method: 'PATCH',
        data,
        transformRequest: transforms.requests.update,
        transformResponse: transforms.responses.update,
    });
};

export const infoUpdate = (data = {}) => {
    return client.request({
        url: config.api.user.info.update,
        method: 'PATCH',
        data,
        transformRequest: transforms.requests.info,
        transformResponse: transforms.responses.info,
    });
};

export const changePassword = (data = {}) => {
    return client.request({
        url: config.api.user.password,
        method: 'POST',
        data,
        transformRequest: transforms.requests.changePassword,
    });
};

export const resetPassword = (data = {}) => {
    return client.request({
        url: config.api.user.resetPassword,
        method: 'POST',
        headers: {
            'X-CSRFToken': utils.common.getCSRFToken(),
        },
        data,
    });
};

export const setPassword = (data = {}) => {
    return client.request({
        url: config.api.user.setPassword,
        method: 'POST',
        headers: {
            'X-CSRFToken': utils.common.getCSRFToken(),
        },
        data,
    });
};
