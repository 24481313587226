<template>
    <div>plugin-home-categories</div>
</template>

<script>
export default {
    name: 'plugin-home-categories',
};
</script>

<style scoped lang="less">
@import "~theme";

.plugin-home-categories {
    .container();

    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: 100vh;
    @media @media-md-down {
        height: auto;
    }
}
</style>
