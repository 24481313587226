<template>
    <a class="sideblock-fitting-slot"
        v-bind:href="catalogHref"
        >
        <div class="sideblock-fitting-slot__bg">
            <common-diagonal-placeholder />
        </div>
        <div class="sideblock-fitting-slot__plus">
            <icon name="plus" v-bind:fill="false" />
        </div>
        <div class="sideblock-fitting-slot__plus _hover">
            <icon name="plus" v-bind:fill="false" />
        </div>
        <div class="sideblock-fitting-slot__text">
            <div class="sideblock-fitting-slot__text-content">
                добавить
            </div>
        </div>
    </a>
</template>

<script>
import config from '~/config';

export default {
    name: 'sideblock-fitting-slot',
    props: {
        isLastItemOptical: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        catalogHref() {
            if (this.isLastItemOptical) {
                return config.urls.catalogOptical;
            }
            return config.urls.catalog;
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-fitting-slot {
    position: relative;

    display: block;
    width: 100%;
    height: 100%;

    text-decoration: none;
    &:hover & {
        &__plus {
            &._hover {
                opacity: 1;
            }
        }
        &__text {
            &-content {
                opacity: 1;
            }
        }
    }
    &__bg {
        width: 100%;
        height: 100%;
    }
    &__plus {
        position: absolute;
        bottom: 20px;
        left: 20px;

        color: @color-gray-lightest;
        font-size: 3.6rem;
        &._hover {
            color: @color-gray-darkest;

            opacity: 0;

            transition: opacity @duration-fast @easing-default;
        }
    }
    &__text {
        position: absolute;
        bottom: 27px;
        left: 56px;

        overflow: hidden;

        text-transform: uppercase;

        &-content {
            padding-left: 11px;

            opacity: 0;

            transition: opacity @duration-fast @easing-default;
        }
    }
    @media @media-md-down {
        &__plus {
            left: 12px;
            bottom: 12px;

            font-size: 2.8rem;
        }
        &__text {
            left: 40px;
            bottom: 15px;
            &-content {
                padding-left: 10px;
            }
        }
    }
}
</style>
