<template>
    <div>
        app-root
    </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import utils from '~/utils';

const frontendDebugFlag = utils.common.parseUrl().find(x => x.title === 'debug');
const sentryUrl = document.getElementById('app')?.getAttribute('sentry-url');

if (sentryUrl) {
    Sentry.init({
        Vue,
        dsn: sentryUrl,
        integrations: [ new BrowserTracing() ],
        tracesSampleRate: 1.0,
    });
}

export default {
    name: 'app-root',
    props: {
        areaId: {
            type: Number,
            default: null,
        },
        staticPathGetter: {
            type: String,
        },
        socialAuths: {
            type: Array,
        },
        menu: {
            type: Array,
            default: () => [],
        },
    },
    provide() {
        const socialAuths = this.socialAuths.filter(x => !!x.url);
        return {
            rootEventBus: new Vue(),
            staticPathRoot: this.staticPathGetter.replace('/static-path-getter', ''),
            socialAuths: socialAuths.length > 0 ? socialAuths : null,
            isDebug: frontendDebugFlag || false,
        };
    },
    inject: [ 'isReinitialized' ],
    data: () => ({
        scrollToSave: 0,
    }),
    computed: {
        isBodyFixed() {
            return this.$store.getters['common/isBodyFixed'];
        },
    },
    mounted() {
        this.$store.dispatch('user/getUser');
        this.$store.dispatch('callbacks/execute');
        this.$store.dispatch('common/checkQueryTriggers');
        this.$store.dispatch('common/checkHashTriggers');
        this.$store.commit('areas/setCurrentId', this.areaId);
        window.addEventListener('hashchange', () => {
            this.$store.dispatch('common/checkHashTriggers');
        });
    },
    beforeMount() {
        this.$store.commit('menu/generateMenu', this.menu);
    },
    watch: {
        isBodyFixed(newValue) {
            const body = document.body;
            const fixedClass = '_fixed';
            if (newValue) {
                this.scrollToSave = window.pageYOffset;
                body.classList.add(fixedClass);
                body.style.top = `${-this.scrollToSave}px`;
            } else {
                body.classList.remove(fixedClass);
                window.scrollTo(0, this.scrollToSave);
                body.style.top = 0;
                this.scrollToSave = 0;
            }
        },
    },
};
</script>

<style lang="less">
@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/swiper/css/swiper.min.css';
@import '~theme';

*,
*:before,
*:after {
    box-sizing: border-box;
}
html {
    height: 100%;

    color: @color-gray-darkest;
    font-family: 'Steinbeck', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 10px;
    line-height: 1;

    &.cms-toolbar-expanded {
        padding-top: @cms-toolbar-height !important;
        margin-top: 0 !important;
    }
}
body {
    position: static;

    width: 100%;
    height: 100%;
    margin: 0;
    overflow-y: scroll;
    scroll-behavior: smooth;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &._fixed {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .cms-toolbar-expanded & {
            padding-top: @cms-toolbar-height;
        }
    }
    &._chatra_fix #chatra.chatra--side-bottom {
        margin-bottom: 60px !important;
    }
}
.app-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    width: 100%;
    min-height: 100%;
}
a {
    cursor: pointer;
    color: inherit;
}
img {
    display: block;
    max-width: 100%;
}
input, textarea {
    display: block;
    max-width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
    outline: none;
    box-shadow: none;
    appearance: none;
    &::placeholder {
        color: inherit;
    }
}
button {
    display: block;
    max-width: 100%;
    border: none;
    padding: 0;
    margin: 0;

    color: inherit;

    background: transparent;

    outline: none;
    box-shadow: none;
    appearance: none;
    cursor: pointer;
}
.svg-icon {
    display: block;
    width: 16px;
    height: 16px;

    color: inherit;

    fill: none;
    stroke: currentColor;
}
.svg-fill {
    fill: currentColor;
    stroke: none;
}
.grecaptcha-badge {
    right: -1000px !important;
}
</style>
