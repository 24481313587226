import { mainClient } from '~/network';

const state = {
    getStoresResult: null,
    getStoresIsLoading: false,
    getStoresError: null,
};

const getters = {};

const actions = {
    async getStores({ state }) {
        if (state.getStoresIsLoading) {
            return;
        }
        state.getStoresIsLoading = true;

        const { data, error, status } = await mainClient.shop.getStores();
        if (error !== null) {
            console.error(error, status);
            state.getStoresError = error;
            state.getStoresResult = null;
        } else {
            state.getStoresError = null;
            state.getStoresResult = data.results;
        }

        state.getStoresIsLoading = false;
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
