<template>
    <section class="plugin-infopage-text"
        v-html="text"
    />
</template>

<script>
export default {
    name: 'plugin-infopage-text',
    props: {
        text: String,
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-text {
    max-width: ~'min(100%, 50vw)';
    &.backgrounded,
    & /deep/ .backgrounded,
    & /deep/ a:hover {
        background-color: @color-accent-yellow;
        box-shadow: 0 0 0 4px @color-accent-yellow;
        box-decoration-break: clone;
    }
    & /deep/ a:hover .backgrounded,
    & /deep/ .backgrounded a:hover,
    & /deep/ a.backgrounded:hover {
        position: relative;
        z-index: 1;

        background-color: @color-accent-yellow-3 !important;
        box-shadow: 0 0 0 4px @color-accent-yellow-3 !important;
    }
    & /deep/ p {
        margin: 0;
        &:not(.uppercase) {
            .typography-body-lg();
        }
        &.uppercase {
            .typography-display-sm();

            text-transform: uppercase;
        }
    }
    & /deep/ p + p {
        margin-top: 24px;
    }
    & /deep/ ul {
        margin: 0;
        padding: 0;

        list-style: none;
        li {
            .typography-body-lg();

            margin: 0;
            padding: 0 0 0 20px;
            &::before {
                content: '•';

                display: inline-block;
                width: 12px;
                margin-left: -20px;
                margin-right: 8px;

                text-align: center;
            }
        }
        li ~ li {
            margin-top: 16px;
        }
    }
    &:not(:first-child) {
        margin-top: 40px;
    }
    .plugin-infopage-slider .plugin-infopage-title + & {
        margin-top: 8px;
    }
    .plugin-infopage-container + &,
    .plugin-infopage-container + .plugin-infopage-container > &:first-child,
    .plugin-infopage-title + & {
        margin-top: 0;
    }
    @media @media-md-down {
        max-width: 100%;
        &:not(:first-child) {
            margin-top: 32px;
        }
        .plugin-infopage-slider .plugin-infopage-title + & {
            margin-top: 4px;
        }
        .plugin-infopage-container + .plugin-infopage-container > &:first-child,
        .plugin-infopage-container + &,
        .plugin-infopage-title + & {
            margin-top: 0;
        }
        .plugin-infopage-container._no_rearrangement .plugin-infopage-title + & {
            margin-top: 4px;
        }
        .plugin-infopage-container._no_rearrangement & /deep/ p {
            .typography-body-md();

            margin-top: 0;
            padding-right: 4px;
        }
    }
}
</style>
