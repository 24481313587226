<template>
    <footer class="common-footer">
        <div class="common-footer__container">
            <div class="common-footer__left">
                <div class="common-footer__left-menu-main">
                    <div class="common-footer__left-menu-main-item"
                        v-for="(item, index) in menuMain"
                        v-bind:key="index"
                        >
                        <a class="common-footer__left-menu-main-item-link"
                            v-bind:href="item.url"
                            v-bind:target="item.target"
                            v-text="item.name"
                        />
                    </div>
                </div>
                <div class="common-footer__left-menu-left">
                    <div class="common-footer__left-menu-left-item"
                        v-for="(item, index) in menuLeft"
                        v-bind:key="index"
                        >
                        <a class="common-footer__left-menu-left-item-link"
                            v-bind:href="item.url"
                            v-bind:target="item.target"
                            v-text="item.name"
                        />
                    </div>
                </div>
                <div class="common-footer__left-menu-right">
                    <div class="common-footer__left-menu-right-item">
                        <a class="common-footer__left-menu-right-item-link"
                            v-bind:href="'tel:' + contacts.phone.value"
                            v-text="contacts.phone.valueFormatted"
                        />
                    </div>
                    <div class="common-footer__left-menu-right-item"
                        v-text="contacts.phone.worktime"
                        >
                    </div>
                    <div class="common-footer__left-menu-right-item"
                        v-for="(item, index) in menuRight"
                        v-bind:key="index"
                        >
                        <a class="common-footer__left-menu-right-item-link"
                            v-bind:href="item.url"
                            v-bind:target="item.target"
                            v-text="item.name"
                        />
                    </div>
                </div>
            </div>
            <div class="common-footer__right">
                <common-footer-subscribe>
                </common-footer-subscribe>
                <div class="common-footer__right-socials">
                    <div class="common-footer__right-socials-item">
                        <div class="common-footer__right-socials-item-title">
                            Следить за
                            <br />
                            новостями
                        </div>
                        <div class="common-footer__right-socials-item-links">
                            <a class="common-footer__right-socials-item-links-item"
                                v-for="(item, index) in contacts.socials"
                                v-bind:key="index"
                                v-bind:href="item.href"
                                target="_blank"
                                >
                                <icon v-bind:name="item.icon" />
                            </a>
                        </div>
                    </div>
                    <div class="common-footer__right-socials-item">
                        <div class="common-footer__right-socials-item-title _app">
                            Виртуальная
                            <br />
                            примерка p.y.e
                        </div>
                        <div class="common-footer__right-socials-item-links">
                            <a class="common-footer__right-socials-item-links-item"
                                v-bind:href="contacts.app.apple.href"
                                target="_blank"
                                >
                                <icon v-bind:name="contacts.app.apple.icon" original height="40px" width="120px" />
                            </a>
                        </div>
                    </div>
                    <div class="common-footer__right-socials-item">
                        <div class="common-footer__right-socials-item-title">
                            поддержка
                            <br />
                            покупателей
                        </div>
                        <div class="common-footer__right-socials-item-links">
                            <a class="common-footer__right-socials-item-links-item"
                                v-for="(item, index) in contacts.messengers"
                                v-bind:key="index"
                                v-bind:href="item.href"
                                target="_blank"
                                >
                                <icon v-bind:name="item.icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="common-footer__botline">
                <div class="common-footer__botline-info">
                    <div class="common-footer__botline-info-item">
                        <a class="common-footer__botline-info-item-link"
                            v-bind:href="urls.oferta"
                            target="_blank"
                            >
                            Оферта
                        </a>
                    </div>
                    <div class="common-footer__botline-info-item">
                        <a class="common-footer__botline-info-item-link"
                            v-bind:href="urls.policy"
                            target="_blank"
                            >
                            Политика конфиденциальности
                        </a>
                    </div>
                </div>
                <div class="common-footer__botline-development">
                    <div class="common-footer__botline-development-item">
                        <span class="common-footer__botline-development-item-title">Разработка сайта: </span><a href="https://www.initflow.com/" target="_blank" class="common-footer__botline-development-item-value">Initflow</a>
                    </div>
                    <div class="common-footer__botline-development-item">
                        <span class="common-footer__botline-development-item-title">Дизайн: </span><a href="https://facultative.works/" target="_blank" class="common-footer__botline-development-item-value">Facultative Works</a> и <a href="https://breadhead.ru/" target="_blank" class="common-footer__botline-development-item-value">Breadhead</a>
                    </div>
                </div>
                <div class="common-footer__botline-copyright">
                    © {{ new Date().getFullYear() }} pyeoptics
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    name: 'common-footer',
    computed: {
        ...mapState({
            menuMain: state => state.menu.footer.main?.items || [],
            menuLeft: state => state.menu.footer.secondary?.items || [],
            menuRight: state => state.menu.footer.tertiary?.items || [],
        }),
        contacts() {
            return this.$store.state.site.contacts;
        },
        urls() {
            return config.urls;
        },
        socials() {
            return [
                {
                    title: 'Следить за новостями',
                    items: this.contacts.socials,
                },
                {
                    title: 'Виртуальная примерка p.y.e',
                    items: [
                        { icon: 'appstore', href: 'http://apps.apple.com/ru/app/pye/id1538573783' },
                    ],
                },
                {
                    title: 'Поддержка покупателей',
                    items: this.contacts.messengers,
                },
            ];
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-footer {
    .typography-body-md();

    background-color: @color-gray-light;
    &__container {
        .container();

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 15px;

    }
    &__left {
        .container-paddings();

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 50%;

        font-size: 1.4rem;
        line-height: 2rem;

        &-menu-main,
        &-menu-left,
        &-menu-right {
            flex: 1 1 auto;
            width: 33%;
            max-width: 180px;
            padding-right: 20px;
            &-item {
                padding: 5px 0;
                &:not(:last-child) {
                    margin-bottom: 6px;
                }
                &-link {
                    display: inline-block;

                    text-decoration: none;

                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        &-menu-main {
            text-transform: uppercase;
        }
    }
    &__right {
        .container-paddings();

        width: 50%;
        &-socials {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            gap: 40px;
            &-item {
                &-title {
                    .typography-input();

                    margin-bottom: 8px;

                    text-transform: uppercase;
                    &._app {
                        margin-bottom: 12px;
                    }
                }
                &-links {
                    display: flex;
                    align-items: stretch;
                    justify-content: flex-start;
                    gap: 12px;

                    text-decoration: none;
                    font-size: 4.8rem;
                }
            }
        }
    }
    &__botline {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-top: 75px;
        &-info {
            .container-paddings();

            flex: 0 0 auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 50%;
            &-item {
                &:not(:last-child) {
                    margin-right: 25px;
                }
                &-link {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        &-development {
            .container-paddings();

            flex: 1 1 auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            &-item {
                flex: 0 0 auto;
                &:not(:last-child) {
                    margin-right: 24px;
                }
                &-title {
                    margin-right: 5px;
                }
                &-value {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        &-copyright {
            .container-paddings();

            flex: 0 0 auto;
        }
    }
    @media @media-lg-down {
        &__botline {
            &-development {
                flex-direction: column;
                &-item {
                    margin-bottom: 11px;
                }
            }
        }
    }
    @media @media-md-down {
        &__container {
            padding: 0 0 15px 0;
        }
        &__right {
            order: -1;
            width: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &-socials {
                .container-paddings();

                margin-top: 28px;
                margin-bottom: 48px;
                width: 100%;
            }
        }
        &__left {
            flex-wrap: wrap;
            width: 100%;
            &-menu-main,
            &-menu-right,
            &-menu-left {
                max-width: none;
            }
            &-menu-right,
            &-menu-left {
                width: 50%;
            }
            &-menu-main {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                padding-right: 0;
                margin-bottom: 38px;
                &-item {
                    &:not(:last-child) {
                        margin-bottom: 0;
                        margin-right: 28px;
                    }
                }
            }
            &-menu-left {
                padding: 0 10px 0 0;
            }
            &-menu-right {
                padding: 0 0 0 10px;
            }
        }
        &__botline {
            flex-direction: column;
            &-info {
                flex-direction: column;
                width: 100%;
                &-item {
                    margin-bottom: 16px;
                    &:not(:last-child) {
                        margin-right: 0;
                    }
                }
            }
            &-development {
                &-item {
                    margin-bottom: 16px;
                }
            }
        }
    }
    @media @media-xs-down {
        &__right {
            &-socials {
                flex-direction: column;
                gap: 24px;
                &-item {
                    display: flex;
                    &-title {
                        flex: 0 1 auto;
                        width: 50%;
                        margin-bottom: 0;
                        &._app {
                            margin-bottom: 8px;
                        }
                    }
                    &-links {
                        flex: 0 1 auto;
                        width: 50%;
                    }
                }
            }
        }
    }
}
</style>
