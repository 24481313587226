<template>
    <div class="sideblock-select-lens-type">
        <div class="sideblock-select-lens-type__description">
            Во всех оптических оправах по умолчанию установлены пластиковые демолинзы, не предназначенные для постоянного ношения. Вы можете выбрать линзы тут или после оформления заказа вместе с нашим консультантом.
        </div>
        <div class="sideblock-select-lens-type__options">
            <div class="sideblock-select-lens-type__options-item"
                v-for="(item, i) in items"
                v-bind:key="i"
                v-on:click="optionClickHandler(item)"
                >
                <div class="sideblock-select-lens-type__options-item-title"
                    v-text="item.title"
                />
                <div class="sideblock-select-lens-type__options-item-description"
                    v-text="item.description"
                />
                <div class="sideblock-select-lens-type__options-item-trigger">
                    Выбрать
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'sideblock-select-lens-type',
    props: {
        items: {
            type: Array,
        },
    },
    methods: {
        optionClickHandler(node) {
            this.$emit('set-node', node);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-select-lens-type {
    &__description {
        padding: 40px 20px 24px;
    }
    &__options {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid @color-gray-darkest;
        border-bottom: 1px solid @color-gray-darkest;
        &-item {
            flex: 0 0 auto;
            width: 50%;
            padding: 24px 40px 20px 20px;
            border-bottom: 1px solid @color-gray-darkest;

            cursor: pointer;

            transition: color @duration-fast @easing-default, background-color @duration-fast @easing-default;
            &:nth-child(2n) {
                border-left: 1px solid @color-gray-darkest;
            }
            &:nth-last-child(1),
            &:nth-last-child(2) {
                border-bottom: none;
            }
            &:hover {
                color: @color-gray-lightest;

                background-color: @color-accent-cold;
            }
            &-title {
                margin-bottom: 16px;

                text-transform: uppercase;
            }
            &-description {
                margin-bottom: 44px;
            }
            &-trigger {
                text-decoration: underline;
            }
        }
    }
    @media @media-sm-down {
        &__options {
            &-item {
                &:hover {
                    color: inherit;

                    background-color: inherit;
                }
            }
        }
    }
}
</style>
