<template>
    <div>plugin-common-accordion</div>
</template>

<script>
export default {
    name: 'plugin-common-accordion',
    data: () => ({
        activeItem: null,
    }),
    methods: {
        setActiveItem(index) {
            if (this.activeItem === index) {
                this.activeItem = null;
            } else {
                this.activeItem = index;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.plugin-common-accordion {
    .typography-body-md();

    width: ~'calc(100% + 40px)';
    margin: 0 -20px;
    & + & {
        margin-top: -1px;
    }
    &__header {
        padding: 11px 20px 9px;

        text-transform: uppercase;

        background: @color-gray-light;
    }
    &__list {
        padding-left: 40px;
        &-item {
            border-bottom: 1px solid @color-gray-darkest;
            &._active & {
                &-title {
                    &-arrow {
                        transform: rotate(0);
                    }
                }
                &-content {
                    display: block;
                }
            }
            &-title {
                display: flex;
                width: 100%;
                padding: 11px 20px 9px;

                text-transform: uppercase;

                cursor: pointer;
                &-text {
                    flex: 1 1 auto;
                }
                &-arrow {
                    flex: 0 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;

                    transform: rotate(-90deg);

                    transition: transform @duration-faster @easing-default;
                }
            }
            &-content {
                display: none;
                max-width: 640px;
                padding: 0 20px;
                & > p {
                    margin: 0 0 20px;
                }
            }
        }
    }
    @media @media-md-down {
        &__header {
            text-align: center;
        }
        &__list {
            padding-left: 0;
        }
    }
}
</style>
