import config from '~/config';
import {
    purchase as purchaseAnalytics,
} from '~/utils/ecommerce';
import { getCookie, parseUrl } from '~/utils/common';
import { parseError } from '~/utils/network';
import { mainClient } from '~/network';
import store from '~/store';

const localStorageKey = config.localStorage.prefix + config.localStorage.affixes.checkout.savedData;
const storedData = JSON.parse(window.localStorage.getItem(localStorageKey) || '{}');

const state = {
    data: {
        country: null,
        customCountry: null,
        city: null,
        customCity: null,
        cityCdekCode: null,
        cityFiasId: null,

        shippingMethod: null,

        // address
        region: null,
        specialAddress: null,
        streetStandard: null,
        street: null,
        house: null,
        building: null,
        apartment: null,
        postcode: null,
        addressComment: null,

        // contacts
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        contactsComment: null,
        communication: null,

        store: null,

        paymentMethod: null,

        ...storedData,
    },

    isLoading: false,
    error: null,

    isShippingDirty: false,
    isPaymentDirty: false,
    dataForLocalStorage: null,
};

const getters = {
    state(state) {
        return state;
    },
    isLoading(state) {
        return state.isLoading;
    },
    isCustomCountry(state) {
        return state.data.country &&
            !state.data.country.country;
    },
    isCustomCity(state, getters) {
        return getters.isCustomCountry ||
            (state.data.country && state.data.country.children.length === 0) ||
            (state.data.city && !state.data.city.city);
    },
    isDadataAvailable(state, getters) {
        return !getters.isCustomCountry &&
            state.data.country &&
            (config.dadata.enabledCountries.length === 0 || config.dadata.enabledCountries.indexOf(state.data.country.name) > -1);
    },
    isShipping(state) {
        return state.data.shippingMethod &&
            state.data.shippingMethod.code !== 'self-pickup';
    },
    isSelfPickup(state) {
        return state.data.shippingMethod &&
            state.data.shippingMethod.code === 'self-pickup';
    },
    sumToFreeShipping(state, getters, rootState, rootGetters) {
        if (getters['isShippingFree']) {
            return 0;
        }
        const sum = config.cart.freeShippingThreshold - rootGetters['cart/totalExcludeDiscounts'];
        return Math.max(0, sum);
    },
    shippingCalculatedPrice(state, getters, rootState) {
        if (!rootState.shippingMethods.getShippingPriceResult ||
            !state.data.shippingMethod?.code ||
            getters['isShippingFree'] ||
            getters['sumToFreeShipping'] === 0
        ) {
            return null;
        }
        return rootState.shippingMethods.getShippingPriceResult.find(x => x.code === state.data.shippingMethod.code) || null;
    },
    isShippingFree(state, getters, rootState, rootGetters) {
        return (rootState.cart.getCartResult && !rootState.cart.getCartResult.isShippingNeeded) ||
            state.data.shippingMethod?.code === 'self-pickup' ||
            state.data.shippingMethod?.code === 'no-shipping' ||
            rootGetters['cart/certificates'].length > 0 ||
            rootGetters['cart/totalExcludeDiscounts'] >= config.cart.freeShippingThreshold;
    },
    isShippingCalculationAvailable(state, getters) {
        return !getters.isShippingFree &&
            config.cart.calculatableShippingMethods.indexOf(state.data.shippingMethod?.type) > -1 &&
            (state.data.cityCdekCode || state.data.cityFiasId);
    },
    customerData(state, getters) {
        const checkoutData = state.data;
        const result = {
            first_name: checkoutData.firstName || '-',
            last_name: checkoutData.lastName || '-',
            line1: 'Default Address',
            line3: 'Default Country',
            line4: 'Default City',
            postcode: (getters.isDadataAvailable && checkoutData.postcode) || '10001',
            state: '',
            country: getters.isCustomCountry ? '/shop/countries/RU/' : checkoutData.country?.countryUrl || '/shop/countries/RU/',
            phone_number: checkoutData.phone || '+79999999999',
        };
        if (checkoutData.region) {
            result.line2 = checkoutData.region;
        }
        if (checkoutData.shippingMethod) {
            if (getters.isCustomCountry) {
                result.line3 = checkoutData.customCountry;
            } else {
                result.line3 = checkoutData.country?.name || 'Default Country';
            }
            if (getters.isCustomCity) {
                result.line4 = checkoutData.customCity;
            } else {
                result.line4 = checkoutData.city?.name || 'Default City';
            }
            if (checkoutData.shippingMethod.code === 'self-pickup') {
                result.line1 = checkoutData.store?.address || 'Default Address';
            } else {
                result.line1 = getters.fullAddress;
            }
        }
        return result;
    },
    fullAddress(state, getters) {
        if (state.data.specialAddress) {
            return state.data.specialAddress;
        }
        if (!getters.isDadataAvailable && state.data.streetStandard) {
            return state.data.streetStandard;
        }
        let address = state.data.street;
        if (address && getters.isDadataAvailable) {
            const dadataHouseIndex = address.search(/ д /);
            const dadataBuildingIndex = address.search(/ к /);
            if (dadataHouseIndex > -1) {
                address = address.substring(0, dadataHouseIndex) + ' д ' + state.data.house;
            } else if (state.data.house) {
                address += ' д ' + state.data.house;
            }
            if (dadataBuildingIndex > -1) {
                address = address.substring(0, dadataBuildingIndex) + ', к ' + state.data.building;
            } else if (state.data.building) {
                address += ', к ' + state.data.building;
            }
            const dadataApartmentIndex = address.search(/ (оф|кв|пом) /);
            if (dadataApartmentIndex > -1) {
                address = address.substring(0, dadataApartmentIndex) + ', кв/офис ' + state.data.apartment;
            } else if (state.data.apartment) {
                address += ', кв/офис ' + state.data.apartment;
            }
        }
        return address || '–';
    },
};

const actions = {
    async checkout({ state, commit, dispatch, getters, rootState, rootGetters }, payload) {
        if (state.isLoading) {
            return;
        }
        state.isLoading = true;
        state.error = null;
        const checkoutData = state.data;
        const module = payload.type === 'fitting' ? 'fitting' : 'cart';
        const cart = rootState[module].getCartResult;
        const isShippingNeeded = rootState[module].getCartResult?.isShippingNeeded;
        const params = {
            guest_email: checkoutData.email,
            basket: cart.url,
            payment: {
                [checkoutData.paymentMethod.code]: {
                    enabled: true,
                    // eslint-disable-next-line camelcase
                    amount: checkoutData.shippingMethod?.charge_incl_tax || 0 + rootGetters[module + '/total'],
                },
            },
            shipping_method_code: (payload.noShipping || !checkoutData.shippingMethod) ? 'no-shipping' : checkoutData.shippingMethod.code,
            phone: checkoutData.phone || '+79999999999',
            code: payload.code,
            roistat: getCookie('roistat_visit'),
            comment: '',
            source: parseUrl().find(x => x.title === 'utm_source')?.value || 'web',
        };

        const customerData = getters.customerData;
        if (customerData.line2) {
            customerData.line2 = customerData.line2.title;
        }
        if (checkoutData.shippingMethod) {
            if (checkoutData.shippingMethod.code !== 'self-pickup') {
                if (checkoutData.addressComment && isShippingNeeded && getters.isShipping) {
                    params.comment += `Комментарий для курьера: ${checkoutData.addressComment}\n\n`;
                }
            } else {
                params.stock_id = checkoutData.store.id;
            }
        }
        if (checkoutData.contactsComment && isShippingNeeded) {
            params.comment += `Комментарий к заказу: ${checkoutData.contactsComment}\n\n`;
        }
        params.comment += `Тип заказа: ${payload.type === 'fitting' ? 'ПРИМЕРКА' : 'ПОКУПКА'}\n\n`;

        if (checkoutData.communication) {
            params.order_information = {
                communication: checkoutData.communication,
            };
        }

        if (payload.noShipping) {
            params.comment += `Без доставки (цифровые сертификаты)`;
        }
        params.shipping_address = customerData;
        params.billing_address = customerData;
        if (checkoutData.cityCdekCode) {
            params.shipping_address = { ...params.shipping_address, cdek_code: checkoutData.cityCdekCode };
        }

        if (payload.type === 'fitting') {
            const { error } = await mainClient.shop.applyPromocode({
                basket_key: config.cart.keys.fitting,
                vouchercode: config.fitting.voucher,
            });
            if (error) {
                console.error(error);
                state.error = error;
                store.commit('modals/push', {
                    name: 'common',
                    props: {
                        title: 'Произошла ошибка',
                        text: 'При оформлении примерки произошла ошибка',
                    },
                });
            }
        }

        if (window.location.hostname === 'localhost' && !window.isCheckoutAllowed) {
            console.warn('Checkout prevented for localhost');
            console.warn('Prevented checkout payload:');
            console.warn(params);
            state.isLoading = false;
            return;
        }
        const { data, error } = await mainClient.shop.checkout(params);
        if (error) {
            if (parseError(error, true) === 'Product is not allowed for fitting') {
                store.commit('modals/push', {
                    name: 'common',
                    props: {
                        title: 'Что-то пошло не так',
                        text: 'Кажется, один или несколько товаров недоступны для примерки в данный момент. Приносим свои извинения. Пожалуйста, напишите нам в чат!',
                        buttons: [
                            {
                                text: 'Открыть чат',
                                callback: () => { window.location.hash = '#chatra_trigger'; },
                            },
                        ],
                    },
                });
            } else {
                const isDebug = parseUrl().some(x => x.title === 'debug');
                console.error(error);
                if (isDebug) {
                    store.commit('modals/push', {
                        name: 'common',
                        props: {
                            title: 'Error Debug Info',
                            text: parseError(error),
                        },
                    });
                } else {
                    store.commit('modals/push', {
                        name: 'common',
                        props: {
                            title: 'Произошла ошибка',
                            text: 'При оформлении заказа произошла ошибка',
                        },
                    });
                }
            }
            state.error = error;
        } else if (data) {
            const ecommerceData = {
                transaction: data.number,
                total: cart.total_incl_tax,
                tax: cart.total_tax,
                shipping: data.shipping_incl_tax,
                products: payload.productsEcommerceData,
                type: payload.type,
            };
            if (cart.voucher_discounts.length > 0) {
                ecommerceData.coupon = cart.voucher_discounts.join('/');
            }
            const orderData = {
                id: data.number,
                checkoutTimestamp: Date.now(),
                isPaid: false,
                store: checkoutData.shippingMethod?.code === 'self-pickup' ? checkoutData.store : null,
            };
            commit('saveOrderToLocalstorage', orderData);
            const paymentErrorHandler = () => {
                store.commit('modals/push', {
                    name: 'common',
                    props: {
                        title: 'Произошла ошибка',
                        text: data.payment_url ? 'При запросе оплаты произошла ошибка' : 'Не удалось запросить оплату',
                    },
                });
            };
            if (checkoutData.paymentMethod.code === 'cash' || checkoutData.paymentMethod.code === 'gift-card') {
                commit('saveOrderToLocalstorage', { ...orderData, isPaid: true });
                commit('purchaseAnalytics', ecommerceData);
            } else if (data.payment_url) {
                const paymentRequest = await mainClient.$client.request({
                    url: data.payment_url,
                    baseURL: '/',
                    method: 'GET',
                });
                if (paymentRequest.status === 200) {
                    state.isLoading = false;
                    dispatch('showPaymentWidget', {
                        ...paymentRequest.data,
                        email: params.guest_email,
                        onSuccess: () => {
                            commit('saveOrderToLocalstorage', { ...orderData, isPaid: true });
                            commit('purchaseAnalytics', ecommerceData);
                        },
                        onFail: () => {
                            document.location = config.urls.checkoutSuccess;
                        },
                    });
                } else {
                    paymentErrorHandler();
                }
            // eslint-disable-next-line no-unreachable
            } else {
                paymentErrorHandler();
            }
            return;
        }
        state.isLoading = false;
    },
    showPaymentWidget({ state, commit }, { onSuccess = null, onFail = null, ...payload }) {
        commit('common/increaseBodyFixedCounter', null, { root: true });
        state.paymentWidget = new window.cp.CloudPayments();
        // eslint-disable-next-line camelcase
        const { email, amount, account_id, invoice_id, public_id, payment_type } = payload;
        const options = {
            publicId: public_id,
            description: 'Оплата товаров на сайте pyeoptics.com',
            amount: parseFloat(amount),
            currency: 'RUB',
            accountId: account_id,
            invoiceId: invoice_id,
            email,
            skin: 'mini',
            // eslint-disable-next-line camelcase
            data: payment_type === 'auth'
                ? undefined
                : {
                    'cloudPayments': {
                        ...payload.receipt_data,
                    },
                },
        };
        state.paymentWidget.pay(
            payment_type,
            options,
            {
                onSuccess: function(options) {
                    onSuccess && onSuccess();
                },
                onFail: function(reason, options) {
                    onFail && onFail();
                },
                onComplete: function(paymentResult, options) {
                    commit('common/decreaseBodyFixedCounter', null, { root: true });
                },
            },
        );
    },
};

const mutations = {
    updateData(state, payload) {
        state.data = {
            ...state.data,
            ...payload,
        };
    },
    setEntity(state, { entity, value }) {
        state[entity] = value;
    },
    setEntities(state, entities) {
        entities.forEach(item => {
            state[item.entity] = item.value;
        });
    },
    purchaseAnalytics(state, payload) {
        purchaseAnalytics(
            payload,
            () => { document.location = config.urls.checkoutSuccess; },
            payload.type === 'fitting'
        );
        setTimeout(() => {
            document.location = config.urls.checkoutSuccess;
        }, 1500);
    },
    saveOrderToLocalstorage(state, payload) {
        window.localStorage.setItem(
            config.localStorage.prefix + config.localStorage.checkoutSuccessAffix,
            JSON.stringify(payload)
        );
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
