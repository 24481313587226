import moment from 'moment';
import { getDeclension } from '~/utils/common';

export const formatNumerWithSpaces = (number) => {
    if (typeof number !== 'number') {
        number = parseFloat(number);
    }
    if (isNaN(number)) {
        return number;
    }
    // const formattedNumber = numbro(number).format(config.formats.numbers.default);
    // const initialCharacter = numbro.languageData().delimiters.thousands;
    // const resultCharacter = ' '; // \xa0 ?
    // const replacer = new RegExp(initialCharacter, 'g');
    // return formattedNumber.replace(replacer, resultCharacter);
    if (number === 0) {
        return '0';
    }
    return new Intl.NumberFormat('ru-RU', { style: 'decimal', minimumSignificantDigits: 2 }).format(number);
};

export const formatPhone = (phoneString) => {
    if (!phoneString || (typeof phoneString !== 'string' && typeof phoneString !== 'number')) {
        return 'no nubmer';
    }
    if (typeof phoneString !== 'string') {
        phoneString = phoneString.toString();
    }
    phoneString = phoneString.replace(/[^0-9+]/g, '');
    if (phoneString[0] !== '+') {
        if (phoneString[0] === '8') {
            phoneString = '+7' + phoneString.substring(1);
        } else {
            phoneString = '+' + phoneString;
        }
    }
    if (phoneString.length > 12) {
        return phoneString.substring(0, 4) + '-' +
        phoneString.substring(4, 7) + '-' +
        phoneString.substring(7, 10) + '-' +
        phoneString.substring(10);
    } else {
        return phoneString.substring(0, 2) + ' (' +
            phoneString.substring(2, 5) + ')-' +
            phoneString.substring(5, 8) + '-' +
            phoneString.substring(8, 10) + '-' +
            phoneString.substring(10, 12);
    }
};

export const formatDuration = (duration) => {
    const momentDuration = moment.duration(duration);
    const hours = momentDuration.hours();
    const minutes = momentDuration.minutes();
    let result = '';

    if (hours > 0) {
        result += `${hours} ${getDeclension(hours, ['час', 'часа', 'часов'])}`;
    }
    if (minutes > 0) {
        result += ` ${minutes} ${getDeclension(minutes, ['минута', 'минуты', 'минут'])}`;
    }
    return result;
};

export const formatDatesRange = (dateFrom, dateTo) => {
    moment.locale('ru');
    if (dateFrom === null || dateTo === null) {
        return '';
    }
    let dateFromFormatted = moment(dateFrom).utcOffset(3).format('DD MMMM YYYY');
    const dateToFormatted = moment(dateTo).utcOffset(3).format('DD MMMM YYYY');
    if (dateFromFormatted === dateToFormatted) {
        return dateFromFormatted;
    }
    if (moment(dateFrom).utcOffset(3).format('YYYY') === moment(dateTo).utcOffset(3).format('YYYY')) {
        dateFromFormatted = moment(dateFrom).utcOffset(3).format('DD MMMM');
    }
    return `${dateFromFormatted} ‑ ${dateToFormatted}`;
};

export const formatTimesRange = (timeFrom, timeTo) => {
    if (timeFrom === null || timeTo === null) {
        return '';
    }
    const timeFromFormatted = moment(timeFrom).utcOffset(3).format('HH:mm');
    const timeToFormatted = moment(timeTo).utcOffset(3).format('HH:mm');

    if (timeFromFormatted === timeToFormatted) {
        return timeFromFormatted;
    } else {
        return `${timeFromFormatted} ‑ ${timeToFormatted}`;
    }
};

export default {
    formatNumerWithSpaces,
    formatPhone,
    formatDuration,
    formatDatesRange,
    formatTimesRange,
};
