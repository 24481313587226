import { render, staticRenderFns } from "./iconed-list-item.vue?vue&type=template&id=120fac66&scoped=true&"
import script from "./iconed-list-item.vue?vue&type=script&lang=js&"
export * from "./iconed-list-item.vue?vue&type=script&lang=js&"
import style0 from "./iconed-list-item.vue?vue&type=style&index=0&id=120fac66&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "120fac66",
  null
  
)

export default component.exports