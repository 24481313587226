import { render, staticRenderFns } from "./header-mobile-menu-item.vue?vue&type=template&id=38493ee4&scoped=true&"
import script from "./header-mobile-menu-item.vue?vue&type=script&lang=js&"
export * from "./header-mobile-menu-item.vue?vue&type=script&lang=js&"
import style0 from "./header-mobile-menu-item.vue?vue&type=style&index=0&id=38493ee4&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38493ee4",
  null
  
)

export default component.exports