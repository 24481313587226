<template>
    <div class="common-lazy-background"
        v-bind:style="paddingStyle"
        >
        <img class="common-lazy-background__image"
            v-bind:key="image"
            v-bind:src="image"
            v-on:load="setImage"
            loading="lazy"
        />
        <transition name="fade">
            <div class="common-lazy-background__cover _prev"
                v-if="prevImageStyle"
                v-bind:style="prevImageStyle"
            />
        </transition>
        <transition name="fade">
            <div class="common-lazy-background__cover"
                v-if="currentImageStyle"
                v-bind:key="currentImage"
                v-bind:style="currentImageStyle"
            />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'common-lazy-background',
    props: {
        image: String,
        aspectRatio: {
            type: Number,
            default: null,
        },
    },
    data: () => ({
        prevImage: null,
        currentImage: null,
    }),
    computed: {
        paddingStyle() {
            if (!this.aspectRatio) {
                return null;
            }
            return { paddingTop: `${100 * this.aspectRatio}%` };
        },
        prevImageStyle() {
            if (!this.prevImage) {
                return null;
            }
            return { backgroundImage: `url(${this.prevImage})` };
        },
        currentImageStyle() {
            if (!this.currentImage) {
                return null;
            }
            return { backgroundImage: `url(${this.currentImage})` };
        },
    },
    methods: {
        setImage() {
            this.prevImage = this.currentImage;
            this.currentImage = this.image;
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.common-lazy-background {
    position: relative;

    width: 100%;
    height: 100%;

    background-color: transparent;
    &__image {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;

        width: 0;
        height: 0;

        opacity: 0;
        pointer-events: none;
    }
    &__cover {
        .transition-fade();
        .mixin-background-image();

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;

        background-color: transparent;
        &._prev {
            z-index: -1;
        }
    }
}
</style>
