<template>
    <article class="common-card-cart"
        ref="root"
        >
        <a class="common-card-cart__image"
            v-bind:href="product.url"
            v-bind:style="{ backgroundImage: product.images && product.images.length > 0 ? `url(${product.images[0].small})` : 'none'}"
            target="_blank"
            >
            <span class="common-card-cart__image-clipon"
                v-if="product.isClipon"
                v-text="'Клипон'"
            />
        </a>
        <div class="common-card-cart__info">
            <div class="common-card-cart__info-headline">
                <div class="common-card-cart__info-headline-title">
                    <span class="common-card-cart__info-headline-title-model"
                        v-text="product.title"
                    /> <span v-if="product.color" v-text="'(' + product.color + ')'" />
                </div>
                <div class="common-card-cart__info-headline-old"
                    v-if="product.price.oldText"
                    v-text="product.price.oldText"
                />
                <div class="common-card-cart__info-headline-price"
                    v-text="currentPrice"
                />
            </div>
            <div class="common-card-cart__info-clipon"
                v-if="product.isClipon"
                >
                Подходит для оправы <a v-bind:href="cliponRelatedData.url" v-text="cliponRelatedData.title + ' ↗\uFE0E'" />
            </div>
            <div class="common-card-cart__info-lenses"
                v-if="product.isOptical && !isFitting"
                >
                <template v-if="attachedLensLine">
                    <div class="common-card-cart__info-lenses-info">
                        <div class="common-card-cart__info-lenses-info-title"
                            v-text="attachedLensTitle"
                        />
                        <div class="common-card-cart__info-lenses-info-price"
                            v-text="attachedLensPrice"
                        />
                    </div>
                    <div class="common-card-cart__info-lenses-actions"
                        v-bind:class="{ _vertical: cart && cart.prescription }"
                        >
                        <div class="common-card-cart__info-lenses-actions-item">
                            <span class="common-card-cart__info-lenses-actions-item-text"
                                v-on:click="changeLenses"
                                v-text="'Изменить линзы'"
                            />
                        </div>
                        <div class="common-card-cart__info-lenses-actions-item"
                            v-if="isCorrection"
                            >
                            <common-card-cart-prescription v-bind:basket-id="cart.id" />
                        </div>
                    </div>
                </template>
                <div class="common-card-cart__info-lenses-select"
                    v-else
                    >
                    <ui-button
                        v-on:click="selectLensesHandler"
                        is-bordered
                        v-bind:is-uppercase="isLargeScreen"
                        >
                        Выбрать линзы
                    </ui-button>
                </div>
            </div>
        </div>
        <div class="common-card-cart__close"
            v-on:click="removeFromCart(product)"
            >
            <icon name="close" />
        </div>
    </article>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'common-card-cart',
    props: {
        product: {
            type: Object,
        },
        ecommerceListData: {
            type: Object,
        },
        isFitting: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        moduleName() {
            return this.isFitting ? 'fitting' : 'cart';
        },
        cart() {
            return this.$store.state[this.moduleName].getCartResult;
        },
        attachedLensLine() {
            if (this.isFitting) {
                return null;
            }
            return this.cart.lines.find(x => x.id === this.product.lineChildId) || null;
        },
        isCorrection() {
            if (!this.attachedLensLine) {
                return false;
            }
            // TODO: decide how to distinguish correction and casual lenses
            return true;
        },
        attachedLensTitle() {
            if (!this.attachedLensLine) {
                return '';
            }
            return `${this.attachedLensLine.product.title} (${this.attachedLensLine.product.attributes.find(x => x.code === 'Покрытие')?.value})`;
        },
        attachedLensPrice() {
            if (!this.attachedLensLine) {
                return '';
            }
            return `+ ${utils.formats.formatNumerWithSpaces(this.attachedLensLine.product.price.value * 2)} ₽`;
        },
        isLargeScreen() {
            return window.innerWidth >= 575;
        },
        cliponRelatedData() {
            if (!this.product.isClipon) {
                return null;
            }
            return this.product.isClipon && this.product.clipon_related_product;
        },
        currentPrice() {
            return ((this.product.quantity || 1) * this.product.price.value).toLocaleString('ru-RU') + ' ₽';
        },
    },
    methods: {
        removeFromCart(product) {
            if (this.attachedLensLine) {
                this.$store.dispatch(this.moduleName + '/updateLine', { ...product, lensLine: this.attachedLensLine });
                return;
            }
            this.$store.dispatch(this.moduleName + '/updateLine', product);
        },
        selectLensesHandler() {
            this.$store.commit('sideblocks/push', {
                name: 'select-lens',
                props: { glasses: this.product, lineId: this.product.lineId },
            });
        },
        changeLenses() {
            this.$store.commit('sideblocks/push', {
                name: 'select-lens',
                props: { glasses: this.product, lineId: this.product.lineId },
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-card-cart {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    &__image {
        flex: 0 0 auto;
        width: 180px;
        height: 100px;
        margin-right: 15px;

        text-decoration: none;

        background-color: @color-gray-main;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        &-clipon {
            display: block;
            margin: 8px;

            font-size: 1rem;
            line-height: 0.8rem;
            letter-spacing: 0.05em;
            text-align: right;
            text-transform: uppercase;

            pointer-events: none;
        }
    }
    &__info {
        flex: 1 1 auto;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        padding-right: 16px;
        &-headline {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            padding-bottom: 16px;
            &-title {
                padding-right: 8px;
                &-model {
                    text-transform: uppercase;
                }
            }
            &-old {
                margin-left: auto;
                margin-right: 8px;

                text-decoration: line-through;
            }
            &-price {
                margin-left: auto;
                margin-right: 0;
            }
            &-old + &-price {
                margin-left: 0;
            }
        }
        &-lenses {
            flex: 0 1 auto;
            &-info {
                display: flex;
                align-items: baseline;
                justify-content: flex-start;
                margin-bottom: 12px;
                &-title {
                    flex: 1 1 auto;
                    padding-right: 16px;
                }
                &-price {
                    flex: 0 0 auto;
                }
            }
            &-select {
                width: fit-content;
            }
            &-actions {
                display: flex;
                &._vertical {
                    flex-direction: column;
                }
                &-item {
                    &:not(:last-child) {
                        margin-right: 12px;
                    }
                    ._vertical > &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                    &-text {
                        text-decoration: underline;

                        cursor: pointer;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    &__close {
        flex: 0 0 auto;
        padding: 5px 20px 15px;

        font-size: 1rem;

        cursor: pointer;
    }
    @media @media-xs-down {
        &__image {
            width: 140px;
            height: 80px;
        }
        &__info {
            padding-right: 0;
            &-headline {
                display: block;
                padding-bottom: 4px;
                &-title {
                    margin-bottom: 4px;
                }
            }
            &-lenses {
                margin-right: -30px;
                &-info {
                    display: block;
                    &-title {
                        padding-right: 0;
                        margin-bottom: 4px;
                    }
                }
                &-actions {
                    display: block;
                    &-item {
                        margin-right: 0;
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}
</style>
