import { mainClient } from '~/network';
import config from '~/config';
import ecommerce from '~/utils/ecommerce';
import { product as productTransform } from '~/network/mainClient/shop/transforms/responses';

const state = {
    getCartResult: null,
    getCartIsLoading: false,
    getCartError: null,

    addToCartResult: null,
    addToCartIsLoading: false,
    addToCartError: null,

    removeFromCartResult: null,
    removeFromCartIsLoading: false,
    removeFromCartError: null,

    decreaseLineQuantityResult: null,
    decreaseLineQuantityIsLoading: false,
    decreaseLineQuantityError: null,

    applyVoucherResult: null,
    applyVoucherIsLoading: false,
    applyVoucherError: null,
};

const getters = {
    count(state) {
        if (state.getCartResult === null) {
            return 0;
        }
        return state.getCartResult.lines.reduce((acc, curr) => acc + curr.quantity, 0);
    },
    currency(state) {
        if (state.getCartResult === null) {
            return '';
        }
        return state.getCartResult.currency;
    },
    total(state) {
        if (state.getCartResult === null) {
            return 0;
        }
        return state.getCartResult.total;
    },
    items(state) {
        const items = [];
        if (state.getCartResult !== null) {
            state.getCartResult.lines.forEach((line, index) => {
                for (let i = 0; i < line.quantity; i++) {
                    items.push({
                        lineIndex: index,
                        ...productTransform(line.product),
                    });
                }
            });
        }
        return items;
    },
    productsEcommerceData(state) {
        if (!state.getCartResult?.lines) {
            return [];
        }
        return state.getCartResult.lines.map(line => ({
            ...line.product.ecommerce,
            quantity: line.quantity,
        }));
    },
    isLoading(state) {
        return state.getCartIsLoading ||
            state.addToCartIsLoading ||
            state.removeFromCartIsLoading ||
            state.decreaseLineQuantityIsLoading ||
            state.applyVoucherIsLoading;
    },
};

const actions = {
    async getCart({ state, getters }) {
        if (getters.isLoading) {
            return;
        }
        state.getCartIsLoading = true;
        const { data = {}, error, status } = await mainClient.shop.getBasket({ basket_key: config.cart.keys.fitting });
        if (error !== null) {
            console.error(error, status);
            console.error('Error above relates to vuex store cart module getCart action');
            state.getCartResult = null;
            state.getCartError = error;
        } else {
            state.getCartResult = data;
            state.getCartError = null;
        }
        state.getCartIsLoading = false;
    },
    async addToCart({ state, getters, dispatch }, params) {
        if (getters.isLoading) {
            return;
        }
        const ecommerceData = params.product.ecommerce;
        params.product = params.product.id;
        state.addToCartIsLoading = true;
        const { data = {}, error, status } = await mainClient.shop.addToCart({ ...params, basket_key: config.cart.keys.fitting });
        if (error !== null) {
            console.error(error, status);
            console.error('Error above relates to vuex store cart module addToCart action');
            state.addToCartResult = null;
            state.addToCartError = error;
        } else {
            state.addToCartResult = data;
            state.addToCartError = null;
            ecommerce.addToCart(ecommerceData, true);
        }
        state.addToCartIsLoading = false;
        error === null && dispatch('getCart');
    },
    updateLine({ state, dispatch }, product) {
        if (state.getCartResult === null) {
            return;
        }
        const line = state.getCartResult.lines[product.lineIndex];
        const params = {
            url: line.url,
            quantity: line.quantity - 1,
            product,
        };
        if (params.quantity > 0) {
            dispatch('decreaseLineQuantity', params);
        } else {
            dispatch('removeFromCart', params);
        }
    },
    async decreaseLineQuantity({ state, getters, dispatch }, params) {
        if (getters.isLoading) {
            return;
        }
        const ecommerceData = params.product.ecommerce;
        delete params.product;
        state.decreaseLineQuantityIsLoading = true;
        const { data = {}, error, status } = await mainClient.shop.updateLine(params);
        if (error !== null) {
            console.error(error, status);
            console.error('Error above relates to vuex store cart module decreaseLineQuantity action');
            state.decreaseLineQuantityResult = null;
            state.decreaseLineQuantityError = error;
        } else {
            state.decreaseLineQuantityResult = data;
            state.decreaseLineQuantityError = null;
            ecommerce.removeFromCart(ecommerceData, true);
        }
        state.decreaseLineQuantityIsLoading = false;
        error === null && dispatch('getCart');
    },
    async removeFromCart({ state, getters, dispatch }, params) {
        if (getters.isLoading) {
            return;
        }
        const ecommerceData = params.product.ecommerce;
        delete params.product;
        state.removeFromCartIsLoading = true;
        const { data = {}, error, status } = await mainClient.shop.removeLine(params);
        if (error !== null) {
            console.error(error, status);
            console.error('Error above relates to vuex store cart module removeFromCart action');
            state.removeFromCartResult = null;
            state.removeFromCartError = error;
        } else {
            state.removeFromCartResult = data;
            state.removeFromCartError = null;
            ecommerce.removeFromCart(ecommerceData, true);
        }
        state.removeFromCartIsLoading = false;
        error === null && dispatch('getCart');
    },
    async applyVoucher({ state, getters, dispatch }, params) {
        if (getters.isLoading) {
            return;
        }
        state.applyVoucherIsLoading = true;
        const { data = {}, error, status } = await mainClient.shop.applyPromocode(params);
        if (error !== null) {
            console.error(error, status);
            console.error('Error above relates to vuex store cart module applyPromocode action');
            state.applyVoucherResult = null;
            state.applyVoucherError = error;
        } else {
            state.applyVoucherResult = data;
            state.applyVoucherError = null;
        }
        state.applyVoucherIsLoading = false;
        error === null && dispatch('getCart');
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
