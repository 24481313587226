import { formatNumerWithSpaces } from '~/utils/formats';

export const checkCertificateBalance = (data) => {
    const statuses = {
        unused: {
            code: 'unused',
            title: 'Сертификат не использован',
        },
        partially_used: {
            code: 'partial',
            title: 'Сертификат использован частично',
        },
        used: {
            code: 'used',
            title: 'Сертификат использован полностью',
        },
        unknown: {
            code: 'unknown',
            title: 'Статус сертификата неизвестен',
        },
    };
    const status = statuses[data.status] || statuses.unknown;
    const result = { status };
    if (status.code === 'unused') {
        result.balance = data.nominal;
    } else if (status.code === 'partial') {
        result.balance = data.remainder;
    } else if (status.code === 'used') {
        result.balance = 0;
    }
    result.balanceFormatted = `${formatNumerWithSpaces(result.balance)} ₽`;
    return result;
};
