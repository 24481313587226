<template>
    <label class="ui-input-base-checkbox"
        v-bind:class="{
            _active: value,
            _focused: isFocused,
        }"
        >
        <div class="ui-input-base-checkbox__background" />
        <div class="ui-input-base-checkbox__mark" />
        <input class="ui-input-base-checkbox__input"
            type="checkbox"
            v-bind:checked="value"
            v-bind:autofocus="isAutofocus"
            v-on:focus="focusHandler"
            v-on:blur="blurHandler"
            v-on:change="updateModel($event.target.checked)"
        />
        <div class="ui-input-base-checkbox__check">
            <icon name="check" />
        </div>
    </label>
</template>

<script>
export default {
    name: 'ui-input-base-checkbox',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        isAutofocus: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    data: () => ({
        isFocused: false,
    }),
    methods: {
        updateModel(newValue) {
            this.$emit('change', newValue);
        },
        focusHandler() {
            this.isFocused = true;
        },
        blurHandler() {
            this.isFocused = false;
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.ui-input-base-checkbox {
    position: relative;

    flex: 0 0 auto;
    display: block;

    width: 26px;
    height: 14px;
    border-radius: 7px;
    overflow: hidden;

    cursor: pointer;
    user-select: none;

    box-shadow: 0 0 0 0 @color-accent-cold;

    transition: box-shadow @duration-fast @easing-default;
    // &._focused {
    //     box-shadow: 0 0 0 3px @color-accent-cold;
    // }
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        display: block;

        border-radius: 7px;

        background-color: @color-gray-light;

        transition: background-color @duration-fast @easing-default;
        ._active > & {
            background-color: @color-accent-warm;
        }
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;
        appearance: none;
    }

    &__mark {
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: 2;

        width: 12px;
        height: 12px;
        border-radius: 50%;

        background: @color-gray-lightest;

        transition: transform @duration-fast @easing-default, box-shadow @duration-fast @easing-default;
        ._active > & {
            transform: translateX(12px);
        }
    }
}
</style>
