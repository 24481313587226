<template>
    <div class="page-checkout-option"
        v-bind:class="{
            _active: isActive,
            _invalid: isInvalid,
        }"
        v-on:click="$emit('click')"
        >
        <div class="page-checkout-option__info">
            <span class="page-checkout-option__info-accent"
                v-text="item.optionTitle"
            />
            <br/>
            <span v-text="item.optionSubitle" />
            <br/>
            <br/>
            <span class="page-checkout-option__info-description"
                v-text="item.customDescription || item.optionDescription"
            />
        </div>
        <div class="page-checkout-option__trigger">
            Выбрать
        </div>
        <div class="page-checkout-option__check">
            ✓
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-checkout-option',
    props: {
        item: {
            type: Object,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-option {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: 100%;
    padding: 16px 29px 16px 20px;
    border-right: 1px solid @color-gray-darkest;
    border-bottom: 1px solid @color-gray-darkest;

    background-color: @color-gray-lightest;

    cursor: pointer;

    transition: background-color @duration-fast @easing-default;
    &:hover,
    &._active {
        background-color: @color-accent-yellow;
    }
    &._invalid {
        border-color: @color-accent-warm;
    }
    &._active & {
        &__trigger {
            display: none;
        }
        &__check {
            display: block;
        }
    }
    &__info {
        margin-bottom: 40px;
        &-accent {
            text-transform: uppercase;
        }
        &-description {
            white-space: pre-line;
        }
    }
    &__trigger {
        margin-top: auto;
        margin-bottom: 0;
    }
    &__check {
        display: none;
        margin-top: auto;
        margin-bottom: 0;
    }
}
</style>
