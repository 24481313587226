<template>
    <div class="plugin-common-notification" />
</template>

<script>
export default {
    name: 'plugin-common-notification',
    props: {
        textColor: {
            type: String,
            default: null,
        },
        backgroundColor: {
            type: String,
            default: null,
        },
        text: {
            type: String,
        },
    },
    mounted() {
        if (this.$store.state.notifications.items.find(x => x.text === this.text)) {
            return;
        }
        this.$store.commit('notifications/push', {
            textColor: this.textColor,
            backgroundColor: this.backgroundColor,
            text: this.text,
        });
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-common-notification {
    display: none;
}
</style>
