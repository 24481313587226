<template>
    <div class="ui-hint"
        v-on:mouseleave="mouseleaveHandler"
        v-on:mouseenter="mouseenterHandler"
        >
        <span class="ui-hint__trigger" ref="trigger">?</span>
        <transition name="fade">
            <div class="ui-hint__content"
                v-if="hovered"
                v-bind:class="'_' + position"
                v-bind:style="offsetStyle"
                ref="content"
                >
                <slot />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ui-hint',
    props: {
        position: {
            type: String,
            default: 'top',
        },
    },
    data: () => ({
        offsetStyle: null,
        hovered: false,
    }),
    methods: {
        mouseenterHandler() {
            this.hovered = true;
            this.offsetStyle = null;
            this.$nextTick(this.checkPosition);
        },
        mouseleaveHandler() {
            this.hovered = false;
        },
        checkPosition() {
            const content = this.$refs.content;
            if (!content) {
                return;
            }
            const boundings = content.getBoundingClientRect();
            const overflow = window.innerWidth - (boundings.x + boundings.width);
            if (overflow < 0) {
                this.offsetStyle = { marginLeft: `${overflow - 16}px` };
            } else {
                this.offsetStyle = null;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-hint {
    position: relative;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    padding-top: 4px;
    border-radius: 50%;

    color: @color-gray-darkest;
    text-align: center;

    cursor: pointer;
    box-shadow: inset 0 0 0 1px @color-gray-darkest;

    transition: all @duration-fast @easing-default;
    &:hover {
        color: @color-gray-lightest;
        box-shadow: inset 0 0 0 10px @color-gray-darkest;
    }
    &:hover &__content,
    &__content {
        .transition-fade();

        position: absolute;
        z-index: 100;

        display: block;
        width: 100vw;
        max-width: 300px;
        padding: 16px;

        color: @color-gray-darkest;
        text-align: left;

        background-color: @color-gray-lightest;

        cursor: auto;
        box-shadow: 0 0 20px @color-accent-cold;

        transition-delay: @duration-fast;
        &._top {
            bottom: ~'calc(100% + 5px)';
            left: -50%;
            &:after {
                top: 100%;
            }
        }
        &._bottom {
            top: ~'calc(100% + 5px)';
            left: -16px;
            &:after {
                bottom: 100%;
            }
        }
        &:after {
            content: '';

            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;

            display: block;
            height: 20px;

            opacity: 0;
        }
    }
}
</style>
