<template>
    <section class="page-giftcard">
        <h1 class="page-giftcard__title">Скачать сертификат</h1>
        <img class="page-giftcard__image"
            v-bind:src="`${staticPathRoot}/images/gift-cards/image-page-download.jpg`"
            alt="Сертификат"
        />
        <div class="page-giftcard__links">
            <div class="page-giftcard__links-item">
                <span class="page-giftcard__links-item-trigger" v-on:click="download('png')">Скачать как картинку в PNG</span>
            </div>
            <div class="page-giftcard__links-item">
                <span class="page-giftcard__links-item-trigger" v-on:click="download('pdf')">Скачать как файл в PDF</span>
            </div>
        </div>
        <div class="page-giftcard__warning">
             Это уникальная страница с вашим сертификатом.
            <br />
            Пожалуйста,{{'\xa0'}}не{{'\xa0'}}публикуйте ее в открытых источниках
        </div>
    </section>
</template>

<script>
import { getOrderGiftCard } from '~/network/mainClient/shop/transforms/responses';

export default {
    name: 'page-giftcard',
    inject: [ 'staticPathRoot' ],
    props: {
        certificateData: {
            type: Object,
        },
    },
    computed: {
        certificate() {
            if (!this.certificateData) {
                return null;
            }
            return getOrderGiftCard(this.certificateData);
        },
    },
    methods: {
        download(mode) {
            if (!this.certificate) {
                console.error('No certificate data');
                return;
            }
            this.$store.commit('modals/push', {
                name: 'render-giftcard',
                props: {
                    mode,
                    givenData: this.certificate,
                },
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-giftcard {
    .container();
    .container-paddings();
    .typography-body-md();

    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-top: 88px;
    padding-bottom: 107px;

    text-align: center;

    &__title {
        .typography-body-md();

        flex: 0 0 auto;
        margin: 0 0 8px 0;

        font-weight: normal;
        text-transform: uppercase;
    }
    &__image {
        flex: 0 0 auto;
        width: 100%;
        max-width: 180px;
        height: auto;
        margin-bottom: 72px;
    }
    &__links {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 72px;
        &-item {
            flex: 0 0 auto;
            display: block;
            margin-bottom: 8px;
            &-trigger {
                .typography-body-md();
                text-decoration: underline;
                cursor: pointer;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    &__warning {
        .typography-body-md();

        flex: 0 0 auto;
        margin-top: auto;
    }
}
</style>
