<template>
    <div class="sideblock-delivery">
        <div class="sideblock-delivery__body">
            <common-fitting-info />
        </div>
    </div>
</template>

<script>
export default {
    name: 'sideblock-delivery',
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-delivery {
    .mixin-sideblock();

    padding: 0;
    &__body {
        padding: 24px 20px 30px;
    }
}
</style>
