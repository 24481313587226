import { mainClient } from '~/network';

const state = {
    checkCertificateBalanceResult: null,
    checkCertificateBalanceIsLoading: false,
    checkCertificateBalanceError: null,
};

const getters = {};

const actions = {
    async checkCertificateBalance({ state }, payload) {
        if (state.checkCertificateBalanceIsLoading) {
            return;
        }
        state.checkCertificateBalanceIsLoading = true;

        const { data, error, status } = await mainClient.certificates.checkCertificateBalance(payload);
        if (error !== null) {
            console.error(error, status);
            state.checkCertificateBalanceError = error;
            state.checkCertificateBalanceResult = null;
        } else {
            state.checkCertificateBalanceError = null;
            state.checkCertificateBalanceResult = data;
        }

        state.checkCertificateBalanceIsLoading = false;
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
