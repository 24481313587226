<template>
    <div class="plugin-home-hero-text-item"
        v-bind:class="{ _half: isHalf }"
        ref="root"
        >
        <div class="plugin-home-hero-text-item__observer">
            <common-intersection-observer
                v-on:intersect="triggerImpressionAnalytics"
                v-bind:options="{ threshold: 0.8 }"
                self-destruct
            />
        </div>
        <div class="plugin-home-hero-text-item__text"
            v-bind:class="`_${textAlign}`"
            v-bind:style="{
                color: textColor,
                backgroundColor,
            }"
            v-html="text"
        />
    </div>
</template>

<script>
import ecommerce from '~/utils/ecommerce';

export default {
    name: 'plugin-home-hero-text-item',
    props: {
        textAlign: { type: String, default: 'top' },
        textColor: { type: String, default: '#000000' },
        backgroundColor: { type: String, default: '#ffffff' },
        text: { type: String, default: null },
        isHalf: { type: Boolean, default: false },
        ecommerceData: { type: Object, default: null },
    },
    methods: {
        triggerImpressionAnalytics() {
            if (this.ecommerceData.promotionId &&
                this.ecommerceData.promotionName &&
                this.ecommerceData.creativeName &&
                this.ecommerceData.creativeSlot
            ) {
                ecommerce.promotionImpression(this.ecommerceData);
            }
        },
        linkClickHandler(e) {
            e.preventDefault();
            ecommerce.promotionClick(this.ecommerceData);
            if (e.metaKey || e.ctrlKey) {
                window.open(e.target.attributes.href.baseURI, '_blank');
            } else {
                window.location.href = e.target.attributes.href.baseURI;
            }
        },
        bindLinksHandlers() {
            if (!this.$refs.root) {
                return;
            }
            const links = this.$refs.root.getElementsByTagName('a');
            for (let i = 0; i < links.length; i++) {
                links[i].addEventListener('click', this.linkClickHandler);
            }
        },
        unbindLinksHandlers() {
            if (!this.$refs.root) {
                return;
            }
            const links = this.$refs.root.getElementsByTagName('a');
            for (let i = 0; i < links.length; i++) {
                links[i].removeEventListener('click', this.linkClickHandler);
            }
        },
    },
    mounted() {
        this.$nextTick(this.bindLinksHandlers);
    },
    beforeDestroy() {
        this.unbindLinksHandlers();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-hero-text-item {
    .typography-display-md();

    position: relative;

    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    padding: 36px 32px;
    /deep/ p {
        margin: 0 auto;

        font-size: inherit;
    }
    &._half {
        width: 50%;
    }
    &__observer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        pointer-events: none;
        opacity: 0;
    }
    &__text {
        white-space: pre-line;
        &._top {
            margin: 0 0 auto;
        }
        &._middle {
            margin: auto 0 auto;
        }
        &._bottom {
            margin: auto 0 0;
        }
    }
    @media @media-md-down {
        padding: 32px 20px;
        &._half {
            width: 100%;
        }
    }
}
</style>
