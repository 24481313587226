import config from '~/config';
import { formatNumerWithSpaces } from '~/utils/formats';
import { isColorLight, injectResize } from '~/utils/common';

export const getPaymentMethods = (data) => {
    return Object.keys(data).map(key => ({
        ...data[key],
        code: key,
    }));
};

export const getShippingMethodsItem = (data) => {
    return {
        ...data,
        priceFormatted: data.price > 0 ? formatNumerWithSpaces(data.price) : 'Бесплатно',
    };
};

export const getShippingMethods = (data) => {
    return data.items.map(x => getShippingMethodsItem(x));
};

export const getShippingPrice = (data) => {
    return data.items.map(x => ({
        code: x.code,
        price: x.price,
        priceFormatted: `${formatNumerWithSpaces(x.price)} ₽`,
        minDuration: x.calendar_min || null,
        maxDuration: x.calendar_max || null,
    }));
};

export const getShippingCityCdekCode = (data) => {
    if (!data?.length) {
        return null;
    }
    return data[0].code;
};

export const getOrders = (data) => {
    return {
        ...data,
        results: data.results.map(order => getOrdersItem(order)),
    };
};

export const getOrdersItem = (data) => {
    const ordinaryOrderLines = data.order_lines?.map(orderLine => ({
        ...orderLine,
        product: product(orderLine.product),
    })) || [];
    return {
        ...data,
        // eslint-disable-next-line camelcase
        trackingUrl: data.order_information?.cdek_tracking_url || data.demand_tracking_url,
        isFitting: data.is_fitting || false,
        order_lines: ordinaryOrderLines,
        giftcard: data.gift_voucher,
    };
};

export const getOrderGiftCard = ({
    // eslint-disable-next-line camelcase
    name_from = null,
    // eslint-disable-next-line camelcase
    name_to = null,
    nominal = '0',
    ...data
}) => {
    return {
        to: name_to,
        from: name_from,
        nominal: parseInt(nominal, 10),
        ...data,
    };
};

export const getBasket = (data) => {
    data.lines.forEach((line, i) => {
        line.product.lineIndex = i;
        line.product.lineId = line.id;
        line.product.lineChildId = line.child_id;
        line.product.lineParentId = line.parent_id;
        line.product = product(line.product, null, data.lines.some(x => x.child_id === line.id));
        const lineEcommerceData = line.description?.analytics;
        if (lineEcommerceData) {
            line.product.ecommerce = {
                ...line.product.ecommerce,
                ...lineEcommerceData,
            };
        }
    });
    return {
        ...data,
        hasCertificate: data.lines.some(x => x.product.isCertificate),
        isShippingNeeded: data.lines.some(x => !x.product.noShippingRequired),
        total: parseFloat(data.total_incl_tax),
        totalExcludeDiscounts: parseFloat(data.total_incl_tax_excl_discounts),
    };
};

export const addToCart = getBasket;

export const getFilters = (data) => {
    return data.results.map(x => {
        const filter = {
            title: x.name,
            code: x.code,
        };
        filter.values = x.filteritem_set.map(v => {
            const colorHexCodeIndex = v.value ? v.value.indexOf('#') : -1;
            const color = colorHexCodeIndex > -1 ? v.value.substring(colorHexCodeIndex + 1, colorHexCodeIndex + 7) : null;
            const value = {
                id: v.id,
                title: v.name,
                color,
                isLight: color !== null ? isColorLight(color) : false,
                image: v.image ? v.image : null,
            };
            return value;
        });
        return filter;
    });
};

export const product = (data, parent = null, isLens = false) => {
    if (!data) {
        return null;
    }
    const parentAttributes = parent?.attributes || data.parent_attributes || null;
    if (parentAttributes) {
        parentAttributes.forEach(parentAttr => {
            if (data.attributes.every(x => x.code !== parentAttr.code)) {
                data.attributes.push(parentAttr);
            }
        });
    }

    const currency = '₽';
    const collection = data.attributes.find(_ => _.code === 'Коллекция')?.value ||
        data.attributes.find(_ => _.code === 'Бренд')?.value ||
        '';
    data.ecommerce = {
        brand: collection || 'PYE',
        categories: [],
        quantity: 1,
    };
    data.color = data.attributes.find(_ => _.code === 'Цвет оправы');
    const certificateType = data.attributes.find(_ => _.code === 'Тип сертификата');
    const isAccessory = data.attributes.find(_ => _.code === 'Тип товара')?.value === 'Аксессуары';
    if (certificateType) {
        data.isCertificate = true;
        data.certificateType = certificateType.value;
        if (data.title.indexOf(certificateType.value) < 0) {
            data.title += ` (${certificateType.value})`;
        }
        if (certificateType.value === 'Электронный') {
            data.noShippingRequired = true;
        }
    }
    data.description = data.description || parent?.description;
    if (isAccessory) {
        data.isAccessory = true;
        data.color = data.attributes.find(_ => _.code === 'Цвет');
    }
    data.color = data.color !== undefined ? data.color.value : '';

    let price = data.price?.value || data.price;
    let priceText = `${formatNumerWithSpaces(price)} ${currency}`;
    let priceOld = null;
    let priceOldText = null;
    if (data.discount && data.discount.value !== 0) {
        priceOld = price;
        priceOldText = priceText;
        if (data.discount.type === 'Absolute') {
            price = price - data.discount.value;
        } else {
            price = price * (100 - data.discount.value) / 100;
        }
        priceText = price.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', maximumFractionDigits: 2 });
    }
    data.price = {
        value: price,
        text: priceText,
        old: priceOld,
        oldText: priceOldText,
    };
    data.isClipon = data.is_clipon || data.clipon_related_product;
    data.lensType = data.attributes.find(x => x.code === 'Тип линз') || null;
    data.isOptical = data.lensType?.value === 'Оптика';
    data.isFittingAllowed = !data.isCertificate && !data.isAccessory && data.fitting_allowed;

    // ecommerce data
    if (data.isAccessory) {
        data.ecommerce.variant = data.color || 'no-variants';
        data.ecommerce.categories.push('Аксессуар');
        // data.ecommerce.categories.push(data.accessoryType);
    } else if (data.isCertificate) {
        data.ecommerce.variant = data.price.value;
        data.ecommerce.categories.push('Сертификат');
        data.ecommerce.categories.push(data.certificateType);
    } else if (isLens) {
        const cover = data.attributes.find(x => x.code === 'Покрытие')?.value;
        const index = data.attributes.find(x => x.code === 'Индекс')?.value;
        data.ecommerce.variant = cover;
        data.ecommerce.categories.push('Линзы');
        data.ecommerce.categories.push(cover);
        data.ecommerce.categories.push(index);
    } else {
        data.ecommerce.variant = data.color || 'no-variants';
        data.ecommerce.categories.push(data.isOptical ? 'Оптика' : 'Солнцезащитные');
        data.ecommerce.categories.push(
            data.attributes.find(_ => _.code === 'Пол')?.value ||
            'Унисекс'
        );
    }
    if (data.price.old) {
        data.ecommerce.discount = data.price.old - data.price.value;
    }
    data.ecommerce.code = data.title;
    data.ecommerce.id = data.id;
    data.ecommerce.price = data.price.value;

    if (data.children) {
        data.children = data.children.map(x => product(x, data));
    }
    const isLocalhost = window.location.host === 'localhost:8000' || window.location.host === '127.0.0.1:8000';
    if (isLocalhost) {
        data.images.forEach(x => {
            Object.keys(x).forEach(key => {
                if (key !== 'gender') {
                    const parts = x[key].split('images/products/')[1].split('/');
                    x[key] = '/media/images/products/' + parts[0] + '/' + parts[1] + '/' + parts[2] + '/' + parts[3] + '/' + parts[parts.length - 1];
                }
            });
        });
    }

    const result = {
        ...data,
        collection: collection?.value || '',
        model: data.title,
        image: (data.images && data.images.length > 0) ? data.images[0].medium : false,
        imageHover: (data.images && data.images.length > 1) ? data.images[1].medium : false,
        video: data.video || null,
        videoPreview: data.video_preview ? {
            small: isLocalhost ? data.video_preview : injectResize(data.video_preview, `${config.media.sizes.images.small}xAUTO`),
            medium: isLocalhost ? data.video_preview : injectResize(data.video_preview, `${config.media.sizes.images.medium}xAUTO`),
            large: isLocalhost ? data.video_preview : injectResize(data.video_preview, `${config.media.sizes.images.large}xAUTO`),
            extra_large: isLocalhost ? data.video_preview : injectResize(data.video_preview, `${config.media.sizes.images.extra_large}xAUTO`),
        } : null,
    };
    return result;
};

export const getProducts = (data) => {
    return {
        count: data.count,
        items: data.results.map(x => product(x)),
    };
};

export const search = (data) => {
    return data.results.map(x => {
        if (x.attributes) {
            return { ...product(x), isProduct: true };
        } else {
            return x;
        }
    });
};

export const lens = (data, parentId = null, depth = 1, maxDepth = null) => {
    const result = {
        id: data.id,
        title: data.name,
        description: data.description,
        image: data.image_url,
        video: data.video_url,
        product: data.product ? product(data.product, null, true) : null,
        children: data.children,
        parentId,
        currentDepth: depth,
    };
    if (maxDepth === null) {
        const getDepth = (children) => {
            if (!children || children.length === 0) {
                return 1;
            }
            return children.reduce((acc, x) => {
                const childDepth = getDepth(x.children);
                return childDepth > acc ? childDepth : acc;
            }, 1) + 1;
        };
        result.maxDepth = getDepth(data.children);
    } else {
        result.maxDepth = maxDepth;
    }
    if (result.children?.length > 0) {
        result.children = result.children.map(x => lens(x, data.id, depth + 1, result.maxDepth));
    }
    if (result.children.every(x => x.product)) {
        result.minPrice = result.children?.reduce((acc, x) => {
            if (x.product && (acc === null || x.product.priceDoubled < acc)) {
                return x.product.priceDoubled;
            }
            return acc;
        }, null);
        result.minPriceFormatted = result.minPrice ? `от ${formatNumerWithSpaces(result.minPrice)} ₽` : null;
    }
    if (result.product) {
        result.product.priceDoubled = result.product.price.value * 2;
        result.product.priceDoubledFormatted = `${formatNumerWithSpaces(result.product.priceDoubled)} ₽`;
    }
    return result;
};

export const getLenses = (data) => {
    return data.results.map(x => lens(x));
};

export default {
    addToCart,
    getBasket,
    getFilters,
    getProducts,
};
