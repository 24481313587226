<template>
    <textarea class="ui-input-base-textarea"
        v-bind:class="{
            _expandable: isExpandable,
            _expanded: isFocused || value.length > 0,
            _focused: isFocused,
            _invalid: isInvalid,
        }"
        v-text="value"
        v-bind:placeholder="placeholder"
        v-bind:autofocus="isAutofocus"
        v-on:focus="focusHandler"
        v-on:blur="blurHandler"
        v-on:keydown.exact.enter.stop
        v-on:keydown.ctrl.enter="submitHandler"
        v-on:keydown.meta.enter="submitHandler"
        v-on="handler"
        v-bind:rows="isExpandable ? 1 : null"
        ref="input"
    />
</template>

<script>
export default {
    name: 'ui-input-base-textarea',
    props: {
        value: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        isAutofocus: {
            type: Boolean,
            default: false,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        onInput: {
            type: Function,
            default: null,
        },
        onFocus: {
            type: Function,
            default: null,
        },
        onBlur: {
            type: Function,
            default: null,
        },
        errors: {
            type: Object,
            default: null,
        },
        isLazy: {
            type: Boolean,
            dafault: false,
        },
        isExpandable: {
            type: Boolean,
            dafault: false,
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    data: () => ({
        isFocused: false,
    }),
    computed: {
        handler() {
            if (this.isLazy) {
                return { change: this.inputHandler };
            }
            return { input: this.inputHandler };
        },
    },
    methods: {
        focusHandler(e) {
            if (this.onFocus !== null) {
                this.onFocus(e);
            }
            this.isFocused = true;
        },
        blurHandler(e) {
            if (this.onBlur !== null) {
                this.onBlur(e);
            }
            this.isFocused = false;
        },
        inputHandler(e) {
            if (this.onInput !== null) {
                this.onInput(e);
            }
            this.$emit('input', e.target.value);
        },
        submitHandler() {
            this.$emit('submit');
        },
    },
    mounted() {
        if (this.isAutofocus) {
            this.$refs.input.focus();
        }
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.ui-input-base-textarea {
    .typography-input();

    display: block;
    width: 100%;
    min-height: 80px;
    padding: 12px 12px 8px;
    border-radius: 0;

    resize: vertical;

    background-color: transparent;

    box-shadow: inset 0 0 0 1px @color-gray-darkest;

    transition: box-shadow @duration-fast @easing-default;
    // &._focused {
    //     box-shadow: inset 0 0 0 1px @color-gray-darkest, 0 0 0 2px @color-gray-darkest;
    // }
    &._invalid,
    &._focused._invalid {
        box-shadow: inset 0 0 0 1px @color-accent-warm;
    }
    &::placeholder {
        color: @color-gray-dark;
    }
    &._expandable {
        min-height: 40px;

        transition: box-shadow @duration-fast @easing-default, min-height @duration-fast @easing-default;
    }
    &._expandable._expanded {
        min-height: 120px;
    }
}
</style>
