import client from '../_client';
import config from '~/config';

export const getCollections = (params = {}) => {
    return client.request({
        url: config.api.collections.list,
        method: 'GET',
        params,
    });
};

export const getPages = (params = { page_size: 100 }) => {
    return client.request({
        url: config.api.collections.pages,
        method: 'GET',
        params,
    });
};

export const getMenu = (params) => {
    return client.request({
        url: config.api.collections.menu,
        method: 'GET',
        params,
    });
};
