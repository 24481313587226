<template>
    <div class="common-header-mobile-menu-prefooter">
        <div class="common-header-mobile-menu-prefooter__socials">
            <a class="common-header-mobile-menu-prefooter__socials-item"
                v-for="(item, index) in contacts.socials"
                v-bind:key="index"
                v-bind:href="item.href"
                >
                <icon v-bind:name="item.icon" />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'common-header-mobile-menu-prefooter',
    computed: {
        contacts() {
            return this.$store.getters['site/contacts'];
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-header-mobile-menu-prefooter {
    &__socials {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 315px;
        padding-left: 9px;
        &-item {
            display: block;

            font-size: 4.8rem;
            text-decoration: none;
            & + & {
                margin-left: 20px;
            }
        }
    }
    @media @media-md-down {
    }
}
</style>
