
import config from '~/config';
import { checkAnalyticsAvailability } from '~/utils/analytics';

const commitEcommerceEvent = (data, code, callback = null) => {
    if (!checkAnalyticsAvailability(code, data)) {
        if (callback) {
            callback();
        }
        return;
    }
    _checkAndPrepareDataLayer();
    window.dataLayer.push(data);
};

const _checkAndPrepareDataLayer = (isResetNeeded = true) => {
    if (window.dataLayer) {
        if (isResetNeeded) {
            window.dataLayer.push({ ecommerce: null });
        }
    } else {
        window.dataLayer = window.dataLayer || [];
    }
};

const transformProductDataToEcommerceData = (productData, isFitting = null) => {
    const result = {
        item_name: productData.name,
        item_id: productData.id,
        price: productData.price,
        item_brand: productData.brand,
        item_category: productData.categories.length > 0 ? productData.categories[0] : 'no-category',
        item_category2: productData.categories.length > 1 ? productData.categories[1] : 'no-category',
        item_variant: productData.variant,
        quantity: productData.quantity || 1,
        item_list_id: productData.listId || '',
        item_list_name: productData.listTitle || '',
    };
    if (productData.discount) {
        result.discount = productData.discount;
    }
    if (isFitting !== null) {
        result.service_type = isFitting ? 'Примерка' : 'Покупка';
    }
    return result;
};

export const addToCart = (data, isFitting = false) => {
    const result = {
        ecommerce: {
            order_type: isFitting ? 'Примерка' : 'Покупка',
            currency: 'RUB',
            items: [ transformProductDataToEcommerceData(data, isFitting) ],
        },
        'event': 'add_to_cart',
    };
    if (data.listId && data.listTitle) {
        result.ecommerce.items[0].item_list_id = data.listId;
        result.ecommerce.items[0].item_list_name = data.listTitle;
    }
    const lastPromotionId = window.sessionStorage.getItem(config.localStorage.prefix + config.localStorage.affixes.lastPromotionId);
    const lastPromotionName = window.sessionStorage.getItem(config.localStorage.prefix + config.localStorage.affixes.lastPromotionName);
    if (lastPromotionId && lastPromotionName) {
        result.ecommerce.items[0].promotion_id = lastPromotionId;
        result.ecommerce.items[0].promotion_name = lastPromotionName;
    }
    commitEcommerceEvent(result, 'addToCart');
};

export const removeFromCart = (data, isFitting = false) => {
    const result = {
        ecommerce: {
            order_type: isFitting ? 'Примерка' : 'Покупка',
            items: [ transformProductDataToEcommerceData(data, isFitting) ],
            currency: 'RUB',
        },
        'event': 'remove_from_cart',
    };
    if (data.listId && data.listTitle) {
        result.ecommerce.items[0].item_list_id = data.listId;
        result.ecommerce.items[0].item_list_name = data.listTitle;
    }
    commitEcommerceEvent(result, 'removeFromCart');
};

export const purchase = (data, callback = null, isFitting = false) => {
    const result = {
        ecommerce: {
            order_type: isFitting ? 'Примерка' : 'Покупка',
            transaction_id: data.transaction,
            affiliation: 'Интернет-магазин',
            value: data.total,
            tax: data.tax,
            shipping: data.shipping,
            currency: 'RUB',
            items: data.products.map(x => transformProductDataToEcommerceData(x, isFitting)),
        },
        'event': 'purchase',
    };
    if (data.coupon) {
        result.ecommerce.coupon = data.coupon;
    }
    if (callback) {
        result.eventCallback = callback;
    }
    commitEcommerceEvent(result, 'purchase', callback);
};

export const checkoutStepPayment = async(data, callback = null, isFitting = false) => {
    const result = {
        ecommerce: {
            order_type: isFitting ? 'Примерка' : 'Покупка',
            payment_type: data.paymentType,
            value: data.total,
            currency: 'RUB',
            items: data.products.map(x => transformProductDataToEcommerceData(x, isFitting)),
        },
        'event': 'add_payment_info',
    };
    if (data.coupon) {
        result.ecommerce.coupon = data.coupon;
    }
    if (callback) {
        result.eventCallback = callback;
    }
    commitEcommerceEvent(result, 'checkoutStepPayment', callback);
};

export const checkoutStepShipping = (data, isFitting = false) => {
    const result = {
        ecommerce: {
            order_type: isFitting ? 'Примерка' : 'Покупка',
            shipping_tier: data.shippingType,
            value: data.total,
            currency: 'RUB',
            items: data.products.map(x => transformProductDataToEcommerceData(x, isFitting)),
        },
        'event': 'add_shipping_info',
    };
    if (data.coupon) {
        result.ecommerce.coupon = data.coupon;
    }
    commitEcommerceEvent(result, 'checkoutStepShipping');
};

export const checkoutStepBeginning = (data, isFitting = false) => {
    const result = {
        ecommerce: {
            order_type: isFitting ? 'Примерка' : 'Покупка',
            value: data.total,
            currency: 'RUB',
            items: data.products.map(x => transformProductDataToEcommerceData(x, isFitting)),
        },
        'event': 'begin_checkout',
    };
    if (data.coupon) {
        result.ecommerce.coupon = data.coupon;
    }
    commitEcommerceEvent(result, 'checkoutStepBeginning');
};

export const checkoutStepCart = (data, isFitting = false) => {
    const result = {
        ecommerce: {
            order_type: isFitting ? 'Примерка' : 'Покупка',
            value: data.total,
            currency: 'RUB',
            items: data.products?.map(x => transformProductDataToEcommerceData(x, isFitting)) || [],
        },
        'event': 'view_cart',
    };
    if (data.coupon) {
        result.ecommerce.coupon = data.coupon;
    }
    commitEcommerceEvent(result, 'checkoutStepCart');
};

export const productDetailsView = (data) => {
    const result = {
        ecommerce: {
            items: [ transformProductDataToEcommerceData(data) ],
            currency: 'RUB',
        },
        'event': 'view_item',
    };
    if (data.listId && data.listTitle) {
        result.ecommerce.items[0].item_list_id = data.listId;
        result.ecommerce.items[0].item_list_name = data.listTitle;
    }
    const lastPromotionId = window.sessionStorage.getItem(config.localStorage.prefix + config.localStorage.affixes.lastPromotionId);
    const lastPromotionName = window.sessionStorage.getItem(config.localStorage.prefix + config.localStorage.affixes.lastPromotionName);
    if (lastPromotionId && lastPromotionName) {
        result.ecommerce.items[0].promotion_id = lastPromotionId;
        result.ecommerce.items[0].promotion_name = lastPromotionName;
    }
    commitEcommerceEvent(result, 'productDetailsView');
};

export const productClick = (data) => {
    const result = {
        ecommerce: {
            items: [{
                ...transformProductDataToEcommerceData(data.product),
                index: data.index,
            }],
            currency: 'RUB',
        },
        'event': 'select_item',
    };
    if (data.listId && data.listTitle) {
        result.ecommerce.items[0].item_list_id = data.listId;
        result.ecommerce.items[0].item_list_name = data.listTitle;
    }
    commitEcommerceEvent(result, 'productClick');
};

export const productImpression = (data) => {
    const result = {
        ecommerce: {
            items: [{
                ...transformProductDataToEcommerceData(data.product),
                index: data.index,
            }],
            currency: 'RUB',
        },
        'event': 'view_item_list',
    };
    if (data.listId && data.listTitle) {
        result.ecommerce.items[0].item_list_id = data.listId;
        result.ecommerce.items[0].item_list_name = data.listTitle;
    }
    if (!checkAnalyticsAvailability('productImpression', result)) {
        return;
    }
    let lastDataLayerEntity = null;
    if (window.dataLayer) {
        lastDataLayerEntity = window.dataLayer[window.dataLayer.length - 1];
    }
    let isResetNeeded = true;
    if (lastDataLayerEntity) {
        isResetNeeded = lastDataLayerEntity['gtm-ee-event-action'] !== 'Product Impressions' ||
            lastDataLayerEntity.ecommerce.items[0].item_list_id !== result.ecommerce.items[0].item_list_id;
    }
    _checkAndPrepareDataLayer(isResetNeeded);
    window.dataLayer.push(result);
};

export const promotionClick = (data) => {
    const result = {
        ecommerce: {
            items: [{
                promotion_id: data.promotionId,
                promotion_name: data.promotionName,
                creative_name: data.creativeName,
                creative_slot: data.creativeSlot,
            }],
        },
        'event': 'select_promotion',
    };
    window.sessionStorage.setItem(config.localStorage.prefix + config.localStorage.affixes.lastPromotionId, data.promotionId);
    window.sessionStorage.setItem(config.localStorage.prefix + config.localStorage.affixes.lastPromotionName, data.promotionName);
    commitEcommerceEvent(result, 'promotionClick');
};

export const promotionImpression = (data) => {
    const result = {
        ecommerce: {
            items: [{
                promotion_id: data.promotionId,
                promotion_name: data.promotionName,
                creative_name: data.creativeName,
                creative_slot: data.creativeSlot,
            }],
        },
        'event': 'view_promotion',
    };
    window.sessionStorage.removeItem(config.localStorage.prefix + config.localStorage.affixes.lastPromotionId);
    window.sessionStorage.removeItem(config.localStorage.prefix + config.localStorage.affixes.lastPromotionName);
    commitEcommerceEvent(result, 'promotionImpression');
};

export default {
    addToCart,
    removeFromCart,
    purchase,
    checkoutStepPayment,
    checkoutStepShipping,
    checkoutStepBeginning,
    checkoutStepCart,
    productDetailsView,
    productClick,
    productImpression,
    promotionClick,
    promotionImpression,
};
