<template>
    <plugin-common-catalog
        hide-filters
        >
    </plugin-common-catalog>
</template>

<script>

export default {
    name: 'plugin-collections-selection',
    props: {
        query: {
            type: String,
        },
    },
    mounted() {
        this.query.split('&').forEach(filter => {
            let [ code, ids ] = filter.split('=');
            ids = ids.split(',').map(x => {
                const parsed = parseInt(x, 10);
                if (isNaN(parsed)) {
                    return x;
                }
                return parsed;
            });
            this.$store.commit('filters/setFilter', { setCode: code, filterIds: ids });
        });
    },
};
</script>

<style scoped lang="less">
@import '~theme';

</style>
