<template>
    <section class="plugin-home-stores">
        <div class="plugin-home-stores__title">Магазины:</div>
        <div class="plugin-home-stores__list">
            <div class="plugin-home-stores__list-item"
                v-for="(store, i) in stores"
                v-bind:key="i"
                v-bind:class="{ _map: activeMapIndexes.indexOf(i) > -1 }"
                >
                <div class="plugin-home-stores__list-item-container">
                    <div class="plugin-home-stores__list-item-container-map">
                        <iframe
                            frameborder="0"
                            style="border: 0"
                            allowfullscreen
                            v-bind:src="store.mapSrc"
                        />
                    </div>
                    <div class="plugin-home-stores__list-item-container-cover"
                        v-bind:style="{ backgroundImage: `url('${store.image ? store.image : 'https://via.placeholder.com/1500x1500/dfdfdf/ccc?text=No+Store+Image'}')` }"
                    />
                </div>
                <div class="plugin-home-stores__list-item-info">
                    <div class="plugin-home-stores__list-item-info-area" v-text="store.area" />
                    <div class="plugin-home-stores__list-item-info-main">
                        <a v-bind:href="store.href"
                            v-text="store.title"
                        />
                        <br />
                        <span v-text="store.address" />
                        <br />
                        <span v-text="store.worktime" />
                        <br />
                        <br />
                        <a v-if="store.contacts"
                            v-bind:href="`tel:${store.contactsHref}`"
                            v-text="store.contacts"
                        />
                    </div>
                    <div class="plugin-home-stores__list-item-info-switcher">
                        <span
                            class="plugin-home-stores__list-item-info-switcher-text"
                            v-text="activeMapIndexes.indexOf(i) > -1 ? 'Фото' : 'Карта'"
                            v-on:click="toggleMap(i)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import config from '~/config';

export default {
    name: 'plugin-home-stores',
    props: {
        storesData: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        activeMapIndexes: [],
    }),
    computed: {
        stores() {
            return this.storesData.map(x => ({
                id: x.id,
                area: x.area.name,
                title: x.name,
                address: x.address,
                worktime: x.work_time,
                mapSrc: x.map_src,
                href: `${config.urls.shops}/${x.id}`,
                contacts: x.contacts,
                contactsHref: x.contacts ? x.contacts.replace(/[- ()]/g, '') : false,
                image: x.image,
            }));
        },
    },
    methods: {
        toggleMap(i) {
            const index = this.activeMapIndexes.indexOf(i);
            if (index > -1) {
                this.activeMapIndexes.splice(index, 1);
            } else {
                this.activeMapIndexes.push(i);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.plugin-home-stores {
    .container();

    position: relative;

    width: 100%;

    font-size: 1.4rem;
    line-height: 2rem;
    &__title {
        display: none;
    }
    &__list {
        position: relative;

        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        padding-right: 50%;
        &-item {
            flex: 0 0 auto;
            width: 50%;
            padding: 20px 20px;

            transition: background-color @duration-fastest @easing-default;
            &:first-child & {
                &-container {
                    z-index: 9;

                    opacity: 1;
                }
            }
            &:hover {
                background-color: @color-gray-lighter;
            }
            &:hover & {
                &-container {
                    z-index: 10;

                    opacity: 1;
                }
            }
            &-container {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;

                width: 50%;
                height: 100%;

                opacity: 0;
                &-map {
                    display: none;
                    width: 100%;
                    height: 100%;

                    background-color: @color-gray-lighter;
                    & > iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
                &-cover {
                    width: 100%;
                    height: 100%;

                    background-color: @color-gray-lightest;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
            &-info {
                &-area {
                    margin-bottom: 18px;

                    text-transform: uppercase;
                }
                &-main {
                }
                &-switcher {
                    margin-top: 20px;
                    &-text {
                        cursor: pointer;
                    }
                }
            }
            &._map & {
                &-container {
                    &-map {
                        display: block;
                    }
                    &-cover {
                        display: none;
                    }
                }
            }
        }
    }
    @media @media-md-down {
        &__list {
            padding-right: 33%;
            &-item {
                &-container {
                    width: 33%;
                }
            }
        }
    }
    @media @media-sm-down {
        &__title {
            .container-paddings();

            display: block;
            padding-top: 10px;
            padding-bottom: 5px;

            text-transform: uppercase;
        }
        &__list {
            padding: 0;
            &-item {
                position: relative;

                width: 100%;
                padding-top: 240px;

                &:hover {
                    background-color: initial;
                }
                &-container {
                    width: 100%;
                    height: 220px;

                    opacity: 1;
                }
            }
        }
    }
}
</style>
