<template>
    <div class="plugin-infopage-iconed-list">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'plugin-infopage-iconed-list',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-iconed-list {
}
</style>
