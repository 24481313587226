import { render, staticRenderFns } from "./render-giftcard.vue?vue&type=template&id=005b572c&scoped=true&"
import script from "./render-giftcard.vue?vue&type=script&lang=js&"
export * from "./render-giftcard.vue?vue&type=script&lang=js&"
import style0 from "./render-giftcard.vue?vue&type=style&index=0&id=005b572c&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "005b572c",
  null
  
)

export default component.exports