<template>
    <form class="ui-form"
        v-on:keydown.enter.prevent="submitHandler"
        v-on:submit.prevent="submitHandler"
        >
        <div class="ui-form__fields">
            <div class="ui-form__fields-item"
                v-for="(field, key) in model"
                v-bind:key="key"
                v-bind:class="{
                    _half: field.isHalf,
                    _third: field.isThird,
                    _quarter: field.isQuarter,
                }"
                >
                <ui-form-field
                    v-bind:model="field"
                    v-bind:validation="getFieldValidation(key)"
                    v-bind:value="field.value"
                    v-on:update="val => modelUpdate(val, key)"
                />
            </div>
        </div>
    </form>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'ui-form',
    props: {
        model: {
            type: Object,
            default: () => ({}),
        },
        validation: {
            type: Object,
            default: null,
        },
        validationPath: {
            type: String,
            default: 'fields',
        },
        submitHandler: {
            type: Function,
            default: () => ({}),
        },
    },
    model: {
        prop: 'model',
        event: 'update',
    },
    methods: {
        modelUpdate(val, key) {
            const newModel = { ...this.model };
            newModel[key].value = val;
            this.$emit('update', newModel);
        },
        getHandler(field) {
            if (field.type === 'select' || field.type === 'checkbox') {
                return 'change';
            } else {
                return 'input';
            }
        },
        getFieldValidation(key) {
            if (!this.validation) {
                return null;
            }
            return utils.common.getObjectValueByPath(this.validation, this.validationPath + '.' + key + '.value');
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';
.ui-form {
    &__fields {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -15px;
        &-item {
            flex: 0 0 auto;
            width: 100%;
            padding: 0 15px;
            &._half {
                width: 50%;
            }
            &._third {
                width: 33.33%
            }
            &._quarter {
                width: 25%
            }
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}
</style>
