<template>
    <div class="common-placeholder-product-slider" />
</template>

<script>
export default {
    name: 'common-placeholder-product-slider',
};
</script>

<style scoped lang="less">
@import "~theme";

.common-placeholder-product-slider {
    display: block;
    width: 100%;
    min-height: 300px;
    padding-top: 125%;

    pointer-events: none;
}
</style>
