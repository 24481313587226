<template>
    <div class="plugin-infopage-slider-item-image">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'plugin-infopage-slider-item-image',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-slider-item-image {
    ._type_square & {
        margin-bottom: 16px;
    }
    @media @media-md-down {
        ._type_square & {
            margin-bottom: 20px;
        }
    }
}
</style>
