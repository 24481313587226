<template>
    <div class="page-dashboard-info-form-info">
        <div class="page-dashboard-info-form-info__loader"
            v-if="isLoading"
            >
            <div class="page-dashboard-info-form-info__loader-wrapper">
                <ui-loader />
            </div>
        </div>
        <div class="page-dashboard-info-form-info__title"
            v-text="'Номер телефона'"
        />
        <div class="page-dashboard-info-form-info__fields">
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
            />
        </div>
        <div class="page-dashboard-info-form-info__error"
            v-if="error !== null"
            v-text="error"
        />
        <div class="page-dashboard-info-form-info__button">
            <ui-button
                v-on:click="submitHandler"
                >
                Сохранить изменения
            </ui-button>
        </div>
    </div>
</template>

<script>
import forms from '~/forms';

export default {
    name: 'page-dashboard-info-form-info',
    data: () => ({
        fields: {
            phone: { ...forms.fields.phone },
        },
        code: null,
    }),
    validations: {
        fields: {
            phone: { ...forms.validations.phone },
        },
    },
    computed: {
        isLoading() {
            return !this.user || this.$store.getters['user/state'].infoUpdateIsLoading;
        },
        error() {
            return this.$store.state.user.infoUpdateError;
        },
        user() {
            return this.$store.getters['user/user'];
        },
    },
    methods: {
        updateFormData(newData) {
            this.fields = {
                ...this.fields,
                ...newData,
            };
        },
        showSmsPopup() {
            this.$store.commit('modals/push', {
                name: 'sms-verification',
                props: {
                    phone: this.fields.phone.value,
                    callback: code => {
                        this.code = code;
                        this.submitHandler();
                    },
                },
            });
        },
        submitHandler() {
            if (this.fields.phone.value === this.user.phone) {
                return;
            }
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (!this.code) {
                this.showSmsPopup();
                return;
            }
            const result = {
                phone: this.fields.phone.value,
                value: this.fields.phone.value,
                code: this.code,
            };
            this.$store.dispatch('user/infoUpdate', result);
        },
    },
    watch: {
        user: {
            immediate: true,
            handler(newVal) {
                if (newVal !== null && newVal.phone) {
                    this.fields.phone.value = newVal.phone;
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-dashboard-info-form-info {
    position: relative;

    &__loader {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        width: 100%;
        height: 100%;

        background: @color-gray-lightest;
        &-wrapper {
            width: 50px;
            margin: auto;
        }
    }
    &__title {
        margin-bottom: 20px;

        text-transform: uppercase;
    }
    &__error {
        margin-top: 20px;

        color: @color-accent-warm;
    }
    &__button {
        margin-top: 20px;
        height: 40px;
    }
}
</style>
