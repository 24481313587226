<template>
    <div class="page-dashboard-info-form">
        <div class="page-dashboard-info-form__item">
            <page-dashboard-info-form-data />
        </div>
        <div class="page-dashboard-info-form__item">
            <page-dashboard-info-form-info />
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-dashboard-info-user',
};
</script>

<style scoped lang="less">
@import '~theme';

.page-dashboard-info-form {
    padding: 0 20px;
    &__item {
        & ~ & {
            margin-top: 40px;
        }
    }
}
</style>
