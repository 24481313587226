<template>
    <div class="page-subscription">
        <transition name="fade">
            <div class="page-subscription__loader"
                v-if="isLoading"
                >
                <div class="page-subscription__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <h1 class="page-subscription__title"
            v-text="'подпишитесь на рассылку пай'"
        />
        <div class="page-subscription__container">
            <h2 class="page-subscription__subtitle"
                v-text="'Узнавайте о скидках первыми! Только самые важные новости + гид по выбору оправ и линз в подарок'"
            />
            <div class="page-subscription__form">
                <ui-form
                    v-bind:model="fields"
                    v-bind:validation="$v"
                    v-on:update="updateHandler"
                    v-bind:submit-handler="submitHandler"
                />
            </div>
            <div class="page-subscription__button">
                <ui-button
                    v-on:click="submitHandler"
                    v-bind:class="{ _disabled: isLoading }"
                    v-bind:disabled="isLoading"
                    is-uppercase
                    type="submit"
                    >
                    подписаться
                </ui-button>
            </div>
            <div class="page-subscription__disclaimer">
                Подписываясь, вы соглашаетесь с условиями <a v-bind:href="url" target="_blank">политики конфиденциальности</a>.
            </div>
            <div class="page-subscription__chubrik">
                <icon name="chubrik" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import forms from '~/forms';

export default {
    name: 'page-subscription',
    data: () => ({
        fields: {
            email: { ...forms.fields.email, label: 'Ваш Email' },
        },
        url: config.urls.policy,
    }),
    validations: {
        fields: {
            email: { ...forms.validations.email },
        },
    },
    computed: {
        ...mapState('subscription', [
            'subscribeResult',
            'subscribeIsLoading',
        ]),
        isLoading() {
            return this.subscribeIsLoading;
        },
    },
    methods: {
        updateHandler(newData) {
            this.fields = newData;
        },
        submitHandler() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.dispatch('subscription/subscribe', {
                onSuccess: () => {
                    this.$store.commit('modals/push', {
                        name: 'common',
                        props: {
                            title: 'Успех!',
                            text: 'Спасибо! Вы успешно подписаны ;-)',
                            buttons: [
                                { text: 'На главную', callback: () => { window.location.href = '/'; } },
                            ],
                        },
                    });
                },
                onError: () => {
                    this.$store.commit('modals/push', {
                        name: 'common',
                        props: {
                            title: 'Ошибка!',
                            text: `Произошла ошибка! :-(
                                Попробуйте еще раз`,
                        },
                    });
                },
                email: this.fields.email.value.toLowerCase(),
            });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-subscription {
    .typography-body-md();
    .mixin-full-height();

    position: relative;

    padding: 40px 40px 180px;

    background: @color-gray-main;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: @color-gray-main;
        &-container {
            width: 100px;
        }
    }
    &__title {
        .typography-display-md();

        max-width: 600px;
        margin: 0 auto 8px;

        font-weight: normal;
        text-transform: uppercase;
        text-align: center;
    }
    &__container {
        max-width: 500px;
        margin: 0 auto;
    }
    &__subtitle {
        .typography-body-md();

        margin: 0 auto 99px;

        font-weight: normal;
        text-align: center;
    }
    &__form {
        margin-bottom: 48px;
    }
    &__button {
        height: 48px;
        margin-bottom: 16px;
    }
    &__chubrik {
        position: absolute;
        bottom: 0;
        left: 40px;
        z-index: 2;

        font-size: 13.6rem;
    }
    @media @media-sm-down {
        &__chubrik {
            left: 20px;

            font-size: 11.6rem;
        }
    }
}
</style>
