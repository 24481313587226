<template>
    <div class="page-checkout-step-shipping-city">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-bind:submit-handler="submitHandler"
            v-on:update="updateFormData"
        />
        <div class="page-checkout-step-shipping-city__warning" v-if="mode === 'fitting' && isCustomCity">
            Отправляем во все крупные города России, кроме Калининградской и Иркутской областей, а также Дальнего Востока.
            <br />
            <br />
            Напишите в <a href="#chatra_trigger">чат поддержки</a>, если сомневаетесь входит ли ваш город в зону доставки.
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-checkout-step-shipping-city',
    inject: [ 'pageCheckoutEventBus', 'mode' ],
    data: () => ({
        innerFields: {
            city: { ...forms.fields.city, noAsterisk: true },
            customCity: { ...forms.fields.customCity, noAsterisk: true },
        },
    }),
    validations() {
        const result = {
            fields: {},
        };
        if (!this.isCustomCountry && this.cities.length > 0) {
            result.fields.city = { ...forms.validations.defaultRequired };
        }
        if (this.isCustomCity) {
            result.fields.customCity = { ...forms.validations.defaultRequired };
        }
        return result;
    },
    computed: {
        ...mapState('checkout', [ 'isShippingDirty' ]),
        ...mapState('areas', [ 'getAreasResult', 'currentId' ]),
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        cities() {
            if (!this.checkoutData.country || this.isCustomCountry) {
                return [];
            }
            return this.checkoutData.country.children;
        },
        isCustomCountry() {
            return this.$store.getters['checkout/isCustomCountry'];
        },
        isCustomCity() {
            return this.$store.getters['checkout/isCustomCity'];
        },
        isDadataAvailable() {
            return this.$store.getters['checkout/isDadataAvailable'];
        },
        fields() {
            const result = {};

            if (!this.isCustomCountry && this.cities.length > 0) {
                result.city = { ...this.innerFields.city };
                const city = this.checkoutData.city && this.cities.length > 0 && this.cities.find(x => x.value === this.checkoutData.city.value);
                result.city.value = city?.value || '';
            }

            if (this.isCustomCity) {
                result.customCity = { ...this.innerFields.customCity };
                if (this.isDadataAvailable) {
                    result.customCity = {
                        ...result.customCity,
                        ...forms.fields.customDadataCity,
                        dadataParams: {
                            locations: [{
                                country: this.checkoutData.country.name,
                                city_type_full: 'город',
                            }],
                            restrict_value: true,
                            from_bound: { value: 'city' },
                            to_bound: { value: 'city' },
                        },
                    };
                }
                result.customCity.value = this.checkoutData.customCity || '';
            }

            return result;
        },
    },
    methods: {
        setData(newData) {
            const result = {
                // cityFiasId: null,
                cityCdekCode: null,
                region: null,
            };
            const validCity = this.cities.find(x => x.value === newData.city?.value);

            if (validCity) {
                result.city = validCity;
                if (validCity.city) {
                    result.cityCdekCode = validCity.cdek_code;
                }
            }
            if (newData.customCity) {
                if (newData.customCity.value) {
                    result.customCity = newData.customCity.value.value;
                    result.region = newData.customCity.value.suggestRegion;
                    result.cityFiasId = newData.customCity.value.fiasId;
                } else {
                    result.customCity = newData.customCity.value;
                    result.cityFiasId = null;
                }
            } else {
                result.cityFiasId = null;
                result.customCity = null;
            }
            this.$store.commit('checkout/updateData', result);
        },
        updateFormData(newData) {
            this.setData(newData);
        },
        submitHandler() {
            this.pageCheckoutEventBus.$emit('shipping-submit');
        },
    },
    beforeMount() {
        if (!this.checkoutData.cityCdekCode || !this.checkoutData.cityFiasId) {
            this.setData(this.fields);
        }
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$emit('shipping-validation', { city: true });
    },
    watch: {
        cities: {
            immediate: true,
            handler(newVal) {
                this.innerFields.city.options = newVal;
            },
        },
        '$v.$invalid': {
            immediate: true,
            handler(newVal) {
                this.pageCheckoutEventBus.$emit('shipping-validation', { city: !newVal });
                if (this.isShippingDirty) {
                    this.$v.$touch();
                }
            },
        },
        isShippingDirty: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                } else {
                    this.$v.$reset();
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-city {
    &__warning {
        margin-top: 16px;
    }
}
</style>
