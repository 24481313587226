<template>
    <div class="plugin-home-features">
        <div class="plugin-home-features__list">
            <div class="plugin-home-features__list-item"
                v-for="(product, i) in products"
                v-bind:key="i"
                >
                <common-card-product
                    v-bind:product="product"
                    v-bind:ecommerce-list-data="{
                        listId: 'special_' + (listId || 'plugin_home_features'),
                        listTitle: 'Special ' + (listTitle || 'Выборка товаров'),
                        index: i,
                    }"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { product as productTransfrom } from '~/network/mainClient/shop/transforms/responses';

export default {
    name: 'plugin-home-features',
    props: {
        productsData: {
            type: Array,
            default: () => [],
        },
        listId: {
            type: String,
            default: null,
        },
        listTitle: {
            type: String,
            default: null,
        },
    },
    computed: {
        products() {
            return this.productsData.map(x => productTransfrom(x));
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";

.plugin-home-features {
    .typography-body-md();
    .container();

    position: relative;

    width: 100%;
    &__list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;
        &-item {
            position: static;
            z-index: 1;

            flex: 0 0 auto;
            width: 25%;

            box-shadow: -1px 0 0 0 @color-gray-lightest;
            &:nth-child(4) ~ & {
                box-shadow: -1px 0 0 0 @color-gray-lightest, 0 -1px 0 0 @color-gray-lightest;
            }
        }
    }
    @media @media-sm-down {
        &__list {
            flex-wrap: wrap;
            &-item {
                width: 50%;
                margin-bottom: 1px;
            }
        }
    }
    @media @media-xs-down {
        &__list {
            &-item {
                width: 100%;
            }
        }
    }
}
</style>
