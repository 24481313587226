import config from '~/config';
import { mainClient } from '~/network';

const state = {
    getMenuResult: null,
    getMenuIsLoading: false,
    getMenuError: null,

    currentCollection: null,
};

const getters = {
    currentCollectionGender(state) {
        if (!state.currentCollection || !state.currentCollection.filterItems) {
            return null;
        }
        const hasMaleFilter = state.currentCollection.filterItems.indexOf(config.filters.gender.values.male.id) > -1;
        const hasFemaleFilter = state.currentCollection.filterItems.indexOf(config.filters.gender.values.female.id) > -1;
        return (hasMaleFilter && hasFemaleFilter) || (!hasMaleFilter && !hasFemaleFilter)
            ? null
            : hasMaleFilter
                ? config.filters.gender.values.male.code
                : config.filters.gender.values.female.code;
    },
    currentCollectionOpticsType(state) {
        if (!state.currentCollection || !state.currentCollection.filterItems) {
            return 'all';
        }
        const hasOpticsFilter = state.currentCollection.filterItems.indexOf(config.filters.opticsType.values.optics.id) > -1;
        const hasSunFilter = state.currentCollection.filterItems.indexOf(config.filters.opticsType.values.sun.id) > -1;
        return (hasOpticsFilter && hasSunFilter) || (!hasOpticsFilter && !hasSunFilter)
            ? 'all'
            : hasOpticsFilter
                ? config.filters.opticsType.values.optics.code
                : config.filters.opticsType.values.sun.code;
    },
};

const mutations = {
};

const actions = {
    async getMenu({ state }, payload) {
        if (state.getMenuIsLoading) {
            return;
        }
        state.getMenuIsLoading = true;
        const { data, error } = await mainClient.collections.getMenu(payload);
        if (error) {
            state.getMenuResult = null;
            state.getMenuError = error;
        } else if (data) {
            state.getMenuResult = data;
            state.getMenuError = null;
        }

        state.getMenuIsLoading = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
