<template>
    <div class="page-checkout-step-shipping-address-street-standard">
        <ui-form
            v-bind:model="fields"
            v-bind:validation="$v"
            v-on:update="updateFormData"
            v-bind:submit-handler="submitHandler"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-checkout-step-shipping-address-street-standard',
    inject: [ 'pageCheckoutEventBus' ],
    data: () => ({
        innerFields: {
            street: {
                ...forms.fields.street,
                noAsterisk: true,
            },
        },
    }),
    validations() {
        const result = {
            fields: {
                street: { ...forms.validations.defaultRequired },
            },
        };
        return result;
    },
    computed: {
        ...mapState('checkout', [ 'isShippingDirty' ]),
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        fields() {
            const result = { ...this.innerFields };
            result.street.value = this.checkoutData.streetStandard;
            return result;
        },
    },
    methods: {
        updateFormData(newData) {
            this.$store.commit('checkout/updateData', {
                streetStandard: newData.street.value,
            });
        },
        submitHandler() {
            this.pageCheckoutEventBus.$emit('shipping-submit');
        },
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$emit('shipping-validation', { streetStandard: true });
    },
    watch: {
        '$v.$invalid': {
            immediate: true,
            handler(newVal) {
                this.pageCheckoutEventBus.$emit('shipping-validation', { streetStandard: !newVal });
            },
        },
        isShippingDirty: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                } else {
                    this.$v.$reset();
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-address-street-standard {
}
</style>
