<template>
    <div class="plugin-infopage-expandable-list">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'plugin-infopage-expandable-list',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-expandable-list {
    border-bottom: 1px solid @color-gray-darkest;
    &:not(:first-child) {
        margin-top: 56px;
    }
    @media @media-md-down {
        &:not(:first-child) {
            margin-top: 48px;
        }
    }
}
</style>
