<template>
    <div class="page-checkout-step-shipping-contacts-messenger">
        <ui-form
            v-bind:model="fields"
            v-bind:submit-handler="submitHandler"
            v-on:update="updateFormData"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';

export default {
    name: 'page-checkout-step-shipping-contacts-messenger',
    inject: [ 'pageCheckoutEventBus' ],
    data: () => ({
        innerFields: {
            communication: {
                ...forms.fields.communication,
                noAsterisk: true,
            },
        },
    }),
    computed: {
        ...mapState('communication', [ 'getCommunicationListResult' ]),
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        fields() {
            const result = { ...this.innerFields };
            result.communication.value = this.checkoutData.communication || '';
            return result;
        },
    },
    methods: {
        updateFormData(newData) {
            this.$store.commit('checkout/updateData', {
                communication: newData.communication.value,
            });
        },
        submitHandler() {
            this.pageCheckoutEventBus.$emit('shipping-submit');
        },
    },
    beforeMount() {
        if (this.checkoutData.communication) {
            this.innerFields.communication.value = this.checkoutData.communication;
        }
    },
    watch: {
        getCommunicationListResult: {
            handler(newVal) {
                if (newVal) {
                    this.innerFields = {
                        ...this.innerFields,
                        communication: {
                            ...this.innerFields.communication,
                            options: newVal.items.map(x => ({
                                value: x.userLabel,
                                title: x.userLabel,
                            })),
                        },
                    };
                    const setDefaultValue = () => {
                        this.$store.commit('checkout/updateData', {
                            communication: newVal.items.find(x => x.isDefault)?.userLabel || newVal.items.length > 0 ? newVal.items[0].userLabel : null,
                        });
                    };
                    if (this.checkoutData.communication) {
                        if (!newVal.items.find(x => x.userLabel === this.checkoutData.communication)) {
                            setDefaultValue();
                        }
                    } else {
                        setDefaultValue();
                    }
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-contacts-messenger {
}
</style>
