<template>
    <div class="plugin-infopage-slider"
        v-bind:class="{ _disabled: !slider }"
        >
        <plugin-infopage-title
            v-if="title"
            v-html="title"
            title-type="h2"
        />
        <div class="plugin-infopage-slider__container swiper-container"
            ref="container"
            >
            <div class="plugin-infopage-slider__wrapper swiper-wrapper"
                ref="wrapper"
                >
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';

export default {
    name: 'plugin-infopage-slider',
    props: {
        title: String,
        type: String,
    },
    data: () => ({
        slider: null,
    }),
    provide() {
        return {
            isSliderChild: true,
        };
    },
    methods: {
        initSlider() {
            if (this.slider !== null) {
                this.slider.update();
                return;
            }
            this.slider = new Swiper(this.$refs.container, {
                slidesPerView: 'auto',
                spaceBetween: 12,
                speed: 500,
                preloadImages: false,
                // lazy: {
                //     loadPrevNext: true,
                //     loadPrevNextAmount: 3,
                // },
                mousewheel: {
                    forceToAxis: true,
                    invert: true,
                },
                watchOverflow: true,
                watchSlidesVisibility: true,
            });
        },
        destroySlider() {
            if (this.slider !== null) {
                this.slider.destroy();
                this.slider = null;
            }
        },
        checkSize() {
            if (window.innerWidth >= 1024) {
                this.destroySlider();
            } else {
                this.initSlider();
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.checkSize);
        this.checkSize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkSize);
        this.destroySlider();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-slider {
    width: 100%;
    &__container {
        cursor: grab;
        .plugin-infopage-slider._disabled & {
            cursor: auto;
        }
        .plugin-infopage-container._padded & {
            margin: 0 -32px;
            padding: 0 32px;
        }
        &:active {
            cursor: grabbing;
        }
    }
    &__item {
        width: 450px;
        height: auto !important;
        overflow: hidden;

        .plugin-infopage-slider._disabled & {
            flex: 1 1 auto;
            width: 100%;
        }
        .plugin-infopage-slider._disabled & + & {
            margin-left: 12px;
        }
    }
    @media @media-md-down {
        &__container {
            .plugin-infopage-container._padded & {
                margin: 0 -20px;
                padding: 0 20px;
            }
        }
        &__item {
            width: 277px;
            max-width: calc(100vw - 66px);
        }
    }
}
</style>
