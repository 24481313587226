import { mainClient } from '~/network';

const state = {
    getOrdersResult: null,
    getOrdersIsLoading: false,
    getOrdersError: null,

    getOrdersItemResult: null,
    getOrdersItemIsLoading: false,
    getOrdersItemError: null,

    getOrderGiftCardResult: null,
    getOrderGiftCardIsLoading: false,
    getOrderGiftCardError: null,
};

const getters = {};

const actions = {
    async getOrders({ state }, payload) {
        if (state.getOrdersIsLoading) {
            return;
        }
        state.getOrdersIsLoading = true;

        const { data, error, status } = await mainClient.shop.getOrders(payload);
        if (error !== null) {
            console.error(error, status);
            state.getOrdersError = error;
            state.getOrdersResult = null;
        } else {
            state.getOrdersError = null;
            state.getOrdersResult = data;
        }

        state.getOrdersIsLoading = false;
    },
    async getOrdersItem({ state }, payload) {
        if (state.getOrdersItemIsLoading) {
            return;
        }
        state.getOrdersItemIsLoading = true;

        const { data, error, status } = await mainClient.shop.getOrdersItem(payload);
        if (error !== null) {
            console.error(error, status);
            state.getOrdersItemError = error;
            state.getOrdersItemResult = null;
        } else {
            state.getOrdersItemError = null;
            state.getOrdersItemResult = data;
        }

        state.getOrdersItemIsLoading = false;
    },
    async getOrderGiftCard({ state }, payload) {
        if (state.getOrderGiftCardIsLoading) {
            return;
        }
        state.getOrderGiftCardIsLoading = true;

        const { data, error, status } = await mainClient.shop.getOrderGiftCard(payload);
        if (error !== null) {
            console.error(error, status);
            state.getOrderGiftCardError = error;
            state.getOrderGiftCardResult = null;
        } else {
            state.getOrderGiftCardError = null;
            state.getOrderGiftCardResult = data;
        }

        state.getOrderGiftCardIsLoading = false;
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
