const state = {
    header: {},
    footer: {},
};

const getters = {
};

const actions = {
};

const mutations = {
    generateMenu(state, menuData) {
        state.header = {
            main: menuData.find(item => item.menu_id === 'HEADER_MENU'),
            additional: menuData.find(item => item.menu_id === 'MENU_ADDITIONAL'),
            mobile: menuData.find(item => item.menu_id === 'MOBILE_MENU'),
            mobileFooter: menuData.find(item => item.menu_id === 'MOBILE_MENU_FOOTER'),
        };
        state.footer = {
            main: menuData.find(item => item.menu_id === 'FOOTER_MENU'),
            secondary: menuData.find(item => item.menu_id === 'FOOTER_MENU_1'),
            tertiary: menuData.find(item => item.menu_id === 'FOOTER_MENU_2'),
        };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
