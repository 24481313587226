<template>
    <div class="sideblock-prescription">
        <transition name="fade">
            <div class="sideblock-prescription__loader"
                v-if="uploadPrescriptionIsLoading || getCartIsLoading"
                >
                <div class="sideblock-prescription__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <form class="sideblock-prescription__form">
            <div class="sideblock-prescription__form-description">
                Если у вас есть рецепт на корригирующие линзы, прикрепите его фотографию. Рецепт также можно будет отправить позже.
            </div>
            <div class="sideblock-prescription__form-error"
                v-if="error"
                v-text="error"
            />
            <label class="sideblock-prescription__form-label">
                <input class="sideblock-prescription__form-label-input"
                    v-on:change="updatePrescriptionFile"
                    ref="fileInput"
                    type="file"
                    accept="image/*"
                />
                <ui-button
                    v-if="!getCartResult.prescription"
                    v-on:click="triggetFileInput"
                    is-bordered
                    is-uppercase
                    >
                    Прикрепить фото
                </ui-button>
                <ui-button
                    v-else
                    v-on:click.stop="removePrescription"
                    is-uppercase
                    >
                    Удалить фото
                </ui-button>
            </label>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'sideblock-prescription',
    props: {
        basketId: {
            type: [ Number, String ],
        },
    },
    data: () => ({
        prescriptionFile: null,
    }),
    computed: {
        ...mapState('cart', [
            'getCartResult',
            'getCartIsLoading',
            'getCartError',
        ]),
        ...mapState('lenses', [
            'uploadPrescriptionResult',
            'uploadPrescriptionIsLoading',
            'uploadPrescriptionError',
        ]),
        cartPrescription() {
            return !!this.getCartResult?.prescription;
        },
        error() {
            if (!this.uploadPrescriptionError) {
                return null;
            }
            return utils.network.parseError(this.uploadPrescriptionError, true);
        },
    },
    methods: {
        removePrescriptionFile() {
            this.prescriptionFile = null;
            const fileInput = this.$refs.fileInput;
            if (!fileInput) {
                return;
            }
            fileInput.value = '';
        },
        removePrescription() {
            this.removePrescriptionFile();
            this.setPrescription();
        },
        updatePrescriptionFile(e) {
            const file = e.target.files[0];
            if (!file) {
                this.prescriptionFile = null;
            } else {
                this.prescriptionFile = file;
            }
            this.setPrescription();
        },
        triggetFileInput() {
            const fileInput = this.$refs.fileInput;
            if (!fileInput) {
                return;
            }
            fileInput.click();
        },
        setPrescription() {
            const formData = new FormData();
            if (this.prescriptionFile) {
                formData.append('image', this.prescriptionFile);
            } else {
                this.removePrescriptionFile();
            }
            formData.append('basket_id', this.basketId);
            this.$store.dispatch('lenses/uploadPrescription', formData);
        },
    },
    watch: {
        uploadPrescriptionIsLoading(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.$store.dispatch('cart/getCart');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-prescription {
    .mixin-sideblock();

    position: relative;

    flex: 1 1 auto;
    width: 720px;
    padding: 20px 0 60px;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;

        background: @color-gray-lightest;
        &-container {
            width: 100px;
        }
    }
    &__form {
        padding: 24px;
        border-bottom: 1px solid @color-gray-darkest;
        margin-bottom: 16px;
        &-description {
            margin-bottom: 16px;
        }
        &-error {
            margin-bottom: 16px;

            color: @color-accent-warm;
        }
        &-label {
            position: relative;
            &-input {
                position: absolute;
                width: 0;
                height: 0;
                overflow: hidden;
                opacity: 0;
                pointer-events: none;
            }
        }
    }
    @media @media-sm-down {
    }
}
</style>
