<template>
    <div class="plugin-infopage-buttongroup">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'plugin-infopage-buttongroup',
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-buttongroup {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    .plugin-infopage-container + &,
    .plugin-infopage-title + &,
    .plugin-infopage-title + &:not(:first-child) {
        margin-top: 8px;
    }
    .plugin-infopage-container + .plugin-infopage-container > &:first-child {
        margin-top: 0;
    }
    &:not(:first-child) {
        margin-top: 40px;
    }
    @media @media-md-down {
        flex-direction: column;
        justify-content: flex-start;
        &:not(:first-child) {
            margin-top: 32px;
        }
        .plugin-infopage-container + &,
        .plugin-infopage-title + & {
            margin-top: 8px;
        }
        .plugin-infopage-container + .plugin-infopage-container > &:first-child {
            margin-top: 0;
        }
    }
}
</style>
