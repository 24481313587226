import { mainClient } from '~/network';
import utils from '~/utils';

const state = {
    getShippingMethodsResult: null,
    getShippingMethodsIsLoading: false,
    getShippingMethodsError: null,

    getShippingPriceResult: null,
    getShippingPriceIsLoading: false,
    getShippingPriceError: null,

    getShippingCityCdekCodeResult: null,
    getShippingCityCdekCodeIsLoading: false,
    getShippingCityCdekCodeError: null,
};

const getters = {};

const actions = {
    async getShippingMethods({ state }, payload) {
        if (state.getShippingMethodsIsLoading) {
            return;
        }

        state.getShippingMethodsIsLoading = true;

        const { data, error, status } = await mainClient.shop.getShippingMethods(payload);
        if (error !== null) {
            console.error(error, status);
            state.getShippingMethodsError = error;
            state.getShippingMethodsResult = null;
        } else {
            state.getShippingMethodsError = null;
            state.getShippingMethodsResult = data;
        }

        state.getShippingMethodsIsLoading = false;
    },
    async getShippingPrice({ state, rootState, rootGetters, dispatch, commit }, { onSuccess } = {}) {
        if (state.getShippingPriceIsLoading) {
            return;
        }

        state.getShippingPriceIsLoading = true;

        // const payload = rootGetters['checkout/customerData'];
        const payload = {
            first_name: '-',
            last_name: '-',
            line1: 'Default Address',
            line3: 'Default Country',
            line4: 'Default City',
            postcode: '10001',
            state: '',
            country: '/shop/countries/RU/',
            phone_number: '+79999999999',
        };
        let cdekCode = rootState.checkout.data.cityCdekCode;

        if (!cdekCode) {
            await dispatch('getShippingCityCdekCode', { fiasId: rootState.checkout.data.cityFiasId });
            if (state.getShippingCityCdekCodeError) {
                console.error(state.getShippingCityCdekCodeError);
                state.getShippingPriceError = 'get CDEK code error';
                state.getShippingPriceResult = null;
            } else {
                if (state.getShippingCityCdekCodeResult === null) {
                    commit('modals/push', {
                        name: 'common',
                        props: {
                            title: 'Ошибка!',
                            text: `Не удалось определить код города для расчета стоимости доставки. Пожалуйста, напишите нам в чат, мы поможем с оформлением заказа.`,
                            buttons: [
                                {
                                    text: 'Написать в чат',
                                    callback: () => {
                                        utils.common.chatraOpen({ callback: () => {
                                            utils.common.chatraSendAutoMessage('Здравствуйте! Готовы помочь с оформлением заказа и доставки');
                                        } });
                                    },
                                },
                            ],
                        },
                    }, { root: true });
                }
                cdekCode = state.getShippingCityCdekCodeResult;
                rootState.checkout.data.cityCdekCode = cdekCode;
            }
        }
        const { data, error, status } = await mainClient.shop.getShippingPrice({ ...payload, cdekCode });
        if (error !== null) {
            console.error(error, status);
            state.getShippingPriceError = error;
            state.getShippingPriceResult = null;
        } else {
            state.getShippingPriceError = null;
            state.getShippingPriceResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }

        state.getShippingPriceIsLoading = false;
    },
    async getShippingCityCdekCode({ state, rootState, rootGetters }, { fiasId, onSuccess } = {}) {
        if (state.getShippingCityCdekCodeIsLoading) {
            return;
        }

        state.getShippingCityCdekCodeIsLoading = true;

        const { data, error, status } = await mainClient.shop.getShippingCityCdekCode({ fiasId });
        if (error !== null) {
            console.error(error, status);
            state.getShippingCityCdekCodeError = error;
            state.getShippingCityCdekCodeResult = null;
        } else {
            state.getShippingCityCdekCodeError = null;
            state.getShippingCityCdekCodeResult = data;
            if (onSuccess) {
                onSuccess(data);
            }
        }

        state.getShippingCityCdekCodeIsLoading = false;
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
