export const changePassword = (data) => {
    return {
        old_password: data.password,
        new_password1: data.passwordNew,
        new_password2: data.passwordNew2,
    };
};

export const update = (data) => {
    return {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
    };
};

export const info = (data) => {
    return {
        phone: data.phone,
        value: data.phone,
        code: data.code,
    };
};

export default {
    changePassword,
    info,
    update,
};
