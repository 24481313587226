<template>
    <div class="page-checkout-step-shipping-method">
        <div class="page-checkout-step-shipping-method__options"
            v-if="availableShippingMethods.length > 0"
            v-bind:class="{ _invalid: $v.$invalid && $v.$dirty }"
            >
            <div class="page-checkout-step-shipping-method__options-item"
                v-for="(item, index) in availableShippingMethods"
                v-bind:key="index"
                v-on:click="setShippingMethod(item)"
                >
                <page-checkout-option
                    v-bind:is-invalid="$v.$invalid && $v.$dirty"
                    v-bind:item="item"
                    v-bind:is-active="checkoutData.shippingMethod && item.code === checkoutData.shippingMethod.code"
                />
            </div>
        </div>
        <div class="page-checkout-step-shipping-method__error"
            v-if="$v.$dirty && availableShippingMethods.length === 0"
            >
            Не найден подходящий способ доставки!<br />
            Пожалуйста, свяжитесь с нами для решения этой проблемы.
        </div>
        <div class="page-checkout-step-shipping-method__error"
            v-else-if="$v.$invalid && $v.$dirty"
            >
            Выберите один из вариантов
        </div>
        <!-- <div class="page-checkout-step-shipping__content-form-label"
            v-if="shippingMethod !== null && !isSelfPickup"
            >
            Заказы доставляются в будние дни с 9:00 до 20:00. Курьерская служба позвонит вам заранее.
        </div> -->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    name: 'page-checkout-step-shipping-method',
    inject: [ 'pageCheckoutEventBus', 'mode' ],
    validations() {
        return {
            checkoutData: {
                isValid: value => !!value.shippingMethod,
            },
        };
    },
    computed: {
        ...mapState('checkout', [ 'isShippingDirty' ]),
        ...mapState('shippingMethods', [ 'getShippingMethodsResult' ]),
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        availableShippingMethods() {
            if (!this.checkoutData.country) {
                return [];
            }
            const fittingShippingsRegExp = new RegExp('^' + config.fitting.shippingPrefix);
            let result = this.getShippingMethodsResult.filter(x => this.mode === 'checkout' ? !fittingShippingsRegExp.test(x.code) : fittingShippingsRegExp.test(x.code));
            if (this.checkoutData.city) {
                result = result.filter(
                    method => method.areas.find(
                        area => area.id === this.checkoutData.city.id
                    )
                );
            } else {
                result = result.filter(
                    method => method.areas.find(
                        area => area.id === this.checkoutData.country.id
                    )
                );
            }
            result.sort((a, b) => a.price > b.price ? 1 : -1);
            result.forEach(x => {
                x.optionTitle = x.name;
                x.optionSubtitle = x.priceFormatted;
                x.optionDescription = x.description;
            });
            return result;
        },
    },
    methods: {
        setShippingMethod(newMethod) {
            this.$store.commit('checkout/updateData', {
                shippingMethod: newMethod,
            });
            this.pageCheckoutEventBus.$emit('analytics-triggered', {
                step: 1,
                option: `User selected shipping method - ${newMethod?.code}`,
            });
        },
    },
    beforeMount() {
        const activeMethod = this.availableShippingMethods.find(x => x.code === this.checkoutData.shippingMethod?.code);
        if (activeMethod) {
            this.setShippingMethod(activeMethod);
        }
    },
    mounted() {
        if (this.checkoutData.shippingMethod) {
            this.pageCheckoutEventBus.$emit('analytics-triggered', {
                step: 1,
                option: `User selected shipping method - ${this.checkoutData.shippingMethod.code}`,
            });
        }
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$emit('shipping-validation', { method: true });
    },
    watch: {
        availableShippingMethods: {
            immediate: true,
            handler(newVal) {
                if (
                    newVal.length === 1 &&
                    (!this.checkoutData.shippingMethod || this.checkoutData.shippingMethod.code !== newVal[0].code)
                ) {
                    this.$store.commit('checkout/updateData', {
                        shippingMethod: newVal[0],
                    });
                    return;
                }
                if (this.checkoutData.shippingMethod && !newVal.some(x => x.code === this.checkoutData.shippingMethod.code)) {
                    this.$store.commit('checkout/updateData', {
                        shippingMethod: null,
                    });
                }
            },
        },
        '$v.$invalid': {
            immediate: true,
            handler(newVal) {
                this.pageCheckoutEventBus.$emit('shipping-validation', { method: !newVal });
            },
        },
        isShippingDirty: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                } else {
                    this.$v.$reset();
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-shipping-method {
    &__options {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
        margin-top: 4px;
        margin-bottom: 20px;
        border-top: 1px solid @color-gray-darkest;
        border-left: 1px solid @color-gray-darkest;
        &._invalid {
            border-color: @color-accent-warm;
        }
    }
    &__error {
        margin-top: 8px;

        color: @color-accent-warm;
    }
}
</style>
