<template>
    <component class="ui-trigger"
        v-bind:is="component"
        v-bind:type="type ? type : null"
        v-bind:class="{
            _disabled: isDisabled,
            _plain: isPlainText,
        }"
        v-on:click="$emit('click')"
        ><slot /></component>
</template>

<script>
export default {
    name: 'ui-trigger',
    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: null,
        },
        isPlainText: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        component() {
            if (this.isPlainText) {
                return 'span';
            }
            if (this.type === 'submit' || this.type === 'button') {
                return 'button';
            }
            return 'div';
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.ui-trigger {
    text-decoration: underline;

    cursor: pointer;

    transition-property: opacity;
    transition-duration: @duration-fast;
    transition-timing-function: @easing-default;
    &:not(._plain) {
        .typography-body-md-caps();

        display: inline-block;
        padding: 12px 10px;

        user-select: none;
    }
    &:hover {
        text-decoration: none;
    }
    // &:focus,
    &:active {
        opacity: 0.8;
    }
    &._disabled {
        opacity: 0.5;

        pointer-events: none;
    }
}
</style>
