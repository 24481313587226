<template>
    <ui-input-base-text
        v-bind:format-down="formatDown"
        v-bind:format-up="formatUp"
        v-bind:on-focus="setInitialValue"
        v-bind:is-invalid="isInvalid"
        v-bind="{ ...model }"
        v-bind:type="'tel'"
        v-on:input="inputHandler"
        inputmode="numeric"
        pattern="\\d*"
        is-phone
    />
</template>

<script>
export default {
    name: 'ui-input-phone',
    props: {
        model: {
            type: Object,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    methods: {
        formatDown(value) {
            if (value.length === 0) {
                return;
            }
            const isStrict = this.model.isStrict !== undefined ? this.model.isStrict : false;
            if (value.substring(0, 3) === '+7+') {
                value = value.substring(2);
            }
            if (isStrict || value.substring(0, 2) === '+7' || value.substring(0, 1) === '8') {
                const first3 = value.substring(0, 3);
                if (first3[0] === '+' && first3[1] !== '7') {
                    value = `+7${value.substring(1, value.length - 1)}`;
                }
                value = value.substring(0, 1) + value.substring(1).replace(/\+./g, '');
                if (first3 === '8' || first3 === '7') {
                    value = '+7';
                }
                if (first3 === '+78') {
                    value = `+7${value.substring(3)}`;
                }
                if (value.substring(0, 2) !== '+7') {
                    if (value[0] !== '+') {
                        value = `+${value}`;
                    }
                    if (value[1] !== '7') {
                        if (value[1] === '8') {
                            value = `+7${value.substring(1)}`;
                        } else {
                            value = `+7${value.substring(0)}`;
                        }
                    }
                }
                if (value.substring(0, 4) === '+7+7') {
                    value = `+7${value.substring(4)}`;
                }
                if (value.substring(0, 3) === '+78' && value.length >= 13) {
                    value = `+7${value.substring(3)}`;
                }
                value = `+${value.substring(1).replace(/[^0-9]/g, '').substring(0, 11)}`;
            } else {
                if (value[0] !== '+') {
                    value = `+${value}`;
                }
                value = value[0].replace(/[^0-9+]/g, '') + value.substring(1).replace(/[^0-9]/g, '');
                value = value.substring(0, 20);
            }
            value = [
                value.substring(0, 2),
                value.substring(2, 5),
                value.substring(5, 8),
                value.substring(8, 10),
                value.substring(10),
            ].filter(x => !!x).join(' ');
            return value;
        },
        formatUp(value) {
            let result = value.replace(/[^0-9+]/g, '');
            return result;
        },
        inputHandler(newValue) {
            this.$emit('input', newValue);
        },
        setInitialValue() {
            if (!this.value) {
                this.$emit('input', '+7');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import "~theme";
</style>
