<template>
    <div class="plugin-collections-listing-header">
        <transition name="fade">
            <div class="plugin-collections-listing-header__loader"
                v-if="isLoading"
                >
                <common-placeholder-collection-list />
            </div>
        </transition>
        <div class="plugin-collections-listing-header__list"
            ref="list"
            >
            <a class="plugin-collections-listing-header__list-item"
                v-for="item in items"
                v-bind:key="item.id"
                v-bind:href="item.url"
                v-bind:class="{ _active: item.collection_id === collectionId }"
                >
                <div class="plugin-collections-listing-header__list-item-image"
                    v-bind:style="item.style"
                />
                <div class="plugin-collections-listing-header__list-item-title"
                    v-text="item.name"
                />
            </a>
        </div>
    </div>
</template>

<script>
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'plugin-collections-listing-header',
    props: {
        collectionId: {
            type: Number,
        },
    },
    computed: {
        isLoading() {
            return this.$store.state.collections.getMenuIsLoading;
        },
        items() {
            return this.$store.state.collections.getMenuResult?.results.map(x => ({
                ...x,
                style: x.image
                    ? { backgroundImage: 'url(' + utils.common.injectResize(x.image, '224xAUTO') + ')' }
                    : null,
            }));
        },
        savedScrollId() {
            return config.localStorage.prefix + '-collections-listing-scroll';
        },
    },
    methods: {
        checkSavedScroll() {
            if (!this.$refs.list) {
                return;
            }
            const savedScroll = window.localStorage.getItem(this.savedScrollId);
            if (savedScroll) {
                this.$refs.list.scrollLeft = parseInt(savedScroll);
            }
            this.$refs.list.addEventListener('scroll', this.scrollHandler);
        },
        scrollHandler() {
            window.localStorage.setItem(this.savedScrollId, this.$refs.list.scrollLeft);
        },
    },
    beforeMount() {
        let typeToLoad = null;
        if (window.location.href.indexOf('ochki-dlya-zreniya') > -1) {
            typeToLoad = 'optics';
        } else if (window.location.href.indexOf('solncezaschitnye-ochki') > -1) {
            typeToLoad = 'sun';
        }
        if (typeToLoad) {
            this.$store.dispatch('collections/getMenu', { type: typeToLoad });
        } else {
            this.$store.dispatch('collections/getMenu');
        }
    },
    watch: {
        isLoading() {
            if (!this.isLoading) {
                this.$nextTick(this.checkSavedScroll);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-collections-listing-header {
    position: relative;

    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        width: 100%;
        height: 100%;

    }
    &__list {
        .container-paddings();

        position: relative;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1px;
        width: 100%;
        min-height: 165px;
        padding-top: 32px;
        padding-bottom: 23px;
        overflow-x: auto;

        background-color: @color-gray-lighter;
        &-item {
            flex: 0 0 auto;
            display: block;
            width: 128px;

            text-decoration: none;

            cursor: pointer;
            &:last-child {
                width: 148px;
                padding-right: 20px;
            }
            &-image {
                height: 82px;
                margin-bottom: 8px;

                background-color: @color-gray-lightest;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                box-shadow: 0 0 0 1px rgba(255, 255, 255, 0) inset;

                transition: box-shadow @duration-fast ease-in-out;
            }
            &:hover &-image,
            &._active &-image {
                box-shadow: 0 0 0 1px @color-gray-darkest inset;
            }
            &-title {
                text-align: center;
            }
            &._active &-title {
                text-decoration: underline;
            }
        }
    }
}
</style>
