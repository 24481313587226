<template>
    <div class="modal-sms-verification">
        <transition name="fade">
            <div class="modal-sms-verification__loader"
                v-if="requestCodeIsLoading || checkCodeIsLoading"
                >
                <div class="modal-sms-verification__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="modal-sms-verification__title">
            Введите код
        </div>
        <div class="modal-sms-verification__text">
            Отправили вам код для подтверждения номера телефона на {{ formattedNumber }}
        </div>
        <div class="modal-sms-verification__form"
            ref="form"
            >
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
            />
        </div>
        <div class="modal-sms-verification__error"
            v-if="error"
            v-text="error"
        />
        <div class="modal-sms-verification__request">
            <span class="modal-sms-verification__request-trigger"
                v-if="requestCodeIsAvailable"
                v-on:click="requestCode"
                >
                Получить новый код
            </span>
            <div class="modal-sms-verification__request-hint"
                v-else
                >
                Получить новый код можно через {{ cooldownFormatted }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'modal-sms-verification',
    props: {
        phone: {
            type: String,
        },
        codeLength: {
            type: Number,
            default: 4,
        },
        isAutoCheck: {
            type: Boolean,
            default: true,
        },
        callback: {
            type: Function,
            default: null,
        },
    },
    data: () => ({
        fields: {
            code: {
                ...forms.fields.smsVerificationCode,
                isAutofocus: true,
            },
        },
    }),
    validations: {
        fields: {
            code: { ...forms.validations.defaultRequired },
        },
    },
    computed: {
        ...mapState('sms', [
            'requestCodeResult',
            'requestCodeIsLoading',
            'requestCodeError',
            'requestCodeIsAvailable',
            'requestCodeTimer',

            'checkCodeResult',
            'checkCodeIsLoading',
            'checkCodeError',
        ]),
        formattedNumber() {
            return utils.formats.formatPhone(this.phone);
        },
        cooldownFormatted() {
            const cooldown = Math.round(this.requestCodeTimer / 1000);
            const minutes = Math.floor(cooldown / 60);
            const seconds = cooldown - minutes * 60;
            return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
        error() {
            if (this.requestCodeError) {
                return 'Произошла ошибка, повторите попытку позже';
            }
            if (this.checkCodeError) {
                return 'Неправильный код. Проверьте ещё раз или получите новый код';
            }
            return null;
        },
    },
    methods: {
        requestCode() {
            if (!this.requestCodeIsAvailable) {
                return;
            }
            this.fields.code.value = '';
            this.$store.commit('sms/resetErrors');
            const onComplete = () => {
                if (this.$refs.form) {
                    this.$refs.form.querySelector('input.ui-input-base-text').focus();
                }
            };
            if (window.grecaptcha && window.grecaptcha.execute) {
                window.onCaptchaResult = (token) => {
                    this.$store.dispatch('sms/requestCode', { phone: this.phone.replace(/[^0-9+]/g, ''), recaptcha: token, onComplete });
                    window.onCaptchaResult = undefined;
                };
                window.grecaptcha.execute();
            } else {
                this.$store.dispatch('sms/requestCode', { phone: this.phone.replace(/[^0-9+]/g, ''), onComplete });
            }
        },
        updateFormData(newData) {
            this.fields = newData;
        },
        submitHandler() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store.dispatch('sms/checkCode', {
                code: this.fields.code.value,
                phone: this.phone.replace(/[^0-9+]/g, ''),
            });
        },
    },
    beforeMount() {
        this.fields.code.maxLength = this.codeLength;
    },
    mounted() {
        this.requestCode();
    },
    watch: {
        checkCodeResult(newVal) {
            if (newVal) {
                if (this.callback) {
                    this.callback(this.fields.code.value);
                }
                this.$store.commit('modals/pop');
            }
        },
        'fields.code.value'(newVal) {
            if (this.isAutoCheck &&
                this.codeLength &&
                newVal &&
                newVal.length === this.codeLength) {
                this.submitHandler();
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-sms-verification {
    .mixin-modal();

    position: relative;

    max-width: 460px;
    padding: 40px;

    box-shadow: 0 0 60px 0 @color-accent-cold;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: @color-gray-lightest;
        &-container {
            width: 50px;
        }
    }
    &__title {
        margin-bottom: 12px;

        text-transform: uppercase;
    }
    &__text {
        max-width: 425px;
        margin-bottom: 36px;
    }
    &__form {
        width: 100%;
        max-width: 240px;
        margin-bottom: 36px;
    }
    &__error {
        margin-bottom: 36px;

        color: @color-accent-warm;
    }
    &__request {
        &-trigger {
            text-decoration: underline;

            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
    }
    @media @media-md-down {
        &__title {
            margin-bottom: 9px;
        }
        &__text {
            font-size: 1.2rem;
            line-height: 2rem;
        }
    }
}
</style>
