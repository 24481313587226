<template>
    <div>
        plugin-collections-headline
    </div>
</template>

<script>

export default {
    name: 'plugin-collections-headline',
    props: {
        buttonsData: {
            type: String,
            default: null,
        },
    },
    computed: {
        buttons() {
            if (this.buttonsData === null) {
                return [];
            }
            return JSON.parse(this.buttonsData.replace(/'/g, '"')).map(x => {
                const button = { title: x.title };
                const [ code, id ] = x.filter.split('=');
                button.filter = { code, id: parseInt(id, 10) };
                return button;
            });
        },
    },
    methods: {
        toggleFilter(filter) {
            if (this.checkFilterActivity(filter)) {
                this.$store.commit('filters/setFilter', { setCode: filter.code, filterIds: [] });
            } else {
                this.$store.commit('filters/setFilter', { setCode: filter.code, filterIds: [ filter.id ] });
            }
        },
        checkFilterActivity(filter) {
            return this.$store.getters['filters/isFilterActive'](filter.code, filter.id);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-collections-headline {
    .container();
    .typography-body-md();

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 40px 20px 20px;
    &__main {
        flex: 1 1 auto;
        max-width: 700px;
        padding-right: 20px;
        &-title {
            margin: 20px 0 12px;

            font-size: 2.8rem;
            line-height: 4rem;
            text-transform: uppercase;
        }
        &-description {
            margin: 0 0 40px;
        }
        &-buttons {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            &-item {
                flex: 0 1 140px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 41px;
                border-top: 1px solid @color-gray-darkest;
                border-bottom: 1px solid @color-gray-darkest;
                border-left: 1px solid @color-gray-darkest;

                text-align: center;

                cursor: pointer;

                transition: background-color @duration-faster @easing-default;
                &._active,
                &:active {
                    background-color: @color-accent-warm;
                }
                &:last-child {
                    border-right: 1px solid @color-gray-darkest;
                }
            }
        }
    }
    &__image {
        flex: 0 0 auto;
        width: 560px;

        background-color: @color-gray-lighter;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &:before {
            content: '';

            display: block;
            width: 100%;
            padding-top: 50%;
        }
    }
    @media @media-lg-down {
        padding: 20px;
        &__image {
            max-width: 50%;
        }
    }
    @media @media-sm-down {
        flex-direction: column;
        padding: 0;
        &__main {
            padding: 0;
            max-width: 100%;
            &-title {
                padding: 0 20px;
                margin-top: 0;
                margin-bottom: 24px;
            }
            &-description {
                padding: 0 20px;
                margin-bottom: 24px;
            }
            &-buttons {
                width: 100%;
                overflow: hidden;
                &-item {
                    flex: 1 1 100%;
                    &:first-child {
                        border-left: none;
                    }
                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
        &__image {
            order: -1;
            width: ~'calc(100% - 16px)';
            max-width: 100%;
            margin: 8px 8px 24px;
        }
    }
}
</style>
