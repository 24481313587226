const state = {
    contacts: {
        phone: {
            value: '+78005552497',
            valueFormatted: '+7 (800) 555-24-97',
            worktime: 'Пн‑Пт 12:00‑20:00',
        },
        app: {
            apple: {
                title: 'pyeapp',
                icon: 'appstore',
                href: 'http://apps.apple.com/ru/app/pye/id1538573783',
            },
        },
        messengers: [
            {
                title: 'whatsapp',
                icon: 'whatsapp',
                href: 'https://wa.me/79219407325',
            },
            {
                title: 'telegram',
                icon: 'telegram',
                href: 'https://t.me/pyeonline',
            },
        ],
        socials: [
            {
                title: 'telegram',
                icon: 'telegram',
                href: 'https://t.me/pyeoptics',
            },
            {
                title: 'vkontakte',
                icon: 'vkontakte',
                href: 'https://vk.com/pyeoptics',
            },
            // {
            //     title: 'instagram',
            //     icon: 'instagram',
            //     href: 'https://www.instagram.com/pyeoptics',
            // },
            // {
            //     title: 'facebook',
            //     icon: 'facebook',
            //     href: 'https://www.facebook.com/pyeoptics',
            // },
        ],
    },
    currency: {
        name: {
            full: 'российский рубль',
            normal: 'рубль',
            short: 'руб.',
        },
        symbol: 'Р',
    },
    company: {
        tin: 0,
        type: '',
        name: '',
    },
};

const getters = {
    contacts(state) {
        return state.contacts;
    },
    currency(state) {
        return state.currency;
    },
    company(state) {
        return state.company;
    },
};

const actions = {
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
