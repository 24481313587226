<template>
    <section class="plugin-home-hero">
        <slot />
    </section>
</template>

<script>
export default {
    name: 'plugin-home-hero',
    inject: [ 'staticPathRoot' ],
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-home-hero {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    &__video {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -10;

        width: 1px;
        height: 1px;
        overflow: hidden;

        opacity: 0;
        pointer-events: none;
        &-sample {
            width: 100px;
            display: block;
        }
    }
}
</style>
