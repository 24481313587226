<template>
    <section class="page-giftcards-items-list">
        <div class="page-giftcards-items-list__list">
            <transition name="fade">
                <div class="page-giftcards-items-list__list-loader"
                    v-if="isLoading"
                    >
                    <div class="page-giftcards-items-list__list-loader-container">
                        <ui-loader />
                    </div>
                </div>
            </transition>
            <div class="page-giftcards-items-list__list-item"
                v-for="(item, i) in items"
                v-bind:key="i"
                v-bind:style="{ color: item.color }"
                v-on:click="clickHandler(item)"
                v-bind:ref="'item' + i"
                >
                <div class="page-giftcards-items-list__list-item-icon"
                    v-text="'+'"
                />
                <div class="page-giftcards-items-list__list-item-icon _hover">
                    <icon name="check" />
                </div>
                <div class="page-giftcards-items-list__list-item-text"
                    v-text="item.titleFormatted"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { mainClient } from '~/network';
import { productClick } from '~/utils/ecommerce';
import { ImpressionController } from '~/utils/analytics';
import { mapState } from 'vuex';

export default {
    name: 'page-giftcards-items-list',
    inject: ['type', 'requestUrl'],
    data: () => ({
        isLoading: false,
        items: [],
        colors: {
            5000: '#FFD43D',
            7000: '#E2663B',
            10000: '#4A90E2',
            15000: '#C2B455',
            // 7000: '#FFD43D',
            // 10000: '#E2663B',
            // 15000: '#4A90E2',
            // 1: '#C2B455',
            1: '#FFD43D',
        },
        impressionControllers: [],
    }),
    computed: {
        ...mapState('cart', [ 'getCartResult' ]),
    },
    methods: {
        async getData() {
            this.isLoading = true;
            const { data, error } = await mainClient.shop.getCertificates({ url: this.requestUrl });
            if (error) {
                console.error(error);
            } else {
                const result = data.items
                    .map(x => {
                        const priceParsed = x.price.value;
                        const result = {
                            ...x,
                            titleFormatted: priceParsed === 1 ? 'Другая сумма' : priceParsed + ' ₽',
                            priceParsed,
                            color: this.colors[priceParsed] || this.colors.other,
                            isCustom: priceParsed === 1,
                        };
                        return result;
                    })
                    .sort((a, b) => {
                        if (a.isCustom) {
                            return 1;
                        }
                        if (b.isCustom) {
                            return -1;
                        }
                        return a.priceParsed > b.priceParsed ? 1 : -1;
                    });
                this.items = result;
            }
            this.isLoading = false;
            this.$nextTick(this.activateImpressionControllers);
        },
        clickHandler(item) {
            if (this.getCartResult && this.getCartResult.lines.length > 0) {
                this.$store.commit('modals/push', {
                    name: 'common',
                    props: {
                        title: 'НЕ МОЖЕМ ДОБАВИТЬ СЕРТИФИКАТ, КОГДА В КОРЗИНЕ ЕСТЬ ДРУГИЕ ТОВАРЫ',
                        text: 'Извините, в корзине может быть либо один сертификат либо другие товары. Пожалуйста, удалите другие товары из корзины перед добавлением сертификата.',
                        buttons: [
                            {
                                text: 'Открыть корзину',
                                callback: () => { this.$store.commit('sideblocks/push', 'cart'); },
                            },
                        ],
                    },
                });
                return;
            }
            item.ecommerce && productClick({
                listId: 'catalog_gift_cards',
                listTitle: 'Подарочные сертификаты',
                product: item.ecommerce,
            });
            this.$emit('step-change', { newStep: 'form', item });
        },
        activateImpressionControllers() {
            this.items.forEach((x, i) => {
                x.ecommerce && this.impressionControllers.push(new ImpressionController(this.$refs['item' + i][0], {
                    listId: 'catalog_gift_cards',
                    listTitle: 'Подарочные сертификаты',
                    product: x.ecommerce,
                }));
            });
        },
        destroyImpressionControllers() {
            this.impressionControllers.forEach(x => {
                x.destroy();
            });
            this.impressionControllers = [];
        },
    },
    mounted() {
        this.getData();
    },
    beforeDestroy() {
        this.destroyImpressionControllers();
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-giftcards-items-list {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    background: @color-gray-main;
    &__list {
        position: relative;

        flex: 0 0 auto;
        display: grid;
        grid-template: auto / repeat(4, 1fr);
        gap: 1px;
        width: 100%;
        aspect-ratio: 4;
        border-top: 1px solid @color-gray-lightest;
        border-bottom: 1px solid @color-gray-lightest;

        background-color: @color-gray-lightest;
        &-loader {
            .transition-fade();

            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            background: @color-gray-main;
            &-container {
                width: 100px;
            }
        }
        &-item {
            position: relative;

            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            background: @color-gray-main;

            cursor: pointer;
            &-text {
                .typography-display-md();

                position: static;
                z-index: 1;

                color: @color-gray-darkest;

                transition: color @duration-normal @easing-default;
            }
            &-icon {
                position: absolute;
                top: 12px;
                left: 20px;
                z-index: 1;

                color: @color-gray-darkest;
                font-size: 2.8rem;
                line-height: 3.6rem;
                &._hover {
                    top: 16px;
                    left: 16px;

                    display: none;

                    font-size: 2.2rem;
                }
            }
            &::before,
            &::after {
                content: '';

                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

                width: 30px;
                height: 30px;
                border-radius: 50%;

                background-color: currentColor;
                opacity: 0;

                transition: opacity @duration-normal @easing-default;
            }
            &::before {
                opacity: 1;
                box-shadow: 0 0 6vw 6vw currentColor;
            }
            &::after {
                opacity: 0;
                box-shadow: 0 0 2vw 7vw currentColor;
            }
            @media (hover) {
                &:hover::before {
                    opacity: 0;
                }
                &:hover::after {
                    opacity: 1;
                }
                &:hover &-text {
                    color: @color-gray-lightest;
                }
                &:hover &-icon {
                    display: none;
                    &._hover {
                        display: block;
                    }
                }
            }
        }
    }
    @media @media-sm-down {
        &__list {
            aspect-ratio: 1;
            grid-template: auto / repeat(2, 1fr);
            &-item {
                &::before {
                    box-shadow: 0 0 12vw 12vw currentColor;
                }
                &::after {
                    box-shadow: 0 0 4vw 14vw currentColor;
                }
                & ~ & {
                    border-left: none;
                }
                &:nth-child(2) ~ & {
                    border-top: 1px solid @color-gray-lightest;
                }
                &:nth-child(2n) {
                    border-left: 1px solid @color-gray-lightest;
                }
            }
        }
    }
    @media @media-xs-down {
        &__list {
            aspect-ratio: unset;
            grid-template: auto / repeat(1, 1fr);
            &-item {
                aspect-ratio: 1;
                &::before {
                    box-shadow: 0 0 24vw 24vw currentColor;
                }
                &::after {
                    box-shadow: 0 0 8vw 28vw currentColor;
                }
                &:nth-child(2n) {
                    border-left: none;
                }
                &:nth-child(1) ~ & {
                    border-top: 1px solid @color-gray-lightest;
                }
            }
        }
    }
}
</style>
