var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common-catalog-filters",class:{ _active: _vm.isActive }},[_c('div',{staticClass:"common-catalog-filters__headline"},[_c('div',{staticClass:"common-catalog-filters__headline-amount"},[_vm._v(" Оправ: "),_c('span',{staticClass:"common-catalog-filters__headline-amount-value",domProps:{"textContent":_vm._s(_vm.productsCount !== null ? _vm.productsCount : '∞')}})]),_c('div',{staticClass:"common-catalog-filters__headline-trigger",on:{"click":_vm.toggle}},[_c('span',{staticClass:"common-catalog-filters__headline-trigger-title",domProps:{"textContent":_vm._s('фильтр')}}),(_vm.count > 0)?_c('span',{staticClass:"common-catalog-filters__headline-trigger-count",domProps:{"textContent":_vm._s(_vm.count)}}):_vm._e(),_c('span',{staticClass:"common-catalog-filters__headline-trigger-arrow",domProps:{"textContent":_vm._s('↓')}})]),(!_vm.isFittingCheckboxDisabled)?_c('div',{staticClass:"common-catalog-filters__headline-fitting"},[_c('common-catalog-filters-fitting')],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.filterItems !== null && _vm.isActive)?_c('div',{staticClass:"common-catalog-filters__content"},[_c('div',{staticClass:"common-catalog-filters__content-list",class:{ _extended: _vm.isListExtended }},_vm._l((_vm.filterItems),function(filterItem,i){return _c('div',{key:i,staticClass:"common-catalog-filters__content-list-item",class:{
                        _wide: filterItem.code === 'color',
                        _small: filterItem.code === 'size',
                    }},[_c('div',{staticClass:"common-catalog-filters__content-list-item-title",domProps:{"textContent":_vm._s(filterItem.title)}}),_c('div',{staticClass:"common-catalog-filters__content-list-item-options"},_vm._l((filterItem.values),function(value,i){return _c('div',{key:i,staticClass:"common-catalog-filters__content-list-item-options-item",class:{
                                _light: value.isLight,
                                _active: _vm.activeFilters[filterItem.code] && _vm.activeFilters[filterItem.code].indexOf(value.id) > -1,
                            },on:{"click":function($event){return _vm.toggleValue(filterItem.code, value.id)}}},[_c('div',{staticClass:"common-catalog-filters__content-list-item-options-item-background",style:({
                                    backgroundColor: value.color !== null ? ("#" + (value.color)) : null,
                                    backgroundImage: value.image !== null ? ("url(" + (value.image) + ")") : null,
                                })}),_c('span',{staticClass:"common-catalog-filters__content-list-item-options-item-text",domProps:{"textContent":_vm._s(value.title)}})])}),0)])}),0),(!_vm.isFittingCheckboxDisabled)?_c('div',{staticClass:"common-catalog-filters__content-fitting"},[_c('common-catalog-filters-fitting')],1):_vm._e(),_c('div',{staticClass:"common-catalog-filters__content-actions"},[_c('div',{staticClass:"common-catalog-filters__content-actions-reset",on:{"click":_vm.resetFilters}},[_c('span',{staticClass:"common-catalog-filters__content-actions-reset-text"},[_vm._v("Очистить фильтры")]),(_vm.count > 0)?_c('span',{staticClass:"common-catalog-filters__content-actions-reset-count",domProps:{"textContent":_vm._s(_vm.count)}}):_vm._e()]),_c('div',{staticClass:"common-catalog-filters__content-actions-apply",on:{"click":_vm.toggle}},[_vm._v(" Применить фильтры ")])]),_c('div',{staticClass:"common-catalog-filters__content-close",on:{"click":_vm.toggle}},[_c('icon',{attrs:{"name":"close"}})],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }