<template>
    <div class="sideblock-fitting-product">
        <a class="sideblock-fitting-product__content"
            v-bind:href="line.product.url"
            v-bind:style="background"
            >
            <div class="sideblock-fitting-product__content-title"
                v-text="line.product.title"
            />
        </a>
        <div class="sideblock-fitting-product__close"
            v-on:click="removeFromCart"
            >
            <icon name="close" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'sideblock-fitting-product',
    props: {
        line: {
            type: Object,
            default: null,
        },
    },
    computed: {
        background() {
            if (this.line.product.images[0]) {
                return { backgroundImage: `url(${this.line.product.images[0].medium})` };
            } else {
                return null;
            }
        },
    },
    methods: {
        removeFromCart() {
            this.$store.dispatch('fitting/removeFromCart', { url: this.line.url, product: this.line.product });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-fitting-product {
    position: relative;

    width: 100%;
    height: 100%;
    &__content {
        .mixin-background-image();

        display: block;
        width: 100%;
        height: 100%;

        text-decoration: none;
        &-title {
            padding: 20px 48px 20px 16px;
            text-transform: uppercase;
        }
    }
    &__close {
        position: absolute;
        top: 0;
        right: 0;

        padding: 21px 23px;

        font-size: 1rem;

        cursor: pointer;
    }
    @media @media-md-down {
        &__content {
            &-title {
                padding: 12px 41px 12px 12px;
            }
        }
        &__close {
            padding: 15px 17px;

            font-size: 0.8rem;
        }
    }
}
</style>
