<template>
    <section class="page-password-reset">
        <div class="page-password-reset__spacer" />
        <div class="page-password-reset__form"
            v-if="!isReset"
            >
            <div class="page-password-reset__title">
                Забыли пароль?
            </div>
            <div class="page-password-reset__form-fields">
                <ui-form
                    v-bind:model="fields"
                    v-bind:validation="$v"
                    v-on:update="updateHandler"
                    v-bind:submit-handler="submitHandler"
                />
            </div>
            <div class="page-password-reset__form-error"
                v-if="error !== null"
                v-text="error"
            />
            <div class="page-password-reset__form-button">
                <ui-button
                    v-on:click="submitHandler"
                    v-bind:class="{ _disabled: isLoading }"
                    v-bind:disabled="isLoading"
                    is-uppercase
                    type="submit"
                    >
                    восстановить пароль
                </ui-button>
            </div>
        </div>
        <div class="page-password-reset__result"
            v-if="isReset"
            >
            <div class="page-password-reset__title">
                МЫ ОТПРАВИЛИ ВАМ ПИСЬМО НА АДРЕС
                <br/>
                {{ fields.email.value }}
            </div>
            <div class="page-password-reset__text _center">
                Пожалуйста, пройдите по ссылке из письма, чтобы установить новый пароль.
            </div>
        </div>
    </section>
</template>

<script>
import forms from '~/forms';

export default {
    name: 'page-password-reset',
    data: () => ({
        fields: {
            email: { ...forms.fields.email },
        },
    }),
    validations: {
        fields: {
            email: { ...forms.validations.email },
        },
    },
    computed: {
        isLoading() {
            return this.$store.getters['user/state'].resetPasswordIsLoading;
        },
        error() {
            if (!this.$v.$dirty || !this.$v.$invalid) {
                return null;
            }
            return this.$store.getters['user/state'].resetPasswordError;
        },
        isReset() {
            return this.$store.getters['user/state'].resetPasswordIsReset;
        },
    },
    methods: {
        updateHandler(newData) {
            this.fields = {
                ...this.fields,
                ...newData,
            };
        },
        submitHandler() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$store.dispatch('user/resetPassword', { email: this.fields.email.value });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-password-reset {
    .typography-body-md();

    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: flex-start;
    width: 100%;
    min-height: ~'calc(100vh - 48px)';
    padding: 40px 0;
    &__spacer {
        flex: 1 1 auto;
        max-height: 132px;
    }
    &__title {
        margin-bottom: 20px;

        text-align: center;
        text-transform: uppercase;
    }
    &__text {
        &._center {
            text-align: center;
        }
    }
    &__form {
        width: 100%;
        max-width: 460px;
        margin: 0 auto;

        flex: 0 0 auto;
        &-fields {
            margin-bottom: 20px;
        }
        &-error {
            margin-bottom: 20px;
        }
        &-button {
            height: 48px;
        }
    }
    &__result {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;

        flex: 0 0 auto;
    }
    @media @media-xs-down {
        &__form,
        &__result {
            max-width: 100%;
            padding: 0 20px;
        }
    }
}
</style>
